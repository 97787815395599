import React, { Fragment, useRef } from 'react';
import { useWindowInfo } from '@faceless-ui/window-info';
import { Modal, ModalToggler } from '@faceless-ui/modal';
import PropTypes from 'prop-types';
import CloseIcon from '../../../icons/CloseIcon';
import useStyles from './css';

const VideoModal = (props) => {
  const {
    modalSlug,
    label,
    videoID,
  } = props;
  const ref = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const window = useWindowInfo();

  const classes = useStyles();

  const iframeStyle = {};

  if (ref.current) {
    const width = ref.current.offsetWidth;
    const height = ref.current.offsetHeight;

    if (width > height) {
      iframeStyle.height = '100%';
      iframeStyle.width = height * 1.7777;
    } else {
      iframeStyle.width = '100%';
      iframeStyle.height = width * 0.5625;
    }
  }

  return (
    <Modal
      slug={modalSlug}
      className={classes.modal}
    >
      {({ isOpen }) => (
        <Fragment>
          <div className={classes.content}>
            <ModalToggler
              className={classes.modalToggler}
              slug={modalSlug}
            >
              <CloseIcon color="white" />
            </ModalToggler>
            <h1 className={classes.title}>
              {`${label} Sign System`}
            </h1>
            <div
              className={classes.ref}
              ref={ref}
            >
              <div
                className={classes.iframeWrapper}
                style={iframeStyle}
              >
                <iframe
                  className={classes.iframe}
                  title={modalSlug}
                  src={isOpen ? `https://www.youtube.com/embed/${videoID}?autoplay=1` : undefined}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  muted
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

VideoModal.defaultProps = {
  modalSlug: undefined,
  label: undefined,
  videoID: undefined,
};

VideoModal.propTypes = {
  modalSlug: PropTypes.string,
  label: PropTypes.string,
  videoID: PropTypes.string,
};

export default VideoModal;
