import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import formatDateTime from '../../utilities/formatDateTime';

const initialDownloadAttributes = {
  download: 'Untitled',
  href: undefined,
};

const HiddenDownloadButton = (props) => {
  const {
    name,
    href: hrefFromProps,
    onDownload,
  } = props;

  const anchorRef = useRef();
  const [downloadAttributes, setDownloadAttributes] = useState(initialDownloadAttributes);

  useEffect(() => {
    if (hrefFromProps) {
      setDownloadAttributes({
        download: `My290-${name ? `${name} ` : ''}${formatDateTime()}.png`,
        href: hrefFromProps,
      });
    }
  }, [hrefFromProps, name]);

  // needs to be a separate effect because 'anchorRef.current.click()' depends on an updated DOM (button with attributes)
  useEffect(() => {
    const { href } = downloadAttributes;
    if (href) {
      anchorRef.current.click();
      setDownloadAttributes(initialDownloadAttributes);
      if (typeof onDownload === 'function') onDownload();
    }
  }, [downloadAttributes, onDownload]);

  return (
    <a
      role="button"
      tabIndex={0}
      {...downloadAttributes || {}}
      style={{ display: 'none' }}
      ref={anchorRef}
    >
      Download
    </a>
  );
};

HiddenDownloadButton.defaultProps = {
  href: undefined,
  name: undefined,
  onDownload: undefined,
};

HiddenDownloadButton.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  onDownload: PropTypes.func,
};

export default HiddenDownloadButton;
