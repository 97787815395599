import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useAuthentication } from '../../../wrappers/Authentication';
import MarginGrid from '../../../components/MarginGrid';
import Card from '../../../components/Card';
import useAPI from '../../../utilities/data/useAPI';
import useStyles from './css';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import defaultImage from '../../../../assets/images/my290-default-image.png';

const Results = (props) => {
  const { allCategories } = props;
  const { search } = useLocation();
  const { categories: categoriesFromURL, page = 1 } = qs.parse(search, { ignoreQueryPrefix: true });
  const urlHasCategories = Array.isArray(categoriesFromURL) && categoriesFromURL.length > 0;

  const { user } = useAuthentication();
  const classes = useStyles();

  const apiSearchQuery = {
    where: {
      or: [],
      and: [{
        organization: {
          equals: user.organization.id,
        },
      }],
    },
    page,
    sort: '-sortPriority',
  };

  if (urlHasCategories) {
    categoriesFromURL.forEach((urlCat) => {
      const fullCategory = allCategories?.find((item) => item.slug === urlCat);
      if (fullCategory) {
        const { id: categoryID } = fullCategory;
        apiSearchQuery.where.or = [
          ...apiSearchQuery.where.or,
          {
            categories: {
              equals: categoryID,
            },
          },
        ];
      }
    });
  }

  const apiSearchQueryString = qs.stringify(apiSearchQuery, { addQueryPrefix: true, encode: false });

  const [{ res: productsRes, isLoading }] = useAPI('get', `${process.env.API_URL}/api/products${apiSearchQueryString}`);

  if (isLoading || productsRes === undefined) {
    return (
      <div className={classes.results}>
        <Loader absolute />
      </div>
    );
  }

  if (!productsRes?.json?.docs.length > 0) return <h2>No results</h2>;

  return (
    <Fragment>
      <MarginGrid
        className={classes.results}
        overflowMobile
        staticColumns
      >
        {productsRes.json.docs.map((product, i) => {
          const {
            id,
            name,
            price,
            images,
          } = product;

          return (
            <Link
              key={i}
              to={`/store/${id}`}
              className={classes.cardWrapper}
            >
              <Card
                image={images?.[0]?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${images[0].image.sizes.card.filename}` : defaultImage}
                className={classes.card}
                title={name}
                price={price}
              />
            </Link>
          );
        })}
      </MarginGrid>
      {productsRes?.json && (
        <Pagination
          limit={productsRes.json.limit}
          totalPages={productsRes.json.totalPages}
          page={productsRes.json.page}
          hasPrevPage={productsRes.json.hasPrevPage}
          hasNextPage={productsRes.json.hasNextPage}
          prevPage={productsRes.json.prevPage}
          nextPage={productsRes.json.nextPage}
        />
      )}
    </Fragment>
  );
};


Results.defaultProps = {
  allCategories: undefined,
};

Results.propTypes = {
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default Results;
