import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ModalProvider, ModalContainer } from '@faceless-ui/modal';
import { WindowInfoProvider } from '@faceless-ui/window-info';
import { ScrollInfoProvider } from '@faceless-ui/scroll-info';
import { CSSFontFaces as FontsForRenderer } from '@two90signs/290-renderer';
import Dashboard from './views/Dashboard';
import Projects from './views/Projects';
import Product from './views/Product';
import Store from './views/Store';
import MyProducts from './views/MyProducts';
import NewProject from './views/NewProject';
import Project from './views/Project';
import Header from './components/Header';
import GlobalCSS from './wrappers/GlobalCSS';
import Authentication from './wrappers/Authentication';
import Account from './views/Account';
import Cart from './views/Cart';
import AuthReminder from './components/AuthReminder';
import ResetPassword from './views/ResetPassword';
import SetNewPassword from './views/SetNewPassword';
import SignUp from './views/SignUp';
import LogIn from './views/LogIn';
import Help from './views/Help';
import NotFound from './views/NotFound';
import Checkout from './views/Checkout';
import OrderConfirmation from './views/OrderConfirmation';
import CartProvider from './wrappers/CartProvider';
import OnRouteChange from './components/OnRouteChange';
import Init from './wrappers/Init';
import View from './wrappers/View';
import FixedBackground from './components/FixedBackground';
import AddSign from './views/AddSign';
import EditTheme from './views/EditTheme';
import EditSign from './views/EditSign';
import BreadcrumbsProvider from './wrappers/BreadcrumbsProvider';
import OnboardingProvider from './wrappers/Onboarding';
import vars, { breakpoints } from './css/vars';
import ActionConfirmation from './wrappers/ActionConfirmation';
import PrintSign from './views/PrintSign';
import PrintProject from './views/PrintProject';
import AlreadyLoggedIn from './views/AlreadyLoggedIn';
import LoggedOut from './views/LoggedOut';
import RestoreScrollPosition from './components/RestoreScrollPosition';
import GoogleAnalytics from './components/GoogleAnalytics';
import Notifications from './wrappers/Notifications';
import NoOrganization from './views/NoOrganization';

require('dotenv').config();

console.log('%cIt\'s a sign...designed and built by %cTRBL %c(https://trbl.design)', 'font-weight: bolder;', 'font-weight: bolder; color: #ff4553;', 'font-weight: bolder;'); // eslint-disable-line no-console

const App = () => (
  <BrowserRouter>
    <FontsForRenderer />
    <ScrollInfoProvider>
      <WindowInfoProvider
        breakpoints={{
          xs: `(max-width: ${breakpoints.xs}px)`,
          s: `(max-width: ${breakpoints.s}px)`,
          m: `(max-width: ${breakpoints.m}px)`,
          l: `(max-width: ${breakpoints.l}px)`,
          xl: `(max-width: ${breakpoints.xl}px)`,
        }}
      >
        <GlobalCSS>
          <ModalProvider
            classPrefix="my290"
            transTime={vars.defaultTransTime}
          >
            <ActionConfirmation>
              <BreadcrumbsProvider>
                <OnRouteChange />
                <RestoreScrollPosition localStorageKey="scrollPositions" />
                <Notifications>
                  <OnboardingProvider>
                    <CartProvider>
                      <Authentication>
                        <FixedBackground />
                        <Header />
                        <Init>
                          <View>
                            <GoogleAnalytics />
                            <Switch>
                              <Route
                                exact
                                path="/"
                              >
                                <Dashboard />
                              </Route>
                              <Route path="/login">
                                <LogIn />
                              </Route>
                              <Route path="/logged-out">
                                <LoggedOut />
                              </Route>
                              <Route path="/already-logged-in">
                                <AlreadyLoggedIn />
                              </Route>
                              <Route path="/signup">
                                <SignUp />
                              </Route>
                              <Route path="/reset-password">
                                <ResetPassword />
                              </Route>
                              <Route path="/set-new-password/:token">
                                <SetNewPassword />
                              </Route>
                              <Route path="/cart">
                                <Cart />
                              </Route>
                              <Route path="/checkout">
                                <Checkout />
                              </Route>
                              <Route path="/help">
                                <Help />
                              </Route>
                              <Route path="/account">
                                <Account />
                              </Route>
                              <Route path="/store/:productID">
                                <Product />
                              </Route>
                              <Route path="/store">
                                <Store />
                              </Route>
                              <Route path="/my-products">
                                <MyProducts />
                              </Route>
                              <Route path="/projects">
                                <Projects />
                              </Route>
                              <Route path="/project/:projectID/print">
                                <PrintProject />
                              </Route>
                              <Route path="/project/:projectID/edit">
                                <EditTheme />
                              </Route>
                              <Route path="/project/:projectID/add-sign">
                                <AddSign />
                              </Route>
                              <Route path="/project/:projectID/:signID/print">
                                <PrintSign />
                              </Route>
                              <Route path="/project/:projectID/:signID">
                                <EditSign />
                              </Route>
                              <Route path="/project/:projectID">
                                <Project />
                              </Route>
                              <Route path="/new-project">
                                <NewProject />
                              </Route>
                              <Route path="/order-confirmation">
                                <OrderConfirmation />
                              </Route>
                              <Route path="/no-organization">
                                <NoOrganization />
                              </Route>
                              <Route path="/not-found">
                                <NotFound />
                              </Route>
                              <Route>
                                <NotFound />
                              </Route>
                            </Switch>
                          </View>
                        </Init>
                        <AuthReminder />
                      </Authentication>
                    </CartProvider>
                  </OnboardingProvider>
                </Notifications>
              </BreadcrumbsProvider>
            </ActionConfirmation>
            <ModalContainer />
          </ModalProvider>
        </GlobalCSS>
      </WindowInfoProvider>
    </ScrollInfoProvider>
  </BrowserRouter>
);

export default App;
