import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { base } from '../../../css/vars';
import { small } from '../../../css/type';

const useStyles = createUseStyles({
  errors: {
    extend: small,
    marginTop: base(0.25),
  },
});

const defaultMessages = {
  required: 'This field is required.',
};

const Error = (props) => {
  const { types } = props;
  const classes = useStyles();

  if (Object.keys(types)?.length > 0) {
    return (
      <div className={classes.errors}>
        {Object.keys(types).map((typeName, index) => {
          const incomingMessage = types[typeName];
          const defaultMessage = defaultMessages[typeName];
          return <div key={index}>{(typeof incomingMessage === 'string' && incomingMessage) || defaultMessage}</div>;
        })}
      </div>
    );
  }
  return null;
};


Error.defaultProps = {
  types: {},
};

Error.propTypes = {
  types: PropTypes.shape({}),
};

export default Error;
