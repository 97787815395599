import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

const { copyCases } = specSheets;

const allEntries = Object.entries(copyCases);
const compliantEntries = Object.entries({ AC: copyCases.AC });

const InsertCopyCases = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    insertType,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();

  let allowedCopyCases = allEntries;
  if (insertType === 'ADA') allowedCopyCases = compliantEntries;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertCopyCases}>
        <SpecHeader
          label={headerLabel || 'Insert Copy Case'}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          {allowedCopyCases.map(([id, copyCase], index) => {
            const { label } = copyCase;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'copyCase',
                  label,
                  value: id,
                  onChange,
                  checked: id === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

InsertCopyCases.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  insertType: undefined,
  headerLabel: undefined,
  specId: undefined,
};

InsertCopyCases.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  insertType: PropTypes.string,
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default InsertCopyCases;
