import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import Button from '../../../components/Button';
import executeCondition from './executeCondition';
import defaultImage from '../../../../assets/images/my290-default-image.png';
import useStyles from './css';

const ImageSlider = (props) => {
  const {
    images,
    selectedAttributes,
  } = props;

  const classes = useStyles();
  const [mainSliderRef, setMainSliderRef] = useState(null);
  const [thumbnailSliderRef, setThumbnailSliderRef] = useState(null);
  const [imagesWithConditions, setImagesWithConditions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesToRender, setImagesToRender] = useState(undefined);

  useEffect(() => {
    let hasChanged = false;

    if (imagesWithConditions && imagesWithConditions.length > 0) {
      const imagesWithNewConditions = imagesWithConditions.map((imageObject) => {
        const { image, conditionalLogic, passesConditions } = imageObject;
        let passesNewConditions = true;

        const { showWhen } = conditionalLogic || {};

        if (showWhen?.attribute) {
          passesNewConditions = executeCondition(
            selectedAttributes?.[showWhen.attribute],
            conditionalLogic.showWhen.condition,
            conditionalLogic.showWhen.value,
          );
        }

        if (!passesNewConditions && conditionalLogic?.orWhen?.length > 0) {
          passesNewConditions = conditionalLogic.orWhen.some((or) => executeCondition(
            selectedAttributes?.[or.attribute],
            or.condition,
            or.value,
          ));
        }

        if (passesNewConditions && conditionalLogic?.andWhen?.length > 0) {
          passesNewConditions = conditionalLogic.andWhen.every((and) => executeCondition(
            selectedAttributes?.[and.attribute],
            and.condition,
            and.value,
          ));
        }

        if (passesConditions !== passesNewConditions) {
          hasChanged = true;
        }

        return {
          image,
          conditionalLogic,
          passesConditions: passesNewConditions,
        };
      });

      if (hasChanged) {
        setImagesWithConditions(imagesWithNewConditions);
      }
    }
  }, [imagesWithConditions, selectedAttributes]);

  // no images pass initially
  useEffect(() => {
    setImagesWithConditions(images.map((image) => ({
      ...image,
      passesConditions: false,
    })));
  }, [images]);

  useEffect(() => {
    const toRender = imagesWithConditions.filter((image) => image.passesConditions);
    setImagesToRender(toRender);
  }, [imagesWithConditions]);

  const mainSliderSettings = useCallback({
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: thumbnailSliderRef,
    ref: (ref) => { setMainSliderRef(ref); },
    afterChange: (newIndex) => { setCurrentIndex(newIndex); },
  }, [thumbnailSliderRef]);

  const thumbnailSliderSettings = useCallback({
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: imagesToRender?.length < 3 ? imagesToRender.length : 3,
    slidesToScroll: 1,
    asNavFor: mainSliderRef,
    ref: (ref) => { setThumbnailSliderRef(ref); },
    centerMode: true,
    centerPadding: '0px',
    focusOnSelect: true,
  }, [mainSliderRef, imagesToRender]);

  return (
    <div className={classes.imageSlider}>
      <div className={classes.mainSlider}>
        <SlickSlider {...mainSliderSettings}>
          {Array.isArray(imagesToRender) && imagesToRender.length === 0 && (
            <img
              src={defaultImage}
              alt="My290"
            />
          )}
          {Array.isArray(imagesToRender) && imagesToRender.length > 0
            && imagesToRender.map((imageObject, index) => (
              <img
                key={index}
                src={imageObject?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${imageObject.image.sizes.card.filename}` : defaultImage}
                alt={imageObject?.image?.alt || ''}
              />
            ))}
        </SlickSlider>
      </div>
      {(Array.isArray(imagesToRender) && imagesToRender.length > 1) && (
        <div className={classes.controls}>
          <Button
            className={classes.button}
            size="s"
            color="lightGray"
            onClick={() => thumbnailSliderRef.slickPrev()}
            htmlAttributes={{ type: 'button' }}
            icon="chevron"
            iconRotation={90}
          />
          <div className={classes.thumbnailSlider}>
            <SlickSlider {...thumbnailSliderSettings}>
              {imagesToRender.map((imageObject, index) => {
                if (imageObject.passesConditions) {
                  return (
                    <div
                      key={index}
                      className={[
                        classes.thumbnail,
                        index === currentIndex && classes.thumbnailActive,
                      ].filter(Boolean).join(' ')}
                    >
                      <img
                        src={imageObject?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${imageObject.image.sizes.card.filename}` : defaultImage}
                        alt={imageObject?.image.alt || ''}
                        className={classes.thumbnailImage}
                      />
                    </div>
                  );
                }

                return null;
              })}
            </SlickSlider>
          </div>
          <Button
            className={classes.button}
            size="s"
            color="lightGray"
            onClick={() => thumbnailSliderRef.slickNext()}
            htmlAttributes={{ type: 'button' }}
            icon="chevron"
            iconRotation={-90}
          />
        </div>
      )}
    </div>
  );
};

ImageSlider.defaultProps = {
  images: [],
  selectedAttributes: undefined,
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  selectedAttributes: PropTypes.shape({}),
};

export default ImageSlider;
