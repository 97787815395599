import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../../../css/vars';

export default createUseStyles({
  nestedSpecLink: {
    borderRadius: `${vars.borderRadius}px`,
    border: `${vars.strokeWidth}px ${colors.lightGray} solid`,
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  wrapper: {
    padding: base(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    flexShrink: 0,
    marginRight: base(),
  },
  currentValue: {
    color: colors.darkGray,
  },
});
