import { createUseStyles } from 'react-jss';
import { largeBreak, midBreak } from '../../../css/queries';
import { base, colors } from '../../../css/vars';

export default createUseStyles({
  orderHistory: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
  },
  orderID: {
    color: colors.darkGray,
  },
  requestStatus: {
    textAlign: 'right',
  },
  tableWrap: {
    ...largeBreak({
      display: 'flex',
      overflowX: 'auto',
      width: `calc(100% + ${base(4)})`,
      '&:after': {
        content: '""',
        paddingLeft: base(4),
        display: 'block',
      },
      ...midBreak({
        '&:after': {
          paddingLeft: base(),
        },
      }),
    }),
    ...midBreak({
      width: `calc(100% + ${base()})`,
    }),
  },
  table: {
    paddingRight: base(),
    paddingLeft: base(),
    width: '100%',
  },
});
