import React, { useState, useCallback, useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import Button from '../../components/Button';
import Submit from '../../forms/fields/Submit';
import Input from '../../forms/fields/Input';
import Form from '../../forms/Form';
import Errors from '../../forms/Errors';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const ResetPassword = () => {
  const [success, setSuccess] = useState(false);
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  const onResponse = useCallback((response) => {
    const { status } = response;
    if (status === 200) {
      setSuccess(true);
    }
  }, [setSuccess]);

  return (
    <Fragment>
      <Helmet>
        <title>
          Reset Password
        </title>
        <meta
          name="description"
          content="Forgot your password? Reset it here."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.resetPasssword}>
        {success ? (
          <Fragment>
            <h1>
              Email sent!
            </h1>
            <p>
              Please check your inbox to finish resetting your password.
            </p>
          </Fragment>
        ) : (
          <h1>
            Reset password
          </h1>
        )}
        {!success && (
          <Form
            method="post"
            action={`${process.env.API_URL}/api/users/forgot-password`}
            onResponse={onResponse}
            className={classes.form}
            defaultValues={{
              email: {
                value: '',
                validation: (incomingValue) => ({
                  isValid: Boolean(incomingValue),
                  message: 'Email is required',
                }),
              },
            }}
          >
            <p className={classes.intro}>
              Can’t remember your password? No worries. Enter your email to reset it securely. You can also
              {' '}
              <Button
                to="/login"
                kind="text"
                label="try again"
              />
              {' or create a '}
              <Button
                to="/signup"
                kind="text"
                label="new account."
              />
            </p>
            <Errors />
            <Input
              {...{
                type: 'email',
                name: 'email',
                id: 'resetPasswordEmail',
                label: 'Email Address',
                required: true,
              }}
            />
            <Submit
              className={classes.submit}
              label="Send reset instructions"
            />
          </Form>
        )}
      </div>
    </Fragment>
  );
};

export default ResetPassword;
