import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './css';

const Back = (props) => {
  const {
    to,
    label,
    darkUI,
    className,
  } = props;

  const classes = useStyles({ darkUI });

  return (
    <Link
      className={[
        classes.back,
        className,
      ].filter(Boolean).join(' ')}
      to={to}
    >
      <span>
        {label}
      </span>
    </Link>
  );
};

Back.defaultProps = {
  to: undefined,
  label: 'Go back',
  darkUI: undefined,
  className: undefined,
};

Back.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  darkUI: PropTypes.bool,
  className: PropTypes.string,
};

export default Back;
