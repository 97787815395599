import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from '../Notifications';

const CartContext = createContext({});

export const useCart = () => useContext(CartContext);

const getCart = () => ({
  products: [],
  projects: [],
  ...JSON.parse(localStorage.getItem('cart')) || {},
});

const arrayHasItems = (array) => Array.isArray(array) && array.length > 0;

const CartProvider = (props) => {
  const { setTimedNotification } = useNotifications();
  const { children } = props;
  const [cart, setCart] = useState(() => getCart());

  const [cartIsEmpty, setCartIsEmpty] = useState(true);

  const addProjectToCart = useCallback((project) => {
    const currentCart = getCart();
    const { projects: projectsInCart } = currentCart;
    const alreadyInCart = projectsInCart?.find((item) => item.uuid === project.uuid);

    if (!alreadyInCart) {
      const withAddedProject = {
        ...currentCart,
        projects: [
          ...projectsInCart || [],
          {
            ...project,
            quantity: 1,
          },
        ],
      };
      localStorage.setItem('cart', JSON.stringify(withAddedProject));
      setCart(withAddedProject);
    }
  }, []);

  const updatePotentialProjectInCart = useCallback((project) => {
    if (project) {
      const currentCart = getCart();
      const { projects: projectsInCart } = currentCart;

      const withUpdatedProject = projectsInCart.reduce((projects, projectInCart) => {
        if (projectInCart.uuid === project.uuid) {
          return [
            ...projects,
            {
              ...project,
              quantity: projectInCart.quantity,
            },
          ];
        }

        return [
          ...projects,
          projectInCart,
        ];
      }, []);

      const newCart = {
        ...currentCart,
        projects: withUpdatedProject,
      };

      localStorage.setItem('cart', JSON.stringify(newCart));
      setCart(newCart);
    }
  }, []);

  const addProductToCart = useCallback((incomingProduct) => {
    const currentCart = getCart();
    const { products: productsInCart } = currentCart;
    const withAddedProduct = {
      ...currentCart,
      products: [
        ...productsInCart || [],
        { ...incomingProduct },
      ],
    };
    localStorage.setItem('cart', JSON.stringify(withAddedProduct));
    setCart(withAddedProduct);
  }, []);

  const updateCartItem = useCallback(({ type, index, payload }) => {
    const currentCart = getCart();
    const withUpdatedItem = { ...currentCart };
    if (withUpdatedItem?.[type]?.[index]) {
      withUpdatedItem[type][index] = {
        ...withUpdatedItem[type][index],
        ...payload,
      };
    }
    localStorage.setItem('cart', JSON.stringify(withUpdatedItem));
    setCart(withUpdatedItem);
  }, []);

  const deleteProjectFromCart = useCallback((incomingUUID) => {
    const currentCart = getCart();
    const { projects: projectsInCart } = currentCart;

    const indexToDelete = projectsInCart?.findIndex((item) => item.uuid === incomingUUID);
    if (indexToDelete > -1) {
      const deletedProject = projectsInCart[indexToDelete];
      const withDeletedProject = { ...currentCart };
      withDeletedProject.projects.splice(indexToDelete, 1);
      localStorage.setItem('cart', JSON.stringify(withDeletedProject));
      setCart(withDeletedProject);
      setTimedNotification({
        id: 'deletedFromCart',
        message: `${deletedProject.name} has been deleted from your cart`,
      });
    }
  }, [setTimedNotification]);

  const deleteProductFromCart = useCallback((indexToDelete) => {
    const currentCart = getCart();
    const { products: productsInCart } = currentCart;
    const withDeletedProduct = { ...currentCart };
    if (Array.isArray(productsInCart) && productsInCart.length > 0 && productsInCart[indexToDelete]) {
      const deletedProduct = productsInCart[indexToDelete];
      withDeletedProduct.products.splice(indexToDelete, 1);
      localStorage.setItem('cart', JSON.stringify(withDeletedProduct));
      setCart(withDeletedProduct);
      setTimedNotification({
        id: 'deletedFromCart',
        message: `${deletedProduct.name} has been deleted from your cart`,
      });
    }
  }, [setTimedNotification]);

  const clearCart = useCallback(() => {
    const emptyCart = {
      products: [],
      projects: [],
    };

    localStorage.setItem('cart', JSON.stringify(emptyCart));
    setCart(emptyCart);
  }, []);

  const isProjectInCart = useCallback((incomingUUID) => {
    let isInCart = false;
    const { projects: projectsInCart } = getCart();
    if (Array.isArray(projectsInCart) && projectsInCart.length > 0) {
      isInCart = projectsInCart.find((item) => item.uuid === incomingUUID);
    }
    return isInCart;
  }, []);

  useEffect(() => {
    const isEmpty = !(arrayHasItems(cart.products) || arrayHasItems(cart.projects));
    setCartIsEmpty(isEmpty);
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        addProjectToCart,
        deleteProjectFromCart,
        updateCartItem,
        isProjectInCart,
        addProductToCart,
        deleteProductFromCart,
        updatePotentialProjectInCart,
        cartIsEmpty,
        clearCart,
      }}
    >
      { children && children}
    </CartContext.Provider>
  );
};

CartProvider.defaultProps = {
  children: undefined,
};

CartProvider.propTypes = {
  children: PropTypes.node,
};

export default CartProvider;
