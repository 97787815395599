import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const gaMeasurementID = process.env.GA_MEASUREMENT_ID;

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;
    const currentPath = `${pathname}${search}`;
    if (gaMeasurementID && typeof window.gtag === 'function') {
      window.gtag('config', gaMeasurementID, { page_path: currentPath });
    }
  }, [location]);

  if (gaMeasurementID) {
    return (
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementID}`}
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${gaMeasurementID}');`}
        </script>
      </Helmet>
    );
  }
  return null;
};

export default GoogleAnalytics;
