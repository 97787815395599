import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';

const ShadowBox = (props) => {
  const {
    className,
    color,
    padding,
    htmlElement,
    htmlFor,
    children,
  } = props;

  const classes = useStyles();

  const Tag = htmlElement;

  return (
    <Tag
      {...{
        htmlFor,
        className: [
          classes.shadowBox,
          classes[color],
          padding && classes[padding],
          className,
        ].filter(Boolean).join(' '),
      }}
    >
      {children && children}
    </Tag>
  );
};

ShadowBox.defaultProps = {
  className: '',
  color: 'lighterGray',
  padding: 'l',
  htmlElement: 'div',
  htmlFor: undefined,
  children: undefined,
};

ShadowBox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'white',
    'lighterGray',
  ]),
  padding: PropTypes.oneOf([
    false,
    's',
    'l',
  ]),
  htmlElement: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.node,
};

export default ShadowBox;
