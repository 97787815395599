import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css/vars';

const Logo = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="172"
      height="82"
      viewBox="0 0 172 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={colors.gray}
        d="M51.5,51.9H0.6C4.3,37.2,14.1,32,26,28.8l11.3-3c2.7-0.7,5.1-2.3,5.5-4.5c0.6-2.8-1.7-4.8-7.5-4.9c-5.4,0-10,2.1-11.6,7.2H8.1c3.7-13,15.1-17.1,29.4-17.1c14.8,0,23.1,4.9,21,14.9C55,38.3,26.9,33.9,18.9,42.1h34.7L51.5,51.9L51.5,51.9z"
      />
      <path
        fill={colors.red}
        d="M78.1,39.4c0.2,2.8,3.4,4,7.8,4c9.2,0,12.2-4.9,13.9-10.3c-4.4,2.5-8.8,3.4-14.4,3.4c-10.4,0-21.7-2.5-19.1-14.8c1.9-9.1,10.9-15.1,27.1-15.1c14.9,0,25.8,4.3,21.8,23.3c-3.5,16.4-15.5,22.9-30.9,22.9c-10.9,0-21.8-2.7-21.1-13.5H78.1L78.1,39.4z M100.8,21.6c0.6-2.7-1.6-5.5-8.5-5.5c-7.6,0-10.2,2.8-10.8,5.5c-0.7,3.3,0.8,6,8.4,6C98.3,27.6,100.1,24.5,100.8,21.6L100.8,21.6z"
      />
      <path
        fill={colors.red}
        d="M164.8,30c-2.5,11.8-11.5,22.9-30.8,22.9S110.4,41.8,113,30c2.6-12.3,11.4-23.3,30.9-23.3C163.2,6.7,167.4,17.8,164.8,30L164.8,30z M127.6,30c-2.2,10.5,3.4,13.1,8.5,13.1c5,0,11.7-2.2,14-13.1c2.3-10.9-3.3-13.5-8.4-13.5S130,19.1,127.6,30L127.6,30z"
      />
      <path
        fill={colors.gray}
        d="M13.3,68.3c0.1-0.9-0.5-1.5-2.4-1.5c-1.4,0-2.3,0.4-2.5,1.2c-0.1,0.4,0.2,0.7,0.8,0.8c1,0.2,4.2,0.7,5.4,0.9c2.3,0.4,3.5,1.4,3,3.6c-0.9,4.3-6.5,4.3-8.2,4.3c-4.3,0-7.2-0.8-6.7-4.3h5c-0.1,1,0.3,1.6,2.5,1.6c1.1,0,2.3-0.2,2.5-1.3c0.1-0.7-0.3-0.9-1.8-1.1l-4.1-0.6c-2.5-0.4-3.3-1.7-2.9-3.6c0.4-1.7,1.9-4,7.4-4c4.6,0,7.1,1.1,6.8,4.1H13.3L13.3,68.3z"
      />
      <path
        fill={colors.gray}
        d="M20.4,67.6h3.8l-2,9.6h-3.8L20.4,67.6L20.4,67.6z M21.1,64.2h3.8l-0.5,2.3h-3.8L21.1,64.2L21.1,64.2z"
      />
      <path
        fill={colors.gray}
        d="M35.5,67.6h3.7l-1.9,8.8c-0.7,3.2-2.8,4.6-7.4,4.6c-3.8,0-6.1-0.6-5.9-3.3h4.2c0,0.9,0.9,1.1,2,1.1c2.1,0,3-0.7,3.3-2.5l0.1-0.7c-1,0.9-2.7,1.2-4.1,1.2c-4.1,0-5.3-2.1-4.7-4.7c0.5-2.5,2.8-4.7,6.7-4.7c1.7,0,3.1,0.4,3.6,1.5h0L35.5,67.6L35.5,67.6z M31.2,74.4c2.1,0,3-1,3.3-2.3c0.3-1.3-0.4-2.3-2.3-2.3c-1.9,0-3,1-3.3,2.3C28.6,73.4,29.3,74.4,31.2,74.4L31.2,74.4z"
      />
      <path
        fill={colors.gray}
        d="M41.3,67.6H45L44.7,69h0c1-0.9,2.5-1.6,4.4-1.6c2.4,0,3.8,1.1,3.3,3.5l-1.3,6.3h-3.8l1.1-5.2c0.3-1.2-0.3-1.8-1.6-1.8c-1.3,0-2.5,0.7-2.8,2.3l-1,4.7h-3.8L41.3,67.6L41.3,67.6z"
      />
      <path
        fill={colors.gray}
        d="M71.3,68.3c0.1-0.9-0.5-1.5-2.4-1.5c-1.4,0-2.3,0.4-2.5,1.2c-0.1,0.4,0.2,0.7,0.8,0.8c1,0.2,4.2,0.7,5.4,0.9c2.3,0.4,3.5,1.4,3,3.6c-0.9,4.3-6.5,4.3-8.2,4.3c-4.3,0-7.2-0.8-6.7-4.3h5c-0.1,1,0.3,1.6,2.5,1.6c1.1,0,2.3-0.2,2.5-1.3c0.1-0.7-0.3-0.9-1.8-1.1l-4.1-0.6c-2.5-0.4-3.3-1.7-2.9-3.6c0.4-1.7,1.9-4,7.4-4c4.6,0,7.1,1.1,6.8,4.1H71.3L71.3,68.3z"
      />
      <path
        fill={colors.gray}
        d="M76.2,78.2h0.9c1.3,0,1.6-0.4,1.9-0.7l0.6-0.9L77,67.6h4.1l1.2,5.7l3.6-5.7h4l-7.4,10.8c-1,1.5-1.7,2.3-4.1,2.3c-1,0-1.9-0.1-2.6-0.1L76.2,78.2L76.2,78.2z"
      />
      <path
        fill={colors.gray}
        d="M93.1,74.2c0,0.4,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.3,1.6,0.3c0.8,0,1.8-0.2,1.9-0.9c0.1-0.5-0.2-0.7-1.1-0.8l-3.3-0.4c-2.1-0.3-3.1-1-2.8-2.7c0.6-2.6,3-3.4,6.6-3.4c2.6,0,5.7,0.5,5.3,3.2h-4.2c0.1-0.4-0.2-0.7-0.5-0.8c-0.3-0.2-0.8-0.2-1.3-0.2c-1.1,0-1.8,0.2-1.9,0.9c-0.1,0.3,0.1,0.6,1.1,0.7l3.2,0.4c2.5,0.3,3.6,1.1,3.2,3c-0.5,2.3-2.9,3.3-6.9,3.3c-2.7,0-6.2-0.5-5.6-3.4H93.1L93.1,74.2z"
      />
      <path
        fill={colors.gray}
        d="M103.3,67.6h2l0.6-3.1h3.8l-0.7,3.1h2.5l-0.5,2.5h-2.5l-0.8,3.9c-0.2,0.9,0,1,1,1c0.5,0,0.8,0,1.3,0l-0.5,2.3c-0.8,0.1-1.8,0.2-2.9,0.2c-2.4,0-3.3-0.6-2.9-2.7l1-4.7h-2L103.3,67.6L103.3,67.6z"
      />
      <path
        fill={colors.gray}
        d="M124.7,74.4c-0.8,1.6-3,3.2-7.1,3.2c-4.3,0-6.4-1.7-5.6-5.2c0.7-3.4,3.7-5.2,7.7-5.2c4.1,0,6.3,1.8,5.4,5.8l-0.1,0.3h-9.4c-0.1,1.2,0.6,2.1,2.6,2.1c1.2,0,1.9-0.5,2.5-1.1H124.7L124.7,74.4z M121.6,71.3c0-1.1-0.6-2-2.4-2c-1.8,0-2.8,0.9-3.2,2H121.6L121.6,71.3z"
      />
      <path
        fill={colors.gray}
        d="M128,67.6h3.7l-0.3,1.4h0c1.1-0.9,2.4-1.7,4.2-1.7c1.3,0,2.5,0.4,3.1,1.7c1.2-1,2.5-1.7,4.4-1.7c2,0,3.5,0.9,3,3.3l-1.4,6.6h-3.8l1.2-5.6c0.2-0.9-0.3-1.4-1.3-1.4c-1.4,0-2.3,0.7-2.5,1.7l-1.1,5.4h-3.8l1.2-5.6c0.2-0.9-0.3-1.4-1.3-1.4c-1.4,0-2.3,0.7-2.5,1.7l-1.1,5.4H126L128,67.6L128,67.6z"
      />
      <path
        fill={colors.gray}
        d="M151.4,74.2c0,0.4,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.3,1.6,0.3c0.8,0,1.8-0.2,1.9-0.9c0.1-0.5-0.2-0.7-1.1-0.8l-3.3-0.4c-2.1-0.3-3.1-1-2.7-2.7c0.6-2.6,3-3.4,6.6-3.4c2.6,0,5.7,0.5,5.3,3.2h-4.2c0.1-0.4-0.2-0.7-0.5-0.8c-0.3-0.2-0.8-0.2-1.3-0.2c-1.1,0-1.8,0.2-1.9,0.9c-0.1,0.3,0.1,0.6,1.1,0.7l3.2,0.4c2.5,0.3,3.6,1.1,3.2,3c-0.5,2.3-2.9,3.3-6.9,3.3c-2.7,0-6.2-0.5-5.6-3.4H151.4L151.4,74.2z"
      />
      <polygon
        fill={colors.gray}
        points="45.8,57.5 49.8,57.5 66.4,1.1 62.5,1.1 45.8,57.5 "
      />
      <polygon
        fill={colors.red}
        points="53.1,57.5 57.2,57.5 73.7,1.1 69.9,1.1 53.1,57.5 "
      />
      <path
        fill={colors.red}
        d="M168.4,4.5c1.6,0,3,1.3,3,3c0,1.7-1.4,3-3,3c-1.6,0-3-1.3-3-3C165.4,5.7,166.8,4.5,168.4,4.5L168.4,4.5zM168.4,10c1.4,0,2.5-1.1,2.5-2.6c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5C165.9,8.9,167,10,168.4,10L168.4,10z M167.3,5.7h1.4c0.8,0,1.2,0.3,1.2,1c0,0.6-0.4,0.9-0.9,1l1,1.5h-0.6l-1-1.5h-0.6v1.5h-0.5V5.7L167.3,5.7z M167.8,7.2h0.6c0.5,0,0.9,0,0.9-0.6c0-0.5-0.4-0.5-0.8-0.5h-0.7V7.2L167.8,7.2z"
      />
    </svg>
  );
};

Logo.defaultProps = {
  className: undefined,
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
