import { useAuthentication } from '../wrappers/Authentication';

export default () => {
  const { user } = useAuthentication();
  let discount = 0;
  if (user) {
    const { organization } = user;
    discount = organization?.discount || 0;
  }
  return discount;
};
