import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'qs';
import SignSystemCard from '../SignSystemCard';
import Input from '../../forms/fields/Input';
import Submit from '../../forms/fields/Submit';
import useStyles from './css';
import createProject from '../../utilities/data/createProject';
import { useAuthentication } from '../../wrappers/Authentication';
import Errors from '../../forms/Errors';
import projectNameValidations from '../../utilities/validations/projectName';
import OnboardingTooltip from '../OnboardingTooltip';

const getCurrentSystem = (search) => {
  let currentSystem = 'S';
  const { signSystem: systemFromURL } = queryString.parse(search, { ignoreQueryPrefix: true });
  if (systemFromURL) currentSystem = systemFromURL;
  return currentSystem;
};

const NewProjectPortal = (props) => {
  const { isLoggedIn } = useAuthentication();
  const { pathname, search } = useLocation();
  const { title, body } = props;
  const classes = useStyles();
  const history = useHistory();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [errors, setErrors] = useState({});
  const hasErrors = errors && Object.keys(errors).length > 0;

  const [signSystem, setSignSystem] = useState(() => getCurrentSystem(search));
  const [name, setName] = useState('');

  const handleChange = useCallback((incomingValue) => {
    setSignSystem(incomingValue);
    const parsedSearch = queryString.parse(search, { ignoreQueryPrefix: true });
    parsedSearch.signSystem = incomingValue;
    const stringifiedSearch = queryString.stringify(parsedSearch, { addQueryPrefix: true });
    history.push(`${pathname}${stringifiedSearch}`);
  }, [pathname, search, history]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const trimmedName = name.trim();
    const isValidName = trimmedName.match(projectNameValidations.regex);

    if (trimmedName) {
      if (isValidName) {
        if (!hasErrors) {
          const projectID = await createProject({
            signSystem,
            name: trimmedName,
            isLoggedIn,
          });

          const cancelledAddSignOnboarding = localStorage.getItem('cancelledAddSignOnboarding');
          history.push(`/project/${projectID}/add-sign${!cancelledAddSignOnboarding ? '?onboard=1' : ''}`);
          return true;
        }
      } else setErrors({ ...errors, name: projectNameValidations.message });
    } else setErrors({ ...errors, name: projectNameValidations.requiredMessage });

    setSubmitAttempted(true);
    return true;
  }, [errors, hasErrors, history, isLoggedIn, name, signSystem]);

  return (
    <div className={classes.newProjectPortal}>
      <div className={classes.content}>
        <h1>
          {title}
        </h1>
        {body && (
          <div className={classes.body}>
            {body}
          </div>
        )}
        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Errors errors={errors} />
          <Input
            {...{
              type: 'text',
              name,
              label: 'Project Name',
              onChange: (incomingName) => {
                const trimmedIncomingName = incomingName.trim();
                const isValidName = trimmedIncomingName.match(projectNameValidations.regex);
                if (submitAttempted) {
                  if (trimmedIncomingName) {
                    if (isValidName) {
                      const withoutError = { ...errors };
                      delete withoutError.name;
                      setErrors(withoutError);
                    } else setErrors({ ...errors, name: projectNameValidations.message });
                  } else setErrors({ ...errors, name: projectNameValidations.requiredMessage });
                }
                setName(incomingName);
              },
              value: name,
              hasError: Boolean(errors?.name),
            }}
          />
          <Submit label="Create new project" />
        </form>
      </div>
      <ul className={classes.list}>
        <li className={classes.item}>
          <SignSystemCard
            system="S"
            label="Slide"
            onChange={handleChange}
            currentValue={signSystem}
          />
          <OnboardingTooltip
            position="topLeft"
            contentPosition="right"
            title="Choose Sign System"
            message="2/90 Sign Systems offers three sign systems to fit the needs of our customers. All three sign systems are priced the same, so your choice comes down to desired aesthetics and sign functions."
          />
        </li>
        <li className={classes.item}>
          <SignSystemCard
            system="A"
            label="Arc"
            onChange={handleChange}
            currentValue={signSystem}
          />
        </li>
        <li className={classes.item}>
          <SignSystemCard
            system="K"
            label="Klik"
            onChange={handleChange}
            currentValue={signSystem}
          />
        </li>
      </ul>
    </div>
  );
};

NewProjectPortal.defaultProps = {
  title: undefined,
  body: undefined,
};

NewProjectPortal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default NewProjectPortal;
