import React, { Fragment } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { Link } from 'react-router-dom';
import ChevronIcon from '../../icons/ChevronIcon';
import ShadowBox from '../../components/ShadowBox';
import useStyles from './css';
import CloseIcon from '../../icons/CloseIcon';
import CheckboxBase from '../../forms/fields/Checkbox/Base';

const SpecAccordion = (props) => {
  const classes = useStyles();

  const {
    label,
    list,
    openOnInit,
  } = props;

  return (
    <Collapsible
      openOnInit={openOnInit}
      open={openOnInit}
    >
      {({ isOpen }) => (
        <ShadowBox
          padding={false}
          color="white"
          className={classes.specAccordion}
        >
          <CollapsibleToggler className={classes.collapsibleHeader}>
            <Fragment>
              <div className={classes.collapsibleHeaderContent}>
                <div className={classes.collapsibleTitle}>
                  <strong>
                    {label}
                  </strong>
                </div>
                <div className={classes.collapsibleIcon}>
                  <ChevronIcon
                    rotation={isOpen ? 180 : 0}
                    size="s"
                    color="gray"
                  />
                </div>
              </div>
              {isOpen && (
                <div className={classes.seperator} />
              )}
            </Fragment>
          </CollapsibleToggler>
          <CollapsibleContent>
            <div className={classes.collapsibleContent}>
              {Array.isArray(list) && list.length > 0 && list.map((item, index) => {
                const {
                  label: itemLabel,
                  currentValue,
                  currentLabel,
                  themeValue,
                  themeLabel,
                  to,
                  onClear,
                  disableClear,
                  type,
                  onChange,
                  name,
                } = item;

                return (
                  <div
                    key={index}
                    className={classes.item}
                  >
                    {type === 'link' && (
                      <Fragment>
                        <div className={classes.link}>
                          <Link
                            key={index}
                            to={to}
                          >
                            <div className={classes.itemLabel}>
                              {itemLabel}
                            </div>
                            <div className={classes.valueWrapper}>
                              {currentValue && (
                                <div className={classes.currentValue}>
                                  {currentLabel}
                                </div>
                              )}
                              {!currentValue && themeValue && (
                                <div className={classes.themeValue}>
                                  {themeLabel}
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                        {currentValue && themeValue && !disableClear && (
                          <div
                            className={classes.clear}
                            onClick={onClear}
                            onKeyPress={onClear}
                            role="button"
                            tabIndex={0}
                          >
                            <CloseIcon
                              color="gray"
                              size="s"
                            />
                          </div>
                        )}
                      </Fragment>
                    )}
                    {type === 'checkbox' && (
                      <Fragment>
                        <label
                          className={classes.checkbox}
                          htmlFor={name}
                        >
                          <div className={classes.itemLabel}>
                            {itemLabel}
                          </div>
                          <CheckboxBase
                            {...{
                              onChange,
                              name,
                              value: currentValue,
                              ghostChecked: typeof currentValue === 'undefined' && themeValue === true,
                            }}
                          />
                        </label>
                        {typeof currentValue !== 'undefined' && typeof themeValue !== 'undefined' && (
                          <div
                            className={classes.clear}
                            onClick={onClear}
                            onKeyPress={onClear}
                            role="button"
                            tabIndex={0}
                          >
                            <CloseIcon
                              color="gray"
                              size="s"
                            />
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                );
              })}
            </div>
          </CollapsibleContent>
        </ShadowBox>
      )}
    </Collapsible>
  );
};

SpecAccordion.defaultProps = {
  list: undefined,
  label: undefined,
  openOnInit: undefined,
};

SpecAccordion.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      currentValue: oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]),
      currentLabel: PropTypes.string,
      themeValue: PropTypes.string,
      themeLabel: PropTypes.string,
      onClear: PropTypes.func,
      disableClear: PropTypes.bool,
      type: PropTypes.oneOf([
        'link',
        'checkbox',
      ]),
      onChange: PropTypes.func,
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  openOnInit: PropTypes.bool,
};

export default SpecAccordion;
