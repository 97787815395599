import { createUseStyles } from 'react-jss';
import { base, colors, zIndex } from '../../css/vars';
import { midBreak } from '../../css/queries';
import { resetButton } from '../../css/other';

export default createUseStyles({
  authReminder: {
    zIndex: zIndex.modal,
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    padding: `${base(1)} ${base(4)}`,
    backgroundColor: colors.darkGray,
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...midBreak({
      padding: base(),
    }),
  },
  content: {
    marginRight: base(),
  },
  button: {
    ...resetButton,
    flexShrink: '0',
    cursor: 'pointer',
    width: base(0.5),
    height: base(0.5),
  },
});
