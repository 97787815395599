import React from 'react';
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './css';
import SpecHeader from '../../SpecHeader';
import CopySizes from './CopySizes';
import Symbols from './Symbols';
import Line from './Line';
import OnboardingTooltip from '../../../components/OnboardingTooltip';

const Lines = (props) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const {
    signIndex: signIndexFromParams,
    insertIndex: insertIndexFromParams,
  } = useParams();

  const signIndex = Number(signIndexFromParams);
  const insertIndex = Number(insertIndexFromParams);

  const {
    insertsMeta,
    currentCopy,
    onCopyChange,
    backTo,
  } = props;

  const insertMeta = insertsMeta[insertIndex];

  if (insertMeta) {
    const { lines: linesMeta } = insertMeta;
    const linesFromCopy = currentCopy?.[signIndex]?.inserts?.[insertIndex]?.lines;

    return (
      <Switch>
        <Route path={`${url}/:lineIndex/copy-size`}>
          <CopySizes
            backTo={url}
            linesMeta={linesMeta}
            onChange={({ lineIndex, incomingValue }) => onCopyChange({
              signIndex,
              insertIndex,
              lineIndex,
              specs: {
                copySize: incomingValue,
              },
            })}
            currentLines={currentCopy?.[signIndex]?.inserts?.[insertIndex]?.lines}
          />
        </Route>
        <Route path={`${url}/:lineIndex/symbol`}>
          <Symbols
            backTo={url}
            linesMeta={linesMeta}
            onChange={({ lineIndex, incomingValue }) => onCopyChange({
              signIndex,
              insertIndex,
              lineIndex,
              specs: {
                symbol: incomingValue,
              },
            })}
            currentLines={currentCopy?.[signIndex]?.inserts?.[insertIndex]?.lines}
          />
        </Route>
        <Route>
          <div className={classes.lines}>
            <div style={{ position: 'relative' }}>
              <SpecHeader
                label={`Sign ${signIndex + 1}, Insert ${insertIndex + 1}`}
                to={backTo}
              />
              <OnboardingTooltip
                position="topLeft"
                contentPosition="left"
                title="Personalize inserts"
                message="Select line(s) to customize each insert. Once copy is updated, click to return to previous screen."
              />
            </div>
            {Array.isArray(linesMeta) && linesMeta.length > 0
              && linesMeta.map((lineMeta, lineIndex) => {
                const {
                  copySizes,
                  allowFlexReverse,
                  allowRoomNumber,
                  allowSymbol,
                  showCopySizeControl,
                  showCopyTextControl,
                } = lineMeta;

                const currentLineCopy = linesFromCopy?.[lineIndex];

                return (
                  <div
                    style={{ position: 'relative' }}
                    key={lineIndex}
                  >
                    <Line
                      {...{
                        lineIndex,
                        signIndex,
                        insertIndex,
                        copySizes,
                        allowFlexReverse,
                        allowRoomNumber,
                        allowSymbol,
                        showLineIndex: linesMeta.length > 1, // more than one line
                        currentLineCopy,
                        onCopyChange,
                        showCopySizeControl,
                        showCopyTextControl,
                      }}
                    />
                    {(lineIndex === 0) && (
                      <OnboardingTooltip
                        position="topLeft"
                        contentPosition="left"
                        title="Add custom copy messages"
                        message="Depending on sign types, add one or more lines of copy to an insert. If needed, adjust copy size to fit."
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </Route>
      </Switch>
    );
  }
  return null;
};

Lines.defaultProps = {
  insertsMeta: undefined,
  currentCopy: undefined,
  onCopyChange: undefined,
  backTo: undefined,
};

Lines.propTypes = {
  insertsMeta: PropTypes.arrayOf(
    PropTypes.shape({
      lines: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
  ),
  currentCopy: PropTypes.arrayOf(
    PropTypes.shape({
      inserts: PropTypes.arrayOf(
        PropTypes.shape({
          lines: PropTypes.arrayOf(
            PropTypes.shape({
              copy: PropTypes.string,
              showCopy: PropTypes.bool,
            }),
          ),
        }),
      ),
    }),
  ),
  onCopyChange: PropTypes.func,
  backTo: PropTypes.string,
};

export default Lines;
