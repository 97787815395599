import { createUseStyles } from 'react-jss';
import { colors, base, bodyFontSize } from '../../css/vars';
import { resetButton } from '../../css/other';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  input: {
    width: '100%',
  },
  htmlLabel: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    left: base(),
    color: colors.gray,
    marginRight: base(),
    flexShrink: 0, // match with 'SpecLink' and 'SpecAccordion'
    flexBasis: base(5), // match with 'SpecLink' and 'SpecAccordion'
    ...midBreak({
      flexBasis: base(4), // match with 'SpecLink' and 'SpecAccordion'
    }),
  },
  htmlInput: {
    width: '1px',
    flexGrow: '1',
    fontSize: `${bodyFontSize}px`,
    backgroundColor: 'transparent',
    color: colors.darkGray,
    fontWeight: 'bold',
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  controls: {
    display: 'flex',
    flexShrink: '0',
  },
  control: {
    ...resetButton,
    '&:hover path': {
      stroke: colors.darkGray,
    },
    '&:not(:last-child)': {
      marginRight: base(0.5),
    },
  },
});
