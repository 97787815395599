import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import CardSlider from '../../components/CardSlider';
import Hero from './Hero';
import Button from '../../components/Button';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import { useAuthentication } from '../../wrappers/Authentication';
import useAPI from '../../utilities/data/useAPI';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import defaultImage from '../../../assets/images/my290-default-image.png';
import staticContent from '../../staticContent';

const Dashboard = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();
  const { user } = useAuthentication();

  const [{
    res: { json: { docs: myProducts } = {} } = {},
    isLoading: isLoadingMyProducts,
  }] = useAPI('get', user?.organization ? `${process.env.API_URL}/api/products/?where[organization][equals]=${user.organization.id}&limit=6&sort=-sortPriority` : null);

  const [{
    res: { json: productSettings } = {},
    isLoading: isLoadingProductSettings,
  }] = useAPI('get', `${process.env.API_URL}/api/globals/productSettings`);

  const {
    featured: featuredProducts,
  } = productSettings || {};

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  return (
    <Fragment>
      <Helmet>
        <title>My290</title>
        <meta
          name="description"
          content="Welcome to the My290 Design Center! The ultimate tool to complete your project."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.dashboard}>
        <header className={classes.header}>
          <h1 className={classes.title}>
            Welcome to My290!
          </h1>
        </header>
        <div className={classes.section}>
          <Hero />
        </div>
        <div className={classes.section}>
          <CardSlider
            title="Store"
            body={staticContent.storeMessage}
            ctas={(
              <Button
                to="/store"
                label="Shop"
              />
            )}
            isLoading={isLoadingProductSettings}
            noResults="There are no products."
            cards={Array.isArray(featuredProducts) && featuredProducts.length > 0 && featuredProducts.map((product) => {
              const {
                id: productID,
                name,
                price,
                images,
              } = product;

              return ({
                title: name,
                linkTitleTo: `/store/${productID}`,
                image: images?.[0]?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${images[0].image.sizes.card.filename}` : defaultImage,
                price,
              });
            })}
          />
        </div>
        {user?.organization && (
          <div className={classes.section}>
            <CardSlider
              title="My Products"
              body="Browse products that are only available to you."
              ctas={(
                <Button
                  to="/my-products"
                  label="Re-order"
                />
              )}
              isLoading={isLoadingMyProducts}
              noResults="Your organization does not have any products assigned to it yet."
              cards={Array.isArray(myProducts) && myProducts.length > 0 && myProducts.map((product) => {
                const { id: productID, name, price, images } = product;

                return {
                  title: name,
                  linkTitleTo: `/store/${productID}`,
                  image: images?.[0]?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${images[0].image.sizes.card.filename}` : defaultImage,
                  price,
                };
              })}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Dashboard;
