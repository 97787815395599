import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SpecHeader from '../SpecHeader';
import useStyles from './css';
import ColorSwatchAccordion from '../ColorSwatchAccordion';
import ShadowBox from '../../components/ShadowBox';
import Radio from '../../forms/fields/Radio';

const { backerFinishesByCategory } = specSheets;

const BackerFinishes = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    headerLabel,
    signSystem,
    backerIndex,
    specId,
  } = props;

  const finishesToUse = { ...backerFinishesByCategory };

  // the first backers of klik signs do not support laminate finishes
  if (signSystem === 'K' && backerIndex === 0) {
    delete finishesToUse.metals;
    delete finishesToUse.woods;
    delete finishesToUse.stones;
  }

  const entries = Object.entries(finishesToUse);

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.backerFinishes}>
        <SpecHeader
          label={headerLabel || 'Backer Finish'}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          <Radio
            {...{
              className: classes.radio,
              name: 'finish',
              label: 'None',
              value: 'none',
              onChange,
              checked: currentValue === 'none',
            }}
          />
        </ShadowBox>
        {entries.map(([categoryID, category], index) => {
          const colorsWithID = Object.entries(category).map(([colorID, color]) => ({ ...color, id: colorID }));

          return (
            <ColorSwatchAccordion
              key={index}
              title={categoryID}
              colors={colorsWithID}
              name="finish"
              onChange={onChange}
              currentValue={currentValue}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

BackerFinishes.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  headerLabel: undefined,
  signSystem: undefined,
  backerIndex: undefined,
  specId: undefined,
};

BackerFinishes.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  headerLabel: PropTypes.string,
  signSystem: PropTypes.string,
  backerIndex: PropTypes.number,
  specId: PropTypes.string,
};

export default BackerFinishes;
