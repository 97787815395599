export default {
  '@font-face': [
    {
      fontFamily: 'Century Gothic',
      fontWeight: 'normal',
      src: `url("${process.env.API_URL}/assets/fonts/century-gothic/gothic.woff") format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/century-gothic/gothic.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/century-gothic/gothic.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Arial Bold',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/arial-bold/Arial-BoldMT.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/arial-bold/Arial-BoldMT.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/arial-bold/Arial-BoldMT.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Arial',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/arial-reg/ArialMT.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/arial-reg/ArialMT.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/arial-reg/ArialMT.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Avante Garde',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/avante-garde/ITCAvantGardeStd-Bk.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/avante-garde/ITCAvantGardeStd-Bk.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/avante-garde/ITCAvantGardeStd-Bk.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'DIN Regular',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/din-reg/DIN-Regular.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/din-reg/DIN-Regular.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/din-reg/DIN-Regular.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Folio Light',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/folio-lig/Folio-Lig.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/folio-lig/Folio-Lig.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/folio-lig/Folio-Lig.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Futura Book',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/futura/Futura-Boo.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/futura/Futura-Boo.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/futura/Futura-Boo.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Futura Book',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/futura/Futura-Boo.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/futura/Futura-Boo.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/futura/Futura-Boo.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Gill Sans',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/gill-sans/GillSansMTStd-Book.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/gill-sans/GillSansMTStd-Book.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/gill-sans/GillSansMTStd-Book.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Helvetica Regular',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/helvetica-reg/HelveticaNeueLTStd-Roman.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/helvetica-reg/HelveticaNeueLTStd-Roman.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/helvetica-reg/HelveticaNeueLTStd-Roman.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'KievittCE Regular',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/kievit/KievitOT-Regular.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/kievit/KievitOT-Regular.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/kievit/KievitOT-Regular.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'MetaBook Roman',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/metabook-roman/MetaBook-Roman.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/metabook-roman/MetaBook-Roman.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/metabook-roman/MetaBook-Roman.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Myriad',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/myriad/MyriadPro-Regular.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/myriad/MyriadPro-Regular.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/myriad/MyriadPro-Regular.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Trade Gothic',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/trade-gothic/TradeGothicLTStd.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/trade-gothic/TradeGothicLTStd.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/trade-gothic/TradeGothicLTStd.eot) format("eot")` },
      ],
    },
    {
      fontFamily: 'Trebuchet',
      fontWeight: 'normal',
      src: `url(${process.env.API_URL}/assets/fonts/trebuchet/TrebuchetMS.woff) format("woff")`,
      fallbacks: [
        { src: `url(${process.env.API_URL}/assets/fonts/trebuchet/TrebuchetMS.ttf) format("ttf")` },
        { src: `url(${process.env.API_URL}/assets/fonts/trebuchet/TrebuchetMS.eot) format("eot")` },
      ],
    },
  ],
};
