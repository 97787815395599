import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

export default createUseStyles({
  attributeControls: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...midBreak({
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
  radios: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: base(0.5),
    },
  },
  cta: {
    ...midBreak({
      display: 'none',
    }),
  },
  errors: {
    marginBottom: base(2),
  },
});
