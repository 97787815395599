import { createUseStyles } from 'react-jss';
import { midBreak } from '../../css/queries';
import { base } from '../../css/vars';

export default createUseStyles({
  view: {
    position: 'relative',
    padding: `0 ${base(4)}`, // coordinate with 'StickyFooter', BlockContainer, etc...
    ...midBreak({
      padding: `0 ${base(1)}`, // coordinate with 'StickyFooter', BlockContainer, etc...
    }),
  },
});
