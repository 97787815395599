import { createUseStyles } from 'react-jss';
import { base, colors, zIndex } from '../../css/vars';
import { midBreak, largeBreak } from '../../css/queries';

export default createUseStyles({
  addSign: {
    marginBottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    ...largeBreak({
      flexDirection: 'column',
    }),
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  sidebar: {
    position: 'sticky',
    top: base(7),
    flexShrink: 0,
    width: '33.33%',
    marginRight: base(2),
    '& > *:not(:last-child)': {
      marginBottom: base(2),
    },
    ...largeBreak({
      position: 'static',
      width: '100%',
      marginRight: 0,
      marginBottom: base(2),
      '& > *:not(:last-child)': {
        marginBottom: base(),
      },
    }),
  },
  content: {
    width: '66.66%',
    ...largeBreak({
      width: '100%',
    }),
  },
  stickyFooter: {
    backgroundColor: colors.white,
    zIndex: zIndex.modal,
    position: 'sticky',
    bottom: '0',
    width: 'fit-content',
    marginRight: base(-4),
    marginLeft: 'auto',
    padding: base(),
    paddingRight: base(2),
    ...midBreak({
      width: `calc(100% + ${base(2)})`,
      marginLeft: base(-1),
      marginRight: 'unset',
      padding: base(),
    }),
  },
  mobileCTACancel: {
    alignSelf: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: base(2),
    ...midBreak({
      marginTop: base(),
    }),
  },
  headerTitle: {
    flexGrow: 1,
    marginBottom: 0,
    marginRight: base(2),
    ...midBreak({
      marginRight: 0,
    }),
  },
  headerCTAs: {
    flexShrink: 0,
    ...midBreak({
      display: 'none',
    }),
  },
});
