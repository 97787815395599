import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import { useLocation, Redirect } from 'react-router-dom';
import BlockContainer from '../../components/BlockContainer';
import Button from '../../components/Button';
import MarginGrid from '../../components/MarginGrid';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const OrderConfirmation = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  const { state } = useLocation();

  const orderID = state?.data?.id;

  useEffect(() => {
    setCrumbs([{
      label: 'Order Confirmation',
    }]);
  }, [setCrumbs]);

  if (!orderID) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Order Confirmation</title>
        <meta
          name="description"
          content="Your order has been confirmed! You will recieve order confirmation, and you can also visit your Order History to view more details."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.orderConfirmation}>
        <h1>Thank you for your order!</h1>
        <BlockContainer>
          <h2>
            {`Your order ID is: #${orderID}`}
          </h2>
          <p>
            We’ve received your order and it will enter production as soon as possible. At any time you can request your order status by accessing your order history through the account tab.
          </p>
          <MarginGrid>
            <Button
              to={`/account/orders/${orderID}`}
              label="Review your order"
            />
          </MarginGrid>
        </BlockContainer>
      </div>
    </Fragment>
  );
};

export default OrderConfirmation;
