import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../css/vars';
import { button } from '../css';

export default createUseStyles({
  page: {
    ...button,
    cursor: 'pointer',
    marginRight: base(),
  },
  isCurrent: {
    background: colors.lightGray,
    color: colors.darkGray,
    cursor: 'default',
  },
  isLastPage: {
    marginRight: 0,
  },
});
