import React from 'react';
import PropTypes from 'prop-types';
import serialize from './serialize';

const RichText = ({ className, content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className={className}>
      {serialize(content)}
    </div>
  );
};

RichText.defaultProps = {
  className: undefined,
  content: null,
};

RichText.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default RichText;
