import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  printProject: {
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
  },
  back: {
    marginBottom: base(),
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  imageReadyHeader: {
  },
  imageReadyTitle: {
    marginBottom: base(),
  },
  htmlToRasterize: {
    position: 'absolute',
    left: '100%',
    top: '100%',
    opacity: 0,
  },
  autoScale: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '& > *': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 'auto',
      height: 'auto',
    },
  },
  maxWidth: ({ numberOfSigns }) => ({
    display: 'flex',
    position: 'relative',
    ...numberOfSigns > 8
      ? {
        flexWrap: 'wrap',
        width: '160in',
      } : {
        flexWrap: 'nowrap',
        maxWidth: '160in',
      },
  }),
  signSpecDocumentRenderingArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '100px 0',
  },
  footNote: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const printStyles = createUseStyles({
  '@page': {
    size: 'letter landscape',
  },
}, { media: 'print' });
