import { createUseStyles } from 'react-jss';
import { midBreak } from '../../../css/queries';
import { base } from '../../../css/vars';

export default createUseStyles({
  confirmationPrompt: {
    pointerEvents: 'all',
  },
  dialogBox: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    maxWidth: '800px',
    ...midBreak({
      width: '100%',
    }),
  },
});
