import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import {
  mergeSpecs,
  injectBackerPositions,
  formatPartNumber,
  AutoScalingSign,
  signTemplateMeta,
  countBackers,
  signTemplates,
  getSignPrice,
  themes,
  mergeCopy,
  getBackerCode,
} from '@two90signs/290-renderer';
import { Redirect, useParams } from 'react-router-dom';
import useStyles from './css';
import useProject from '../../utilities/data/useProject';
import Loader from '../../components/Loader';
import { fireRequest } from '../../api';
import SpecDocument from '../../components/SpecDocument';
import Button from '../../components/Button';
import Back from '../../components/Back';
import useThrottledEffect from '../../utilities/performance/useThrottledEffect';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import formatDateTime from '../../utilities/formatDateTime';
import Errors from '../../forms/Errors';
import getAllCSS from '../../utilities/getAllCSS';

const scaleCountReducer = (currentCount, payload) => {
  let newCount = currentCount;
  const { type } = payload;
  if (type === 'INCREMENT') newCount += 1;
  return newCount;
};

const PrintSign = () => {
  const [downloadHref, setDownloadHref] = useState('');
  const [scaleCount, dispatchScaleCount] = useReducer(scaleCountReducer, 0);
  const { projectID, signID } = useParams();
  const { isLoading: isLoadingProject, project: loadedProject } = useProject(projectID);
  const [isLoadingSign, setIsLoadingSign] = useState(true);
  const [withMergedSpecs, setWithMergedSpecs] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const pageRef = useRef();
  const { setCrumbs } = useBreadcrumbs();
  const [apiErrors, setAPIErrors] = useState();

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
      url: '/projects',
    }, {
      label: loadedProject?.name || projectID,
      url: `/project/${projectID}`,
      useEllipsis: true,
    }, {
      label: withMergedSpecs?.name || signID,
      url: `/project/${projectID}/${signID}`,
      useEllipsis: true,
    }, {
      label: 'Print',
    }]);
  }, [setCrumbs, projectID, signID, loadedProject, withMergedSpecs]);

  // When the project loads, reduce the result
  useEffect(() => {
    if (!isLoadingProject) {
      if (loadedProject) {
        const {
          theme,
          specs: themeSpecs,
        } = loadedProject;

        const baseThemeSpecs = themes[theme];
        const foundSign = loadedProject.signs.find((sign) => sign.uuid === signID);

        if (foundSign) {
          const {
            specs: signSpecs,
            copy,
            templateID,
          } = foundSign;

          const mergedSignSystem = signSpecs.signSystem || themeSpecs.signSystem;
          const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);
          const withMergedTheme = mergeSpecs(mergedTheme, signSpecs);
          const withMergedCopy = mergeCopy(withMergedTheme, copy[0]);

          const backersWithPositions = injectBackerPositions(withMergedTheme?.backers, mergedSignSystem, templateID);

          setWithMergedSpecs({
            ...foundSign,
            specs: {
              ...withMergedCopy,
              backers: backersWithPositions,
            },
          });
        }
        setIsLoadingSign(false);
      }
      setIsLoadingSign(false);
    }
  }, [isLoadingProject, loadedProject, signID]);

  const {
    templateID,
    quantity,
    specs: mergedSpecs,
  } = withMergedSpecs || {};

  const onScale = useCallback(() => {
    dispatchScaleCount({ type: 'INCREMENT' });
  }, []);

  // IMPORTANT: this effect must be throttled, because 'onScale' will fired any number of times as it receives, processes, and renders out it's given specs
  // Since there's no 'onFinalScale' hook, the throttle needs to be reasonably slow so that it fires only after the final 'onScale event has come through
  // tldr; the goal here is to wait until the sign is finished rendering before rasterizing it, to avoid rasterizing a partially rendered sign
  useThrottledEffect(() => {
    if (scaleCount > 0) {
      const allCSS = getAllCSS();

      const action = async () => {
        const { res: { base64, status }, err } = await fireRequest({
          method: 'post',
          url: `${process.env.API_URL}/generate-pdf`,
          parseBase64: true,
          options: {
            body: JSON.stringify({
              // re-create the html here (do not use document.documentElement) because other things are rendered on the page that we do not want to be downloaded
              // resolution is set by adding style to the body, double for resolution (see ../../components/SpecDocument/css.js)
              htmlTemplate: `<html><head><style>${allCSS} body { width: 22in; height: 17in }</style></head><body>{{{html}}}<body><html>`,
              pages: [{
                html: pageRef.current.outerHTML
                  .replace(/\n\s+|\n/g, '')
                  // Chrome removes the -webkit-background-clip vendor prefix used in <Sign /> for some unknown reason (https://github.com/facebook/react/issues/14200), but is needed by Puppeteer (headless Chrome)
                  .replace(/background-clip: text;/g, '-webkit-background-clip: text;'),
              }],
            }),
          },
        });
        if (err) console.warn(err);
        if (status === 200) {
          setDownloadHref(`data:application/pdf;base64,${base64}`);
        } else if (status > 400) {
          setAPIErrors({
            [status]: `A ${status} error has occurred.`,
          });
        }
        setIsLoadingImage(false);
      };
      action();
    }
  }, 2000, [scaleCount]);

  const classes = useStyles();
  if (isLoadingProject || isLoadingSign) return <Loader fixed />;
  if (!loadedProject || (!isLoadingSign && !withMergedSpecs)) return <Redirect to="/not-found" />;

  const {
    name: projectName,
  } = loadedProject;

  const {
    insertArea,
    overallSizes,
  } = signTemplateMeta?.[templateID] || {};

  const template = signTemplates.find(({ templateID: id }) => id === templateID) || {};
  const { name: templateName } = template;

  const numberOfBackers = countBackers({}, mergedSpecs);
  const partNumber = formatPartNumber({}, mergedSpecs, templateID);
  const formattedInsertArea = insertArea && `${insertArea.height}" x ${insertArea.width}"`;
  const backerCode = getBackerCode(mergedSpecs);
  const formattedBackingArea = overallSizes && `${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`;

  const foundSign = loadedProject.signs.find((sign) => sign.uuid === signID);

  return (
    <div className={classes.printSign}>
      <Helmet>
        <title>
          Print View
        </title>
        <meta
          name="description"
          content="Print your sign."
        />
      </Helmet>
      {apiErrors && (
        <Errors errors={apiErrors} />
      )}
      {isLoadingImage && (
        <Loader
          fixed
          message="Generating pdf..."
        />
      )}
      {!isLoadingImage && downloadHref && (
        <div className={classes.imageReady}>
          <Back
            className={classes.back}
            to={`/project/${projectID}/${signID}`}
            label="Back to sign"
          />
          <div className={classes.imageReadyHeader}>
            <h1 className={classes.imageReadyTitle}>
              Your PDF is ready!
            </h1>
            <div>
              <Button
                htmlElement="a"
                htmlAttributes={{
                  download: `My290-${formatDateTime()}.pdf`,
                  href: downloadHref,
                }}
                label="Download"
              />
            </div>
          </div>
        </div>
      )}
      <div className={classes.htmlToRasterize}>
        <SpecDocument
          signTemplate={templateID}
          ref={pageRef}
          specs={mergedSpecs}
          price={getSignPrice({}, mergedSpecs, templateID)}
          insertArea={formattedInsertArea}
          overallSize={formattedBackingArea}
          partNumber={partNumber}
          projectName={projectName}
          templateID={templateID}
          foundSign={foundSign}
          showSignStandards
          renderingArea={(
            <div className={classes.specDocumentRenderingArea}>
              <AutoScalingSign
                signProps={{
                  ...withMergedSpecs,
                  footNote: (
                    <div className={classes.footNote}>
                      <div>
                        <b>
                          {partNumber}
                        </b>
                      </div>
                      <div>
                        {foundSign.name ?? templateName}
                      </div>
                      {insertArea && (
                        <div>
                          {`Insert area: ${formattedInsertArea}`}
                        </div>
                      )}
                      {numberOfBackers > 0 && overallSizes && (
                        <div>
                          {`Overall size: ${formattedBackingArea}`}
                        </div>
                      )}
                      <div>
                        {`Quantity: ${quantity}`}
                      </div>
                    </div>
                  ),
                }}
                centerInContainer
                onScale={onScale}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default PrintSign;
