import React from 'react';
import PropTypes from 'prop-types';
import CheckmarkIcon from '../../../../icons/CheckmarkIcon';
import useStyles from './css';

const CheckboxBase = (props) => {
  const {
    name,
    disabled,
    onChange,
    value,
    className,
    ghostChecked,
  } = props;

  const checked = Boolean(value);
  const classes = useStyles();

  return (
    <div
      className={[
        classes.checkboxBase,
        className,
      ].filter(Boolean).join(' ')}
    >
      <input
        {...{
          id: name,
          name,
          type: 'checkbox',
          disabled,
          className: [
            classes.htmlInput,
            (checked || ghostChecked) && classes.htmlInputActive,
            ghostChecked && classes.htmlInputGhostActive,
          ].filter(Boolean).join(' '),
          onChange: onChange ? (e) => { onChange(e.target.checked); } : undefined,
          checked,
        }}
      />
      <span
        className={[
          classes.checkmark,
          (checked || ghostChecked) && classes.checkmarkActive,
          ghostChecked && classes.checkmarkGhostActive,
        ].filter(Boolean).join(' ')}
      >
        <CheckmarkIcon color="white" />
      </span>
    </div>
  );
};

CheckboxBase.defaultProps = {
  disabled: false,
  onChange: undefined,
  value: undefined,
  className: undefined,
  ghostChecked: undefined,
};

CheckboxBase.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  className: PropTypes.string,
  ghostChecked: PropTypes.bool,
};

export default CheckboxBase;
