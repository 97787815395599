import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';
import { useCart } from '../../wrappers/CartProvider';
import useStyles from './css';

const CartIcon = (props) => {
  const { color, rotation, className, size } = props;
  const iconClasses = useIconStyles({ color, rotation });
  const { cartIsEmpty } = useCart();
  const classes = useStyles();

  return (
    <div className={classes.cartIcon}>
      {!cartIsEmpty && (
        <span className={classes.indicator} />
      )}
      <svg
        className={[
          iconClasses.wrapper,
          className,
          size && iconClasses[size],
        ].filter(Boolean).join(' ')}
        width="21"
        height="18"
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          className={iconClasses.fill}
          cx="8.34839"
          cy="16.5903"
          rx="1.34839"
          ry="1.29861"
        />
        <ellipse
          className={iconClasses.fill}
          cx="15.3484"
          cy="16.5903"
          rx="1.34839"
          ry="1.29861"
        />
        <path
          className={iconClasses.stroke}
          d="M1 1.00001L4.1871 1L6.63872 11.2709H16.671L19 4.65976H5.5"
        />
      </svg>
    </div>
  );
};

CartIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

CartIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default CartIcon;
