export default () => {
  const allCSS = [...document.styleSheets].map((styleSheet) => {
    try {
      const cssAsText = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
      return cssAsText;
    } catch (e) {
      console.warn('Access to stylesheet %s is denied. Ignoring...', styleSheet.href); // eslint-ignore-line no-console
    }
    return null;
  }).filter(Boolean).join('\n');

  return allCSS;
};
