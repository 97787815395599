import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SpecHeader from '../SpecHeader';
import useStyles from './css';
import ColorSwatchAccordion from '../ColorSwatchAccordion';
import ShadowBox from '../../components/ShadowBox';
import Notice from '../../components/Notice';

const { copyColorsByCategory } = specSheets;
const entries = Object.entries(copyColorsByCategory);

const InsertCopyColors = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    headerLabel,
    noticeMessage,
    specId,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertCopyColors}>
        <SpecHeader
          label={headerLabel || 'Insert Copy Color'}
          to={backTo}
          specId={specId}
        />
        {noticeMessage && (
          <ShadowBox color="white">
            <Notice message={noticeMessage} />
          </ShadowBox>
        )}
        {entries.map(([categoryID, category], index) => {
          const colorsWithID = Object.entries(category).map(([colorID, color]) => ({ ...color, id: colorID }));

          return (
            <ColorSwatchAccordion
              key={index}
              title={categoryID}
              colors={colorsWithID}
              name="copyColor"
              onChange={onChange}
              currentValue={currentValue}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

InsertCopyColors.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  headerLabel: undefined,
  noticeMessage: undefined,
  specId: undefined,
};

InsertCopyColors.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  headerLabel: PropTypes.string,
  noticeMessage: PropTypes.string,
  specId: PropTypes.string,
};

export default InsertCopyColors;
