import { createUseStyles } from 'react-jss';
import { largeBreak } from '../../../../css/queries';
import vars, { base } from '../../../../css/vars';
import { inputShareables } from '../../../../css/other';

export default createUseStyles({
  paymentWrapper: {
    width: '66.66%',
    paddingRight: base(2),
    ...largeBreak({
      paddingRight: 0,
      width: '100%',
    }),
  },
  selectPayment: {
    marginBottom: base(),
    display: 'flex',
    '& > label': {
      paddingRight: base(),
    },
  },
  paymentInput: {
    width: '100%',
    background: 'white',
    borderRadius: `${vars.borderRadius}px`,
    padding: base(),
    ...inputShareables,
  },
  label: {
    marginBottom: base(0.5),
    display: 'block',
  },
});
