import { createUseStyles } from 'react-jss';
import { hexToRGBA } from '@trbl/utils';
import { resetButton } from '../../../css/other';
import { colors, base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

const modalClass = '.my290__modal-item';

export default createUseStyles({
  modal: {
    transition: 'opacity 250ms linear',
    padding: `${base(2)} ${base(4)}`,
    opacity: 0,
    border: 'none',
    width: '100%',
    height: '100%',
    backgroundColor: hexToRGBA(colors.darkGray, 0.8),
    color: colors.white,
    '& h1, h2, h3, h4, h5, h6, a': {
      color: colors.white,
    },
    [`&${modalClass}--appearActive, &${modalClass}--appearDone, &${modalClass}--enterActive, &${modalClass}--enterDone`]: {
      opacity: 1,
    },
    [`&${modalClass}--exitActive, &${modalClass}--exitDone`]: {
      opacity: 0,
    },
    ...midBreak({
      padding: base(),
    }),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    position: 'relative',
    '& h1': {
      flexGrow: 0,
    },
  },
  modalToggler: {
    ...resetButton,
    display: 'block',
    marginLeft: 'auto',
    width: base(),
    height: base(),
    position: 'absolute',
    top: 0,
    right: 0,
  },
  ref: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframeWrapper: {
    position: 'relative',
  },
  iframe: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
});
