import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SpecHeader from '../SpecHeader';
import useStyles from './css';
import ColorSwatchAccordion from '../ColorSwatchAccordion';

const { endCapColorsByCategory } = specSheets;
const entries = Object.entries(endCapColorsByCategory);

const EndCapColors = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.endCapColors}>
        <SpecHeader
          label="End Cap Color"
          to={backTo}
        />
        {entries.map(([categoryID, category], index) => {
          const colorsWithID = Object.entries(category).map(([colorID, color]) => ({ ...color, id: colorID }));

          return (
            <ColorSwatchAccordion
              key={index}
              title={categoryID}
              colors={colorsWithID}
              name="endCapColor"
              onChange={onChange}
              currentValue={currentValue}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

EndCapColors.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
};

EndCapColors.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default EndCapColors;
