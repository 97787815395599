import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';

export default createUseStyles({
  loggedOut: {
    '& a': {
      color: colors.darkGray,
    },
  },
  whatNext: {
    marginTop: base(),
  },
});
