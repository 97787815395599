import React from 'react';
import Helmet from 'react-helmet';
import Button from '../../../components/Button';
import MarginGrid from '../../../components/MarginGrid';
import CardSlider from '../../../components/CardSlider';
import useAPI from '../../../utilities/data/useAPI';
import useStyles from './css';
import defaultImage from '../../../../assets/images/my290-default-image.png';
import staticContent from '../../../staticContent';

const EmptyCart = () => {
  const classes = useStyles();
  const [{ res: productSettingsRes }] = useAPI('get', `${process.env.API_URL}/api/globals/productSettings`);

  return (
    <div className={classes.emptyCart}>
      <Helmet>
        <title>Cart</title>
        <meta
          name="description"
          content="Your cart is currently empty, you can browse the My290 store or create a project."
        />
      </Helmet>
      <div className={classes.section}>
        <h1>
          Cart
        </h1>
        <h3>
          Your cart is currently empty.
        </h3>
        <p>
          {'You can add a project to your cart by either '}
          <Button
            to="/new-project"
            kind="text"
            label="creating a new project"
          />
          {' or browsing '}
          <Button
            to="/projects"
            kind="text"
            label="My Projects"
          />
          {' and adding the project that you’d like to order to your cart. Or, you can '}
          <Button
            to="/store"
            kind="text"
            label="browse more products"
          />
          {' that we have to offer.'}
        </p>
        <MarginGrid>
          <Button
            to="/new-project"
            label="Create new project"
          />
          <Button
            to="/projects"
            color="lightGray"
            label="Browse your projects"
          />
          <Button
            to="/store"
            color="lightGray"
            label="Browse the store"
          />
        </MarginGrid>
      </div>
      {productSettingsRes?.json?.featured?.length > 0 && (
        <div className={classes.section}>
          <CardSlider
            title="Store"
            body={staticContent.storeMessage}
            ctas={(
              <Button
                to="/store"
                color="lightGray"
                label="Shop"
              />
            )}
            cards={productSettingsRes.json.featured.map(({ id: productID, name, price, images }) => ({
              title: name,
              linkTitleTo: `/store/${productID}`,
              image: images?.[0]?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${images[0].image.sizes.card.filename}` : defaultImage,
              price,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyCart;
