import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../css/vars';
import { largeBreak, midBreak } from '../../../css/queries';

export default createUseStyles({
  sidebar: {
    '& > *:not(:last-child)': {
      marginBottom: base(2),
    },
    ...largeBreak({
      width: '100%',
      marginRight: 0,
    }),
    ...midBreak({
      '& > *:not(:last-child)': {
        marginBottom: base(),
      },
    }),
  },
  intro: {
    display: 'flex',
    flexDirection: 'column',
  },
  addToCart: {
    ...midBreak({
      display: 'none',
    }),
  },
  isInCartLink: {
    color: colors.red,
  },
  number: {
    color: colors.darkGray,
  },
});
