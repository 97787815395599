import React, { useEffect, useState, Fragment, useCallback } from 'react';
import Helmet from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import useAPI from '../../utilities/data/useAPI';
import BlockContainer from '../../components/BlockContainer';
import ImageSlider from './ImageSlider';
import AttributeControls from './AttributeControls';
import Submit from '../../forms/fields/Submit';
import { useCart } from '../../wrappers/CartProvider';
import StickyFooter from '../../components/StickyFooter';
import Price from '../../components/Price';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import getProductPrice from '../../utilities/getProductPrice';
import Loader from '../../components/Loader';
import RenderRichText from '../../components/RenderRichText';

const Product = () => {
  const classes = useStyles();
  const { addProductToCart } = useCart();
  const history = useHistory();
  const { setCrumbs } = useBreadcrumbs();
  const [product, setProduct] = useState({});
  const [price, setPrice] = useState();
  const name = product?.name || '';
  const [quantity, setQuantity] = useState(1);
  const [selectedAttributes, setSelectedAttributes] = useState();
  const [hasLoadedProduct, setHasLoadedProduct] = useState(false);
  const { productID } = useParams();
  const [{ isLoading: isLoadingProduct, res: productRes }] = useAPI('get', `${process.env.API_URL}/api/products/${productID}`);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCrumbs([{
      label: 'Store',
      url: '/store',
    }, {
      label: name,
    }]);
  }, [setCrumbs, name]);

  useEffect(() => {
    const result = productRes?.json;

    if (result && Object.keys(result).length > 0) {
      const { attributes } = result;
      const defaultSelectedAttributes = {};

      if (attributes && attributes.length > 0) {
        attributes.forEach(({ name: attributeName, defaultValue }) => {
          defaultSelectedAttributes[attributeName] = defaultValue;
        });
      }

      setProduct(result);
      setPrice(result.price);
      setSelectedAttributes({ ...defaultSelectedAttributes });
      setHasLoadedProduct(true);
    }
  }, [productRes]);

  useEffect(() => {
    setPrice(getProductPrice(product, selectedAttributes));
  }, [price, product, selectedAttributes]);

  const validateFields = useCallback(() => {
    const { attributes } = product;
    const validationErrors = {};
    attributes.forEach((item) => {
      const {
        name: attributeName,
        required,
        label,
      } = item;
      if (required && (!selectedAttributes[attributeName] || selectedAttributes[attributeName] === undefined)) {
        validationErrors[attributeName] = `${label} is required`;
      }
    });

    setErrors(validationErrors);

    return {
      isValid: Object.keys(validationErrors).length === 0,
      validationErrors,
    };
  }, [product, selectedAttributes]);

  if (isLoadingProduct) return <Loader />;

  if (hasLoadedProduct) {
    const {
      images,
      description,
      attributes,
    } = product;

    return (
      <Fragment>
        <Helmet>
          <title>{name}</title>
          <meta
            name="description"
            content="Product details."
          />
        </Helmet>
        <ScrollToTopOnMount />
        <form
          className={classes.product}
          onSubmit={(e) => {
            e.preventDefault();
            const { isValid, validationErrors } = validateFields();

            if (isValid) {
              addProductToCart({
                id: productID,
                name,
                price,
                quantity,
                attributes: Object.entries(selectedAttributes).map(([option, value]) => {
                  const attribute = attributes.find((attr) => attr.name === option);

                  return {
                    option,
                    value,
                    label: attribute?.label,
                  };
                }),
                images,
              });
              history.push('/cart');
            } else {
              setErrors(validationErrors);
            }
          }}
        >
          <BlockContainer className={classes.wrapper}>
            <div className={classes.contentWrapper}>
              <div className={classes.header}>
                <h1>
                  {name}
                </h1>
                <Price price={price} />
              </div>
              <div className={classes.content}>
                <div className={classes.sliderWrapper}>
                  <ImageSlider
                    {...{
                      selectedAttributes,
                      images,
                    }}
                  />
                </div>
                <RenderRichText
                  className={classes.desktopDescription}
                  content={description}
                />
              </div>
            </div>
            <div className={classes.attributesWrapper}>
              <AttributeControls
                {...{
                  attributes,
                  selectedAttributes,
                  setSelectedAttributes,
                  quantity,
                  setQuantity,
                  errors,
                  setErrors,
                }}
              />
            </div>
            <RenderRichText
              className={classes.mobileDescription}
              content={description}
            />
          </BlockContainer>
          <StickyFooter className={classes.mobileCTA}>
            <Submit label="Add to cart" />
          </StickyFooter>
        </form>
      </Fragment>
    );
  }

  return null;
};

export default Product;
