import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { asModal } from '@faceless-ui/modal';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import ShadowBox from '../../../components/ShadowBox';
import MarginGrid from '../../../components/MarginGrid';
import useStyles from './css';
import Input from '../../../forms/fields/Input';
import Errors from '../../../forms/Errors';
import Submit from '../../../forms/fields/Submit';

const slug = 'confirm-action';

const ConfirmationPrompt = (props) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const submitRef = useRef();

  const {
    isOpen,
    modal: {
      open: openModal,
      closeAll: closeModal,
    },
    needsConfirmation,
    setNeedsConfirmation,
    message,
    inputLabel,
    onValidate,
    useInput,
    confirmLabel,
    cancelLabel,
    action,
  } = props;

  const classes = useStyles();

  useEffect(() => () => setName(''), []);

  useEffect(() => {
    if (needsConfirmation) openModal(slug);
  }, [needsConfirmation, openModal]);

  const handleSubmit = useCallback(() => {
    let validity = true;
    if (typeof onValidate === 'function') validity = onValidate(name);
    if (validity) {
      const withoutError = { ...errors };
      delete withoutError.name;
      setErrors(withoutError);
      setSubmitAttempted(false);
      setName(null);
      closeModal();
      if (typeof action === 'function') action();
    } else {
      setSubmitAttempted(true);
      setErrors({ ...errors, name: 'Name must match project' });
    }
  }, [onValidate, errors, action, name, closeModal]);

  const handleInputChange = useCallback((incomingValue) => {
    if (submitAttempted) {
      let validity = true;
      if (typeof onValidate === 'function') validity = onValidate(incomingValue);
      if (validity) {
        const withoutError = { ...errors };
        delete withoutError.name;
        setErrors(withoutError);
      } else setErrors({ ...errors, name: 'Name must match project' });
    }
    setName(incomingValue);
  }, [onValidate, submitAttempted, errors]);

  // use an effect on 'isOpen' that reports to the ActionConfirmationContext
  // this is to account for cases where the modal is closed via `esc` key, or any other way that can't be directly managed in this component
  useEffect(() => {
    if (!isOpen) setNeedsConfirmation(false);
  }, [isOpen, setNeedsConfirmation]);

  useEffect(() => {
    if (isOpen) submitRef.current.focus();
    else submitRef.current.blur();
  }, [isOpen]);

  const ConditionalMessageWrapper = typeof message === 'string' ? 'div' : Fragment;

  return (
    <div className={classes.confirmationPrompt}>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      >
        <ShadowBox
          className={classes.dialogBox}
          color="white"
        >
          {message && (
            <ConditionalMessageWrapper>
              {message}
            </ConditionalMessageWrapper>
          )}
          {useInput && (
            <Fragment>
              <Errors errors={errors} />
              <Input
                id="action-confirmation-input"
                name="name"
                label={inputLabel}
                onChange={(incomingValue) => { handleInputChange(incomingValue); }}
                hasError={Boolean(errors?.name)}
                value={name}
              />
            </Fragment>
          )}
          <MarginGrid>
            <Button
              color="lightGray"
              onClick={() => {
                setName(null);
                closeModal();
              }}
              label={cancelLabel || 'Cancel'}
            />
            <Submit
              ref={submitRef}
              label={confirmLabel || 'Yes'}
            />
          </MarginGrid>
        </ShadowBox>
      </form>
    </div>
  );
};

ConfirmationPrompt.defaultProps = {
  isOpen: undefined,
  needsConfirmation: undefined,
  setNeedsConfirmation: undefined,
  message: undefined,
  confirmLabel: undefined,
  cancelLabel: undefined,
  action: undefined,
  modal: {},
  inputLabel: undefined,
  onValidate: undefined,
  useInput: undefined,
};

ConfirmationPrompt.propTypes = {
  isOpen: PropTypes.bool,
  needsConfirmation: PropTypes.bool,
  setNeedsConfirmation: PropTypes.func,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  action: PropTypes.func,
  modal: PropTypes.shape({
    open: PropTypes.func,
    closeAll: PropTypes.func,
  }),
  inputLabel: PropTypes.string,
  onValidate: PropTypes.func,
  useInput: PropTypes.bool,
};

export default asModal(ConfirmationPrompt, slug);
