import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';
import { midBreak } from '../../css/queries';

const h1Adjustment = 22;
const h2Adjustment = 9;

export default createUseStyles({
  editableHeading: ({ isH1 }) => ({
    paddingTop: isH1 ? `${h1Adjustment}px` : `${h2Adjustment}px`,
    ...midBreak({
      paddingTop: 0,
    }),
  }),
  errorWrapper: {
    marginBottom: ({ inline }) => (inline ? base() : base(2)),
  },
  wrapper: {
    display: ({ inline }) => (inline ? 'flex' : undefined),
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: ({ isH1 }) => (isH1 ? base(-2) : base(-1)),
    padding: ({ isH1 }) => (isH1 ? base(1) : base(0.5)),
    '& > *': {
      margin: ({ isH1 }) => (isH1 ? base(1) : base(0.5)),
    },
    ...midBreak({
      flexDirection: 'column',
    }),
  },
  h2: ({ isH1, darkUI }) => ({
    top: isH1 ? `-${h1Adjustment}px` : `-${h2Adjustment}px`, // adjust for center alignment with 'navigationControls'
    marginBottom: isH1 ? `-${h1Adjustment}px` : `-${h2Adjustment}px`, // counteract the negative top displacement
    position: 'relative',
    hyphens: 'auto',
    fontWeight: 'bold',
    color: darkUI ? colors.white : colors.darkGray,
    '&:focus': {
      outline: 'none',
    },
    ...midBreak({
      top: '0',
      marginBottom: '0',
    }),
  }),
  buttons: {
    flexShrink: 0,
    display: 'flex',
    flexWrap: 'wrap',
    margin: base(-0.25),
    '& > *': {
      display: 'inline-block',
      margin: base(0.25),
    },
  },
  hasError: {
    color: colors.red,
  },
});
