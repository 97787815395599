import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const ResetIcon = (props) => {
  const { color, rotation, className, size } = props;
  const classes = useIconStyles({ color, rotation });

  return (
    <svg
      className={[
        classes.wrapper,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.stroke}
        d="M9 12C12.0376 12 14.5 9.53757 14.5 6.5C14.5 3.46243 12.0376 1 9 1C5.96243 1 3.5 3.46243 3.5 6.5"
      />
      <path
        className={classes.fill}
        d="M3.5 10L0 6.5H7L3.5 10Z"
      />
    </svg>

  );
};

ResetIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

ResetIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default ResetIcon;
