import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SpecHeader from '../SpecHeader';
import useStyles from './css';
import ColorSwatchAccordion from '../ColorSwatchAccordion';

const { baseplateFinishesByCategory } = specSheets;
const entries = Object.entries(baseplateFinishesByCategory);

const BaseplateFinishes = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.baseplateFinishes}>
        <SpecHeader
          label="Baseplate Finish"
          to={backTo}
        />
        {entries.map(([categoryID, category], index) => {
          const colorsWithID = Object.entries(category).map(([colorID, color]) => ({ ...color, id: colorID }));

          return (
            <ColorSwatchAccordion
              key={index}
              title={categoryID}
              colors={colorsWithID}
              name="baseplateFinish"
              onChange={onChange}
              currentValue={currentValue}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

BaseplateFinishes.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
};

BaseplateFinishes.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default BaseplateFinishes;
