import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AutoScalingSign,
  mergeCopy,
  formatPartNumber,
  getSignPrice,
  countBackers,
  signTemplateMeta,
  getBackerCode,
} from '@two90signs/290-renderer';
import { useParams } from 'react-router-dom';
import { useWindowInfo } from '@faceless-ui/window-info';
import Price from '../../../components/Price';
import EditableHeading from '../../../components/EditableHeading';
import useStyles from './css';
import Back from '../../../components/Back';
import ArrowNavigation from '../../../components/ArrowNavigation';
import signNameValidations from '../../../utilities/validations/signName';
import { fireRequest } from '../../../api';
import HiddenDownloadButton from '../../../components/HiddenDownloadButton';
import OnboardingTooltip from '../../../components/OnboardingTooltip';
import { useNotifications } from '../../../wrappers/Notifications';
import getAllCSS from '../../../utilities/getAllCSS';

const Content = (props) => {
  const { setTimedNotification } = useNotifications();
  const signRef = useRef(null);
  const { projectID, signID } = useParams();

  const {
    breakpoints: {
      m: jsMidBreak,
    },
  } = useWindowInfo();

  const {
    signState: {
      name,
      templateID,
      quantity,
      copy,
    },
    dispatchSignState,
    selectedPreviewIndex,
    setSelectedPreviewIndex,
    mergedSpecs,
    darkUI,
  } = props;

  const [scaleState, setScaleState] = useState({});
  const [isDownloadingImage, setIsDownloadingImage] = useState(false);
  const [downloadHref, setDownloadHref] = useState();
  const [signProps, setSignProps] = useState(() => ({
    template: templateID,
    specs: mergeCopy(mergedSpecs, copy[selectedPreviewIndex]),
  }));

  const classes = useStyles({ darkUI });

  useEffect(() => {
    setSignProps({
      template: templateID,
      specs: mergeCopy(mergedSpecs, copy[selectedPreviewIndex]),
    });
  }, [
    mergedSpecs,
    copy,
    selectedPreviewIndex,
    templateID,
  ]);

  const signPrice = getSignPrice({}, mergedSpecs, templateID);
  const {
    insertArea,
    overallSizes,
  } = signTemplateMeta?.[templateID] || {};
  const numberOfBackers = countBackers({}, mergedSpecs);
  const backerCode = getBackerCode(mergedSpecs);

  const onNameSubmit = useCallback((incomingName) => {
    dispatchSignState({
      type: 'UPDATE_SIGN',
      payload: {
        name: incomingName,
      },
    });
  }, [dispatchSignState]);

  const generatePNG = useCallback(() => {
    const action = async () => {
      const {
        originalSize: {
          width: signWidth,
          height: signHeight,
        } = {},
      } = scaleState;

      const allCSS = getAllCSS();

      const { res: { base64 }, err } = await fireRequest({
        method: 'post',
        url: `${process.env.API_URL}/generate-png`,
        options: {
          body: JSON.stringify({
            // re-create the html here (do not use document.documentElement) because other things are rendered on the page that we do not want to be downloaded
            // set resolution with width and height as inline style on the body tag (plus padding to accommodate any shadows), then transform: scale the sign to fit
            htmlTemplate: `<html><head><style>${allCSS} body { width: ${(signWidth * 2) + 200}px; height: ${(signHeight * 2) + 200}px; display: flex; justify-content: center; align-items: center; }</style></head><body><div style="transform: scale(2);">{{{ sign }}}</div><body><html>`,
            html: [{
              sign: signRef.current.outerHTML
                .replace(/\n\s+|\n/g, '')
                // Chrome removes the -webkit-background-clip vendor prefix used in <Sign /> for some unknown reason (https://github.com/facebook/react/issues/14200), but is needed by Puppeteer (headless Chrome)
                .replace(/background-clip: text;/g, '-webkit-background-clip: text;'),
            }],
            transparent: true,
          }),
        },
        parseBase64: true,
      });

      if (err) console.warn(err);
      if (base64) setDownloadHref(`data:image/png;base64,${base64}`);
    };

    action();
  }, [scaleState]);

  const onDownload = useCallback(() => {
    setDownloadHref(undefined);
    setIsDownloadingImage(false);
    setTimedNotification({
      id: 'pngDownloadCompleted',
      message: 'Your PNG has been downloaded!',
    });
  }, [setTimedNotification]);

  useEffect(() => {
    if (isDownloadingImage) generatePNG();
  }, [isDownloadingImage, generatePNG]);

  const onScale = useCallback((incomingScaleState) => {
    setScaleState({ ...incomingScaleState });
  }, []);

  return (
    <div className={classes.content}>
      <HiddenDownloadButton
        href={downloadHref}
        onDownload={onDownload}
      />
      <header className={classes.header}>
        <Back
          to={`/project/${projectID}`}
          label="Back to project"
          darkUI={darkUI}
        />
        <EditableHeading
          onSubmit={onNameSubmit}
          initialValue={name}
          onValidate={(incomingName) => {
            const isValid = incomingName.match(signNameValidations.regex);
            if (!isValid) return signNameValidations.message;
            return true;
          }}
          requiredMessage={signNameValidations.requiredMessage}
          inline
          darkUI={darkUI}
          additionalButtons={[
            {
              flexReverse: true,
              icon: 'document',
              label: 'Print view',
              to: `/project/${projectID}/${signID}/print`,
              tooltip: true,
              tooltipContent: {
                position: 'topLeft',
                contentPosition: 'right',
                title: 'Select to download sign family and individual sign drawings',
                message: 'Under profile settings select to display or hide pricing',
              },
            },
            {
              flexReverse: true,
              icon: 'download',
              label: isDownloadingImage ? 'downloading...' : 'png',
              onClick: () => {
                if (!isDownloadingImage) { // protect against brute force
                  setIsDownloadingImage(true);
                }
              },
            },
          ]}
        />
        <div className={classes.meta}>
          <span>
            {formatPartNumber({}, mergedSpecs, templateID)}
          </span>
          <span className={classes.metaGuts}>
            {insertArea && (
              <span>
                {' '}
                &mdash;
                {' '}
                {`Insert area: ${insertArea.height}" x ${insertArea.width}"`}
              </span>
            )}
            {numberOfBackers > 0 && overallSizes && (
              <span>
                {' '}
                &mdash;
                {' '}
                {`Overall size: ${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`}
              </span>
            )}
          </span>
          <span>
            {' '}
            &mdash;
            {' '}
            <Price price={signPrice} />
          </span>
        </div>
        {quantity > 1 && (
          <div style={{ position: 'relative' }}>
            <ArrowNavigation
              onPrev={(incomingIndex) => setSelectedPreviewIndex(incomingIndex)}
              onNext={(incomingIndex) => setSelectedPreviewIndex(incomingIndex)}
              total={quantity}
              currentIndex={selectedPreviewIndex}
            />
            <OnboardingTooltip
              position="topLeft"
              title="View additional signs"
              message="Scroll with arrows to see additional signs."
            />
          </div>
        )}
      </header>
      <div className={classes.body}>
        <AutoScalingSign
          signProps={signProps}
          centerInContainer
          enableShadow
          onScale={onScale}
          signRef={signRef}
          scaleType={jsMidBreak ? 'width' : 'objectFit'}
        />
      </div>
    </div>
  );
};

Content.defaultProps = {
  dispatchSignState: undefined,
  signState: undefined,
  selectedPreviewIndex: undefined,
  setSelectedPreviewIndex: undefined,
  mergedSpecs: undefined,
  darkUI: undefined,
};

Content.propTypes = {
  signState: PropTypes.shape({
    name: PropTypes.string,
    templateID: PropTypes.string,
    price: PropTypes.number,
    specs: PropTypes.shape({
      signSystem: PropTypes.string,
    }),
    quantity: PropTypes.number,
    copy: PropTypes.arrayOf(
      PropTypes.shape({
        inserts: PropTypes.arrayOf(
          PropTypes.shape({
            copy: PropTypes.string,
          }),
        ),
      }),
    ),
    inserts: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
  dispatchSignState: PropTypes.func,
  selectedPreviewIndex: PropTypes.number,
  setSelectedPreviewIndex: PropTypes.func,
  mergedSpecs: PropTypes.shape({
    signSystem: PropTypes.string,
  }),
  darkUI: PropTypes.bool,
};

export default Content;
