import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../css/vars';
import { midBreak } from '../../css/queries';
import { resetButton } from '../../css/other';

export default createUseStyles({
  copy: {
    position: 'relative',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
  collapsible: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  collapsibleHeader: {
    padding: base(2),
    position: 'relative',
    ...midBreak({
      padding: base(),
    }),
  },
  seperator: {
    height: `${vars.strokeWidth}px`,
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    backgroundColor: colors.lightGray,
  },
  collapsibleHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  collapsibleTitle: {
    ...resetButton,
    flexGrow: '1',
    textAlign: 'left',
    marginRight: base(),
    color: colors.darkGray,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  collapsibleIcon: {
    ...resetButton,
    flexShrink: '0',
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
  collapsibleContent: {
    padding: base(2),
    '& > *:not(:last-child)': {
      marginBottom: base(0.5),
    },
    ...midBreak({
      padding: base(1),
    }),
  },
  backToInserts: {
    color: colors.darkGray,
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(0.5),
    },
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  lines: {
    '& > *:not(:last-child)': {
      marginBottom: base(0.5),
    },
  },
});
