import React, { useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import BlockContainer from '../../components/BlockContainer';
import Filter from './Filter';
import Results from './Results';
import useAPI from '../../utilities/data/useAPI';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import { useAuthentication } from '../../wrappers/Authentication';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const MyProducts = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();
  const { user } = useAuthentication();

  const apiSearchQuery = {
    where: {},
    sort: '-sortPriority',
    limit: 20,
  };

  if (user?.organization?.id) {
    apiSearchQuery.where.organization = {
      equals: user.organization.id,
    };
  }

  const apiSearchQueryString = qs.stringify(apiSearchQuery, { addQueryPrefix: true, encode: false });

  const [{ res: categoriesRes }] = useAPI('get', `${process.env.API_URL}/api/categories${apiSearchQueryString}`);

  useEffect(() => {
    setCrumbs([{
      label: 'My Products',
    }]);
  }, [setCrumbs]);

  const allCategories = categoriesRes?.json?.docs || [];

  if (user?.organization) {
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <div className={classes.products}>
          <h1>
            My Products
          </h1>
          <BlockContainer className={classes.wrapper}>
            <div className={classes.sidebar}>
              <Filter allCategories={allCategories} />
            </div>
            <div className={classes.content}>
              <Results allCategories={allCategories} />
            </div>
          </BlockContainer>
        </div>
      </Fragment>
    );
  }
  return <Redirect to="/store" />;
};

export default MyProducts;
