import React, { useEffect, Fragment, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import qs from 'qs';
import BlockContainer from '../../../components/BlockContainer';
import { useBreadcrumbs } from '../../../wrappers/BreadcrumbsProvider';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import Table from '../../../components/Table';
import useAPI from '../../../utilities/data/useAPI';
import { useAuthentication } from '../../../wrappers/Authentication';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';

import useStyles from './css';
import Loader from '../../../components/Loader';
import Price from '../../../components/Price';
import formatDateTime from '../../../utilities/formatDateTime';

const createOrderAPIURL = (userID, organizationID, page) => {
  if (userID) {
    const where = {
      or: [
        {
          orderedBy: {
            user: {
              equals: userID,
            },
          },
        },
        ...organizationID ? [{
          orderedBy: {
            organization: {
              equals: organizationID,
            },
          },
        }] : [],
      ],
    };

    const stringifiedQuery = qs.stringify({
      where,
      page,
    }, { encode: false });

    return `${process.env.API_URL}/api/orders?${stringifiedQuery}`;
  }

  return null;
};

const OrderHistory = () => {
  const { setCrumbs } = useBreadcrumbs();
  const classes = useStyles();
  const { user } = useAuthentication();
  const { search } = useLocation();
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
  const { page } = parsedSearch;

  const {
    id: userID,
    organization,
  } = user;

  const organizationID = organization?.id; // Must use optional chaining here, organization may be null, so destructuring 'id' will fail

  const [orderURL, setOrderURL] = useState(() => createOrderAPIURL(userID, organizationID, page));
  const [{ res: ordersRes, isLoading }] = useAPI('get', orderURL);
  const { json: { docs: orders } = {} } = ordersRes || {};

  const [columns] = useState([
    {
      accessor: 'id',
      components: {
        Heading: 'Order Number',
        renderCell: (rowData, data) => {
          const {
            id,
          } = rowData;
          return (
            <Link
              to={`/account/orders/${id}`}
              className={classes.orderID}
            >
              <strong>{data}</strong>
            </Link>
          );
        },
      },
    },
    {
      accessor: 'orderedBy',
      components: {
        Heading: 'Ordered By',
        renderCell: (_, data) => `${data?.firstName} ${data?.lastName}`,
      },
    },
    {
      accessor: 'total',
      components: {
        Heading: 'Total',
        renderCell: (rowData, data) => {
          const {
            discount,
          } = rowData;
          return (
            <Price
              price={data / (1 - discount)} // price already includes discount, so reverse it
              discount={discount}
              neverHide
            />
          );
        },
      },
    },
    {
      accessor: 'createdAt',
      components: {
        Heading: 'Date Placed',
        renderCell: (_, data) => formatDateTime(data),
      },
    },
    {
      components: {
        Heading: '',
        renderCell: (rowData) => {
          const {
            id,
          } = rowData;
          return (
            <div className={classes.requestStatus}>
              <Button
                size="xs"
                label="Request Status"
                color="lightGray"
                htmlElement="a"
                htmlAttributes={{
                  href: `mailto:info@290signs.com?subject=${encodeURIComponent(`Please send me the status of order #${id}`)}&body=${encodeURIComponent(`My User ID is ${userID}. Thank you!`)}`,
                  target: '_blank',
                }}
              />
            </div>
          );
        },
      },
    },
  ]);

  useEffect(() => {
    setOrderURL(createOrderAPIURL(userID, organizationID, page));
  }, [userID, organizationID, page]);

  useEffect(() => {
    setCrumbs([{
      label: 'Account',
      url: '/account',
    }, {
      label: 'Orders',
    }]);
  }, [setCrumbs]);

  if (isLoading && orders === undefined) return <Loader fixed />;
  if (!orders) return <Redirect to="/not-found" />;

  const hasOrders = Array.isArray(orders) && orders?.length > 0;

  return (
    <Fragment>
      <Helmet>
        <title>Order History</title>
        <meta
          name="description"
          content="Browse your order history and see more details about your previous orders."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.orderHistory}>
        <BlockContainer>
          {hasOrders
            ? (
              <div className={classes.tableWrap}>
                <Table
                  className={classes.table}
                  data={orders}
                  columns={columns}
                />
              </div>
            ) : (
              <Fragment>
                <h3>
                  You have no previous orders!
                </h3>
                <span>
                  {'Start your first order by  '}
                  <Button
                    to="/new-project"
                    kind="text"
                    label="creating a new project"
                  />
                  {' or '}
                  <Button
                    to="/store"
                    kind="text"
                    label="browse the store"
                  />
                  {' to see what we offer. If you have already started a project, you may '}
                  <Button
                    to="/projects"
                    kind="text"
                    label="continue working on it"
                  />
                  .
                </span>
              </Fragment>
            )}
        </BlockContainer>
      </div>
      {ordersRes?.json && (
        <Pagination
          limit={ordersRes.json.limit}
          totalPages={ordersRes.json.totalPages}
          page={ordersRes.json.page}
          hasPrevPage={ordersRes.json.hasPrevPage}
          hasNextPage={ordersRes.json.hasNextPage}
          prevPage={ordersRes.json.prevPage}
          nextPage={ordersRes.json.nextPage}
        />
      )}
    </Fragment>
  );
};

export default OrderHistory;
