import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoScalingSign, mergeSpecs, injectBackerPositions, themes } from '@two90signs/290-renderer';
import useStyles from './css';
import ArrowNavigation from '../../../../components/ArrowNavigation';

const MobileSignArea = (props) => {
  const {
    signs,
    theme,
    themeSpecs,
  } = props;

  const baseThemeSpecs = themes[theme];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [signSpecs, setSignSpecs] = useState();

  useEffect(() => {
    if (signs && signs.length > 0) {
      const currentSign = signs[currentIndex];

      if (currentSign) {
        const {
          specs,
          templateID,
        } = currentSign;

        const { signSystem } = specs;
        const mergedSignSystem = signSystem || themeSpecs.signSystem;
        const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);
        const withMergedTheme = mergeSpecs(mergedTheme, specs);
        const backersWithPositions = injectBackerPositions(withMergedTheme.backers, mergedSignSystem, templateID);

        setSignSpecs({
          ...withMergedTheme,
          backersWithPositions,
        });
      }
    }
  }, [themeSpecs, signs, currentIndex, baseThemeSpecs]);

  const classes = useStyles({});

  return (
    <div className={classes.mobileSignArea}>
      <ArrowNavigation
        total={signs.length || 0}
        currentIndex={currentIndex}
        onPrev={(incomingIndex) => setCurrentIndex(incomingIndex)}
        onNext={(incomingIndex) => setCurrentIndex(incomingIndex)}
      />
      <div className={classes.signWrapper}>
        <AutoScalingSign
          signProps={{ specs: signSpecs }}
          scaleType="width"
        />
      </div>
    </div>
  );
};

MobileSignArea.defaultProps = {
  signs: undefined,
  theme: undefined,
  themeSpecs: undefined,
};

MobileSignArea.propTypes = {
  signs: PropTypes.arrayOf(
    PropTypes.shape({
      templateID: PropTypes.string,
      specs: PropTypes.shape({
        signSystem: PropTypes.string,
      }),
    }),
  ),
  theme: PropTypes.string,
  themeSpecs: PropTypes.shape({
    signSystem: PropTypes.string,
  }),
};

export default MobileSignArea;
