import { useState, useEffect } from 'react';
import { fireRequest } from '../../api';

export default (method, url, options, sleepDuration) => {
  const [res, setRes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [internalError, setInternalError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      if (url) {
        setIsLoading(true);
        const { res: resFromFetch, err } = await fireRequest({
          method,
          url,
          options: {
            ...options || {},
            signal: abortController.signal,
          },
          sleepDuration,
        });
        if (err) setInternalError(err);
        else setRes(resFromFetch);
      }
    };

    fetchData();

    return () => abortController.abort();
  }, [method, url, options, sleepDuration]);

  useEffect(() => res && setIsLoading(false), [res]); // set the loading status in a separate effect to avoid batch updates / ensure correct order of execution

  return [{ isLoading, res, internalError }];
};
