import { createUseStyles } from 'react-jss';
import { hexToRGBA } from '@trbl/utils';
import vars, { colors, base } from '../../css/vars';
import { midBreak } from '../../css/queries';
import { resetButton } from '../../css/other';

const modalClass = '.my290__modal-item';

export default createUseStyles({
  modal: {
    transition: `${vars.defaultTransTime}ms ${vars.defaultTransCurve} transform`,
    margin: '0',
    left: 'auto',
    right: '0',
    backgroundColor: hexToRGBA(colors.red, 0.93),
    color: colors.white,
    '& h1, h2, h3, h4, h5, h6, a': {
      color: colors.white,
    },
    padding: `${base(2.25)} ${base(4)} ${base(2)}`, // ensure the close icon aligns with the hamburger behind it, the logo is 'base(1.5)' tall so add an additional '0.25' to padding-top
    height: '100%',
    width: '600px',
    transform: 'translate3d(100%, 0, 0)',
    border: 'none',
    [`&${modalClass}--appearActive, &${modalClass}--appearDone, &${modalClass}--enterActive, &${modalClass}--enterDone`]: {
      transform: 'translate3d(0, 0, 0)',
    },
    [`&${modalClass}--exitActive, &${modalClass}--exitDone`]: {
      transform: 'translate3d(100%, 0, 0)',
    },
    ...midBreak({
      width: '100%',
      padding: base(1),
    }),
  },
  closeButton: {
    ...resetButton,
    display: 'block',
    marginLeft: 'auto',
    cursor: 'pointer',
    width: base(),
    height: base(),
  },
});
