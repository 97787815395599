import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AutoScalingSign,
  signTemplateMeta,
  mergeCopy,
  formatPartNumber,
  countBackers,
  getSignPrice,
  injectBackerPositions,
  mergeSpecs,
  themes,
  getBackerCode,
} from '@two90signs/290-renderer';
import { Link } from 'react-router-dom';
import ArrowNavigation from '../../../../components/ArrowNavigation';
import Card from '../../../../components/Card';
import OnboardingTooltip from '../../../../components/OnboardingTooltip';
import useStyles from './css';
import { useActionConfirmation } from '../../../../wrappers/ActionConfirmation';

const FormattedCard = (props) => {
  const { confirmAction } = useActionConfirmation();
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

  const {
    onDelete,
    sign,
    project,
    index,
  } = props;

  const {
    uuid: projectID,
    wallColor: projectWallColor,
    theme,
    specs: themeSpecs,
  } = project;

  const {
    uuid: signID,
    name: signName,
    wallColor,
    specs: signSpecs,
    copy,
    templateID,
    quantity,
  } = sign;

  const baseThemeSpecs = themes[theme];
  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

  const classes = useStyles();

  const {
    insertArea,
    overallSizes,
  } = signTemplateMeta?.[templateID] || {};

  const numberOfBackers = countBackers(mergedTheme, signSpecs);
  const withMergedCopy = mergeCopy(signSpecs, copy[currentPreviewIndex]);
  const withMergedTheme = mergeSpecs(mergedTheme, withMergedCopy);
  const backersWithPositions = injectBackerPositions(withMergedTheme.backers, withMergedTheme.signSystem, templateID);
  const backerCode = getBackerCode(withMergedTheme);

  return (
    <Card
      className={classes.formattedCard}
      title={signName}
      linkTitleTo={`/project/${projectID}/${signID}`}
      subtitle={(
        <div>
          <div>
            {formatPartNumber(themeSpecs, signSpecs, templateID)}
          </div>
          {insertArea && (
            <div className={classes.signSize}>
              {`Insert area: ${insertArea.height}" x ${insertArea.width}"`}
            </div>
          )}
          {numberOfBackers > 0 && overallSizes && (
            <div className={classes.signSize}>
              {`Overall size: ${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`}
            </div>
          )}
        </div>
      )}
      price={getSignPrice(themeSpecs, signSpecs, templateID)}
      enableImagePadding
      ctas={[
        {
          tooltipLabel: 'Edit',
          icon: 'edit',
          to: `/project/${projectID}/${signID}`,
          AdditionalComponent: index === 0 ? (
            <OnboardingTooltip
              position="topLeft"
              contentPosition="right"
              title="Edit individual sign specs"
              message="You can override the base specs by editing each individual sign. You can also select mounting option, add quantity, and personalize copy."
            />
          ) : null,
        },
        {
          tooltipLabel: 'Delete',
          icon: 'close',
          onClick: () => {
            confirmAction({
              message: (
                <Fragment>
                  <div>
                    {'Are you sure you want to delete '}
                    <b>{signName || 'this sign'}</b>
                    {'? You will have to add '}
                    <b>
                      {`Sign Type ${templateID}`}
                    </b>
                    {' back to this project and start over if you change your mind. This action cannot be undone.'}
                  </div>
                  <div>
                    Press
                    <b>
                      {' enter '}
                    </b>
                    to confirm, or
                    <b>
                      {' esc '}
                    </b>
                    to cancel.
                  </div>
                </Fragment>
              ),
              cancelLabel: 'No, thank you!',
              confirmLabel: 'Yes, delete!',
              action: () => { onDelete(signID); },
            });
          },
        },
      ]}
      customImg={(
        <div className={classes.cardImage}>
          <Link to={`/project/${projectID}/${signID}`}>
            <AutoScalingSign
              hideWhenOffscreen
              signProps={{
                specs: {
                  ...withMergedTheme,
                  backers: backersWithPositions,
                },
              }}
              centerInContainer
            />
          </Link>
          {quantity > 1 && (
            <ArrowNavigation
              className={classes.pagination}
              total={quantity}
              currentIndex={currentPreviewIndex}
              onPrev={(incomingValue) => setCurrentPreviewIndex(incomingValue)}
              onNext={(incomingValue) => setCurrentPreviewIndex(incomingValue)}
              size="s"
            />
          )}
        </div>
      )}
      imageBackgroundColor={wallColor || projectWallColor}
    />
  );
};

FormattedCard.defaultProps = {
  onDelete: undefined,
  project: undefined,
  sign: undefined,
  index: undefined,
};

FormattedCard.propTypes = {
  index: PropTypes.number,
  onDelete: PropTypes.func,
  project: PropTypes.shape({
    uuid: PropTypes.string,
    wallColor: PropTypes.string,
    theme: PropTypes.string,
    specs: PropTypes.shape({}),
  }),
  sign: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    wallColor: PropTypes.string,
    specs: PropTypes.shape({}),
    copy: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    templateID: PropTypes.string,
    quantity: PropTypes.number,
  }),
};

export default FormattedCard;
