const getProductPrice = (product, selectedAttributes) => {
  const { price, attributes } = product;

  if (attributes && attributes.length > 0 && Object.keys(selectedAttributes).length > 0) {
    return Object.entries(selectedAttributes).reduce((newPrice, [attrKey, val]) => {
      const attribute = attributes.find((attr) => attr.name === attrKey);
      if (attribute) {
        if (attribute.blockType === 'select') {
          const option = attribute.options.find((opt) => opt.value === val);

          if (option?.priceAdjustment) {
            return newPrice + option.priceAdjustment;
          }
        }

        if (attribute.blockType === 'checkbox' && attribute.priceAdjustment && val) {
          return newPrice + attribute.priceAdjustment;
        }
      }

      return newPrice;
    }, price);
  }

  return price;
};

export default getProductPrice;
