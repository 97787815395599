import React from 'react';
import { createUseStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import { colors, base } from '../../css/vars';
import { largeBreak } from '../../css/queries';

const useStyles = createUseStyles({
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
  },
  crumb: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    '&:last-child': {
      color: colors.darkGray,
    },
    '&:not(:last-child)': {
      marginRight: base(0.5),
    },
    ...largeBreak({
      '&:not(:last-child)': {
        marginRight: base(0.25),
      },
    }),
    '& a': {
      textDecoration: 'none',
    },
    '&:hover a': {
      textDecoration: 'underline',
    },
  },
  seperator: {
    marginRight: base(0.5),
    '& > path': {
      fill: 'none',
      stroke: colors.gray,
    },
    ...largeBreak({
      marginRight: base(0.25),
    }),
  },
  label: {
    whiteSpace: 'nowrap',
  },
  labelEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: base(6),
    ...largeBreak({
      maxWidth: base(4),
    }),
  },
});

const SlashSVG = () => {
  const classes = useStyles();

  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.seperator}
    >
      <path d="M6.42799 0.164032L1.47235 17.164" />
      <path d="M9.96761 0.164032L5.01196 17.164" />
    </svg>
  );
};

const Breadcrumbs = (props) => {
  const { className } = props;
  const classes = useStyles();
  const { crumbs } = useBreadcrumbs();

  return (
    <div className={classes.breadcrumbs}>
      {Array.isArray(crumbs) && crumbs.length > 0 && crumbs.map((crumb, index) => {
        const { label, url, useEllipsis } = crumb;

        return (
          <div
            key={index}
            className={[
              classes.crumb,
              className,
            ].filter(Boolean).join(' ')}
          >
            <SlashSVG />
            <div
              className={[
                classes.label,
                useEllipsis && classes.labelEllipsis,
              ].filter(Boolean).join(' ')}
            >
              {url && (
                <NavLink to={url}>
                  <span>
                    {label}
                  </span>
                </NavLink>
              )}
              {!url && (
                <span>
                  {label}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

Breadcrumbs.defaultProps = {
  className: undefined,
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
};

export default Breadcrumbs;
