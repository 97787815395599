import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useStyles from './css';
import Button from '../../../../../components/Button';

const NestedSpecLink = (props) => {
  const {
    label,
    to,
    currentValue,
    currentLabel,
    arrow,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.nestedSpecLink}>
      <Link
        to={to}
        className={classes.wrapper}
      >
        <div className={classes.label}>
          {label}
        </div>
        {currentValue && (
          <div className={classes.currentValue}>
            {currentLabel}
          </div>
        )}
        {arrow && (
          <Button
            size="s"
            icon="arrow"
            color="lightGray"
            iconRotation={180}
          />
        )}
      </Link>
    </div>
  );
};

NestedSpecLink.defaultProps = {
  label: undefined,
  currentValue: undefined,
  currentLabel: undefined,
  to: undefined,
  arrow: undefined,
};

NestedSpecLink.propTypes = {
  label: PropTypes.string,
  currentValue: PropTypes.string,
  currentLabel: PropTypes.string,
  to: PropTypes.string,
  arrow: PropTypes.bool,
};

export default NestedSpecLink;
