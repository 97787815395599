import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';

export default createUseStyles({
  notice: {
    backgroundColor: colors.lighterGray,
    padding: base(0.5),
    margin: `0 -${base(0.5)}`,
    width: `calc(100% + ${base()})`,
  },
});
