import { createUseStyles } from 'react-jss';
import vars, { colors, base } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  card: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: `${vars.borderRadius}px`,
    backgroundColor: colors.white,
    // boxShadow: shadows.s, shadows are cutoff within sliders
    color: colors.darkGray,
  },
  image: ({ imageBackgroundColor }) => ({
    position: 'relative',
    height: '0',
    width: '100%',
    paddingBottom: '80%', // 4:5 aspect ratio
    borderBottom: `1px solid ${colors.lightGray}`,
    backgroundColor: imageBackgroundColor || colors.white,
    borderTopLeftRadius: `${vars.borderRadius}px`,
    borderTopRightRadius: `${vars.borderRadius}px`,
  }),
  imageInnerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: ({ enableImagePadding }) => (enableImagePadding ? base(0.5) : undefined),
    overflow: 'hidden',
  },
  htmlImage: {
    objectFit: 'cover',
    maxWidth: 'initial',
    width: '100%',
    height: '100%',
  },
  customImg: {
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: base(),
    flexGrow: '1',
  },
  header: {
    flexGrow: '1',
    display: 'flex',
    ...midBreak({
      flexDirection: 'column',
    }),
  },
  titleWrapper: {
    flexGrow: 1,
    '& > *:not(:last-child)': {
      marginBottom: base(0.25),
    },
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  title: {
    hyphens: 'auto',
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  ctas: {
    position: 'relative',
    flexShrink: '0',
    marginLeft: base(),
    display: 'flex',
    '& > *:not(:last-child)': {
      display: 'inline-block',
      marginRight: base(0.25),
    },
    ...midBreak({
      marginLeft: 0,
    }),
  },
  footer: {
    '& > *:first-child': {
      marginTop: base(0.5),
    },
  },
});
