import { createUseStyles } from 'react-jss';
import { base } from '../../../../css/vars';

export default createUseStyles({
  mobileSignArea: {
    // height: '600px',
  },
  signWrapper: {
    padding: `${base()} 0`,
  },
});
