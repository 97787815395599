import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ModalToggler } from '@faceless-ui/modal';
import { useScrollInfo } from '@faceless-ui/scroll-info';
import MainMenuDrawer from './MainMenuDrawer';
import AuthDrawer from './AuthDrawer';
import Logo from '../Logo';
import Hamburger from './Hamburger';
import ShoppingCart from '../../icons/CartIcon';
import AccountIcon from '../../icons/AccountIcon';
import { useAuthentication } from '../../wrappers/Authentication';
import Breadcrumbs from '../Breadcrumbs';
import useStyles from './css';

const Header = () => {
  const location = useLocation();
  const classes = useStyles();
  const [hasScrolled, setHasScrolled] = useState(false);

  const { isLoggedIn, user } = useAuthentication();
  const {
    eventsFired: scrollEvents,
    y: scrollY,
  } = useScrollInfo();

  useEffect(() => {
    if (scrollEvents > 0) {
      if (scrollY > 0) setHasScrolled(true);
      else setHasScrolled(false);
    }
  }, [
    scrollEvents,
    scrollY,
  ]);

  return (
    <header
      className={[
        classes.header,
        hasScrolled && classes.hasScrolled,
      ].filter(Boolean).join(' ')}
    >
      <div className={classes.logoWrapper}>
        <Link to="/">
          <Logo className={classes.logo} />
        </Link>
        <Breadcrumbs className={classes.breadcrumbs} />
      </div>
      {isLoggedIn && (
        <Link
          to="/account"
          className={[
            classes.accountLink,
            location.pathname === '/account' && classes.accountLinkActive,
          ].filter(Boolean).join(' ')}
        >
          <b>
            {user?.email || 'Account'}
          </b>
        </Link>
      )}
      <menu className={classes.menu}>
        <Link to="/cart">
          <ShoppingCart color="darkGray" />
        </Link>
        {!isLoggedIn && (
          <ModalToggler
            slug="auth"
            className={classes.modalToggler}
          >
            <AccountIcon color="darkGray" />
          </ModalToggler>
        )}
        <Hamburger />
      </menu>
      <MainMenuDrawer />
      <AuthDrawer />
    </header>
  );
};

export default Header;
