
import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const DocumentIcon = (props) => {
  const { color, rotation, className, size } = props;
  const classes = useIconStyles({ color, rotation });

  return (
    <svg
      className={[
        classes.wrapper,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        className={classes.stroke}
        points="2.014 10 2 0.986 13.014 0.986 13.014 14 6.014 14 2.014 10"
      />
      <polyline
        className={classes.stroke}
        points="6.014 14 6.014 10 2.014 10"
      />
    </svg>
  );
};

DocumentIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

DocumentIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default DocumentIcon;
