import { breakpoints } from './vars';

export const midBreak = (styles) => ({
  [`@media(max-width: ${breakpoints.m}px)`]: {
    ...styles,
  },
});

export const largeBreak = (styles) => ({
  [`@media(max-width: ${breakpoints.l}px)`]: {
    ...styles,
  },
});

export const desktopBreak = (styles) => ({
  [`@media(max-width: ${breakpoints.xl}px)`]: {
    ...styles,
  },
});

export default {
  midBreak,
  largeBreak,
  desktopBreak,
};
