import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

// Scroll restoration always takes places for 'POP' events (browser navigation)
// Opt-in to scroll restoration for 'push' events (like links) by sending 'attemptScrollRestore: true' through react router state
// This component uses localStorage to save an object whose keys are every route visited, each with the last known scrollX and scrollY ({ '/home': { scrollX: 0, scrollY: 149 } })

const RestoreScrollPosition = (props) => {
  const {
    scrollDelay,
    localStorageKey,
    scrollToFunction,
    skipScrollTo,
    includeSearch,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const prevLocation = useRef(location);

  const restoreScrollPosition = useCallback((route) => {
    const storedPositions = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
    const storedPosition = storedPositions[route];

    let scrollToX;
    let scrollToY;

    if (storedPosition) {
      const {
        scrollX: storedScrollX,
        scrollY: storedScrollY,
      } = storedPosition;

      scrollToX = storedScrollX;
      scrollToY = storedScrollY;
    }

    if (scrollToX || scrollToY) {
      setTimeout(() => {
        if (typeof scrollToFunction === 'function') scrollToFunction(scrollToX, scrollToY);
        else window.scrollTo(scrollToX, scrollToY);
      }, scrollDelay);
    }
  }, [localStorageKey, scrollDelay, scrollToFunction]);

  const saveScrollPosition = useCallback((route, scrollX, scrollY) => {
    const storedPositions = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

    storedPositions[route] = {
      scrollX,
      scrollY,
    };

    localStorage.setItem(localStorageKey, JSON.stringify(storedPositions));
  }, [localStorageKey]);

  useEffect(() => {
    const unlisten = history.listen((incomingLocation, action) => {
      const {
        pathname,
        search,
        state: incomingLocationState,
      } = incomingLocation;

      const { attemptScrollRestore } = incomingLocationState || {};

      const {
        pathname: pathnameFrom,
        search: searchFrom,
      } = prevLocation.current || {};

      const routeTo = `${pathname}${includeSearch ? search : ''}`;
      const routeFrom = `${pathnameFrom}${includeSearch ? searchFrom : ''}`;

      if (action === 'POP' || attemptScrollRestore) {
        restoreScrollPosition(routeTo);
      }

      if (action === 'PUSH') {
        if (routeTo !== routeFrom && !skipScrollTo) {
          saveScrollPosition(routeFrom, window.scrollX, window.scrollY);
          prevLocation.current = incomingLocation;
        }
      }
    });

    return () => unlisten();
  }, [history, restoreScrollPosition, saveScrollPosition, skipScrollTo, includeSearch]);

  return null;
};

RestoreScrollPosition.defaultProps = {
  scrollDelay: 0,
  localStorageKey: 'scrollPositions',
  scrollToFunction: undefined,
  skipScrollTo: undefined,
  includeSearch: false,
};

RestoreScrollPosition.propTypes = {
  scrollDelay: PropTypes.number,
  localStorageKey: PropTypes.string,
  scrollToFunction: PropTypes.func,
  skipScrollTo: PropTypes.bool,
  includeSearch: PropTypes.bool,
};

export default RestoreScrollPosition;
