import React, { Fragment, useCallback, useEffect } from 'react';
import Input from '../../../forms/fields/Input';
import Section from './Section';
import { useAuthentication } from '../../../wrappers/Authentication';
import { useBreadcrumbs } from '../../../wrappers/BreadcrumbsProvider';
import { useOnboarding } from '../../../wrappers/Onboarding';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import Select from '../../../forms/fields/Select';
import { allCountries, unitedStates } from '../../../../../shared/countries';
import states from '../../../../../shared/states';
import useStyles from './css';
import Button from '../../../components/Button';
import BlockContainer from '../../../components/BlockContainer';
import Loader from '../../../components/Loader';
import Form from '../../../forms/Form';
import Checkbox from '../../../forms/fields/Checkbox';
import staticContent from '../../../staticContent';

const Profile = () => {
  const { user, setUser } = useAuthentication();
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  useEffect(() => {
    setCrumbs([{
      label: 'Account',
    }]);
  }, [setCrumbs]);

  const {
    optedOut: onboardingStatus,
    optOut: optOutOfOnboarding,
    optIn: optInToOnboarding,
  } = useOnboarding();

  const handleUserResponse = useCallback((res) => {
    const { json: { doc: incomingUser } } = res;
    setUser(incomingUser);
  }, [setUser]);

  if (!user) return <Loader fixed />;

  const {
    id: userID,
    organization: organizationID,
    firstName,
    lastName,
    email,
    phone,
    shippingAddress,
    billingAddress,
    hidePrices,
  } = user;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.profile}>
        <Section
          action={`${process.env.API_URL}/api/users/${userID}`}
          title="Basic Info"
          defaultValues={{
            firstName: {
              value: firstName,
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'First Name is required',
              }),
            },
            lastName: {
              value: lastName,
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Last Name is required',
              }),
            },
            email: {
              value: email,
              validation: () => ({
                isValid: Boolean(email),
                message: 'Email is required',
              }),
            },
            phone: {
              value: phone,
              validation: () => ({ isValid: true }), // TODO: write phone validate function
            },
          }}
          onResponse={handleUserResponse}
        >
          {(isEditable) => (
            <Fragment>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                label="First Name"
                disabled={!isEditable}
                required
              />
              <Input
                type="text"
                name="lastName"
                id="lastName"
                label="Last Name"
                disabled={!isEditable}
                required
              />
              <Input
                type="email"
                name="email"
                id="email"
                label="Email"
                disabled
                required
              />
              <Input
                type="text"
                name="phone"
                id="phone"
                label="Phone Number"
                disabled={!isEditable}
              />
              <Input
                type="password"
                name="password"
                id="password"
                label="Password"
                disabled={!isEditable}
              />
              <Input
                type="password"
                name="passwordConfirm"
                id="passwordConfirm"
                label="Confirm Password"
                disabled={!isEditable}
              />
            </Fragment>
          )}
        </Section>
        <Section
          title="Billing Address"
          action={`${process.env.API_URL}/api/users/${userID}`}
          defaultValues={{
            'billingAddress.line1': {
              value: billingAddress?.line1,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'billingAddress.line2': {
              value: billingAddress?.line2,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'billingAddress.city': {
              value: billingAddress?.city,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'billingAddress.state': {
              value: billingAddress?.state,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'billingAddress.country': {
              value: billingAddress?.country || 'United States',
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'billingAddress.zip': {
              value: billingAddress?.zip,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
          }}
          onResponse={handleUserResponse}
        >
          {(isEditable) => (
            <Fragment>
              <Input
                type="text"
                name="billingAddress.line1"
                id="billingAddress.line1"
                label="Address Line 1"
                disabled={!isEditable}
              />
              <Input
                type="text"
                name="billingAddress.line2"
                id="billingAddress.line2"
                label="Address Line 2"
                disabled={!isEditable}
              />
              <Input
                type="text"
                name="billingAddress.city"
                id="billingAddress.city"
                label="City"
                disabled={!isEditable}
              />
              <Select
                type="text"
                name="billingAddress.state"
                id="billingAddress.state"
                label="State"
                disabled={!isEditable}
                options={states}
              />
              <Input
                type="text"
                name="billingAddress.zip"
                id="billingAddress.zip"
                label="Zip Code"
                disabled={!isEditable}
              />
              <Select
                type="text"
                name="billingAddress.country"
                id="billingAddress.country"
                label="Country"
                disabled={!isEditable}
                options={[...allCountries]}
              />
            </Fragment>
          )}
        </Section>
        <Section
          title="Shipping Address"
          action={`${process.env.API_URL}/api/users/${userID}`}
          defaultValues={{
            'shippingAddress.line1': {
              value: shippingAddress?.line1,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'shippingAddress.line2': {
              value: shippingAddress?.line2,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'shippingAddress.city': {
              value: shippingAddress?.city,
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'shippingAddress.state': {
              value: shippingAddress?.state,
              validation: () => ({ isValid: true }), // TODO: write state validate function
            },
            'shippingAddress.country': {
              value: shippingAddress?.country || 'United States',
              validation: () => ({ isValid: true }), // TODO: write string validate function
            },
            'shippingAddress.zip': {
              value: shippingAddress?.zip,
              validation: () => ({ isValid: true }), // TODO: write zip validate function
            },
          }}
          onResponse={handleUserResponse}
        >
          {(isEditable) => (
            <Fragment>
              <Input
                type="text"
                name="shippingAddress.line1"
                id="shippingAddress.line1"
                label="Address Line 1"
                disabled={!isEditable}
              />
              <Input
                type="text"
                name="shippingAddress.line2"
                id="shippingAddress.line2"
                label="Address Line 2"
                disabled={!isEditable}
              />
              <Input
                type="text"
                name="shippingAddress.city"
                id="shippingAddress.city"
                label="City"
                disabled={!isEditable}
              />
              <Select
                type="text"
                name="shippingAddress.state"
                id="shippingAddress.state"
                label="State"
                disabled={!isEditable}
                options={states}
              />
              <Input
                type="text"
                name="shippingAddress.zip"
                id="shippingAddress.zip"
                label="Zip Code"
                disabled={!isEditable}
              />
              <Select
                type="text"
                name="shippingAddress.country"
                id="shippingAddress.country"
                label="Country"
                disabled={!isEditable}
                options={[{ ...unitedStates }]}
              />
            </Fragment>
          )}
        </Section>
        <BlockContainer>
          <h2>
            User Preferences
          </h2>
          <Form
            method="put"
            action={`${process.env.API_URL}/api/users/${userID}`}
            onResponse={handleUserResponse}
            className={classes.hidePricesForm}
            defaultValues={{
              hidePrices: {
                value: hidePrices,
              },
            }}
            submitOnChange
            myForm
          >
            <Checkbox
              name="hidePrices"
              id="hidePrices"
              label="Hide prices and discounts"
            />
          </Form>
          <br />
          <div>
            <Checkbox
              name="hideTips"
              id="hideTips"
              label="Hide tips and tricks"
              value={onboardingStatus}
              onChange={(incomingValue) => {
                if (incomingValue === true) optOutOfOnboarding();
                else optInToOnboarding();
              }}
            />
          </div>
        </BlockContainer>
        {organizationID
          ? (
            <BlockContainer>
              <Fragment>
                <div>
                  <h2>
                    {`Your Organization: ${user.organization.name} `}
                  </h2>
                  {'Do you wish to change your organization? '}
                  <Button
                    kind="text"
                    label="Ask us to add you to a new one."
                    htmlElement="a"
                    htmlAttributes={{
                      href: `mailto:${staticContent.techSupportEmailAddress}?subject=${encodeURIComponent('Please add me to a new organization on the My290 app')}&body=${encodeURIComponent(`Hello! I'd like to be added to an organization.\n\nOrganization Name:\nCustomer #:\nEmail: ${email}\nUser ID: ${userID}\n\nThank you!\n${firstName}${firstName && lastName && ' '}${lastName}`)}`,
                      target: '_blank',
                    }}
                  />
                </div>
              </Fragment>
            </BlockContainer>
          )
          : (
            <BlockContainer>
              <Fragment>
                <div>
                  <h2>Request Organization</h2>
                  {'Are you associated with an organization? '}
                  <Button
                    kind="text"
                    label="Ask us to add you to one."
                    htmlElement="a"
                    htmlAttributes={{
                      href: `mailto:${staticContent.techSupportEmailAddress}?subject=${encodeURIComponent('Please add me to an organization on the My290 app')}&body=${encodeURIComponent(`Hello! I'd like to be added to an organization.\n\nOrganization Name:\nCustomer #:\nEmail: ${email}\nUser ID: ${userID}\n\nThank you!\n${firstName}${firstName && lastName && ' '}${lastName}`)}`,
                      target: '_blank',
                    }}
                  />
                </div>
              </Fragment>
            </BlockContainer>
          )}
      </div>
    </Fragment>
  );
};

export default Profile;
