import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';

const Table = ({ columns, data }) => {
  const classes = useStyles();

  if (columns && columns.length > 0) {
    return (
      <div>
        <table
          className={classes.table}
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr>
              {columns.map((col, i) => (
                <th
                  key={i}
                  className={classes.col}
                >
                  {col.components.Heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={classes.row}
              >
                {columns.map((col, colIndex) => (
                  <td
                    key={colIndex}
                    className={`${classes.col} ${classes.cell}`}
                  >
                    {col.components.renderCell(row, row[col?.accessor])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      components: PropTypes.shape({
        Heading: PropTypes.node,
        renderCell: PropTypes.function,
      }),
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default Table;
