import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';

export default createUseStyles({
  results: {
    minHeight: base(2),
    marginBottom: base(2),
  },
  cardWrapper: {
    flexDirection: 'column',
  },
});
