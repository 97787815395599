import { createUseStyles } from 'react-jss';
import vars, { colors } from '../../css/vars';

export default createUseStyles({
  colorSwatch: {
    position: 'relative',
  },
  metallic: ({ isMetallic, metallicPNG, metallicWidth, metallicHeight }) => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage: isMetallic ? `url(${metallicPNG})` : null,
    backgroundSize: isMetallic ? `${metallicWidth}px ${metallicHeight}px` : null,
    backgroundRepeat: 'repeat',
    opacity: 0.33,
  }),
  selectOverlay: {
    borderRadius: vars.borderRadius,
    backgroundColor: ({ color }) => color || colors.lighterGray,
    backgroundImage: ({ img }) => `url(${img})`,
    mixBlendMode: ({ isMetallic }) => (isMetallic ? 'multiply' : undefined),
    backgroundSize: 'cover',
  },
  colorSwatchInner: {
    height: 0, // make a perfect square
    paddingTop: '100%', // make a perfect square
    position: 'relative',
  },
});
