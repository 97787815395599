import { createUseStyles } from 'react-jss';
import vars, { base, shadows, colors } from '../../css/vars';

export default createUseStyles({
  tooltip: {
    position: 'relative',
    '&:hover $label': {
      opacity: 1,
      transitionDelay: ({ delayHover }) => (delayHover ? '1s' : undefined),
    },
  },
  label: {
    zIndex: 1, // have to lift out of the 'SelectOverlay'
    pointerEvents: 'none',
    opacity: 0,
    position: 'absolute',
    bottom: `calc(100% + ${base(0.5)})`,
    left: '50%',
    transform: 'translate3d(-50%, 0, 0)',
    backgroundColor: colors.white,
    padding: base(0.25),
    boxShadow: shadows.xs,
    borderRadius: vars.borderRadius,
    transition: `opacity ${vars.transTime}ms linear`,
    color: colors.gray,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translate3d(-50%, 100%, 0)',
      width: 0,
      height: 0,
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: `4px solid ${colors.white}`,
    },
  },
});
