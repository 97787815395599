import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { largeBreak, midBreak } from '../../css/queries';

export default createUseStyles({
  projects: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
  },
  header: {
    display: 'flex',
    marginBottom: base(2),
    ...midBreak({
      marginTop: base(),
      flexDirection: 'column',
      alignItems: 'flex-start',
    }),
  },
  titleWrapper: {
    flexGrow: '1',
    marginRight: base(2),
    display: 'flex',
    flexDirection: 'column',
    ...largeBreak({
      marginRight: 0,
      marginBottom: base(),
    }),
  },
  title: {
    marginBottom: base(),
  },
  total: {
    marginBottom: 0,
  },
  body: {
    '& > *:not(:last-child)': {
      marginBottom: base(2),
    },
    ...midBreak({
      '& > *:not(:last-child)': {
        marginBottom: base(),
      },
    }),
  },
  bottomCTA: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
