import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../Form/context';

import useStyles from './css';

const Radio = (props) => {
  const {
    name,
    value: valueFromProps,
    label,
    className,
    onChange,
    checked: checkedFromProps,
  } = props;

  const classes = useStyles();
  const formContext = useForm();
  const checkedFromContextOrProps = formContext?.fieldState?.[name]?.value === valueFromProps || checkedFromProps;
  const [internalState, setInternalState] = useState(checkedFromContextOrProps); // use internal state to avoid external debouncing

  // state can be externally controlled, either directly through props or automatically via the 'formContext'
  useEffect(() => {
    if (checkedFromContextOrProps !== internalState) setInternalState(checkedFromContextOrProps);
  }, [checkedFromContextOrProps, internalState]);

  return (
    <label
      htmlFor={`${name}-${valueFromProps}`}
      className={[
        classes.radio,
        className,
        internalState && classes.radioIsChecked,
      ].filter(Boolean).join(' ')}
    >
      <input
        {...{
          className: classes.htmlInput,
          id: `${name}-${valueFromProps}`,
          name,
          value: valueFromProps,
          type: 'radio',
          onChange: (e) => {
            const { value: incomingValue } = e.target;
            const incomingIsChecked = incomingValue === valueFromProps;
            setInternalState(incomingIsChecked);

            if (typeof onChange === 'function') onChange(incomingValue);

            if (formContext) {
              formContext.dispatchFieldState({
                type: 'UPDATE_FIELD',
                payload: {
                  name,
                  value: incomingValue,
                },
              });
            }
          },
          checked: internalState || false,
        }}
      />
      {label}
    </label>
  );
};

Radio.defaultProps = {
  className: undefined,
  name: undefined,
  value: undefined,
  label: undefined,
  onChange: undefined,
  checked: undefined,
};

Radio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default Radio;
