import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import MarginGrid from '../../components/MarginGrid';
import Button from '../../components/Button';
import useStyles from './css';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import { useAuthentication } from '../../wrappers/Authentication';

const AlreadyLoggedIn = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();
  const { isLoggedIn } = useAuthentication();

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  if (!isLoggedIn) return <Redirect to="/login" />;

  return (
    <div className={classes.alreadyLoggedIn}>
      <Helmet>
        <title>Log In</title>
        <meta
          name="description"
          content="You are already logged in!"
        />
      </Helmet>
      <h2>You are already logged in</h2>
      <p>Now that&apos;s enthusiasm! What would you like to do next?</p>
      <MarginGrid className={classes.whatNext}>
        <Button
          to="/"
          label="Dashboard"
        />
        <Button
          to="/account"
          color="lightGray"
          label="Account"
        />
      </MarginGrid>
    </div>
  );
};

export default AlreadyLoggedIn;
