import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useModal } from '@faceless-ui/modal';

const OnRouteChange = () => {
  const { pathname } = useLocation();
  const { closeAll: closeAllModals } = useModal();

  useEffect(() => {
    closeAllModals();
  }, [pathname, closeAllModals]);

  return null;
};

export default OnRouteChange;
