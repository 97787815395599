import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';
import ArrowIcon from '../../icons/ArrowIcon';

const Pagination = (props) => {
  const {
    className,
    total,
    onPrev,
    onNext,
    currentIndex,
    size,
  } = props;

  const classes = useStyles({ size });

  const incrementIndex = useCallback(() => {
    let newIndex;
    if (currentIndex + 1 > (total - 1)) newIndex = 0;
    else newIndex = currentIndex + 1;
    if (typeof onNext === 'function') onNext(newIndex);
  }, [currentIndex, total, onNext]);

  const decrementIndex = useCallback(() => {
    let newIndex;
    if (currentIndex - 1 < 0) newIndex = total - 1;
    else newIndex = currentIndex - 1;
    if (typeof onPrev === 'function') onPrev(newIndex);
  }, [currentIndex, total, onPrev]);

  const ConditionalSmall = size === 's' ? 'small' : Fragment;

  return (
    <div
      className={[
        className,
        classes.arrowNavigation,
      ].filter(Boolean).join(' ')}
    >
      <div
        className={classes.control}
        onClick={decrementIndex}
        onKeyPress={decrementIndex}
        role="button"
        tabIndex={0}
      >
        <ArrowIcon
          color={currentIndex === 0 ? 'lightGray' : 'gray'}
          size={size}
        />
      </div>
      <div className={classes.label}>
        <ConditionalSmall>
          {currentIndex + 1}
          <span className={classes.delimiter}>
            &nbsp;
            {size === 's' ? '/' : 'of'}
            &nbsp;
          </span>
          {total}
        </ConditionalSmall>
      </div>
      <div
        className={classes.control}
        onClick={incrementIndex}
        onKeyPress={incrementIndex}
        role="button"
        tabIndex={0}
      >
        <ArrowIcon
          size={size}
          color={currentIndex === total - 1 ? 'lightGray' : 'gray'}
          rotation={180}
        />
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  className: undefined,
  total: undefined,
  currentIndex: undefined,
  onPrev: undefined,
  onNext: undefined,
  size: 'l',
};

Pagination.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  currentIndex: PropTypes.number,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  size: PropTypes.oneOf([
    's',
    'l',
  ]),
};

export default Pagination;
