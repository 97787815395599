import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import BlockContainer from '../../components/BlockContainer';
import Button from '../../components/Button';
import MarginGrid from '../../components/MarginGrid';
import FAQs from './FAQs';
import ShadowBox from '../../components/ShadowBox';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import staticContent from '../../staticContent';

const Help = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  useEffect(() => {
    setCrumbs([{
      label: 'Help',
    }]);
  }, [setCrumbs]);

  return (
    <div className={classes.help}>
      <Helmet>
        <title>Help</title>
        <meta
          name="description"
          content="Get help and browse our frequently asked questions."
        />
      </Helmet>
      <h1>
        Help Center
      </h1>
      <BlockContainer className={classes.section}>
        <div className={classes.intro}>
          <h3>
            If you’ve run into a problem, browse our FAQ or reach out directly.
          </h3>
          <p>
            If you have a question about your order or a problem with your account, you can contact 2/90 through email or by phone during our normal business hours.
          </p>
          <MarginGrid>
            <Button
              label={`Email: ${staticContent.techSupportEmailAddress}`}
              htmlElement="a"
              htmlAttributes={{
                href: `mailto:${staticContent.techSupportEmailAddress}?subject=${encodeURIComponent('I need help!')}&body=${encodeURIComponent('Thank you!')}`,
                target: '_blank',
              }}
            />
            <Button
              color="lightGray"
              label="Phone: 800-777-4310"
              htmlElement="a"
              htmlAttributes={{
                href: 'tel:800-777-4310',
              }}
            />
          </MarginGrid>
        </div>
      </BlockContainer>
      <BlockContainer className={classes.section}>
        <div className={classes.sectionHeader}>
          <h2>
            Frequently Asked Questions
          </h2>
        </div>
        {FAQs.map((faq, index) => {
          const { question, answer } = faq;
          return (
            <Collapsible key={index}>
              {({ isOpen }) => (
                <div className={classes.collapsible}>
                  <CollapsibleToggler className={classes.collapsibleToggler}>
                    <ShadowBox
                      padding="s"
                      color="white"
                      className={classes.collapsibleTogglerContent}
                    >
                      <div className={classes.collapsibleTitle}>
                        <strong>
                          {question}
                        </strong>
                      </div>
                      <Button
                        htmlElement="div"
                        icon="chevron"
                        color="lightGray"
                        size="s"
                        iconRotation={isOpen ? 180 : 0}
                      />
                    </ShadowBox>
                  </CollapsibleToggler>
                  <CollapsibleContent>
                    <div
                      className={classes.collapsibleContent}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </CollapsibleContent>
                </div>
              )}
            </Collapsible>
          );
        })}
      </BlockContainer>
    </div>
  );
};

export default Help;
