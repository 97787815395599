import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShadowBox from '../../components/ShadowBox';
import Button from '../../components/Button';
import useStyles from './css';

const SpecHeader = (props) => {
  const { label, labelPrefix, to, specId } = props;
  const classes = useStyles();

  return (
    <div className={classes.specHeader}>
      <Link
        to={{
          pathname: to,
          state: {
            attemptScrollRestore: true,
            open: specId,
          },
        }}
      >
        <ShadowBox
          color="white"
          className={classes.wrapper}
        >
          <Button
            size="s"
            color="lightGray"
            icon="arrow"
            htmlElement="span"
          />
          <span>
            {labelPrefix && (
              <Fragment>
                {labelPrefix}
                &nbsp;
                &ndash;
                &nbsp;
              </Fragment>
            )}
            <b className={classes.label}>
              {label}
            </b>
          </span>
        </ShadowBox>
      </Link>
    </div>
  );
};

SpecHeader.defaultProps = {
  label: undefined,
  labelPrefix: undefined,
  to: undefined,
  specId: undefined,
};

SpecHeader.propTypes = {
  label: PropTypes.string,
  labelPrefix: PropTypes.string,
  to: PropTypes.string,
  specId: PropTypes.string,
};

export default SpecHeader;
