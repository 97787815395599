import { createUseStyles } from 'react-jss';
import { largeBreak, midBreak } from '../../../css/queries';
import vars, { base, colors } from '../../../css/vars';

export default createUseStyles({
  section: {
    display: 'flex',
    alignItems: 'flex-start',
    ...largeBreak({
      flexDirection: 'column',
    }),
  },
  sidebar: {
    flexShrink: '0',
    width: `calc(33.33% - ${base(2)})`,
    marginRight: base(2),
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...largeBreak({
      marginRight: 0,
      width: '66.66%',
      marginBottom: base(2),
    }),
    ...midBreak({
      width: '100%',
      marginBottom: base(),
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
  title: {
    display: 'block',
    textDecoration: 'none',
    '& > *': {
      marginBottom: '0',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    ...midBreak({
      marginTop: base(0.25),
    }),
  },
  info: {
    '& > *:not(:last-child)': {
      marginBottom: base(0.5),
    },
  },
  cards: {
    width: '66.66%',
    ...largeBreak({
      width: '100%',
    }),
  },
  scrollContainer: {
    width: `calc(100% + ${base(4)})`,
    overflow: 'auto',
    ...midBreak({
      width: `calc(100% + ${base()})`,
    }),
  },
  cardsWrapper: {
    display: 'flex',
    margin: `0 ${base(-1)}`,
    ...midBreak({
      margin: `0 ${base(-0.5)}`,
    }),
    '&::after': {
      content: '""',
      paddingLeft: base(3),
      display: 'block',
      ...largeBreak({
        paddingLeft: base(3.5),
      }),
      ...midBreak({
        paddingLeft: base(0.75),
      }),
    },
    '& > *': {
      width: `calc(33.33% - ${base(2)})`,
      flexShrink: 0,
      margin: `0 ${base()}`,
      ...largeBreak({
        width: `calc(66.66% - ${base()})`,
        margin: `0 ${base(0.5)}`,
      }),
      ...midBreak({
        width: `calc(66.66% - ${base()})`,
        margin: `0 ${base(0.25)}`,
      }),
    },
  },
  finalCard: {
    backgroundColor: colors.white,
    padding: base(),
    borderRadius: vars.borderRadius, // match 'Card'
    color: colors.darkGray,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  signSize: {
    color: colors.gray,
  },
  number: {
    color: colors.darkGray,
  },
  isInCartLink: {
    color: colors.red,
  },
  noSigns: {
    width: '100%',
  },
});
