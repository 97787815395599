import { createUseStyles } from 'react-jss';
import vars, { colors, base } from '../../../css/vars';
import { largeBreak, midBreak } from '../../../css/queries';

export default createUseStyles({
  content: {
    marginBottom: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: base(2), // to compensate 'BlockContainer' negative right margin
    ...largeBreak({
      paddingRight: '0',
    }),
    ...midBreak({
      height: 'unset',
    }),
  },
  meta: ({ darkUI }) => ({
    color: darkUI ? colors.white : colors.darkGray,
  }),
  header: {
    '& > *:not(:last-child)': {
      marginBottom: base(0.5),
    },
  },
  renderingArea: {
    position: 'relative',
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: `${base(2)} 0`,
    ...midBreak({
      margin: 0,
    }),
  },
  specDebugger: {
    padding: base(),
    color: colors.darkGray,
    backgroundColor: colors.lightGray,
    borderRadius: `${vars.borderRadius}px`,
    overflow: 'auto',
    flexShrink: '1',
    flexGrow: '1',
    margin: `${base()} 0`,
    marginRight: base(),
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
