import { createUseStyles } from 'react-jss';
import { resetButton } from '../../css/other';
import vars, { colors, base } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  specAccordion: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  collapsibleHeader: {
    ...resetButton,
    padding: base(2),
    position: 'relative',
    ...midBreak({
      padding: base(),
    }),
  },
  seperator: {
    height: `${vars.strokeWidth}px`,
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    backgroundColor: colors.lightGray,
  },
  collapsibleHeaderContent: {
    ...resetButton,
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  collapsibleTitle: {
    flexGrow: '1',
    textAlign: 'left',
    color: colors.darkGray,
    fontWeight: 'bold',
  },
  collapsibleIcon: {
    flexShrink: '0',
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
  collapsibleContent: {
    padding: base(2),
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...midBreak({
      padding: base(1),
      '& > *:not(:last-child)': {
        marginBottom: base(1),
      },
    }),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  link: {
    flexGrow: 1,
    cursor: 'pointer',
    '& > a': {
      textDecoration: 'inherit',
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    '&:hover': {
      textDecoration: 'underline',
      color: colors.darkGray,
    },
  },
  checkbox: {
    flexGrow: 1,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  itemLabel: {
    marginRight: base(),
    flexShrink: 0, // match with 'SpecLink' and 'Quantity'
    flexBasis: base(5), // match with 'SpecLink' and 'Quantity'
    ...midBreak({
      flexBasis: base(4), // match with 'SpecLink' and 'Quantity'
    }),
  },
  valueWrapper: {
    flexGrow: 1,
    color: colors.darkGray,
    fontWeight: 'bold',
  },
  themeValue: {
    color: colors.gray,
  },
  clear: {
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
});
