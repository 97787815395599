import React, { createRef, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  Sign,
  mergeSpecs,
  injectBackerPositions,
  getSignPrice,
  AutoScalingSign,
  formatPartNumber,
  signTemplateMeta,
  signTemplates,
  countBackers,
  themes,
  mergeCopy,
  getBackerCode,
} from '@two90signs/290-renderer';
import useStyles from './css';
import useProject from '../../utilities/data/useProject';
import Loader from '../../components/Loader';
import { fireRequest } from '../../api';
import SpecDocument from '../../components/SpecDocument';
import Button from '../../components/Button';
import Back from '../../components/Back';
import getProjectPrice from '../../utilities/getProjectPrice';
import AutoScale from '../../components/AutoScale';
import { base } from '../../css/vars';
import formatDateTime from '../../utilities/formatDateTime';
import useThrottledEffect from '../../utilities/performance/useThrottledEffect';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import Errors from '../../forms/Errors';
import getAllCSS from '../../utilities/getAllCSS';

const scaleCountReducer = (currentCount, payload) => {
  let newCount = currentCount;
  const { type } = payload;
  if (type === 'INCREMENT') newCount += 1;
  return newCount;
};

const PrintProject = () => {
  const [scaleCount, dispatchScaleCount] = useReducer(scaleCountReducer, 0);
  const [downloadHref, setDownloadHref] = useState('');
  const { projectID } = useParams();
  const [projectState, setProjectState] = useState();
  const { isLoading: isLoadingProject, project: loadedProject } = useProject(projectID);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const coverPageRef = useRef();
  const [signsWithMergedSpecs, setSignsWithMergedSpecs] = useState();
  const [pageRefs, setPageRefs] = useState([]);
  const { setCrumbs } = useBreadcrumbs();
  const [apiErrors, setAPIErrors] = useState();

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
      url: '/projects',
    }, {
      label: loadedProject?.name || projectID,
      url: `/project/${projectID}`,
      useEllipsis: true,
    }, {
      label: 'Print',
    }]);
  }, [setCrumbs, projectID, loadedProject]);

  useEffect(() => {
    if (loadedProject) {
      const {
        signs,
        theme,
        specs: themeSpecs,
      } = loadedProject;

      const baseThemeSpecs = themes[theme];
      const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

      if (Array.isArray(signs) && signs.length > 0) {
        const newPageRefs = [];
        const newSignContainerRefs = [];
        const newSignsWithMergedSpecs = [...signs];

        signs.forEach((sign, signIndex) => {
          newPageRefs.push(createRef());
          newSignContainerRefs.push(createRef());

          const {
            specs: signSpecs,
            copy,
            templateID,
          } = sign;

          const { signSystem } = signSpecs;

          const mergedSignSystem = signSystem || themeSpecs.signSystem;
          const withMergedTheme = mergeSpecs(mergedTheme, signSpecs);
          const withMergedCopy = mergeCopy(withMergedTheme, copy[0]);
          const backersWithPositions = injectBackerPositions(withMergedTheme.backers, mergedSignSystem, templateID);

          newSignsWithMergedSpecs[signIndex].specs = {
            ...withMergedCopy,
            backers: backersWithPositions,
          };
        });

        setPageRefs(newPageRefs);
        setSignsWithMergedSpecs(newSignsWithMergedSpecs);
      }
      setProjectState(loadedProject);
    }
  }, [loadedProject]);

  const onScale = useCallback(() => {
    dispatchScaleCount({ type: 'INCREMENT' });
  }, []);

  // IMPORTANT: this effect must be throttled, because 'onScale' will fired any number of times as it receives, processes, and renders out it's given specs
  // Since there's no 'onFinalScale' hook, the throttle needs to be reasonably slow so that it fires only after the final 'onScale event has come through
  // tldr; the goal here is to wait until the sign is finished rendering before rasterizing it, to avoid rasterizing a partially rendered sign
  useThrottledEffect(() => {
    const allCSS = getAllCSS();

    const hasPages = Array.isArray(pageRefs) && pageRefs.length > 0;

    const body = JSON.stringify({
      // re-create the html here (do not use document.documentElement) because other things are rendered on the page that we do not want to be downloaded
      // resolution is set by adding style to the body, double for resolution (see ../../components/SpecDocument/css.js)
      htmlTemplate: `<html><head><style>${allCSS} body { width: 22in; height: 17in }</style></head><body>{{{html}}}<body><html>`,
      pages: [
        {
          html: coverPageRef.current.outerHTML
            .replace(/\n\s+|\n/g, '')
            // Chrome removes the -webkit-background-clip vendor prefix used in <Sign /> for some unknown reason (https://github.com/facebook/react/issues/14200), but is needed by Puppeteer (headless Chrome)
            .replace(/background-clip: text;/g, '-webkit-background-clip: text;'),
        },
        ...hasPages ? pageRefs.map((pageRef) => ({
          html: pageRef.current.outerHTML
            .replace(/\n\s+|\n/g, '')
            // Chrome removes the -webkit-background-clip vendor prefix used in <Sign /> for some unknown reason (https://github.com/facebook/react/issues/14200), but is needed by Puppeteer (headless Chrome)
            .replace(/background-clip: text;/g, '-webkit-background-clip: text;'),
        })) : [],
      ],
    });

    const action = async () => {
      const { res: { status, base64 }, err } = await fireRequest({
        method: 'post',
        url: `${process.env.API_URL}/generate-pdf`,
        parseBase64: true,
        options: {
          body,
        },
      });
      if (err) console.warn(err);
      if (status === 200) {
        setDownloadHref(`data:application/pdf;base64,${base64}`);
      } else if (status > 400) {
        setAPIErrors({
          [status]: `A ${status} error has occurred.`,
        });
      }
      setIsLoadingImage(false);
    };

    action();
  }, 2000, [scaleCount, pageRefs]);

  const classes = useStyles({
    numberOfSigns: signsWithMergedSpecs?.length,
  });

  if (isLoadingProject || projectState === undefined) return <Loader fixed />;
  if (!projectState) return <Redirect to="/not-found" />;

  const {
    name: projectName,
    theme,
    specs: themeSpecs,
  } = loadedProject || {};

  const baseThemeSpecs = themes[theme];
  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

  return (
    <div className={classes.printProject}>
      {apiErrors && (
        <Errors errors={apiErrors} />
      )}
      {isLoadingImage && (
        <Loader
          fixed
          message="Generating pdf..."
        />
      )}
      {!isLoadingImage && downloadHref && (
        <div className={classes.imageReady}>
          <Back
            className={classes.back}
            to={`/project/${projectID}/edit`}
            label="Back to project"
          />
          <div className={classes.imageReadyHeader}>
            <h1 className={classes.imageReadyTitle}>
              Your PDF is ready!
            </h1>
            <div>
              <Button
                htmlElement="a"
                htmlAttributes={{
                  download: `My290-${formatDateTime()}.pdf`,
                  href: downloadHref,
                }}
                label="Download"
              />
            </div>
          </div>
        </div>
      )}
      <div className={classes.htmlToRasterize}>
        <SpecDocument
          ref={coverPageRef}
          isCoverPage
          showCopyCase={false}
          showCopyPosition={false}
          specs={mergedTheme}
          price={getProjectPrice(projectState)}
          projectName={projectName}
          renderingArea={(
            <div className={classes.scrollContainer}>
              <AutoScale
                signs={signsWithMergedSpecs}
                className={classes.autoScale}
                onScale={onScale}
                render={(remeasure, scale) => (
                  <div className={classes.maxWidth}>
                    {Array.isArray(signsWithMergedSpecs) && signsWithMergedSpecs.length > 0 && (
                      signsWithMergedSpecs.map((sign, signIndex) => {
                        const { specs: mergedSpecs } = sign;

                        return (
                          <div
                            key={signIndex}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end',
                              padding: `calc(${base(0.5)} / ${scale})`, // 'unscale' the padding
                            }}
                          >
                            <Sign
                              specs={mergedSpecs}
                              onResize={remeasure}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                )}
              />
            </div>
          )}
        />
        {Array.isArray(signsWithMergedSpecs) && signsWithMergedSpecs.length > 0
          && signsWithMergedSpecs.map((sign, signIndex) => {
            const {
              templateID,
              specs: mergedSpecs,
              quantity,
            } = sign;

            const {
              insertArea,
              overallSizes,
            } = signTemplateMeta?.[templateID] || {};

            const template = signTemplates.find(({ templateID: id }) => id === templateID) || {};
            const { name: templateName } = template;

            const numberOfBackers = countBackers({}, mergedSpecs);
            const partNumber = formatPartNumber({}, mergedSpecs, templateID);
            const formattedInsertArea = insertArea && `${insertArea.height}" x ${insertArea.width}"`;
            const backerCode = getBackerCode(mergedSpecs);
            const formattedBackingArea = overallSizes && `${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`;

            return (
              <SpecDocument
                key={signIndex}
                ref={pageRefs[signIndex]}
                signTemplate={templateID}
                specs={mergedSpecs}
                price={getSignPrice({}, mergedSpecs, templateID)}
                insertArea={formattedInsertArea}
                overallSize={formattedBackingArea}
                partNumber={partNumber}
                projectName={projectName}
                showSignStandards
                templateID={templateID}
                renderingArea={(
                  <div className={classes.signSpecDocumentRenderingArea}>
                    <AutoScalingSign
                      signProps={{
                        ...sign,
                        footNote: (
                          <div className={classes.footNote}>
                            <div>
                              <b>
                                {partNumber}
                              </b>
                            </div>
                            <div>
                              {sign.name ?? templateName}
                            </div>
                            {insertArea && (
                              <div>
                                {`Insert area: ${formattedInsertArea}`}
                              </div>
                            )}
                            {numberOfBackers > 0 && overallSizes && (
                              <div>
                                {`Overall size: ${formattedBackingArea}`}
                              </div>
                            )}
                            <div>
                              {`Quantity: ${quantity}`}
                            </div>
                          </div>
                        ),
                      }}
                      centerInContainer
                    />
                  </div>
                )}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PrintProject;
