import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './css';
import reducer from './reducer';

const NotificationsContext = createContext({});
export const useNotifications = () => useContext(NotificationsContext);

const Notifications = (props) => {
  const [savingInProgress, setSavingInProgress] = useState(false); // this state prevents route transitions, but caches their attempt, shows them a message, and redirects once falsey again
  const [notifications, dispatchNotifications] = useReducer(reducer);
  const history = useHistory();
  const location = useLocation();
  const prevLocation = useRef(location);
  const [blockedLocation, setBlockedLocation] = useState();

  const { children } = props;

  const setTimedNotification = useCallback(({
    id,
    message,
    duration = 5000,
  }) => {
    const timerID = setTimeout(() => {
      dispatchNotifications({
        type: 'DELETE_NOTIFICATION',
        payload: {
          id,
        },
      });
    }, duration);

    dispatchNotifications({
      type: 'ADD_NOTIFICATION',
      payload: {
        [id]: {
          message,
          duration,
        },
      },
    });

    return () => clearTimeout(timerID);
  }, []);

  const handleKeydown = useCallback((e) => {
    const { keyCode, metaKey } = e;
    if (keyCode === 83 && metaKey) {
      e.preventDefault();
      setTimedNotification({
        id: 'already-saved',
        message: 'My290 auto-saves your work!',
        duration: 2000,
      });
    }
  }, [setTimedNotification]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  // Notify the user that a save is currently in progress and their route transition is blocked,
  // once finished, push them into their attempted route
  useEffect(() => {
    if (blockedLocation) {
      if (savingInProgress) {
        dispatchNotifications({
          type: 'ADD_NOTIFICATION',
          payload: {
            redirectAfterSave: {
              message: 'Saving in progress, will redirect afterwards...',
            },
          },
        });
      } else {
        dispatchNotifications({
          type: 'DELETE_NOTIFICATION',
          payload: {
            id: 'redirectAfterSave',
          },
        });
        const { pathname, search } = blockedLocation;
        history.push(`${pathname}${search}`);
        setBlockedLocation(false);
      }
    }
  }, [blockedLocation, savingInProgress, history]);

  // If the user attempted to navigate away during a save, block the transition, but save the location
  useEffect(() => {
    let transitionIsBlocked = false;
    const unlisten = history.listen((incomingLocation, action) => {
      if (action === 'PUSH' && !transitionIsBlocked) {
        const pathnameDiffers = prevLocation.current !== incomingLocation;
        if (pathnameDiffers && savingInProgress) {
          const { pathname, search } = prevLocation.current;
          transitionIsBlocked = true;
          history.push(`${pathname}${search}`);
          setBlockedLocation(incomingLocation);
        }
      }
      prevLocation.current = incomingLocation;
    });
    return () => unlisten();
  }, [history, savingInProgress]);

  const classes = useStyles();

  const notifs = Object.keys(notifications || {});

  return (
    <NotificationsContext.Provider
      value={{
        setTimedNotification,
        notifications,
        setSavingInProgress,
        savingInProgress,
      }}
    >
      {children}
      {Array.isArray(notifs) && notifs.length > 0 && (
        <div className={classes.notifications}>
          {notifs.map((notif, index) => {
            const notification = notifications[notif];
            const { message } = notification;
            return (
              <div
                key={index}
                className={classes.notification}
              >
                {message}
              </div>
            );
          })}
        </div>
      )}
    </NotificationsContext.Provider>
  );
};

Notifications.defaultProps = {
  children: undefined,
};

Notifications.propTypes = {
  children: PropTypes.node,
};

export default Notifications;
