import React from 'react';
import useCSS from './css';

const Separator = () => {
  const { separator } = useCSS();

  return (
    <span className={separator}>&mdash;</span>
  );
};

export default Separator;
