import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const ArrowIcon = (props) => {
  const { className, color, rotation, size } = props;
  const classes = useIconStyles({ color, rotation });

  return (
    <svg
      className={[
        classes.wrapper,
        classes.rotation,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="16"
      height="7"
      viewBox="0 0 16 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.stroke}
        d="M14.96 3.49998L2.37996 3.49998M2.37996 3.49998L4.41996 5.53998M2.37996 3.49998L4.41996 1.45998"
      />
    </svg>

  );
};

ArrowIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

ArrowIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default ArrowIcon;
