import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import NewProjectPortal from '../../components/NewProjectPortal';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import useStyles from './css';
import { useOnboarding } from '../../wrappers/Onboarding';

const NewProject = () => {
  const { setCrumbs } = useBreadcrumbs();
  const classes = useStyles();

  useEffect(() => {
    setCrumbs([{
      label: 'New Project',
    }]);
  }, [setCrumbs]);

  const { optedOut } = useOnboarding();

  return (
    <Fragment>
      <Helmet>
        <title>New Project</title>
        <meta
          name="description"
          content="Start a new project."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <NewProjectPortal
        className={classes.newProject}
        title="New Project"
        body={(
          <div>
            <p>
              Name your project and choose a sign system to get started — don’t worry, you’ll be able to change or mix and match systems later.
            </p>
            {!optedOut && (
              <Fragment>
                <p>
                  Throughout the app you’ll have access to tips that should help answer questions you have along the way. Simply click on the tooltips when you need additional information.
                </p>
                <p>
                  {'If you wish to hide the tooltips, just click '}
                  <strong>
                    {'hide all messages like this '}
                  </strong>
                  {'in the tooltip window. To re-enable the tooltips, simply go to your account dashboard and uncheck '}
                  <strong>
                    {'Hide Tips '}
                    &amp;
                    {' Tricks '}
                  </strong>
                  under User Preferences.
                </p>
              </Fragment>
            )}
            <p>
              If you need additional help, email
              {' '}
              <a href="mailto:marketing@290signs.com">marketing@290signs.com</a>
              .
            </p>

          </div>
        )}
      />
    </Fragment>
  );
};

export default NewProject;
