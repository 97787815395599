import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../css/vars';

export default createUseStyles({
  separator: {
    marginRight: base(0.25),
    alignSelf: 'center',
    color: colors.gray,
  },
});
