import React, { Fragment, useState } from 'react';
import { Modal, ModalToggler } from '@faceless-ui/modal';
import useDrawerStyles from '../useDrawerStyles';
import LogIn from './LogIn';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import CloseIcon from '../../../icons/CloseIcon';

const drawers = {
  resetPassword: ResetPassword,
  logIn: LogIn,
  signUp: SignUp,
};

const AuthDrawer = () => {
  const drawerClasses = useDrawerStyles();
  const [content, setContent] = useState('logIn');

  const DrawerContent = drawers[content];

  return (
    <Modal
      slug="auth"
      className={drawerClasses.modal}
    >
      <Fragment>
        <ModalToggler
          slug="auth"
          className={drawerClasses.closeButton}
        >
          <CloseIcon color="white" />
        </ModalToggler>
        {DrawerContent && <DrawerContent setContent={setContent} />}
      </Fragment>
    </Modal>
  );
};

export default AuthDrawer;
