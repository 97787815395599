import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import { useAuthentication } from '../../wrappers/Authentication';
import { useCart } from '../../wrappers/CartProvider';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import CheckoutForm from './Form';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import EmptyCheckout from './EmptyCheckout';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const {
    cart,
    cartIsEmpty,
    clearCart,
  } = useCart();

  const { setCrumbs } = useBreadcrumbs();
  const { user } = useAuthentication();

  useEffect(() => {
    setCrumbs([{
      label: 'Checkout',
    }]);
  }, [setCrumbs]);

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            redirect: '/checkout',
            intro: 'preCheckout',
          },
        }}
      />
    );
  }

  if (cart && cartIsEmpty) return <EmptyCheckout />;

  const {
    organization: organizationID,
  } = user;

  if (!organizationID) return <Redirect to="/no-organization" />;

  return (
    <Fragment>
      <Helmet>
        <title>Checkout</title>
        <meta
          name="description"
          content="Checkout with the items in your cart, proceed with payment and place your order."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div>
        <h1>Checkout</h1>
        <StripeElementsProvider stripe={stripePromise}>
          <CheckoutForm
            cart={cart}
            clearCart={clearCart}
          />
        </StripeElementsProvider>
      </div>
    </Fragment>
  );
};

export default Checkout;
