import { fontFamily } from './type';
import vars, { baselinePX, colors, shadows } from './vars';

export const resetButton = { // eslint-disable-line
  fontSize: 'inherit',
  textAlign: 'left',
  fontFamily,
  lineHeight: `${baselinePX}px`,
  backgroundColor: 'transparent',
  padding: '0',
  border: 'none',
  color: 'inherit',
  fontWeight: 'inherit',
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
};

export const inputShareables = {
  transition: `${vars.defaultTransTime}ms ${vars.defaultTransCurve}`,
  border: `${colors.lightGray} solid ${vars.strokeWidth}px`,
  boxShadow: shadows.inputS,
  '&:hover': {
    boxShadow: shadows.inputM,
  },
};
