import { createUseStyles } from 'react-jss';
import { colors } from '../../css/vars';

export default createUseStyles({
  cartIcon: {
    position: 'relative',
  },
  indicator: {
    position: 'absolute',
    top: '0',
    left: '0',
    transform: 'translate3d(-100%, -100%, 0)',
    backgroundColor: colors.red,
    width: '8px',
    height: '8px',
    borderRadius: '100%',
  },
});
