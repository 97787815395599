import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../css/vars';

export default createUseStyles({
  input: {
    color: colors.gray,
    '&:hover': {
      opacity: '1',
      color: colors.darkGray,
      textDecoration: 'underline',
    },
  },
  inputChecked: {
    color: colors.darkGray,
  },
  htmlLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  inputWrapper: {
    position: 'relative',
    marginRight: base(0.5),
  },
});
