const defaultHeaders = {
  'Content-Type': 'application/json',
};

function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => { binary += String.fromCharCode(b); });
  return window.btoa(binary);
}

export const requests = {
  get: ({ url, options = {} }) => fetch(url, {
    ...options,
    method: 'get',
    headers: {
      ...defaultHeaders,
    },
    credentials: 'include',
  }),

  post: ({ url, options = {} }) => fetch(url, {
    ...options,
    method: 'post',
    headers: {
      ...defaultHeaders,
      ...options?.headers || {},
    },
    credentials: 'include',
  }),

  put: ({ url, options = {} }) => fetch(url, {
    ...options,
    method: 'put',
    headers: {
      ...defaultHeaders,
      ...options?.headers || {},
    },
    credentials: 'include',
  }),

  delete: ({ url, options = {} }) => fetch(url, {
    ...options,
    method: 'delete',
    headers: {
      ...defaultHeaders,
      ...options?.headers || {},
    },
    credentials: 'include',
  }),
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fireRequest = async ({ method, url, options, sleepDuration, parseJSON = true, parseBase64 }) => {
  const lowercasedMethod = method && method.toLowerCase();

  if (requests[lowercasedMethod] && url) {
    if (sleepDuration) await sleep(sleepDuration);
    try {
      const res = await requests[lowercasedMethod]({ url, options });
      let json;
      if (parseJSON && !parseBase64) json = await res.json();
      let base64;
      if (parseBase64) {
        const buffer = await res.arrayBuffer();
        const imageStr = arrayBufferToBase64(buffer);
        base64 = imageStr;
      }
      return {
        res: {
          status: res.status,
          json,
          base64,
        },
      };
    } catch (err) {
      return { err };
    }
  }
  return {};
};
