import { useEffect, useState, useCallback } from 'react';
import { useAuthentication } from '../../wrappers/Authentication';
import { fireRequest } from '../../api';

export default (uuid) => {
  const { isLoggedIn } = useAuthentication();
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [internalError, setInternalError] = useState(false);

  const fetchDataFromAPI = useCallback(async () => {
    const { res, err } = await fireRequest({
      method: 'GET',
      url: `${process.env.API_URL}/api/projects?where[uuid][equals]=${uuid}&depth=0`,
    });

    if (err || res?.json?.docs?.length < 1) {
      setInternalError(err);
      setProject(null);
    } else {
      setProject(res.json.docs[0]);
    }

    setIsLoading(false);
  }, [uuid]);

  const getDataFromLocalStorage = useCallback(() => {
    const storedProjects = window.localStorage.getItem('projects');

    if (storedProjects) {
      const parsedProjects = JSON.parse(storedProjects);

      const storedProject = parsedProjects.find((item) => item.uuid === uuid);

      if (storedProject) {
        setProject(storedProject);
      } else {
        setProject(null);
      }
    }

    setIsLoading(false);
  }, [uuid]);

  useEffect(() => {
    setIsLoading(true);
    const doTheThing = async () => {
      if (isLoggedIn) fetchDataFromAPI();
      else getDataFromLocalStorage();
    };
    doTheThing();
  }, [isLoggedIn, fetchDataFromAPI, getDataFromLocalStorage]);

  return { isLoading, project, internalError };
};
