import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../css/vars';

const Logo = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="113"
      height="36"
      viewBox="0 0 113 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={colors.red}
        d="M6.62695 28.5H1.24414L4.44336 9.92676H11.7559L12.9492 21.5557L18.2305 9.92676H25.4795L22.2041 28.5H16.8213L17.7607 22.7998L18.4336 19.9434L19.2715 16.5918H19.2207L13.8379 28.5H9.64844L8.30273 16.5918L8.1123 19.3721L7.73145 22.2031L6.62695 28.5ZM30.7232 15.0303L31.5991 23.3711L35.3696 15.0303H40.4605L33.9986 27.6494C33.3469 28.9105 32.8306 29.8415 32.4497 30.4424C32.0689 31.0433 31.6288 31.5426 31.1294 31.9404C30.6301 32.3467 30.008 32.6387 29.2632 32.8164C28.5269 33.0026 27.5663 33.0957 26.3814 33.0957C26.0851 33.0957 25.3023 33.0576 24.0327 32.9814L24.6802 29.2109H25.4165C26.1275 29.2109 26.6776 29.1178 27.0669 28.9316C27.4647 28.7454 27.6636 28.3646 27.6636 27.7891C27.6636 27.6452 27.6509 27.4717 27.6255 27.2686L25.3911 15.0303H30.7232Z"
      />
      <path
        fill={colors.gray}
        d="M63.6747 28.6215H42C43.5705 22.3699 47.7637 20.1361 52.8107 18.8171L57.629 17.544C58.7802 17.239 59.7941 16.5605 59.9924 15.638C60.244 14.441 59.23 13.6024 56.8132 13.5719C54.526 13.5719 52.5438 14.4715 51.8882 16.6215H45.202C46.7268 11.0713 51.6366 9.36353 57.7281 9.36353C64.0102 9.36353 67.5629 11.4525 66.6633 15.699C65.1385 22.8197 53.1919 20.9747 49.7992 24.4283H64.5362L63.6747 28.6215Z"
      />
      <path
        fill={colors.red}
        d="M74.9815 23.3152C75.0806 24.5121 76.4148 25.0306 78.2903 25.0306C82.2013 25.0306 83.4898 22.9645 84.1988 20.6544C82.3354 21.6825 80.2261 22.1808 78.0997 22.0954C73.6931 22.0954 68.8672 21.0356 69.9726 15.8133C70.8036 11.9251 74.6308 9.37109 81.5152 9.37109C87.8735 9.37109 92.486 11.2161 90.7706 19.2821C89.2915 26.2428 84.153 29.0407 77.627 29.0407C73.0069 29.0407 68.3563 27.8971 68.6537 23.2999L74.9815 23.3152ZM84.641 15.7675C84.8849 14.624 83.9701 13.4346 81.0349 13.4346C77.8023 13.4346 76.674 14.624 76.4605 15.7675C76.1708 17.1551 76.8189 18.3292 80.0514 18.3292C83.5965 18.291 84.3589 16.9797 84.641 15.7675Z"
      />
      <path
        fill={colors.red}
        d="M111.911 19.297C110.836 24.3212 107.032 29.0556 98.7905 29.0556C90.549 29.0556 88.7574 24.3212 89.8324 19.297C90.9379 14.0747 94.6812 9.38599 102.976 9.38599C111.271 9.38599 113.009 14.1433 111.911 19.297V19.297ZM96.084 19.297C95.131 23.7799 97.5325 24.8701 99.6825 24.8701C101.832 24.8701 104.668 23.9171 105.652 19.297C106.635 14.677 104.234 13.5563 102.084 13.5563C99.9341 13.5563 97.0675 14.6465 96.084 19.297Z"
      />
      <path
        fill={colors.gray}
        d="M61.2275 31H62.9658L70.0026 7H68.3559L61.2275 31Z"
      />
      <path
        fill={colors.red}
        d="M64.3535 31H66.0918L73.1362 7H71.4819L64.3535 31Z"
      />
    </svg>
  );
};

Logo.defaultProps = {
  className: undefined,
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
