import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../../../components/ScrollToTopOnMount';
import ShadowBox from '../../../../components/ShadowBox';
import SpecHeader from '../../../SpecHeader';
import Radio from '../../../../forms/fields/Radio';
import useStyles from './css';

const { symbols } = specSheets;

// copy sizes are defined on an insert-by-insert basis, therefore options must be passed via props
// as opposed to bulk imported and generically mapped through

const Symbols = (props) => {
  const { lineIndex } = useParams();

  const {
    backTo,
    currentLines,
    onChange,
    linesMeta,
  } = props;

  const classes = useStyles();
  const { symbols: availableSymbols } = linesMeta[lineIndex];
  const { symbol: currentSymbol } = currentLines ? currentLines[lineIndex] : {};
  const numberOfLines = linesMeta.length;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.symbols}>
        <SpecHeader
          label={`${numberOfLines > 1 ? `Line ${Number(lineIndex) + 1} ` : ''}Symbol`}
          to={backTo}
        />
        {Array.isArray(availableSymbols) && availableSymbols.length > 0 && (
          <ShadowBox color="white">
            {availableSymbols.map((symbolID, index) => {
              const symbol = symbols[symbolID];
              const { label } = symbol;

              return (
                <Radio
                  {...{
                    key: index,
                    className: classes.radio,
                    name: 'copySize',
                    label,
                    value: symbolID,
                    onChange: (incomingValue) => onChange({ lineIndex, incomingValue }),
                    checked: symbolID === currentSymbol,
                  }}
                />
              );
            })}
          </ShadowBox>
        )}
      </div>
    </Fragment>
  );
};

Symbols.defaultProps = {
  backTo: undefined,
  currentLines: undefined,
  onChange: undefined,
  linesMeta: undefined,
};

Symbols.propTypes = {
  backTo: PropTypes.string,
  currentLines: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  onChange: PropTypes.func,
  linesMeta: PropTypes.arrayOf(
    PropTypes.shape({
      symbols: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
  ),
};

export default Symbols;
