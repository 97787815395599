import React, { useEffect, useState, Fragment, useReducer, useCallback } from 'react';
import Helmet from 'react-helmet';
import { specSheets, signTemplates, sortSignsAlphanumerically } from '@two90signs/290-renderer';
import { useParams, useHistory, Redirect, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useModal } from '@faceless-ui/modal';
import qs from 'qs';
import BlockContainer from '../../components/BlockContainer';
import Filter from './Filter';
import Results from './Results';
import Submit from '../../forms/fields/Submit';
import Button from '../../components/Button';
import MarginGrid from '../../components/MarginGrid';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import useStyles from './css';
import useProject from '../../utilities/data/useProject';
import Loader from '../../components/Loader';
import saveProject from '../../utilities/data/saveProject';
import { useAuthentication } from '../../wrappers/Authentication';
import { useCart } from '../../wrappers/CartProvider';
import OnboardingModal from './OnboardingModal';
import reducer from './reducer';
import { useNotifications } from '../../wrappers/Notifications';
import OnboardingTooltip from '../../components/OnboardingTooltip';

const { signCategories } = specSheets;

const initialSelectedCategories = {};

Object.keys(signCategories).forEach((key) => {
  initialSelectedCategories[key] = true;
});

const initialSelectedSigns = {};

signTemplates.forEach((signTemplate) => { // key by templateID
  initialSelectedSigns[signTemplate.templateID] = false;
});

const AddSign = () => {
  const { setTimedNotification } = useNotifications();
  const { isLoggedIn } = useAuthentication();
  const { projectID } = useParams();
  const { updatePotentialProjectInCart } = useCart();
  const history = useHistory();
  const { setCrumbs } = useBreadcrumbs();
  const [hasLoadedProject, setHasLoadedProject] = useState(false);
  const { isLoading: isLoadingProject, project: loadedProject } = useProject(projectID);
  const { search } = useLocation();
  const { open: openModal } = useModal();
  const [selectedSignSystem, setSelectedSignSystem] = useState();
  const [selectedSigns, dispatchSelectedSigns] = useReducer(reducer, initialSelectedSigns);
  const [selectedCategories, setSelectedCategories] = useState(initialSelectedCategories);

  const numberOfSelectedSigns = Object.values(selectedSigns).filter(Boolean)?.length || 0;
  const allSignsAreSelected = numberOfSelectedSigns === signTemplates.length;
  const classes = useStyles();

  // When the project loads, set the result
  useEffect(() => {
    if (loadedProject) {
      setSelectedSignSystem(loadedProject?.specs?.signSystem);
      setHasLoadedProject(true);
    }
  }, [loadedProject, projectID, dispatchSelectedSigns]);

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
      url: '/projects',
    }, {
      label: loadedProject?.name || projectID,
      url: `/project/${projectID}`,
      useEllipsis: true,
    }, {
      label: 'Add Sign(s)',
    }]);
  }, [setCrumbs, projectID, loadedProject]);

  useEffect(() => {
    const { onboard } = qs.parse(search, { ignoreQueryPrefix: true });
    if (onboard) openModal('project-onboarding');
  }, [search, openModal]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    const allTemplateIDs = Object.keys(selectedSigns);
    const selectedTemplateIDs = [];

    if (Array.isArray(allTemplateIDs) && allTemplateIDs.length > 0) {
      const withNewSigns = [...loadedProject.signs];
      const themeSignSystem = loadedProject.specs?.signSystem;

      if (Array.isArray(allTemplateIDs) && allTemplateIDs.length > 0) {
        allTemplateIDs.forEach((templateID, index) => {
          const isSelected = Boolean(selectedSigns[templateID]);

          if (isSelected) {
            const selectedTemplateID = allTemplateIDs[index];
            selectedTemplateIDs.push(selectedTemplateID);
            const signID = uuid();

            const signTemplate = signTemplates.find((template) => template.templateID === selectedTemplateID);
            const { specs: templateSpecs, alternateSpecs } = signTemplate;
            const specsToUse = alternateSpecs?.[selectedSignSystem] || templateSpecs;

            // extract copy from the sign template
            const copy = [{
              inserts: specsToUse?.inserts.map((insert) => ({
                lines: insert.lines?.map((line) => ({ ...line })) || [],
              })) || [],
            }];

            withNewSigns.push({
              uuid: signID,
              ...signTemplate,
              wallColor: '',
              quantity: 1,
              copy,
              deferCopy: false,
              specs: {
                ...specsToUse,
                signSystem: selectedSignSystem !== themeSignSystem ? selectedSignSystem : undefined, // do not create overrides if the selected sign system matches the theme
              },
            });
          }
        });
      }

      const action = async () => {
        const project = {
          ...loadedProject,
          signs: sortSignsAlphanumerically(withNewSigns),
        };

        const { res, err } = await saveProject({
          project,
          isLoggedIn,
        });

        if (err) console.warn(err);

        if (res?.json?.errors) {
          setTimedNotification({
            id: 'errorAddingSignsToProject',
            message: res.json.errors?.[0]?.message || 'An error has occured',
          });
        }

        if (res?.status === 200) {
          updatePotentialProjectInCart(project);
          history.push(`/project/${loadedProject.uuid}`);
          setTimedNotification({
            id: 'signsAddedToProject',
            message: `${selectedTemplateIDs.length} sign${selectedTemplateIDs.length > 1 ? 's' : ''} ${selectedTemplateIDs.length > 1 ? 'have' : 'has'} been added to ${loadedProject.name}`,
          });
        }
      };

      action();
    }
  }, [history, isLoggedIn, loadedProject, selectedSignSystem, selectedSigns, updatePotentialProjectInCart, setTimedNotification]);

  if (isLoadingProject) return <Loader fixed />;

  if (hasLoadedProject) {
    if (loadedProject) {
      const themeSignSystem = loadedProject.specs?.signSystem;

      return (
        <Fragment>
          <Helmet>
            <title>Add Sign(s)</title>
            <meta
              name="description"
              content="Browse available signs, set your preferences and add signs to your cart."
            />
          </Helmet>
          <ScrollToTopOnMount />
          <OnboardingModal />
          <form
            className={classes.addSign}
            onSubmit={onSubmit}
          >
            <header className={classes.header}>
              <h1 className={classes.headerTitle}>
                Add sign(s) to project
              </h1>
            </header>
            <BlockContainer className={classes.wrapper}>
              <div className={classes.sidebar}>
                <Filter
                  {...{
                    projectID,
                    themeSignSystem,
                    selectedSignSystem,
                    setSelectedSignSystem,
                    selectedCategories,
                    setSelectedCategories,
                    numberOfSelectedSigns,
                    dispatchSelectedSigns,
                    allSignsAreSelected,
                  }}
                />
              </div>
              <div className={classes.content}>
                <Results
                  {...{
                    project: loadedProject,
                    selectedCategories,
                    selectedSigns,
                    dispatchSelectedSigns,
                    selectedSignSystem,
                  }}
                />
              </div>
            </BlockContainer>
            <div className={classes.stickyFooter}>
              <MarginGrid>
                {numberOfSelectedSigns > 0 && (
                  <div style={{ position: 'relative' }}>
                    <OnboardingTooltip
                      position="topLeft"
                      contentPosition="top"
                      title="Save your changes"
                      message="Once your selection is complete, add the sign(s) to your project."
                    />
                    <Submit label={`Add ${numberOfSelectedSigns} sign${numberOfSelectedSigns === 1 ? '' : 's'} to project`} />
                  </div>
                )}
                <Button
                  to={`/project/${projectID}`}
                  label="Cancel"
                  kind={numberOfSelectedSigns > 0 ? 'text' : 'primary'}
                  color="lightGray"
                  htmlAttributes={{
                    type: 'button',
                  }}
                />
              </MarginGrid>
            </div>
          </form>
        </Fragment>
      );
    }
    return <Redirect to="/not-found" />;
  }
  return null;
};

export default AddSign;
