import React, { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import MarginGrid from '../../../../components/MarginGrid';
import BlockContainer from '../../../../components/BlockContainer';
import Button from '../../../../components/Button';
import Form from '../../../../forms/Form';
import Submit from '../../../../forms/fields/Submit';
import Errors from '../../../../forms/Errors';
import useStyles from './css';

const Section = (props) => {
  const [isEditable, setIsEditable] = useState(false);

  const {
    title,
    action,
    defaultValues,
    children,
    onResponse: onResponseFromProps,
  } = props;

  const classes = useStyles();

  const onResponse = useCallback(async (response) => {
    const { status } = response;
    if (status === 200) setIsEditable(false);
    if (typeof onResponseFromProps === 'function') onResponseFromProps(response);
  }, [onResponseFromProps]);

  return (
    <BlockContainer className={classes.section}>
      <Form
        method="put"
        action={action}
        onResponse={onResponse}
        className={classes.form}
        defaultValues={defaultValues}
        unflattenOnSubmit
      >
        <div className={classes.header}>
          <h2 className={classes.title}>
            {title}
          </h2>
          <Errors className={classes.errors} />
          <MarginGrid>
            {!isEditable && (
              <Button
                color="lightGray"
                onClick={() => setIsEditable(true)}
                label="Edit"
              />
            )}
            {isEditable && (
              <Fragment>
                <Submit
                  color="red"
                  label="Save"
                />
                <Button
                  color="lightGray"
                  onClick={() => setIsEditable(false)}
                  label="Cancel"
                />
              </Fragment>
            )}
          </MarginGrid>
        </div>
        <div className={classes.body}>
          <MarginGrid
            className={classes.marginGroup}
            forceWithOverflow
          >
            {typeof children === 'function' && children(isEditable)}
          </MarginGrid>
        </div>
      </Form>
    </BlockContainer>
  );
};

Section.defaultProps = {
  title: undefined,
  action: undefined,
  defaultValues: undefined,
  children: undefined,
  onResponse: undefined,
};

Section.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  defaultValues: PropTypes.shape({}),
  children: PropTypes.func,
  onResponse: PropTypes.func,
};

export default Section;
