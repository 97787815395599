import React from 'react';
import PropTypes from 'prop-types';
import Chevron from '../../../icons/ChevronIcon';
import useCSS from './css';

const ClickableArrow = (props) => {
  const {
    updatePage,
    isDisabled,
    iconRotation,
  } = props;

  const classes = useCSS();

  const className = [
    classes.clickableArrow,
    isDisabled && classes.isDisabled,
  ].filter(Boolean).join(' ');

  return (
    <button
      className={className}
      onClick={!isDisabled ? updatePage : undefined}
      type="button"
    >
      <Chevron
        size="s"
        rotation={iconRotation}
        color={isDisabled ? 'gray' : 'darkGray'}
      />
    </button>
  );
};

ClickableArrow.defaultProps = {
  updatePage: null,
  isDisabled: false,
  iconRotation: undefined,
};

ClickableArrow.propTypes = {
  updatePage: PropTypes.func,
  isDisabled: PropTypes.bool,
  iconRotation: PropTypes.number,
};

export default ClickableArrow;
