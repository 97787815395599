export default (state, action) => {
  let reducedState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case 'ADD_NOTIFICATION': {
      reducedState = {
        ...reducedState,
        ...payload,
      };
      break;
    }
    case 'DELETE_NOTIFICATION': {
      const { id } = payload;
      if (reducedState[id]) delete reducedState[id];
      break;
    }
    default: {
      break;
    }
  }

  return reducedState;
};
