import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';

export default createUseStyles({
  '@keyframes glow': {
    '0%': { fillOpacity: 0 },
    '50%': { fillOpacity: 1 },
    '100%': { fillOpacity: 0 },
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  svg: {
    width: base(2),
    height: base(2),
  },
  background: {
    fill: colors.white,
  },
  line: {
    animationName: '$glow',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    fillOpacity: 0,
  },
  line1: {
    fill: colors.lighterGray,
  },
  line2: {
    fill: colors.lightGray,
    animationDelay: '0.25s',
  },
  line3: {
    fill: colors.gray,
    animationDelay: '0.5s',
  },
  line4: {
    fill: colors.red,
    animationDelay: '0.75s',
  },
  fixed: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  absolute: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  message: {
    position: 'absolute',
    top: `calc(100% + ${base(0.5)})`,
    left: '50%',
    transform: 'translateX(-50%)',
    width: base(8),
    textAlign: 'center',
    color: colors.gray,
  },
});
