import { requests } from '../../api';

export default async ({ project, isLoggedIn }) => {
  if (project) {
    if (isLoggedIn) {
      requests.delete({
        url: `${process.env.API_URL}/api/projects/${project.id}`,
      });
    } else {
      const storedProjects = window.localStorage.getItem('projects');
      const withDeletedProject = storedProjects ? JSON.parse(storedProjects) : [];
      const projectIndex = withDeletedProject.findIndex((item) => item.uuid === project.uuid);
      withDeletedProject.splice(projectIndex, 1);
      window.localStorage.setItem('projects', JSON.stringify(withDeletedProject));
    }
  }
};
