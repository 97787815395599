import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../css/vars';
import { largeBreak, midBreak } from '../../../css/queries';

export default createUseStyles({
  item: {
    margin: 0,
  },
  header: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    marginBottom: base(2),
    ...midBreak({
      marginBottom: base(),
    }),
  },
  orderIDWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  orderID: {
    wordBreak: 'break-all',
  },
  requestStatus: {
    textAlign: 'right',
  },
  meta: {
    position: 'relative',
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    width: `calc(100% + ${base(2)})`,
    margin: base(-1),
    '& > *': {
      width: `calc(25% - ${base(2)})`,
      margin: base(),
    },
    ...largeBreak({
      margin: base(-0.5),
      flexWrap: 'wrap',
      width: `calc(100% + ${base()})`,
      '& > *': {
        margin: base(0.5),
        width: `calc(50% - ${base()})`,
      },
    }),
  },
  itemsHeader: {
    display: 'flex',
    fontWeight: 'bold',
    color: colors.darkGray,
    marginBottom: base(),
    marginLeft: base(2),
    ...midBreak({
      display: 'none',
    }),
  },
  quantityHeading: {
    marginLeft: 'auto',
  },
  totalHeading: {
    marginRight: base(6),
    width: base(8),
    textAlign: 'right',
  },
  items: {
    marginLeft: 0,
    padding: 0,
    listStyle: 'none',
    margin: 0,
  },
  total: {
    backgroundColor: colors.darkGray,
    borderRadius: vars.borderRadius,
    padding: base(2),
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  totalTitles: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: base(2),
  },
  totalValues: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});
