import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Error from '../Error';
import useStyles from './css';
import CheckboxBase from './Base';
import { useForm } from '../../Form/context';

const Checkbox = (props) => {
  const {
    name,
    label,
    disabled,
    onChange,
    value,
    errors,
    className,
    ghostChecked,
  } = props;

  const formContext = useForm();
  const valueFromContextOrProps = formContext ? formContext.fieldState?.[name]?.value : value;
  const [internalState, setInternalState] = useState(valueFromContextOrProps); // use internal state to avoid external debouncing

  // state can be externally controlled, either directly through props or automatically via the 'formContext'
  useEffect(() => {
    if (valueFromContextOrProps !== undefined && valueFromContextOrProps !== internalState) setInternalState(valueFromContextOrProps);
  }, [valueFromContextOrProps, internalState]);

  const checked = Boolean(internalState);
  const classes = useStyles();

  return (
    <div
      className={[
        classes.input,
        className,
        checked && classes.inputChecked,
      ].filter(Boolean).join(' ')}
    >
      <label
        htmlFor={name}
        className={classes.htmlLabel}
      >
        <div className={classes.inputWrapper}>
          <CheckboxBase
            {...{
              name,
              disabled,
              onChange: (incomingValue) => {
                setInternalState(incomingValue);
                if (formContext) {
                  formContext.dispatchFieldState({
                    type: 'UPDATE_FIELD',
                    payload: {
                      name,
                      value: incomingValue,
                    },
                  });
                }
                if (typeof onChange === 'function') onChange(incomingValue);
              },
              value: checked,
              ghostChecked,
            }}
          />
        </div>
        <span
          className={[
            classes.label,
            checked && classes.labelActive,
            disabled && classes.labelDisabled,
            errors && classes.labelError,
          ].filter(Boolean).join(' ')}
        >
          {label}
        </span>
      </label>
      <Error {...errors} />
    </div>
  );
};

Checkbox.defaultProps = {
  label: 'Label',
  disabled: false,
  onChange: undefined,
  errors: undefined,
  value: undefined,
  className: undefined,
  ghostChecked: undefined,
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.shape({}),
  value: PropTypes.bool,
  className: PropTypes.string,
  ghostChecked: PropTypes.bool,
};

export default Checkbox;
