import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { fireRequest, requests } from '../../api';
import syncProjects from '../../utilities/data/syncProjects';
import { useNotifications } from '../Notifications';

const AuthenticationContext = createContext({});
export const useAuthentication = () => useContext(AuthenticationContext);

const Authentication = (props) => {
  const { setTimedNotification } = useNotifications();
  const { children } = props;
  const [exp, setExp] = useState(undefined); // in seconds
  const [user, setUser] = useState(undefined);
  const history = useHistory();
  const { pathname } = useLocation();

  const isLoggedIn = user === undefined ? undefined : Boolean(user);

  const syncThenSetUser = useCallback((incomingUser) => {
    if (incomingUser) {
      const action = async () => {
        const syncedIDs = await syncProjects();
        const numberOfSyncedProjects = syncedIDs.length;
        if (numberOfSyncedProjects > 0) {
          setTimedNotification({
            id: 'syncSuccessful',
            message: `${numberOfSyncedProjects} new project${numberOfSyncedProjects > 1 ? 's' : ''} ${numberOfSyncedProjects > 1 ? 'have' : 'has'} synced to your account`,
          });
        }
        setUser(incomingUser);
      };
      action();
    } else setUser(incomingUser);
  }, [setTimedNotification]);

  const refreshToken = useCallback(() => {
    if (isLoggedIn) {
      const makeRequest = async () => {
        const { res, err } = await fireRequest({
          method: 'post',
          url: `${process.env.API_URL}/api/users/refresh-token`,
        });

        if (err) setUser(null);

        if (res) {
          const {
            status,
            json: {
              errors,
              exp: incomingExp,
            },
          } = res;

          if (status === 200) setExp(incomingExp);
          if (errors) setUser(null);
        }
      };

      makeRequest();
    }
  }, [isLoggedIn]);

  const fetchMe = useCallback(() => {
    const makeRequest = async () => {
      const { res, err } = await fireRequest({
        method: 'get',
        url: `${process.env.API_URL}/api/users/me`,
      });

      if (err) setUser(null);

      if (res) {
        const {
          status,
          json: {
            errors,
            user: incomingUser,
          },
        } = res;
        if (status === 200) { syncThenSetUser(incomingUser); }
        if (errors) setUser(null);
      }
    };

    makeRequest();
  }, [syncThenSetUser]);

  const logOut = useCallback(async () => {
    setUser(null);
    requests.post({
      url: `${process.env.API_URL}/api/users/logout`,
    });
    localStorage.removeItem('cancelledAuthReminder');
  }, []);

  // On mount, first check if the user is logged in
  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  // On every route change, refresh the token
  useEffect(() => {
    refreshToken();
  }, [pathname, refreshToken]);

  // coordinate an automatic force logout and redirection when the token expires
  useEffect(() => {
    let timerID;
    if (exp) {
      const now = Math.round((new Date()).getTime() / 1000);
      const remainingTime = -(now - exp);
      timerID = setTimeout(() => {
        logOut();
        history.push({
          pathname: '/logged-out',
          state: {
            title: 'You\'ve been logged out due to inactivity',
          },
        });
      }, remainingTime * 1000);
    }
    return () => {
      if (timerID) clearTimeout(timerID);
    };
  }, [exp, history, logOut]);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        isLoggedIn,
        logOut,
        setUser: syncThenSetUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

Authentication.defaultProps = {
  children: undefined,
};

Authentication.propTypes = {
  children: PropTypes.node,
};

export default Authentication;
