import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

export default createUseStyles({
  categoryWrapper: {
    '&:not(:last-child)': {
      marginBottom: base(2),
    },
    ...midBreak({
      '&:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
  categorySigns: {
    listStyle: 'none',
  },
  sign: {
    '&:not(:last-child)': {
      marginBottom: base(2),
    },
  },
  bottomCTA: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...midBreak({
      display: 'none',
    }),
  },
});
