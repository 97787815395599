import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import ShadowBox from '../../../components/ShadowBox';
import Checkbox from '../../../forms/fields/Checkbox';
import ChevronIcon from '../../../icons/ChevronIcon';
import useStyles from './css';

const Filter = (props) => {
  const {
    allCategories,
  } = props;

  const { search, pathname } = useLocation();
  const history = useHistory();
  const { categories: categoriesFromURL } = qs.parse(search, { ignoreQueryPrefix: true });
  const classes = useStyles();

  const handleChange = useCallback((slug, incomingValue) => {
    const withChangedCategory = [...categoriesFromURL || []];
    const indexOfSlugInURL = withChangedCategory.findIndex((item) => item === slug);

    if (incomingValue === true) {
      if (indexOfSlugInURL > -1) withChangedCategory[indexOfSlugInURL] = true;
      else withChangedCategory.push(slug);
    } else if (indexOfSlugInURL > -1) withChangedCategory.splice(indexOfSlugInURL, 1);
    const stringifiedSearch = qs.stringify({ categories: withChangedCategory }, { addQueryPrefix: true, encode: false });
    history.push(`${pathname}${stringifiedSearch}`);
  }, [categoriesFromURL, history, pathname]);

  const urlHasCategories = Array.isArray(categoriesFromURL) && categoriesFromURL.length > 0;

  return (
    <ShadowBox
      className={classes.filter}
      color="white"
    >
      <Collapsible openOnInit>
        {({ isOpen }) => (
          <Fragment>
            <CollapsibleToggler className={classes.label}>
              <strong className={classes.labelTitle}>
                Categories
              </strong>
              <div className={classes.icon}>
                <ChevronIcon rotation={isOpen ? 180 : 0} />
              </div>
            </CollapsibleToggler>
            <CollapsibleContent>
              {Array.isArray(allCategories) && allCategories.length > 0 && (
                <form className={classes.form}>
                  <ul className={classes.list}>
                    {allCategories.map((category, index) => {
                      const {
                        slug,
                        name,
                      } = category;

                      return (
                        <li
                          key={index}
                          className={classes.item}
                        >
                          <Checkbox
                            {...{
                              id: `category-${slug}`,
                              key: index,
                              name: slug,
                              label: name,
                              onChange: (incomingValue) => handleChange(slug, incomingValue),
                              value: urlHasCategories && categoriesFromURL.some((urlCat) => urlCat === slug),
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </form>
              )}
            </CollapsibleContent>
          </Fragment>
        )}
      </Collapsible>
    </ShadowBox>
  );
};

Filter.defaultProps = {
  allCategories: undefined,
};

Filter.propTypes = {
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
};

export default Filter;
