import validateFields from './validateFields';

export default (fieldState, action) => {
  let reducedFieldState = { ...fieldState };
  const { type, payload, hasSubmitted } = action;

  switch (type) {
    case 'UPDATE_FIELD': {
      const {
        name,
        value: incomingValue,
      } = payload;

      reducedFieldState = {
        ...reducedFieldState,
        [name]: {
          ...reducedFieldState[name],
          value: incomingValue,
        },
      };
      break;
    }
    case 'SET_ALL_FIELDS': {
      reducedFieldState = {
        ...payload,
      };
      break;
    }
    default: {
      break;
    }
  }

  if (hasSubmitted) {
    const validatedFields = validateFields(reducedFieldState);
    reducedFieldState = validatedFields;
  }

  return reducedFieldState;
};
