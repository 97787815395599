import { createUseStyles } from 'react-jss';
import { base } from '../../../../css/vars';

export default createUseStyles({
  symbols: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
  radio: {
    '&:not(:last-child)': {
      marginBottom: base(0.5),
    },
  },
});
