import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';
import { largeBreak } from '../../../css/queries';

export default createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: base(2),
    ...largeBreak({
      flexDirection: 'column',
      marginRight: '0',
      alignItems: 'flex-start',
    }),
  },
  header: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
    marginRight: base(2),
    ...largeBreak({
      flexDirection: 'column',
      width: '100%',
      marginRight: '0',
      alignItems: 'flex-start',
      marginBottom: base(),
    }),
  },
  image: {
    flexShrink: 0,
    marginRight: base(2),
    width: base(4),
    ...largeBreak({
      width: '100%',
      marginRight: '0',
      marginBottom: base(),
    }),
  },
  subtitleWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    ...largeBreak({
      width: '100%',
    }),
  },
  subtitle: {
    '& > *:not(:last-child)': {
      marginBottom: base(0.25),
    },
    ...largeBreak({
      flexGrow: '1',
      marginRight: base(),
    }),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      '& $backlinkLabel': {
        textDecoration: 'underline',
      },
    },
  },
  title: {
    marginBottom: 0,
  },
  backlinkLabel: {
    marginLeft: base(0.25),
  },
  footer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    ...largeBreak({
      width: '100%',
    }),
  },
  quantityInput: {
    flexShrink: 0,
    width: base(4),
    marginRight: base(2),
    ...largeBreak({
      flexShrink: 1,
      marginRight: base(),
      width: '100%',
    }),
  },
  priceWrapper: {
    flexShrink: 0,
  },
  desktopCTA: {
    flexShrink: '0',
    ...largeBreak({
      display: 'none',
    }),
  },
  mobileCTA: {
    display: 'none',
    ...largeBreak({
      display: 'block',
      flexShrink: '0',
    }),
  },
});
