import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../css/vars';
import { midBreak, largeBreak } from '../../css/queries';

export default createUseStyles({
  summary: {
    padding: '0',
    ...largeBreak({
      position: 'unset',
      top: 'unset',
    }),
  },
  content: {
    padding: base(2),
    ...midBreak({
      padding: base(),
    }),
  },
  header: {
    marginBottom: base(),
    color: colors.darkGray,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: base(),
    },
    '& > *': {
      color: colors.gray,
      marginBottom: '0',
    },
  },
  priceLabel: {
    marginRight: base(),
  },
  total: {
    color: colors.darkGray,
    '& > *': {
      color: colors.darkGray,
    },
  },
  noticeWrap: {
    padding: `${base()} ${base(2)}`,
    borderTop: `${colors.lighterGray} ${vars.strokeWidth}px solid`,
  },
  footer: {
    padding: base(2),
    borderTop: `${colors.lighterGray} ${vars.strokeWidth}px solid`,
    ...midBreak({
      display: 'none',
    }),
  },
  cta: {
    width: '100%',
  },
  notLoggedIn: {
    marginBottom: 0,
  },
});
