import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';
import Notice from '../../components/Notice';

const { copyStyles, copyStylesByCategory } = specSheets;

const allEntries = Object.entries(copyStyles);
const compliantEntries = Object.entries(copyStylesByCategory.compliant);

const InsertCopyStyles = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    insertType,
    headerLabel,
    noticeMessage,
    specId,
  } = props;

  const classes = useStyles();

  let allowedCopyStyles = allEntries;
  if (insertType === 'ADA') allowedCopyStyles = compliantEntries;
  const sortedCopyStyles = allowedCopyStyles.sort(([, a], [, b]) => a.label.localeCompare(b.label));

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertCopyStyles}>
        <SpecHeader
          label={headerLabel}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          {noticeMessage && (
            <div className={classes.noticeWrapper}>
              <Notice message={noticeMessage} />
            </div>
          )}
          {sortedCopyStyles.map(([id, copyStyle], index) => {
            const { label } = copyStyle;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'copyStyle',
                  label,
                  value: id,
                  onChange,
                  checked: id === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

InsertCopyStyles.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  insertType: undefined,
  headerLabel: undefined,
  noticeMessage: undefined,
  specId: undefined,
};

InsertCopyStyles.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  insertType: PropTypes.string,
  headerLabel: PropTypes.string,
  noticeMessage: PropTypes.string,
  specId: PropTypes.string,
};

export default InsertCopyStyles;
