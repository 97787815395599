import { createUseStyles } from 'react-jss';
import { midBreak } from '../../css/queries';
import vars, { base, shadows } from '../../css/vars';
// import { midBreak } from '../../css/queries';

export default createUseStyles({
  table: {
    width: '100%',
    borderSpacing: `0 ${base()}`,
    maxWidth: '100%',
    '& th': {
      textAlign: 'left',
    },
    '& td:first-child': {
      borderTopLeftRadius: vars.borderRadius,
      borderBottomLeftRadius: vars.borderRadius,
    },
    '& td:last-child': {
      borderTopRightRadius: vars.borderRadius,
      borderBottomRightRadius: vars.borderRadius,
    },
    ...midBreak({
      borderSpacing: `0 ${base(0.5)}`,
    }),
  },
  row: {
    borderRadius: vars.borderRadius,
    background: 'white',
    boxShadow: `${shadows.s}, ${shadows.xs}`,
  },
  col: {
    minWidth: '150px',
    paddingLeft: base(1.25),
    paddingRight: base(1.25),
  },
  cell: {
    paddingTop: base(2),
    paddingBottom: base(2),
    ...midBreak({
      paddingTop: base(),
      paddingBottom: base(),
    }),
  },
});
