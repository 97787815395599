import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  account: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: base(2),
    ...midBreak({
      flexDirection: 'column',
      marginBottom: base(),
    }),
  },
  title: {
    marginBottom: '0',
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  navLink: {
    color: 'unset',
    '&.active': {
      color: colors.darkGray,
    },
  },
  menuItem: {
    marginBottom: '0',
    color: 'inherit',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    margin: '0',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
});
