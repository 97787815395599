import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import qs from 'qs';
import BlockContainer from '../../components/BlockContainer';
import Filter from './Filter';
import Results from './Results';
import useAPI from '../../utilities/data/useAPI';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const Store = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  const categoriesSearchQuery = qs.stringify({
    where: {
      or: [
        {
          organization: {
            exists: false,
          },
        },
        {
          organization: {
            equals: 'null',
          },
        },
      ],
    },
    sort: '-sortPriority',
    limit: 20,
  }, { addQueryPrefix: true, encode: false });

  const [{ res: categoriesRes }] = useAPI('get', `${process.env.API_URL}/api/categories${categoriesSearchQuery}`);

  useEffect(() => {
    setCrumbs([{
      label: 'Store',
    }]);
  }, [setCrumbs]);

  const allCategories = categoriesRes?.json?.docs || [];

  return (
    <Fragment>
      <Helmet>
        <title>Store</title>
        <meta
          name="description"
          content="Browse the My290 store!"
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.products}>
        <h1>
          Store
        </h1>
        <BlockContainer className={classes.wrapper}>
          <div className={classes.sidebar}>
            <Filter allCategories={allCategories} />
          </div>
          <div className={classes.content}>
            <Results allCategories={allCategories} />
          </div>
        </BlockContainer>
      </div>
    </Fragment>
  );
};

export default Store;
