import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../css/vars';

export default createUseStyles({
  input: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  htmlLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  htmlInput: {
    flexShrink: '0',
    marginRight: base(0.5),
    width: base(),
    height: base(),
    padding: '0',
    backgroundColor: 'transparent',
    borderRadius: '100%',
    border: `solid ${colors.gray} ${vars.strokeWidth}px`,
    overflow: 'hidden',
    cursor: 'inherit',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-color-swatch': {
      border: 'none',
    },
    '&::-webkit-color-swatch-wrapper': {
      padding: '0',
    },
  },
  label: {

  },
});
