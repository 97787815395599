import React, { useEffect, useReducer, Fragment, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import {
  mergeSpecs,
  injectBackerPositions,
  themes,
} from '@two90signs/290-renderer';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import BlockContainer from '../../components/BlockContainer';
import Sidebar from './Sidebar';
import Content from './Content';
import useStyles from './css';
import reducer from './reducer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import useDebouncedCallback from '../../utilities/performance/useDebouncedCallback';
import useDebounce from '../../utilities/performance/useDebounce';
import useProject from '../../utilities/data/useProject';
import Loader from '../../components/Loader';
import saveProject from '../../utilities/data/saveProject';
import { useAuthentication } from '../../wrappers/Authentication';
import { useCart } from '../../wrappers/CartProvider';
import isDarkColor from '../../utilities/isDarkColor';
import { useNotifications } from '../../wrappers/Notifications';

const EditSign = () => {
  const { setSavingInProgress, setTimedNotification } = useNotifications();
  const { isLoggedIn } = useAuthentication();
  const { projectID, signID } = useParams();
  const [signState, dispatchSignState] = useReducer(reducer, undefined);
  const [mergedSpecs, setMergedSpecs] = useState(undefined);
  const { setCrumbs } = useBreadcrumbs();
  const { updatePotentialProjectInCart } = useCart();
  const { isLoading: isLoadingProject, project: loadedProject } = useProject(projectID);

  useEffect(() => {
    if (loadedProject && signState) {
      const {
        templateID,
        specs: signSpecs,
      } = signState;

      const {
        theme,
        specs: themeSpecs,
      } = loadedProject;


      const baseThemeSpecs = themes[theme];

      // If you come into edit Sign before going into edit base specs, first.  The theme backers are all undefined.
      // So load in the baseSpec default backer options.
      if (!themeSpecs.backers || themeSpecs.backers.length < 1) {
        themeSpecs.backers = baseThemeSpecs.backers;
      }

      const mergedSignSystem = signSpecs.signSystem || themeSpecs.signSystem;
      const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);
      const newMergedSpecs = mergeSpecs(mergedTheme, signSpecs);
      const backersWithPositions = injectBackerPositions(newMergedSpecs?.backers, mergedSignSystem, templateID);

      setMergedSpecs({
        ...newMergedSpecs,
        backers: backersWithPositions,
      });
    }
  }, [loadedProject, signState]);

  // When the project loads, reduce the result
  useEffect(() => {
    if (loadedProject) {
      const loadedSign = loadedProject.signs.find((sign) => sign.uuid === signID);
      dispatchSignState({
        type: 'UPDATE_SIGN',
        payload: loadedSign,
        project: loadedProject,
      });
    }
  }, [loadedProject, signID]);

  const classes = useStyles();

  // Although sign state is already debounced (see dispatchDebouncedSignState)
  // interactions with the API and localStorage needs an additional, heavier throttling
  const debouncedSignState = useDebounce(signState, 500);
  useEffect(() => {
    if (loadedProject && debouncedSignState) {
      const makeUpdate = async () => {
        const withUpdatedSign = { ...loadedProject };
        const indexOfSign = withUpdatedSign?.signs?.findIndex((item) => item.uuid === signID);
        if (indexOfSign > -1) withUpdatedSign.signs[indexOfSign] = debouncedSignState;

        const { res, err } = await saveProject({
          project: withUpdatedSign,
          isLoggedIn,
        });

        if (err) console.warn(err);

        if (res?.json?.errors) {
          setTimedNotification({
            id: 'errorSavingSign',
            message: res.json.errors?.[0]?.message || 'An error has occurred',
          });
        }

        if (res?.status === 200) {
          updatePotentialProjectInCart(withUpdatedSign);
          setSavingInProgress(false);
        }
      };

      makeUpdate();
    }
  }, [
    debouncedSignState,
    signID,
    projectID,
    isLoggedIn,
    loadedProject,
    updatePotentialProjectInCart,
    setSavingInProgress,
    setTimedNotification,
  ]);

  // This callback needs a minor debounce to handle re-renderings of more higher-rate specs like wallColor and copy
  const dispatchDebouncedSignState = useDebouncedCallback((action) => {
    setSavingInProgress(true);
    dispatchSignState({ ...action, project: loadedProject }); // attach project to every dispatch, so that specs can be sanitized
  }, 25, [loadedProject]);

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
      url: '/projects',
    }, {
      label: loadedProject?.name || projectID,
      url: `/project/${projectID}`,
      useEllipsis: true,
    }, {
      label: signState?.name || signID,
      useEllipsis: true,
    }]);
  }, [setCrumbs, projectID, signID, loadedProject, signState]);

  const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(0);

  const { wallColor } = signState || {};
  const wallColorToUse = wallColor || loadedProject?.wallColor;
  const [isDarkWallColor, setIsDarkWallColor] = useState(() => isDarkColor(wallColor));

  useEffect(() => {
    setIsDarkWallColor(isDarkColor(wallColorToUse));
  }, [wallColorToUse]);

  if (isLoadingProject || signState === undefined || mergedSpecs === undefined) {
    return <Loader fixed />;
  }

  if (!signState) return <Redirect to="/not-found" />;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.editSign}>
        <div className={classes.contentWrapper}>
          <BlockContainer
            dynamicColor={wallColorToUse}
            className={classes.blockContainer}
          >
            <Content
              {...{
                dispatchSignState: dispatchDebouncedSignState,
                signState,
                selectedPreviewIndex,
                setSelectedPreviewIndex,
                mergedSpecs,
                darkUI: isDarkWallColor,
              }}
            />
            <div className={classes.wallOverlay} />
          </BlockContainer>
        </div>
        <div className={classes.specsWrapper}>
          <Sidebar
            {...{
              project: loadedProject,
              dispatchSignState: dispatchDebouncedSignState,
              signState,
              selectedPreviewIndex,
              setSelectedPreviewIndex,
              mergedSpecs,
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default EditSign;
