import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import NoProjects from './NoProjects';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import Section from './Section';
import useProjects from '../../utilities/data/useProjects';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import BlockContainer from '../../components/BlockContainer';
import MarginGrid from '../../components/MarginGrid';
import Pagination from '../../components/Pagination';
import ScrollToTopOnSearch from '../../components/ScrollToTopOnSearch';

const Projects = () => {
  const [refreshCount, setRefreshCount] = useState(0);
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();
  const [hasLoadedProjects, setHasLoadedProjects] = useState(false);
  const { search } = useLocation();
  const { page: pageFromParams = 1 } = qs.parse(search, { ignoreQueryPrefix: true });

  const [{
    isLoading: isLoadingProjects,
    projects: loadedProjects,
    pagination,
  }] = useProjects({
    refreshTrigger: refreshCount,
    page: Number(pageFromParams),
  });

  // When the project loads, set the result
  useEffect(() => {
    setHasLoadedProjects(true);
  }, [loadedProjects]);

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
    }]);
  }, [setCrumbs]);

  const triggerRefresh = useCallback(() => {
    setRefreshCount(refreshCount + 1);
  }, [refreshCount]);

  if (isLoadingProjects) return <Loader fixed />;

  if (hasLoadedProjects) {
    const hasProjects = Array.isArray(loadedProjects) && loadedProjects.length > 0;

    if (hasProjects) {
      const {
        page,
        hasNextPage,
        hasPrevPage,
        limit,
        nextPage,
        pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      } = pagination;

      return (
        <Fragment>
          <Helmet>
            <title>My Projects</title>
            <meta
              name="description"
              content="View your project collection and make changes."
            />
          </Helmet>
          <ScrollToTopOnMount />
          <ScrollToTopOnSearch />
          <div className={classes.projects}>
            <header className={classes.header}>
              <div className={classes.titleWrapper}>
                <h1 className={classes.title}>
                  My projects
                </h1>
                <h4 className={classes.total}>
                  {`You have ${totalDocs} project${totalDocs > 1 ? 's' : ''}`}
                </h4>
              </div>
              <MarginGrid className={classes.headerCTAs}>
                <Button
                  label="Create new project"
                  to="/new-project"
                />
              </MarginGrid>
            </header>
            <div className={classes.body}>
              {loadedProjects.map((project, index) => (
                <Section
                  key={index}
                  project={project}
                  triggerRefresh={triggerRefresh}
                />
              ))}
              <Pagination
                {...{
                  page,
                  hasNextPage,
                  hasPrevPage,
                  limit,
                  nextPage,
                  pagingCounter,
                  prevPage,
                  totalDocs,
                  totalPages,
                }}
              />
              <BlockContainer className={classes.bottomCTA}>
                <Button
                  color="lightGray"
                  label="Create new project"
                  to="/new-project"
                />
              </BlockContainer>
            </div>
          </div>
        </Fragment>
      );
    }
    return <NoProjects />;
  }
  return null;
};

export default Projects;
