import React, { useEffect, useReducer, Fragment, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import BlockContainer from '../../components/BlockContainer';
import Sidebar from './Sidebar';
import Content from './Content';
import useStyles from './css';
import reducer from './reducer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import useDebounce from '../../utilities/performance/useDebounce';
import useDebouncedCallback from '../../utilities/performance/useDebouncedCallback';
import useProject from '../../utilities/data/useProject';
import Loader from '../../components/Loader';
import saveProject from '../../utilities/data/saveProject';
import { useAuthentication } from '../../wrappers/Authentication';
import isDarkColor from '../../utilities/isDarkColor';
import { useCart } from '../../wrappers/CartProvider';
import { useNotifications } from '../../wrappers/Notifications';

const EditTheme = () => {
  const { setSavingInProgress, setTimedNotification } = useNotifications();
  const { isLoggedIn } = useAuthentication();
  const { projectID } = useParams();
  const [projectState, dispatchProjectState] = useReducer(reducer);
  const { setCrumbs } = useBreadcrumbs();
  const [hasLoadedProject, setHasLoadedProject] = useState(false);
  const { isLoading: isLoadingProject, project: loadedProject } = useProject(projectID);
  const { updatePotentialProjectInCart } = useCart();

  const { wallColor, name: projectName } = projectState || {};

  const classes = useStyles();

  // When the project loads, reduce the result
  useEffect(() => {
    if (loadedProject) {
      dispatchProjectState({
        type: 'UPDATE_PROJECT',
        payload: loadedProject,
      });
      setHasLoadedProject(true);
    }
  }, [loadedProject, projectID]);

  // Although sign state is already debounced (see dispatchDebouncedProjectState)
  // interactions with the API and localStorage needs an additional, heavier throttling
  const debouncedProjectState = useDebounce(projectState, 500);

  useEffect(() => {
    if (debouncedProjectState) {
      const makeUpdate = async () => {
        const { res, err } = await saveProject({
          project: debouncedProjectState,
          isLoggedIn,
        });

        if (err) console.warn(err);

        if (res?.json?.errors) {
          setTimedNotification({
            id: 'errorSavingSign',
            message: res.json.errors?.[0]?.message || 'An error has occurred',
          });
        }

        if (res?.status === 200) {
          updatePotentialProjectInCart(debouncedProjectState);
          setSavingInProgress(false);
        }
      };

      makeUpdate();
    }
  }, [
    debouncedProjectState,
    projectID,
    isLoggedIn,
    updatePotentialProjectInCart,
    setSavingInProgress,
    setTimedNotification,
  ]);

  // This callback needs a minor debounce to handle re-renderings of more higher-rate specs like wallColor
  const dispatchDebouncedProjectState = useDebouncedCallback((action) => {
    setSavingInProgress(true);
    dispatchProjectState({ ...action });
  }, 25, []);

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
      url: '/projects',
    }, {
      label: projectName || projectID,
      url: `/project/${projectID}`,
      useEllipsis: true,
    }, {
      label: 'Edit Base Specifications',
    }]);
  }, [setCrumbs, projectID, projectName]);

  const [isDarkWallColor, setIsDarkWallColor] = useState(() => isDarkColor(wallColor));

  useEffect(() => {
    setIsDarkWallColor(isDarkColor(wallColor));
  }, [wallColor]);

  if (isLoadingProject) return <Loader fixed />;

  if (hasLoadedProject) {
    if (projectState) {
      return (
        <Fragment>
          <ScrollToTopOnMount />
          <div className={classes.editTheme}>
            <div className={classes.contentWrapper}>
              <BlockContainer
                dynamicColor={wallColor}
                className={classes.blockContainer}
              >
                <Content
                  {...{
                    dispatchProjectState: dispatchDebouncedProjectState,
                    projectState,
                    projectID,
                    darkUI: isDarkWallColor,
                  }}
                />
                <div className={classes.wallOverlay} />
              </BlockContainer>
            </div>
            <div className={classes.sidebarWrapper}>
              <Sidebar
                {...{
                  dispatchProjectState: dispatchDebouncedProjectState,
                  projectState,
                }}
              />
            </div>
          </div>
        </Fragment>
      );
    }
    return <Redirect to="/not-found" />;
  }
  return null;
};

export default EditTheme;
