import { createUseStyles } from 'react-jss';
import { hexToRGBA } from '@trbl/utils';
import vars, { colors, base } from '../../css/vars';
import { midBreak } from '../../css/queries';

const modalClass = '.my290__modal-item';

export default createUseStyles({
  confirmationPrompt: {
    pointerEvents: 'none', // let pointer events pass through to 'ModalContainer' so that closeOnBlur will work (enable it again where needed in children)
    transition: `${vars.defaultTransTime}ms ${vars.defaultTransCurve} opacity`,
    border: 'none',
    backgroundColor: hexToRGBA(colors.darkGray, 0.93),
    padding: base(2),
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0',
    [`&${modalClass}--appearActive, &${modalClass}--appearDone, &${modalClass}--enterActive, &${modalClass}--enterDone`]: {
      opacity: '1',
    },
    [`&${modalClass}--exitActive, &${modalClass}--exitDone`]: {
      opacity: '0',
    },
    ...midBreak({
      padding: base(),
    }),
  },
});
