import React, { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '../../wrappers/Authentication';
import Button from '../Button';
import CloseIcon from '../../icons/CloseIcon';
import useStyles from './css';

const blacklist = [
  '/login',
  '/signup',
  '/reset-password',
  '/set-new-password',
  '/no-organization',
];

const AuthReminder = () => {
  const { pathname } = useLocation();

  const { isLoggedIn } = useAuthentication();
  const classes = useStyles();
  const [showReminder, setShowReminder] = useState(false);
  const [pageIsBlacklisted, setPageIsBlacklisted] = useState(false);

  const cancelAuthReminder = useCallback(() => {
    localStorage.setItem('cancelledAuthReminder', true);
    setShowReminder(false);
  }, []);

  useEffect(() => {
    let timerID;
    const cancelledAuthReminder = localStorage.getItem('cancelledAuthReminder');
    if (!isLoggedIn && !cancelledAuthReminder) {
      timerID = setTimeout(() => setShowReminder(true), 120000);
    } else clearTimeout(timerID);
  }, [isLoggedIn, cancelAuthReminder]);

  useEffect(() => {
    const isBlacklisted = blacklist.indexOf(pathname) > -1;
    setPageIsBlacklisted(isBlacklisted);
  }, [pathname]);

  if (showReminder && !isLoggedIn && !pageIsBlacklisted) {
    return (
      <div className={classes.authReminder}>
        <span className={classes.content}>
          <Button
            to="/login"
            kind="text"
            label="Sign in"
          />
          {' or '}
          <Button
            to="/signup"
            kind="text"
            label="create an account"
          />
          {' to save your progress. Otherwise, you might lose your beautiful work!'}
        </span>
        <button
          className={classes.button}
          onClick={cancelAuthReminder}
          type="button"
        >
          <CloseIcon color="white" />
        </button>
      </div>
    );
  }
  return null;
};

export default AuthReminder;
