import {
  getSignPrice,
  themes,
  mergeSpecs,
} from '@two90signs/290-renderer';

export default (project) => {
  const {
    theme,
    specs: themeSpecs,
    signs,
  } = project;

  const baseThemeSpecs = themes[theme];
  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

  let price = 0;

  if (Array.isArray(signs) && signs.length > 0) {
    signs.forEach((sign) => {
      const {
        templateID,
        specs: signSpecs,
        quantity,
      } = sign;

      const signPrice = getSignPrice(mergedTheme, signSpecs, templateID);
      const signTotal = signPrice * quantity;
      price += signTotal;
    });
  }

  return price;
};
