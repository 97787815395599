import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { specSheets, themes } from '@two90signs/290-renderer';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useActionConfirmation } from '../../../wrappers/ActionConfirmation';
import OnboardingTooltip from '../../../components/OnboardingTooltip';

import AccentStripColors from '../../../specControls/AccentStripColors';
import BackerShapes from '../../../specControls/BackerShapes';
import BackerFinishes from '../../../specControls/BackerFinishes';
import BackerPatterns from '../../../specControls/BackerPatterns';
import BackerPatternColors from '../../../specControls/BackerPatternColors';
import Button from '../../../components/Button';
import Color from '../../../forms/fields/Color';
import EdgeRailColors from '../../../specControls/EdgeRailColors';
import EndCapColors from '../../../specControls/EndCapColors';
import EndCapStyles from '../../../specControls/EndCapStyles';
// import EnableDisable from '../../../specControls/EnableDisable';
import FrameColors from '../../../specControls/FrameColors';
import BaseplateFinishes from '../../../specControls/BaseplateFinishes';
import InsertFinishes from '../../../specControls/InsertFinishes';
import InsertCopyColors from '../../../specControls/InsertCopyColors';
import InsertCopyStyles from '../../../specControls/InsertCopyStyles';
import InsertCopyCases from '../../../specControls/InsertCopyCases';
import InsertTypes from '../../../specControls/InsertTypes';
import Papers from '../../../specControls/Papers';
import SignSystems from '../../../specControls/SignSystems';
import SpecAccordion from '../../../specControls/SpecAccordion';
import SpecLink from '../../../specControls/SpecLink';
// import TrimColors from '../../../specControls/TrimColors';

import useStyles from './css';
import { defaultWallColor } from '../../../css/vars';
import staticContent from '../../../staticContent';
import intToChar from '../../../utilities/charToInt';

const {
  signSystems,
  edgeRailColors,
  accentStripColors,
  endCapStyles,
  endCapColors,
  frameColors,
  baseplateFinishes,
  backerShapes,
  insertFinishes,
  papers,
  copyColors,
  copyStyles,
  copyCases,
  backerFinishes,
  backerPatterns,
  backerPatternColors,
  insertTypes,
} = specSheets;

const Sidebar = (props) => {
  const { confirmAction } = useActionConfirmation();
  const { url, path } = useRouteMatch();
  const { state } = useLocation();

  const {
    projectState: {
      name: projectName,
      wallColor,
      theme: selectedTheme,
      specs: themeSpecs,
    },
    dispatchProjectState,
  } = props;

  const baseThemeSpecs = themes[selectedTheme];

  const classes = useStyles();

  const {
    signSystem,
    edgeRailColor,
    // enableAccentStrip,
    accentStripColor,
    endCapStyle,
    endCapColor,
    // enableTrim,
    // trimColor,
    frameColor,
    baseplateFinish,
    inserts,
    backers,
  } = themeSpecs;

  // Since every <Switch /> child needs to be a <Route />
  // Mapping during render, and returning a <Fragment /> does not work
  // Instead, generate an array of components and call the function during render
  // so that each <Route /> is top-level
  const insertRoutes = useCallback(() => {
    const routes = [];

    if (Array.isArray(baseThemeSpecs.inserts) && baseThemeSpecs.inserts.length > 0) {
      baseThemeSpecs.inserts.forEach((baseThemeInsert, insertIndex) => {
        const {
          type: insertType,
          finish,
          copyColor,
          copyStyle,
          copyStyleADA,
          copyCase,
          paper,
          copyColorLP,
        } = inserts?.[insertIndex] || {};

        const headerPrefix = `Insert ${intToChar(insertIndex)} —`;

        routes.push(
          <Route
            key={`${path}/insert-${insertIndex + 1}/type`}
            path={`${path}/insert-${insertIndex + 1}/type`}
          >
            <InsertTypes
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(incomingValue) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'type',
                  specValue: incomingValue,
                },
              })}
              options={['DP', 'WI'].map((insertTypeID) => {
                const { label } = insertTypes[insertTypeID];
                return ({
                  id: insertTypeID,
                  label,
                });
              })}
              currentValue={insertType}
              headerLabel={`${headerPrefix} Non-ADA Insert Type`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/finish`}
            path={`${path}/insert-${insertIndex + 1}/finish`}
          >
            <InsertFinishes
              signSystem={signSystem}
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                sanitizerOptions: {
                  shouldSanitizeBaseplate: true,
                },
                payload: {
                  index: insertIndex,
                  specKey: 'finish',
                  specValue: value,
                },
              })}
              currentValue={finish}
              headerLabel={`${headerPrefix} Finish`}
              insertType={insertType}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/paper`}
            path={`${path}/insert-${insertIndex + 1}/paper`}
          >
            <Papers
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'paper',
                  specValue: value,
                },
              })}
              currentValue={paper}
              headerLabel={`${headerPrefix} Paper`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/copy-color`}
            path={`${path}/insert-${insertIndex + 1}/copy-color`}
          >
            <InsertCopyColors
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'copyColor',
                  specValue: value,
                },
              })}
              currentValue={copyColor}
              headerLabel={`${headerPrefix} Copy Color`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/copy-color-lp`}
            path={`${path}/insert-${insertIndex + 1}/copy-color-lp`}
          >
            <InsertCopyColors
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'copyColorLP',
                  specValue: value,
                },
              })}
              currentValue={copyColorLP}
              headerLabel={`${headerPrefix} Copy Color (LP)`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/copy-style`}
            path={`${path}/insert-${insertIndex + 1}/copy-style`}
          >
            <InsertCopyStyles
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'copyStyle',
                  specValue: value,
                },
              })}
              currentValue={copyStyle}
              headerLabel={`${headerPrefix} Copy Style`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/copy-style-ada`}
            path={`${path}/insert-${insertIndex + 1}/copy-style-ada`}
          >
            <InsertCopyStyles
              insertType="ADA"
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'copyStyleADA',
                  specValue: value,
                },
              })}
              currentValue={copyStyleADA}
              headerLabel={`${headerPrefix} Copy Style (ADA)`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${insertIndex + 1}/copy-case`}
            path={`${path}/insert-${insertIndex + 1}/copy-case`}
          >
            <InsertCopyCases
              backTo={url}
              specId={`insert-${insertIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_INSERT',
                payload: {
                  index: insertIndex,
                  specKey: 'copyCase',
                  specValue: value,
                },
              })}
              currentValue={copyCase}
              headerLabel={`${headerPrefix} Copy Case`}
            />
          </Route>,
        );
      });
    }

    return routes;
  }, [url, path, inserts, dispatchProjectState, signSystem, baseThemeSpecs]);

  const backerRoutes = useCallback(() => {
    const routes = [];

    if (Array.isArray(baseThemeSpecs.backers) && baseThemeSpecs.backers.length > 0) {
      baseThemeSpecs.backers.forEach((baseThemeBacker, backerIndex) => {
        const {
          shape,
          finish,
          pattern,
          patternColor,
        } = backers?.[backerIndex] || {};

        // note: lock down backers[1] shape options to match the backers[0] so as to keep with the "essentials" package (i.e. Arch, Double Arch, Curve, Double Curve, etc)
        // note: backers[1] auto-shape positioning is also necessary to keep with the "essentials" package (see '../reducer')
        const shapeOptions = [];
        if (backerIndex === 0) {
          Object.entries(backerShapes).forEach(([backerID, backerEntry]) => {
            shapeOptions.push({ ...backerEntry, id: backerID });
          });
        } else {
          const backer1Shape = backers?.[0]?.shape;
          if (backer1Shape && backer1Shape !== 'none') {
            const { label } = backerShapes[backer1Shape];
            shapeOptions.push({ id: backer1Shape, label });
          }
        }

        // note: flare on backer 1 is not available with klik signs
        if (signSystem === 'K') {
          const indexOfFlare = shapeOptions.findIndex((item) => item.id === 'F');
          if (indexOfFlare > -1) shapeOptions.splice(indexOfFlare, 1);
        }

        const headerPrefix = `Backer ${backerIndex + 1}  —`;

        routes.push(
          <Route
            key={`${path}/backer-${backerIndex + 1}/shape`}
            path={`${path}/backer-${backerIndex + 1}/shape`}
          >
            <BackerShapes
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_BACKER',
                payload: {
                  index: backerIndex,
                  specs: {
                    shape: value,
                  },
                },
              })}
              currentValue={shape}
              options={shapeOptions}
              headerLabel={`${headerPrefix} Shape`}
            />
          </Route>,
          <Route
            key={`${path}/backer-${backerIndex + 1}/finish`}
            path={`${path}/backer-${backerIndex + 1}/finish`}
          >
            <BackerFinishes
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(value) => {
                const specsToUpdate = { finish: value };
                const shouldResetPattern = pattern !== 'none' && value !== 'none'; // prevent finishes in combination with patterns
                if (shouldResetPattern) specsToUpdate.pattern = 'none';

                const action = () => dispatchProjectState({
                  type: 'UPDATE_BACKER',
                  payload: {
                    index: backerIndex,
                    specs: specsToUpdate,
                  },
                });

                if (shouldResetPattern) {
                  confirmAction({
                    message: (
                      <Fragment>
                        <div>
                          <b>
                            Backers cannot have both a finish and a pattern at the same time.
                          </b>
                        </div>
                        <div>
                          {'Changing this backer\'s finish to '}
                          <b>
                            {`${value} ${backerFinishes[value]?.label}`}
                          </b>
                          {' will also remove it\'s '}
                          <b>
                            {`${backerPatterns[pattern]?.label}`}
                          </b>
                          {' pattern.'}
                        </div>
                      </Fragment>
                    ),
                    confirmLabel: 'Ok',
                    action,
                  });
                } else action();
              }}
              currentValue={finish}
              headerLabel={`${headerPrefix} Finish`}
              backerIndex={backerIndex}
              signSystem={signSystem}
            />
          </Route>,
          <Route
            key={`${path}/backer-${backerIndex + 1}/pattern`}
            path={`${path}/backer-${backerIndex + 1}/pattern`}
          >
            <BackerPatterns
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(value) => {
                const specsToUpdate = { pattern: value };
                const shouldResetFinish = finish !== 'none' && value !== 'none'; // prevent patterns in combination with finishes
                if (shouldResetFinish) specsToUpdate.finish = 'none';

                const action = () => dispatchProjectState({
                  type: 'UPDATE_BACKER',
                  payload: {
                    index: backerIndex,
                    specs: specsToUpdate,
                  },
                });

                if (shouldResetFinish) {
                  confirmAction({
                    message: (
                      <Fragment>
                        <div>
                          <b>
                            Backers cannot have both a finish and a pattern at the same time.
                          </b>
                        </div>
                        <div>
                          {'Changing this backer\'s pattern to '}
                          <b>
                            {`${backerPatterns[value]?.label}`}
                          </b>
                          {' will also remove it\'s '}
                          <b>
                            {`${finish} ${backerFinishes[finish]?.label}`}
                          </b>
                          {' finish.'}
                        </div>
                      </Fragment>
                    ),
                    confirmLabel: 'Ok',
                    action,
                  });
                } else action();
              }}
              currentValue={pattern}
              headerLabel={`${headerPrefix} Pattern`}
            />
          </Route>,
          <Route
            key={`${path}/backer-${backerIndex + 1}/pattern-color`}
            path={`${path}/backer-${backerIndex + 1}/pattern-color`}
          >
            <BackerPatternColors
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_BACKER',
                payload: {
                  index: backerIndex,
                  specs: {
                    patternColor: value,
                  },
                },
              })}
              currentValue={patternColor}
              headerLabel={`${headerPrefix} Pattern Color`}
            />
          </Route>,
        );
      });
    }

    return routes;
  }, [url, path, backers, dispatchProjectState, confirmAction, signSystem, baseThemeSpecs]);

  return (
    <div className={classes.sidebar}>
      <Switch>
        <Route path={`${path}/sign-system`}>
          <SignSystems
            backTo={url}
            onChange={(incomingValue) => {
              const action = () => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'signSystem',
                  specValue: incomingValue,
                },
              });

              const shouldConfirm = incomingValue !== (signSystem);

              if (shouldConfirm) {
                confirmAction({
                  message: staticContent.signSystemChangeMessage,
                  confirmLabel: 'Ok',
                  action,
                });
              } else action();
            }}
            currentValue={signSystem}
          />
        </Route>
        <Route path={`${path}/edge-rail-color`}>
          <EdgeRailColors
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'edgeRailColor',
                specValue: value,
              },
            })}
            currentValue={edgeRailColor}
          />
        </Route>
        <Route path={`${path}/accent-strip-color`}>
          <AccentStripColors
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'accentStripColor',
                specValue: value,
              },
            })}
            currentValue={accentStripColor}
          />
        </Route>
        <Route path={`${path}/end-cap-style`}>
          <EndCapStyles
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'endCapStyle',
                specValue: value,
              },
            })}
            currentValue={endCapStyle}
          />
        </Route>
        <Route path={`${path}/end-cap-color`}>
          <EndCapColors
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'endCapColor',
                specValue: value,
              },
            })}
            currentValue={endCapColor}
          />
        </Route>
        {/* <Route path={`${path}/trim-color`}>
          <TrimColors
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'trimColor',
                specValue: value,
              },
            })}
            currentValue={trimColor}
          />
        </Route> */}
        <Route path={`${path}/frame-color`}>
          <FrameColors
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'frameColor',
                specValue: value,
              },
            })}
            currentValue={frameColor}
          />
        </Route>
        <Route path={`${path}/baseplate-finish`}>
          <BaseplateFinishes
            backTo={url}
            onChange={(value) => dispatchProjectState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'baseplateFinish',
                specValue: value,
              },
            })}
            currentValue={baseplateFinish}
          />
        </Route>
        {insertRoutes()}
        {backerRoutes()}
        <Route>
          <header className={classes.header}>
            <div style={{ position: 'relative' }}>
              <Button
                label="Reset"
                icon="reset"
                color="lightGray"
                onClick={() => {
                  confirmAction({
                    message: (
                      <div>
                        {'Are you sure you want to reset '}
                        <b>{projectName || 'this project'}</b>
                        {' to match the base specifications? This action'}
                        <b>
                          {' cannot '}
                        </b>
                        be undone.
                      </div>
                    ),
                    cancelLabel: 'No, thank you!',
                    confirmLabel: 'Yes, please!',
                    action: () => dispatchProjectState({
                      type: 'RESET_THEME',
                    }),
                  });
                }}
                htmlAttributes={{
                  type: 'button',
                }}
              />
              <OnboardingTooltip
                position="topLeft"
                contentPosition="left"
                title="Change your mind?"
                message="Select Reset when you want to go back to default specifications."
              />
            </div>
            <Color
              id="wallColor"
              name="wallColor"
              label="Wall color"
              currentValue={wallColor || defaultWallColor}
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_PROJECT',
                payload: {
                  wallColor: value,
                },
              })}
            />
          </header>
          <SpecLink
            to={`${url}/sign-system`}
            label="Sign System"
            currentValue={signSystem}
            currentLabel={signSystems[signSystem]?.label}
          />
          {signSystem === 'A' && (
            <SpecLink
              to={`${url}/edge-rail-color`}
              label="Edge Rail Color"
              currentValue={edgeRailColor}
              currentLabel={`${edgeRailColor} ${edgeRailColors[edgeRailColor]?.label}`}
              themeValue={baseThemeSpecs.edgeRailColor}
              themeLabel={`${baseThemeSpecs.edgeRailColor} ${edgeRailColors[baseThemeSpecs.edgeRailColor]?.label}`}
              onClear={() => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'edgeRailColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {/* {signSystem === 'S' && (
            <EnableDisable
              label="Enable Accent Strip"
              currentValue={enableAccentStrip}
              htmlName="enableAccentStrip"
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'enableAccentStrip',
                  specValue: value,
                },
              })}
            />
          )} */}
          {signSystem === 'A' && (
            <SpecLink
              to={`${url}/accent-strip-color`}
              label="Accent Strip Color"
              currentValue={accentStripColor}
              currentLabel={`${accentStripColor} ${accentStripColors[accentStripColor]?.label}`}
              themeValue={baseThemeSpecs.accentStripColor}
              themeLabel={`${baseThemeSpecs.accentStripColor} ${accentStripColors[baseThemeSpecs.accentStripColor]?.label}`}
              onClear={() => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'accentStripColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {signSystem === 'S' && (
            <SpecLink
              to={`${url}/end-cap-style`}
              label="End Cap Style"
              currentValue={endCapStyle}
              currentLabel={endCapStyles[endCapStyle]?.label}
              themeValue={baseThemeSpecs.endCapStyle}
              themeLabel={endCapStyles[baseThemeSpecs.endCapStyle]?.label}
              onClear={() => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'endCapStyle',
                  specValue: undefined,
                },
              })}
            />
          )}
          {signSystem === 'S' && (
            <SpecLink
              to={`${url}/end-cap-color`}
              label="End Cap Color"
              currentValue={endCapColor}
              currentLabel={`${endCapColor} ${endCapColors[endCapColor]?.label}`}
              themeValue={baseThemeSpecs.endCapColor}
              themeLabel={`${baseThemeSpecs.endCapColor} ${endCapColors[baseThemeSpecs.endCapColor]?.label}`}
              onClear={() => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'endCapColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {/* {signSystem === 'S' && (
            <EnableDisable
              label="Enable Trim"
              currentValue={enableTrim}
              htmlName="enableTrim"
              onChange={(value) => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'enableTrim',
                  specValue: value,
                },
              })}
            />
          )}
          {signSystem === 'S' && enableTrim && (
            <SpecLink
              to={`${url}/trim-color`}
              label="Trim Color"
              currentValue={trimColor}
            />
          )} */}
          {signSystem === 'A' && (
            <SpecLink
              to={`${url}/frame-color`}
              label="Frame Color"
              currentValue={frameColor}
              currentLabel={`${frameColor} ${frameColors[frameColor]?.label}`}
              themeValue={baseThemeSpecs.frameColor}
              themeLabel={`${baseThemeSpecs.frameColor} ${frameColors[baseThemeSpecs.frameColor]?.label}`}
              onClear={() => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'frameColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {Array.isArray(baseThemeSpecs.inserts) && baseThemeSpecs.inserts.length > 0
            && baseThemeSpecs.inserts.map((baseThemeInsert, insertIndex) => {
              const {
                type: baseThemeInsertType,
                finish: baseThemeFinish,
                paper: baseThemePaper,
                copyColor: baseThemeCopyColor,
                copyColorLP: baseThemeCopyColorLP,
                copyStyle: baseThemeCopyStyle,
                copyStyleADA: baseThemeADACopyStyle,
                copyCase: baseThemeCopyCase,
              } = baseThemeInsert;

              const {
                type: insertType,
                finish,
                paper,
                copyColor,
                copyColorLP,
                copyStyle,
                copyStyleADA,
                copyCase,
              } = inserts?.[insertIndex] || {};

              return (
                <div
                  key={insertIndex}
                  style={{ position: 'relative' }}
                >
                  {insertIndex === 0 && (
                    <OnboardingTooltip
                      position="topLeft"
                      contentPosition="left"
                      title="Specs are organized within these panels"
                      message="Changes here apply to every sign in your project, unless that sign has been individually edited.
                      Header Insert specs populate the top insert of multi-insert signs or single insert signs.
                      Accent Insert specs populate additional inserts. Not all options are available for ADA Inserts.
                      Window Inserts with Paper are not available for all signs."
                    />
                  )}
                  <SpecAccordion
                    openOnInit={Boolean(state ? state.open === `insert-${insertIndex + 1}` : false)}
                    label={`Insert ${intToChar(insertIndex)}`}
                    list={[{
                      type: 'link',
                      label: (
                        <Fragment>
                          Non-ADA
                          <br />
                          Insert Type
                        </Fragment>
                      ),
                      currentValue: insertType,
                      currentLabel: insertTypes[insertType]?.label,
                      themeValue: baseThemeInsertType,
                      themeLabel: insertTypes[baseThemeInsertType]?.label,
                      to: `${url}/insert-${insertIndex + 1}/type`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'type',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Finish',
                      currentValue: finish,
                      currentLabel: `${finish} ${insertFinishes[finish]?.label}`,
                      themeValue: baseThemeFinish,
                      themeLabel: `${baseThemeFinish} ${insertFinishes[baseThemeFinish]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/finish`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'finish',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Paper',
                      currentValue: paper,
                      currentLabel: `${paper} ${papers[paper]?.label}`,
                      themeValue: baseThemePaper,
                      themeLabel: `${baseThemePaper} ${papers[baseThemePaper]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/paper`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'paper',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Copy Color',
                      currentValue: copyColor,
                      currentLabel: `${copyColor} ${copyColors[copyColor]?.label}`,
                      themeValue: baseThemeCopyColor,
                      themeLabel: `${baseThemeCopyColor} ${copyColors[baseThemeCopyColor]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/copy-color`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyColor',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Copy Color (LP)',
                      currentValue: copyColorLP,
                      currentLabel: `${copyColorLP} ${copyColors[copyColorLP]?.label}`,
                      themeValue: baseThemeCopyColorLP,
                      themeLabel: `${baseThemeCopyColorLP} ${copyColors[baseThemeCopyColorLP]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/copy-color-lp`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyColorLP',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Copy Style (ADA)',
                      currentValue: copyStyleADA,
                      currentLabel: copyStyles[copyStyleADA]?.label,
                      themeValue: baseThemeADACopyStyle,
                      themeLabel: copyStyles[baseThemeADACopyStyle]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-style-ada`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyStyleADA',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Copy Style',
                      currentValue: copyStyle,
                      currentLabel: copyStyles[copyStyle]?.label,
                      themeValue: baseThemeCopyStyle,
                      themeLabel: copyStyles[baseThemeCopyStyle]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-style`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyStyle',
                          specValue: undefined,
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Copy Case',
                      currentValue: copyCase,
                      currentLabel: copyCases[copyCase]?.label,
                      themeValue: baseThemeCopyCase,
                      themeLabel: copyCases[baseThemeCopyCase]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-case`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyCase',
                          specValue: undefined,
                        },
                      }),
                    }]}
                  />
                </div>
              );
            })}
          {signSystem === 'K' && (
            <SpecLink
              to={`${url}/baseplate-finish`}
              label="Baseplate Finish"
              currentValue={baseplateFinish}
              currentLabel={`${baseplateFinish} ${baseplateFinishes[baseplateFinish]?.label}`}
              themeValue={baseThemeSpecs.baseplateFinish}
              themeLabel={`${baseThemeSpecs.baseplateFinish} ${baseplateFinishes[baseThemeSpecs.baseplateFinish]?.label}`}
              onClear={() => dispatchProjectState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'baseplateFinish',
                  specValue: undefined,
                },
              })}
            />
          )}
          {Array.isArray(baseThemeSpecs.backers) && baseThemeSpecs.backers.length > 0
            && baseThemeSpecs.backers.map((baseThemeBacker, backerIndex) => {
              const {
                shape: baseThemeShape,
                finish: baseThemeFinish,
                pattern: baseThemePattern,
                patternColor: baseThemePatternColor,
              } = baseThemeBacker;

              const {
                shape,
                finish,
                pattern,
                patternColor,
              } = backers?.[backerIndex] || {};

              return (
                <SpecAccordion
                  openOnInit={Boolean(state ? state.open === `backer-${backerIndex + 1}` : false)}
                  label={`Backer ${backerIndex + 1}`}
                  list={[
                    {
                      type: 'link',
                      label: 'Shape',
                      currentValue: shape,
                      currentLabel: shape === 'none' ? 'None' : `${backerShapes[shape]?.label}`,
                      themeValue: baseThemeShape,
                      themeLabel: baseThemeShape === 'none' ? 'None' : backerShapes[baseThemeShape]?.label,
                      to: `${url}/backer-${backerIndex + 1}/shape`,
                      onClear: () => dispatchProjectState({
                        type: 'UPDATE_BACKER',
                        payload: {
                          index: backerIndex,
                          specs: {
                            shape: undefined,
                          },
                        },
                      }),
                    }, {
                      type: 'link',
                      label: 'Finish',
                      currentValue: finish,
                      currentLabel: finish === 'none' ? 'None' : `${finish} ${backerFinishes[finish]?.label}`,
                      themeValue: baseThemeFinish,
                      themeLabel: baseThemeFinish === 'none' ? 'None' : `${baseThemeFinish} ${backerFinishes[baseThemeFinish]?.label}`,
                      to: `${url}/backer-${backerIndex + 1}/finish`,
                    }, {
                      type: 'link',
                      label: 'Pattern',
                      currentValue: pattern,
                      currentLabel: pattern === 'none' ? 'None' : backerPatterns[pattern]?.label,
                      themeValue: baseThemePattern,
                      themeLabel: baseThemePattern === 'none' ? 'None' : backerPatterns[baseThemePattern]?.label,
                      to: `${url}/backer-${backerIndex + 1}/pattern`,
                    },
                    backerPatterns[pattern]?.grayScale
                      ? {}
                      : {
                        type: 'link',
                        label: 'Pattern Color',
                        currentValue: patternColor,
                        currentLabel: `${patternColor} ${backerPatternColors[patternColor]?.label}`,
                        themeValue: baseThemePatternColor,
                        themeLabel: `${baseThemePatternColor} ${backerPatternColors[baseThemePatternColor]?.label}`,
                        to: `${url}/backer-${backerIndex + 1}/pattern-color`,
                        onClear: () => dispatchProjectState({
                          type: 'UPDATE_BACKER',
                          payload: {
                            index: backerIndex,
                            specs: {
                              patternColor: undefined,
                            },
                          },
                        }),
                      },
                  ]}
                />
              );
            })}
        </Route>
      </Switch>
    </div>
  );
};

Sidebar.defaultProps = {
  projectState: undefined,
  dispatchProjectState: undefined,
};

Sidebar.propTypes = {
  projectState: PropTypes.shape({
    quantity: PropTypes.number,
    name: PropTypes.string,
    templateID: PropTypes.string,
    prices: PropTypes.shape({
      signOnly: PropTypes.number,
    }),
    wallColor: PropTypes.string,
    theme: PropTypes.string,
    specs: PropTypes.shape({
      signSystem: PropTypes.string,
      edgeRailColor: PropTypes.string,
      accentStripColor: PropTypes.string,
      endCapStyle: PropTypes.string,
      endCapColor: PropTypes.string,
      frameColor: PropTypes.string,
      baseplateFinish: PropTypes.string,
      inserts: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        }),
      ),
      backers: PropTypes.arrayOf(
        PropTypes.shape({
          shape: PropTypes.string,
          pattern: PropTypes.string,
          finish: PropTypes.string,
        }),
      ),
    }),
  }),
  dispatchProjectState: PropTypes.func,
};

export default Sidebar;
