import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Sign, mergeSpecs, injectBackerPositions, mergeCopy, themes } from '@two90signs/290-renderer';
import useStyles from './css';
import AutoScale from '../../../../components/AutoScale';
import { base } from '../../../../css/vars';

const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

const DesktopSignArea = (props) => {
  const {
    signs,
    theme,
    themeSpecs,
  } = props;

  const baseThemeSpecs = themes[theme];

  const [signsWithMergedSpecs, setSignsWithMergedSpecs] = useState();
  const [showSigns] = useState(true);

  // Merge each sign with the theme and maintain in state
  // Also build a sign ref and a sign container ref for each ref of the render function
  useEffect(() => {
    if (Array.isArray(signs) && signs.length > 0) {
      const newSignRefs = [];
      const newSignContainerRefs = [];
      const newSignsWithMergedSpecs = cloneDeep(signs);

      const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

      signs.forEach((sign, signIndex) => {
        newSignRefs.push(createRef());
        newSignContainerRefs.push(createRef());

        const {
          templateID,
          specs: signSpecs,
          copy,
        } = sign;

        const { signSystem } = signSpecs;

        const mergedSignSystem = signSystem || themeSpecs.signSystem;

        const withMergedCopy = mergeCopy(signSpecs, copy[0]); // use the copy from the first sign
        const withMergedTheme = mergeSpecs(mergedTheme, withMergedCopy);
        const backersWithPositions = injectBackerPositions(withMergedTheme.backers, mergedSignSystem, templateID);
        newSignsWithMergedSpecs[signIndex].specs = {
          ...withMergedTheme,
          backers: backersWithPositions,
        };
      });

      setSignsWithMergedSpecs(newSignsWithMergedSpecs);
    }
  }, [signs, themeSpecs, baseThemeSpecs]);

  const classes = useStyles({
    showSigns,
    numberOfSigns: signs.length,
  });

  return (
    <div className={classes.desktopSignArea}>
      <div className={classes.scrollContainer}>
        <AutoScale
          signs={signs}
          className={classes.autoScale}
          render={(remeasure, scale) => (
            <div className={classes.maxWidth}>
              {Array.isArray(signsWithMergedSpecs) && signsWithMergedSpecs.length > 0 && (
                signsWithMergedSpecs.map((sign, index) => {
                  const { specs } = sign;

                  return (
                    <div
                      key={index}
                      className={classes.signWrapper}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        padding: `calc(${base(0.5)} / ${scale})`, // 'unscale' the padding
                      }}
                    >
                      <Sign
                        specs={specs}
                        onResize={remeasure}
                      />
                    </div>
                  );
                })
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

DesktopSignArea.defaultProps = {
  signs: undefined,
  themeSpecs: undefined,
  theme: undefined,
};

DesktopSignArea.propTypes = {
  signs: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  theme: PropTypes.string,
  themeSpecs: PropTypes.shape({
    signSystem: PropTypes.string,
  }),
};

export default DesktopSignArea;
