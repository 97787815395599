import { createUseStyles } from 'react-jss';
import { largeBreak, midBreak, desktopBreak } from '../../../css/queries';
import vars, { base } from '../../../css/vars';
import { small } from '../../../css/type';

export default createUseStyles({
  contentWrapper: {
    width: '100%',
    display: 'flex',
    paddingBottom: base(2),
    alignItems: 'flex-start',
    ...largeBreak({
      flexDirection: 'column',
    }),
  },
  sections: {
    flexGrow: 1,
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  section: {
    position: 'relative',
    marginBottom: base(2),
    ...midBreak({
      marginBottom: base(),
    }),
  },
  blockContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: `calc(100% + ${base(10)})`,
    margin: `0 ${base(-8)} 0 ${base(-2)}`,
  },
  sectionContent: {
    position: 'relative',
    padding: `${base(2)} 0`,
    display: 'flex',
    ...desktopBreak({
      flexWrap: 'wrap',
    }),
  },
  sectionTitle: {
    width: '33.33%',
    paddingRight: base(2),
    ...desktopBreak({
      width: '100%',
    }),
    ...largeBreak({
      paddingRight: 0,
    }),
  },
  marginGrid: {
    width: '66.66%',
    paddingRight: base(2),
    '& > * > *': {
      width: `calc(50% - ${base(1)})`,
    },
    ...desktopBreak({
      width: '100%',
    }),
    ...largeBreak({
      paddingRight: 0,
    }),
    ...midBreak({
      '& > * > *': {
        width: '100%',
      },
    }),
  },
  summaryWrapper: {
    flexShrink: 0,
    position: 'sticky',
    width: '33.33%',
    top: base(7),
    marginTop: base(2),
    ...largeBreak({
      width: '100%',
      marginTop: 0,
    }),
  },
  mobileCTA: {
    display: 'none',
    ...midBreak({
      display: 'block',
    }),
  },
  mobileCTAButton: {
    width: '100%',
  },
  paymentWrapper: {
    width: '66.66%',
    paddingRight: base(2),
    ...largeBreak({
      paddingRight: 0,
      width: '100%',
    }),
  },
  paymentInput: {
    width: '100%',
    background: 'white',
    borderRadius: `${vars.borderRadius}px`,
    padding: base(),
  },
  label: {
    extend: small,
    marginBottom: base(0.5),
    display: 'block',
  },
  errors: {
    marginBottom: base(2),
  },
});
