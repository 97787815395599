import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWindowInfo } from '@faceless-ui/window-info';

const AutoScale = (props) => {
  const {
    children,
    className,
    render,
    onScale,
    signs,
  } = props;

  const windowInfo = useWindowInfo();
  const [showResults, setShowResults] = useState(false);

  const containerRef = useRef();
  const dimensionsRef = useRef();

  const [state, setState] = useState({
    hasScaled: false,
    scale: 1,
    originalSize: {
      width: 0,
      height: 0,
    },
    marginBottom: 0,
    marginRight: 0,
    containerWidth: 0,
  });

  const measure = useCallback(() => {
    if (windowInfo.eventsFired > 0) {
      if (dimensionsRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;

        const signWidth = dimensionsRef.current.clientWidth;
        const signHeight = dimensionsRef.current.clientHeight;

        const widthRatio = containerWidth / signWidth;
        const heightRatio = containerHeight / signHeight;

        let scale = 1;
        if (widthRatio < 1 || heightRatio < 1) scale = heightRatio < widthRatio ? heightRatio : widthRatio;

        // need the below block for inital base spec render and the print view
        // don't yet have the container the signs will live in, so we need to calculate the container width
        // as close as we can so we don't render signs over the edge.
        if (signWidth === containerWidth) {
          let widthInIn = 0;

          signs.forEach((e) => {
            const widthStr = e.specs.inserts[0].width;
            const widthIn = widthStr.replace('in', '');
            const padIn = e.specs.inserts[0].padding.replace('in', '');

            widthInIn += Number(widthIn);
            widthInIn += Number(padIn);
          });

          const cont = widthInIn * 96; // 96 should be the pixel to inch conversion, add a couple extra to be safe
          const pad = (16 / (containerWidth / cont)) * (signs.length * 2);

          const newWidthRatio = containerWidth / (cont + pad);
          scale = newWidthRatio;
        }

        const marginBottom = (signHeight - (signHeight * scale)) * -1;
        const marginRight = (signWidth - (signWidth * scale)) * -1;

        setState({
          hasScaled: true,
          scale,
          marginBottom,
          marginRight,
          containerWidth,
          originalSize: {
            width: signWidth,
            height: signHeight,
          },
        });

        if (typeof onScale === 'function') onScale(scale);
      }
    }
  }, [onScale, windowInfo.eventsFired, signs]);

  useEffect(() => {
    if (state.hasScaled) setShowResults(true);
  }, [state.hasScaled]);

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        opacity: showResults ? '1' : '0',
      }}
    >
      <div ref={dimensionsRef}>
        <div
          style={{ transform: `scale(${state.scale})`, transformOrigin: 'top left' }}
        >
          {typeof render === 'function' && render(measure, state.scale)}
          {children}
        </div>
      </div>
    </div>
  );
};

AutoScale.defaultProps = {
  children: undefined,
  className: undefined,
  render: undefined,
  onScale: undefined,
  signs: undefined,
};

AutoScale.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  render: PropTypes.func,
  onScale: PropTypes.func,
  signs: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default AutoScale;
