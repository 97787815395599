import { createUseStyles } from 'react-jss';
import { base } from '../../../../css/vars';

export default createUseStyles({
  desktopSignArea: {
    overflow: 'hidden',
    position: 'relative',
    flexShrink: '1',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  scrollContainer: {
    flexGrow: 1,
    overflow: 'hidden',
    margin: base(-1),
    padding: base(),
  },
  autoScale: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '& > *': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 'auto',
      height: 'auto',
    },
  },
  maxWidth: ({ numberOfSigns }) => ({
    display: 'flex',
    position: 'relative',
    ...numberOfSigns > 8
      ? {
        flexWrap: 'wrap',
        width: '160in',
      } : {
        flexWrap: 'nowrap',
        maxWidth: '160in',
      },
  }),
});
