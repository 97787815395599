import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import Error from '../Error';
import useStyles from './css';
import { useForm } from '../../Form/context';

const Select = (props) => {
  const inputRef = useRef(null);

  const {
    id,
    name,
    label,
    className,
    disabled,
    onChange,
    options,
    value, // (might also be renamed to 'initialValue', as this component controls itself after initializing)
    hasError: hasErrorFromProps,
    required,
  } = props;

  const formContext = useForm();
  const initialValue = formContext?.fieldState?.[name]?.value || value;
  const hasError = hasErrorFromProps || formContext?.fieldState?.[name]?.isValid === false;
  const [internalState, setInternalState] = useState(initialValue); // use internal state to avoid external debouncing
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles();

  // this component is not controls itself after defining its initial state, but sometimes externals need to force (or momentarily control) the input to have a specific value
  // To temporarily switch back to being controlled, all you have to do is pass in a new 'value' (this means maintaining a synced state for 'value' in the parent component, and only setting for special events)
  useEffect(() => {
    if (value !== undefined && value !== internalState) setInternalState(value);
    if (initialValue !== internalState) setInternalState(initialValue);
  }, [initialValue, value, internalState, formContext]);

  return (
    <div
      className={[
        classes.select,
        className,
      ].filter(Boolean).join(' ')}
    >
      <label
        htmlFor={id}
        className={classes.htmlLabel}
      >
        <span
          className={[
            classes.label,
            (internalState || isFocused) && classes.labelActive,
            disabled && classes.labelDisabled,
            hasError && classes.labelError,
          ].filter(Boolean).join(' ')}
        >
          {`${label}${required ? '*' : ''}`}
        </span>
        <select
          {...{
            ref: inputRef,
            id,
            name,
            disabled,
            className: classes.htmlSelect,

            onChange: (e) => {
              const { value: incomingValue } = e.target;
              setInternalState(incomingValue);
              if (typeof onChange === 'function') onChange(incomingValue);
              if (formContext) {
                formContext.dispatchFieldState({
                  type: 'UPDATE_FIELD',
                  payload: {
                    name,
                    value: incomingValue,
                  },
                });
              }
            },
            value: internalState || '',
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          required
        >
          {Array.isArray(options) && options.length > 0 && options.map((option, index) => {
            const { label: optionLabel, value: optionValue } = option;
            return (
              <option
                key={index}
                value={optionValue}
              >
                {optionLabel}
              </option>
            );
          })}
        </select>
      </label>
      {/* <Error {...errors} /> */}
    </div>
  );
};

Select.defaultProps = {
  id: '',
  label: undefined,
  className: undefined,
  disabled: false,
  onChange: undefined,
  value: undefined,
  hasError: undefined,
  resetTo: undefined,
  options: [],
  required: undefined,
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasError: PropTypes.bool,
  resetTo: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  required: PropTypes.bool,
};

export default Select;
