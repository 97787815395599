import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';

const Loader = (props) => {
  const classes = useStyles();
  const {
    fixed,
    absolute,
    message,
  } = props;

  return (
    <div
      className={[
        classes.loader,
        fixed && classes.fixed,
        absolute && classes.absolute,
      ].filter(Boolean).join(' ')}
    >
      <svg
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <rect
          className={classes.background}
          width="32"
          height="32"
          rx="4"
        />
        <path
          className={`${classes.line} ${classes.line1}`}
          d="M3.44,23h3l4.12-13.9h-3Z"
        />
        <path
          className={`${classes.line} ${classes.line2}`}
          d="M9.44,23h3l4.12-13.9h-3Z"
        />
        <path
          className={`${classes.line} ${classes.line3}`}
          d="M15.44,23h3l4.12-13.9h-3Z"
        />
        <path
          className={`${classes.line} ${classes.line4}`}
          d="M21.44,23h3l4.12-13.9h-3Z"
        />
      </svg>
      {message && (
        <div className={classes.message}>
          {message}
        </div>
      )}
    </div>
  );
};

Loader.defaultProps = {
  fixed: undefined,
  absolute: undefined,
  message: undefined,
};

Loader.propTypes = {
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  message: PropTypes.string,
};

export default Loader;
