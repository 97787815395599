import React, { useRef, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import useStyles from './css';
import Errors from '../../forms/Errors';
import OnboardingTooltip from '../OnboardingTooltip';

// Note: This component has to remain uncontrolled in order to maintain focus on the contenteditable node between updates

const EditableHeading = (props) => {
  const {
    className,
    onSubmit,
    initialValue,
    inline,
    additionalButtons,
    onValidate,
    requiredMessage,
    darkUI,
    htmlElement: HTMLElement,
  } = props;

  const classes = useStyles({ inline, darkUI, isH1: HTMLElement === 'h1' });
  const [isEditting, setIsEditting] = useState(false);
  const [name, setName] = useState(initialValue);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [errors, setErrors] = useState({});

  const nameRef = useRef();

  const highlightText = useCallback(() => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNode(nameRef.current);
    range.setStart(nameRef.current, 0);

    if (nameRef.current.innerText) range.setEnd(nameRef.current, 1);
    else range.setEnd(nameRef.current, 0);

    selection.removeAllRanges();
    selection.addRange(range);
  }, []);

  const placeCursorAtEnd = useCallback(() => {
    const selection = window.getSelection();
    const range = document.createRange();

    if (nameRef.current.innerText) range.setStart(nameRef.current, 1);
    else range.setStart(nameRef.current, 0);

    selection.removeAllRanges();
    selection.addRange(range);
  }, []);

  // useLayoutEffect to focus an element after switching it to contentEditable - but focus() does not work until the HTML actually has that attribute
  // Since useEffect runs async, it's ref is stale, even when using a callback ref
  useLayoutEffect(() => {
    if (isEditting) {
      highlightText();
    }
  }, [isEditting, highlightText]);

  const onChange = useCallback((e) => {
    const { target: { innerText } } = e;
    if (submitAttempted) {
      const trimmedName = innerText.trim();
      if (trimmedName) {
        let isValidName = true;
        let validationMessage = '';
        if (typeof onValidate === 'function') {
          const validity = onValidate(trimmedName);
          if (typeof validity === 'string') {
            isValidName = false;
            validationMessage = validity;
          }
          const errorsWithoutName = { ...errors };
          delete errorsWithoutName.name;
          setErrors({ ...errorsWithoutName });
        }
        if (!isValidName) setErrors({ ...errors, name: validationMessage });
      } else setErrors({ ...errors, name: requiredMessage });
    }
    setName(innerText);
  }, [submitAttempted, errors, onValidate, requiredMessage]);

  useEffect(() => {
    const node = nameRef.current;
    node.addEventListener('input', onChange);
    return () => node.removeEventListener('input', onChange);
  }, [onChange]);

  const handleSubmit = useCallback(() => {
    setSubmitAttempted(true);
    const trimmedName = name.trim();

    if (trimmedName) {
      let isValidName = true;
      let validationMessage = '';
      if (typeof onValidate === 'function') {
        const validity = onValidate(trimmedName);
        if (typeof validity === 'string') {
          isValidName = false;
          validationMessage = validity;
        }
      }
      if (isValidName) {
        if (typeof onSubmit === 'function') onSubmit(name);
        const errorsWithoutName = { ...errors };
        delete errorsWithoutName.name;
        setErrors({ ...errorsWithoutName });
        setIsEditting(false);
        const selection = window.getSelection();
        selection.removeAllRanges();
        setSubmitAttempted(false);
      } else {
        setErrors({ ...errors, name: validationMessage });
        placeCursorAtEnd();
      }
    } else {
      setErrors({ ...errors, name: requiredMessage });
      placeCursorAtEnd();
    }
  }, [onSubmit, name, errors, onValidate, requiredMessage, placeCursorAtEnd]);

  return (
    <div
      className={[
        classes.editableHeading,
        className,
      ].filter(Boolean).join(' ')}
    >
      <Errors
        className={classes.errorWrapper}
        errors={errors}
      />
      <div className={classes.wrapper}>
        <HTMLElement
          className={[
            classes.h2,
            Boolean(errors.name) && classes.hasError,
          ].filter(Boolean).join(' ')}
          contentEditable={isEditting}
          suppressContentEditableWarning
          ref={nameRef}
        >
          {initialValue}
        </HTMLElement>
        <div className={classes.buttonsWrapper}>
          <div className={classes.buttons}>
            {isEditting && (
              <Button
                size="xs"
                label="Save"
                onClick={handleSubmit}
              />
            )}
            {!isEditting && (
              <Button
                size="xs"
                label="Rename"
                color={darkUI ? 'lightGray' : 'darkGray'}
                onClick={() => setIsEditting(true)}
              />
            )}
            {Array.isArray(additionalButtons) && additionalButtons.length > 0 && additionalButtons.map((button, index) => (
              <div key={index}>
                {button.tooltip && (
                <div style={{ position: 'relative' }}>
                  <OnboardingTooltip
                    {...button.tooltipContent}
                  />
                  <Button
                    size="xs"
                    color={darkUI ? 'lightGray' : 'darkGray'}
                    htmlAttributes={{
                      type: 'button',
                    }}
                    {...button}
                  />
                </div>
                )}
                {!button.tooltip
                && (
                <Button
                  size="xs"
                  color={darkUI ? 'lightGray' : 'darkGray'}
                  htmlAttributes={{
                    type: 'button',
                  }}
                  {...button}
                />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

EditableHeading.defaultProps = {
  className: undefined,
  project: undefined,
  onSubmit: undefined,
  initialValue: undefined,
  inline: undefined,
  additionalButtons: undefined,
  onValidate: undefined,
  requiredMessage: undefined,
  darkUI: undefined,
  htmlElement: 'h2',
};

EditableHeading.propTypes = {
  className: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  initialValue: PropTypes.string,
  inline: PropTypes.bool,
  additionalButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  onValidate: PropTypes.func,
  requiredMessage: PropTypes.string,
  darkUI: PropTypes.bool,
  htmlElement: PropTypes.oneOf([
    'h1',
    'h2',
  ]),
};

export default EditableHeading;
