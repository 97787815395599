import { createUseStyles } from 'react-jss';
import { desktopBreak, largeBreak, midBreak } from '../../../css/queries';
import vars, { base, colors, shadows } from '../../../css/vars';

export default createUseStyles({
  hero: {
    display: 'flex',
    alignItems: 'flex-start',
    ...desktopBreak({
      flexDirection: 'column',
    }),
  },
  content: {
    flexShrink: '0',
    width: `calc(33.33% - ${base(2)})`,
    marginRight: base(2),
    ...desktopBreak({
      marginRight: '0',
      width: '66.66%',
      marginBottom: base(2),
    }),
    ...midBreak({
      width: '100%',
    }),
  },
  list: {
    flexGrow: 1,
    marginBottom: base(4), // coordinate with bottom on 'list::before'
    display: 'flex',
    position: 'relative',
    margin: '0',
    padding: '0',
    '&::before': {
      position: 'absolute',
      content: '""',
      left: base(4),
      bottom: base(-4), // coordinate with marginBottom on 'list'
      width: '100%',
      height: '100%',
      borderRadius: vars.borderRadius,
      backgroundColor: colors.lighterGray,
      boxShadow: shadows.l,
      ...midBreak({
        display: 'none',
      }),
    },
    '&::after': {
      ...midBreak({
        content: '""',
        display: 'inline-block',
        padding: base(0.5),
      }),
    },
    ...desktopBreak({
      width: '100%',
    }),
    ...midBreak({
      overflow: 'auto',
      width: `calc(100% + ${base()})`,
      marginBottom: '0',
    }),
  },
  item: {
    position: 'relative',
    width: '33.33%',
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginRight: base(2),
    },
    ...largeBreak({
      '&:not(:last-child)': {
        marginRight: base(),
      },
    }),
    ...midBreak({
      width: `calc(75% - ${base()})`,
      flexShrink: '0',
    }),
  },
});
