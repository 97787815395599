import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { useForm } from '../../Form/context';

const Submit = forwardRef((props, ref) => {
  const {
    label,
    isLoading: isLoadingFromProp,
  } = props;

  const formContext = useForm();
  const isLoading = formContext ? formContext.isLoading : isLoadingFromProp;

  return (
    <Button
      {...{
        ...props,
        htmlElement: 'button',
        htmlAttributes: {
          disabled: isLoading,
          type: 'submit',
        },
        label: !isLoading ? label : 'Loading',
        ref,
      }}
    />
  );
});

Submit.defaultProps = {
  onClick: undefined,
  label: undefined,
  isLoading: undefined,
};

Submit.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Submit;
