import {
  sanitizeSpecs,
  sanitizeCopy,
  signTemplates,
  mergeSpecs,
  themes,
} from '@two90signs/290-renderer';
import { defaultWallColor } from '../../css/vars';

const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

const reducer = (state, action) => {
  const {
    theme,
    specs: themeSpecs,
    signs,
  } = state || {};

  const baseThemeSpecs = themes[theme];

  let reducedProject = { ...state };

  const {
    type,
    payload,
    sanitizerOptions,
  } = action;

  switch (type) {
    case 'RESET_THEME': {
      const { signSystem } = themeSpecs;
      reducedProject = {
        ...reducedProject,
        wallColor: defaultWallColor,
        specs: {
          signSystem,
        },
      };
      break;
    }

    case 'UPDATE_PROJECT': {
      reducedProject = {
        ...reducedProject,
        ...payload,
      };
      break;
    }

    case 'UPDATE_SPEC': {
      const { specKey, specValue } = payload;

      // alternate specs are sign system dependent, so need to re-merge every sign with its template if the sign system changes
      // note: the same is done in the sign reducer ('../EditSign/reducer')
      const shouldMergeTemplates = specKey === 'signSystem';
      let reducedSigns = cloneDeep(signs);

      if (shouldMergeTemplates) {
        if (Array.isArray(reducedSigns) && reducedSigns.length > 0) {
          reducedSigns = reducedSigns.map((reducedSign) => {
            const {
              templateID,
              specs: signSpecs,
            } = reducedSign;

            const { signSystem } = signSpecs;

            const signTemplate = signTemplates.find((template) => template.templateID === templateID);

            const {
              specs: templateSpecs,
              alternateSpecs: alternateTemplateSpecs,
            } = signTemplate || {};

            let templateSpecsToUse = templateSpecs;

            // if there are alternative specs, then the copy has to be reset because it may be incompatible
            if (alternateTemplateSpecs) {
              const mergedSignSystem = signSystem || specValue || themeSpecs?.signSystem;
              templateSpecsToUse = alternateTemplateSpecs?.[mergedSignSystem] || templateSpecs;
              const { inserts: insertsFromTemplate } = templateSpecsToUse;
              // extract copy from inserts for first sign only (quantity resets to 1)
              const sign = {
                ...reducedSign,
                specs: mergeSpecs(signSpecs, templateSpecsToUse),
                copy: [{
                  inserts: insertsFromTemplate.map(({ lines }) => ({ lines })),
                }],
              };

              return sign;
            }

            return reducedSign;
          });
        }
      }

      reducedProject = {
        ...reducedProject,
        specs: {
          ...themeSpecs,
          [specKey]: specValue,
        },
        signs: reducedSigns,
      };

      break;
    }

    case 'UPDATE_INSERT': {
      const { index, specKey, specValue } = payload;

      const withUpdatedInsert = themeSpecs?.inserts || [];

      withUpdatedInsert[index] = {
        ...withUpdatedInsert[index],
        [specKey]: specValue,
      };

      reducedProject = {
        ...reducedProject,
        specs: {
          ...themeSpecs,
          inserts: [
            ...withUpdatedInsert,
          ],
        },
      };
      break;
    }

    case 'UPDATE_BACKER': {
      const { index, specs } = payload;

      if (specs && typeof specs === 'object') {
        const updatedBackers = [...themeSpecs?.backers || []];

        updatedBackers[index] = {
          ...updatedBackers[index],
          ...specs,
        };

        reducedProject = {
          ...reducedProject,
          specs: {
            ...themeSpecs,
            backers: updatedBackers,
          },
        };
      }
      break;
    }

    default: {
      break;
    }
  }

  const {
    specs: reducedThemeSpecs,
    signs: reducedSigns,
  } = reducedProject;

  const sanitizedThemeSpecs = sanitizeSpecs({
    baseSpecs: baseThemeSpecs,
    specs: reducedThemeSpecs,
    theme,
    options: sanitizerOptions,
  });

  // sanitize the theme specs
  const sanitizedProject = {
    ...reducedProject,
    specs: sanitizedThemeSpecs,
  };

  // then sanitize the individual signs specs
  if (Array.isArray(reducedSigns) && reducedSigns.length > 0) {
    reducedSigns.forEach((reducedSign, signIndex) => {
      const {
        templateID,
        specs: signSpecs,
        copy,
      } = reducedSign;

      sanitizedProject.signs[signIndex].copy = sanitizeCopy(copy, templateID);

      sanitizedProject.signs[signIndex].specs = sanitizeSpecs({
        baseSpecs: sanitizedThemeSpecs,
        specs: signSpecs,
        selectedSignTemplate: templateID,
        theme,
        options: sanitizerOptions,
        template: templateID,
      });
    });
  }

  return sanitizedProject;
};

export default reducer;
