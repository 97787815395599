import { createUseStyles } from 'react-jss';

export default createUseStyles({
  '@keyframes glow': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  recording: {
    animationName: '$glow',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
    opacity: 0,
  },
});
