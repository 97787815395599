import { createUseStyles } from 'react-jss';
import { colors, base } from '../../../css/vars';
import { resetButton } from '../../../css/other';

export default createUseStyles({
  filter: {

  },
  label: {
    ...resetButton,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  labelTitle: {
    marginRight: base(),
    color: colors.darkGray,
  },
  icon: {
    width: base(0.5),
    height: base(0.5),
    flexShrink: '0',
  },
  form: {
    margin: 0,
    marginTop: base(),
  },
  list: {
    padding: '0',
    margin: '0',
    listStyle: 'none',
  },
  item: {
    marginBottom: base(),
  },
});
