import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../css/vars';
import { desktopBreak } from '../../../css/queries';

export default createUseStyles({
  imageSlider: {
    overflow: 'hidden',
    '& .slick-track': {
      display: 'flex',
    },
    // marginBottom: base(-0.75), // negative margins to account for .slide-slide padding
    // ...desktopBreak({
    //   marginBottom: base(-0.25), // negative margins to account for .slide-slide padding
    // }),
  },
  mainSlider: {
    marginBottom: base(0.75),
    ...desktopBreak({
      marginBottom: base(0.25),
    }),
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  thumbnailSlider: {
    flexGrow: '1',
    overflow: 'hidden',
    margin: `0 ${base(0.5)}`, // fractional margins to account for .slide-slide padding
    '& .slick-track': {
      display: 'flex',
      '& .slick-slide': {
        padding: base(0.5),
        ...desktopBreak({
          padding: base(0.25),
        }),
      },
    },
    ...desktopBreak({
      margin: `0 ${base(0.25)}`, // fractional margins to account for .slide-slide padding
    }),
  },
  thumbnail: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    paddingTop: '56.25%', // 16:9
    position: 'relative',
    height: '0',
    border: `${vars.strokeWidth}px solid`,
    borderRadius: `${vars.borderRadius}px`,
    borderColor: 'transparent',
    overflow: 'hidden',
  },
  thumbnailImage: {
    objectFit: 'cover',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  thumbnailActive: {
    borderColor: colors.gray,
  },
  button: {
    flexShrink: '0',
  },
});
