import { useEffect, useState, useCallback } from 'react';
import qs from 'qs';
import { useAuthentication } from '../../wrappers/Authentication';
import { fireRequest } from '../../api';

export default ({ refreshTrigger, page, limit = 6 } = {}) => {
  const { isLoggedIn, user } = useAuthentication();
  const [projects, setProjects] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    limit,
    nextPage: undefined,
    pagingCounter: undefined,
    prevPage: undefined,
    totalDocs: undefined,
    totalPages: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [internalError, setInternalError] = useState(false);

  const userID = user?.id;
  const organizationID = user?.organization?.id;

  const fetchDataFromAPI = useCallback(async () => {
    if (userID) {
      const where = {
        or: [
          {
            'owner.value': {
              equals: userID,
            },
          },
        ],
      };

      if (organizationID) {
        where.or.push({
          'owner.value': {
            equals: organizationID,
          },
        });
      }

      const { res, err } = await fireRequest({
        method: 'GET',
        url: `${process.env.API_URL}/api/projects?depth=0&${qs.stringify({ where, page, limit })}`,
      });
      if (err) setInternalError(err);
      if (res?.json) {
        const {
          page: pageFromRes,
          docs,
          hasNextPage,
          hasPrevPage,
          nextPage,
          pagingCounter,
          prevPage,
          totalDocs,
          totalPages,
        } = res.json;

        setProjects(docs);

        setPagination({
          page: pageFromRes,
          hasNextPage,
          hasPrevPage,
          nextPage,
          pagingCounter,
          prevPage,
          totalDocs,
          totalPages,
        });
      }
    }

    setIsLoading(false);
  }, [userID, organizationID, page, limit]);

  const getDataFromLocalStorage = useCallback(() => {
    const storedProjects = window.localStorage.getItem('projects');

    if (storedProjects) {
      const parsedProjects = JSON.parse(storedProjects);
      const docs = parsedProjects.slice(limit * (page - 1), limit * page);
      const totalDocs = parsedProjects.length;
      const totalPages = Math.ceil(totalDocs / limit);
      const hasNextPage = page + 1 <= totalPages;
      const hasPrevPage = page - 1 > 0;
      const nextPage = hasNextPage ? page + 1 : null;
      const prevPage = hasPrevPage ? page - 1 : null;

      setPagination({
        page,
        hasNextPage,
        hasPrevPage,
        limit,
        nextPage,
        // pagingCounter,
        prevPage,
        totalDocs,
        totalPages,
      });

      setProjects(docs);
    }

    setIsLoading(false);
  }, [limit, page]);

  useEffect(() => {
    setIsLoading(true);
    if (isLoggedIn) fetchDataFromAPI();
    else getDataFromLocalStorage();
  }, [isLoggedIn, fetchDataFromAPI, getDataFromLocalStorage, refreshTrigger]);

  return [{ isLoading, projects, internalError, pagination }];
};
