import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AutoScalingSign,
  mergeSpecs,
  mergeCopy,
  getSignPrice,
  injectBackerPositions,
  formatPartNumber,
  specSheets,
  signTemplateMeta,
  countBackers,
  themes,
  getBackerCode,
} from '@two90signs/290-renderer';
import Price from '../../../../../components/Price';
import Button from '../../../../../components/Button';
import SpecPanel from '../../../../../components/SpecPanel';
import useStyles from './css';

const {
  accentStripColors,
  edgeRailColors,
  frameColors,
  baseplateFinishes,
  endCapColors,
  insertFinishes,
  papers,
  copyColors,
  copyStyles,
  copyCases,
  copyPositions,
} = specSheets;

const SignRow = (props) => {
  const {
    project,
    sign,
    discount,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const {
    templateID,
    specs: signSpecs,
    copy,
    quantity,
    name,
    deferCopy,
  } = sign;

  const {
    insertArea,
    overallSizes,
  } = signTemplateMeta?.[templateID] || {};

  const {
    theme,
    specs: themeSpecs,
  } = project;

  const baseThemeSpecs = themes[theme];
  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

  const price = getSignPrice(themeSpecs, sign, templateID);

  const withMergedCopy = mergeCopy(signSpecs, copy[0]); // use the copy from the first sign
  const withMergedTheme = mergeSpecs(mergedTheme, withMergedCopy);
  const backersWithPositions = injectBackerPositions(withMergedTheme.backers, withMergedTheme.signSystem, templateID);
  const mergedSpecs = {
    ...withMergedTheme,
    backers: backersWithPositions,
  };

  const numberOfBackers = countBackers({}, mergedSpecs);
  const backerCode = getBackerCode(mergedSpecs);

  const {
    signSystem,
    endCapColor,
    edgeRailColor,
    endCapStyle,
    accentStripColor,
    frameColor,
    baseplateFinish,
    inserts,
    backers,
  } = mergedSpecs;

  const classes = useStyles();

  return (
    <div className={classes.signRow}>
      <div className={classes.wrap}>
        <div className={classes.thumbnail}>
          <AutoScalingSign
            className={classes.projectThumbnail}
            signProps={{ specs: mergedSpecs }}
            centerInContainer
          />
        </div>
        <div className={classes.content}>
          <ul className={classes.primaryContent}>
            <li>
              <span className={classes.metaLabel}>
                Sign Type
              </span>
              <span className={classes.metaValue}>
                <strong>
                  {formatPartNumber({}, mergedSpecs, templateID)}
                </strong>
              </span>
            </li>
            <li>
              <span className={classes.metaLabel}>
                Quantity
              </span>
              <span className={classes.metaValue}>
                <strong>
                  {quantity}
                </strong>
              </span>
            </li>
            <li>
              <span className={classes.metaLabel}>
                Price
              </span>
              <span className={classes.metaValue}>
                <Price
                  price={price}
                  discount={discount}
                  neverHide
                />
              </span>
            </li>
          </ul>
          <ul className={classes.secondaryContent}>
            <li>
              <span className={classes.metaLabel}>
                Sign Name
              </span>
              <span className={classes.metaValue}>
                {name}
              </span>
            </li>
            {insertArea && (
              <li>
                <span className={classes.metaLabel}>
                  Insert Area
                </span>
                <span className={classes.metaValue}>
                  {`${insertArea.height}" x ${insertArea.width}"`}
                </span>
              </li>
            )}
            {numberOfBackers > 0 && (
              <li>
                <span className={classes.metaLabel}>
                  Overall Size
                </span>
                <span className={classes.metaValue}>
                  {`${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`}
                </span>
              </li>
            )}
            <li className={classes.toggle}>
              <Button
                kind="text"
                label="Details"
                onClick={() => setIsOpen(!isOpen)}
                className={classes.toggleButton}
                icon="chevron"
                iconRotation={isOpen ? 180 : 0}
                size="s"
              />
            </li>
          </ul>
        </div>
      </div>
      {isOpen && (
        <ul className={classes.drawerContent}>
          {signSystem === 'S' && (
            <li>
              <SpecPanel
                background="white"
                heading="End Cap"
                specs={[
                  {
                    label: 'Style',
                    value: endCapStyle,
                  },
                  {
                    label: 'Color',
                    value: `${endCapColor} ${endCapColors[endCapColor]?.label}`,
                  },
                ]}
              />
            </li>
          )}
          {signSystem === 'A' && (
            <Fragment>
              <li>
                <SpecPanel
                  background="white"
                  heading="Edge Rail"
                  specs={[
                    {
                      label: 'Color',
                      value: `${edgeRailColor} ${edgeRailColors[edgeRailColor]?.label}`,
                    },
                  ]}
                />
              </li>
              {inserts.length > 1 && (
                <li>
                  <SpecPanel
                    background="white"
                    heading="Accent Strip"
                    specs={[
                      {
                        label: 'Color',
                        value: `${accentStripColor} ${accentStripColors[accentStripColor]?.label}`,
                      },
                    ]}
                  />
                </li>
              )}
              <li>
                <SpecPanel
                  background="white"
                  heading="Frame"
                  specs={[
                    {
                      label: 'Color',
                      value: `${frameColor} ${frameColors[frameColor]?.label}`,
                    },
                  ]}
                />
              </li>
            </Fragment>
          )}
          {signSystem === 'K' && (
            <li>
              <SpecPanel
                background="white"
                heading="Baseplate"
                specs={[
                  {
                    label: 'Finish',
                    value: `${baseplateFinish} ${baseplateFinishes[baseplateFinish]?.label}`,
                  },
                ]}
              />
            </li>
          )}
          {Array.isArray(inserts) && inserts.length > 0
            && inserts.map((insert, insertIndex) => {
              const {
                type,
                finish,
                copyColor,
                copyColorLP,
                copyStyle,
                copyStyleADA,
                paper,
                copyCase,
                copyPosition,
              } = insert;

              return (
                <li key={insertIndex}>
                  <SpecPanel
                    background="white"
                    heading={insertIndex > 0 ? `Accent Insert ${insertIndex + 1}` : 'Header Insert'}
                    specs={[
                      {
                        label: 'Type',
                        value: type,
                      },
                      {
                        label: 'Finish',
                        value: `${finish} ${insertFinishes[finish]?.label}`,
                      },
                      ...type === 'WI'
                        ? [{
                          label: 'Copy Color (LP)',
                          value: `${copyColorLP} ${copyColors[copyColorLP]?.label}`,
                        }, {
                          label: 'Paper',
                          value: `${paper} ${papers[paper]?.label}`,
                        }] : [{
                          label: 'Copy Color',
                          value: `${copyColor} ${copyColors[copyColor]?.label}`,
                        }],
                      ...type === 'ADA' ? [{
                        label: 'Copy Style (ADA)',
                        value: copyStyles[copyStyleADA]?.label,
                      }] : [{
                        label: 'Copy Style',
                        value: `${copyStyle} ${copyStyles[copyStyle]?.label}`,
                      }],
                      {
                        label: 'Copy Case',
                        value: copyCases[copyCase]?.label,
                      }, {
                        label: 'Copy Position',
                        value: copyPositions[copyPosition]?.label,
                      },
                    ]}
                  />
                </li>
              );
            })}
          {Array.isArray(backers) && backers.length > 0
            && backers.map((backer, backerIndex) => {
              const {
                shape,
                pattern,
                patternColor,
                finish,
              } = backer;

              if (shape) {
                return (
                  <li key={backerIndex}>
                    <SpecPanel
                      background="white"
                      heading={`Backer ${backerIndex + 1}`}
                      specs={[
                        {
                          label: 'Shape',
                          value: shape,
                        },
                        ...finish ? [{
                          label: 'Finish',
                          value: finish,
                        }] : [],
                        ...pattern ? [{
                          label: 'Pattern',
                          value: pattern,
                        }] : [],
                        ...patternColor ? [{
                          label: 'Pattern Color',
                          value: patternColor,
                        }] : [],
                      ]}
                    />
                  </li>
                );
              }
              return null;
            })}
          {!deferCopy && Array.isArray(copy) && copy.length > 0
            && copy.map((signCopy, signCopyIndex) => {
              const {
                inserts: insertsFromCopy,
              } = signCopy;

              return (
                <SpecPanel
                  key={signCopyIndex}
                  background="white"
                  heading={`Sign ${signCopyIndex + 1} Copy Schedule`}
                  specs={insertsFromCopy.map((insertCopy, insertCopyIndex) => {
                    const { lines } = insertCopy;

                    return ({
                      heading: `Insert ${insertCopyIndex + 1}`,
                      specs: lines.map((line, lineIndex) => {
                        const {
                          copy: lineCopy,
                          copySize,
                          symbol,
                          roomNumber,
                          flipContent,
                        } = line;

                        return ({
                          specs: [
                            {
                              label: `Line ${lineIndex + 1} Copy`,
                              value: lineCopy,
                            },
                            {
                              label: `Line ${lineIndex + 1} Copy Size`,
                              value: copySize,
                            },
                            ...symbol ? [{
                              label: 'Symbol',
                              value: symbol,
                            }] : [],
                            ...roomNumber ? [{
                              label: 'Room Number',
                              value: roomNumber,
                            }] : [],
                            ...Object.prototype.hasOwnProperty.call(line, 'flipContent') ? [{
                              label: 'Flip Content',
                              value: flipContent,
                            }] : [],
                          ],
                        });
                      }),
                    });
                  })}
                />
              );
            })}
          {deferCopy && (
            <SpecPanel
              heading="Copy Schedule"
              background="white"
              specs={[{
                label: 'Will be emailed',
              }]}
            />
          )}
        </ul>
      )}
    </div>
  );
};

SignRow.defaultProps = {
  discount: undefined,
};

SignRow.propTypes = {
  discount: PropTypes.number,
  sign: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    templateID: PropTypes.string,
    specs: PropTypes.shape({
      signSystem: PropTypes.string,
      endCapColor: PropTypes.string,
      endCapStyle: PropTypes.string,
      inserts: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
      backers: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
    copy: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    deferCopy: PropTypes.bool,
  }).isRequired,
  project: PropTypes.shape({
    theme: PropTypes.string,
    specs: PropTypes.shape({}),
  }).isRequired,
};

export default SignRow;
