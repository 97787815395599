import { createUseStyles } from 'react-jss';
import { resetButton } from '../../../css/other';
import { colors, base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

export default createUseStyles({
  filter: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...midBreak({
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
  label: {
    ...resetButton,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  labelTitle: {
    marginRight: base(),
    color: colors.darkGray,
  },
  icon: {
    width: base(0.5),
    height: base(0.5),
    flexShrink: '0',
  },
  contentWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
  list: {
    marginTop: base(),
    padding: '0',
    margin: '0',
    listStyle: 'none',
  },
  item: {
    '&:not(:last-child)': {
      marginBottom: base(),
    },
  },
});
