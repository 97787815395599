import { createUseStyles } from 'react-jss';
import { midBreak } from '../../../../css/queries';
import vars, { base, colors } from '../../../../css/vars';

export default createUseStyles({
  line: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
  lineTitle: {
    color: colors.darkGray,
  },
  checkbox: {
    borderRadius: `${vars.borderRadius}px`,
    border: `${vars.strokeWidth}px ${colors.lightGray} solid`,
    padding: base(1),
    flexGrow: 1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  checkboxLabel: {
    marginRight: base(),
    flexBasis: base(6), // match with 'SpecLink', 'Quantity', and 'Lines checkbox'
    ...midBreak({
      flexBasis: base(4), // match with 'SpecLink', 'Quantity', and 'Lines checkbox'
    }),
  },
});
