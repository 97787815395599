import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import Error from '../Error';
import useStyles from './css';
import { useForm } from '../../Form/context';

const Input = (props) => {
  const inputRef = useRef(null);

  const {
    id,
    name,
    label,
    type,
    className,
    autoComplete,
    disabled,
    onChange,
    value: valueFromProps, // (might also be renamed to 'initialValue', as this component controls itself after initializing)
    hasError: hasErrorFromProps,
    min,
    step,
    required,
  } = props;

  const formContext = useForm();
  const valueFromContextOrProps = formContext?.fieldState?.[name]?.value || valueFromProps;
  const hasError = hasErrorFromProps || formContext?.fieldState?.[name]?.isValid === false;
  const [internalState, setInternalState] = useState(valueFromContextOrProps); // use internal state to avoid external debouncing
  const [isFocused, setIsFocused] = useState(false);

  // state can be externally controlled, either directly through props or automatically via the 'formContext'
  useEffect(() => {
    if (valueFromContextOrProps !== undefined && valueFromContextOrProps !== internalState) setInternalState(valueFromContextOrProps);
  }, [valueFromContextOrProps, internalState]);

  const classes = useStyles({ type });

  return (
    <div
      className={[
        classes.input,
        className,
      ].filter(Boolean).join(' ')}
    >
      <label
        htmlFor={id}
        className={classes.htmlLabel}
      >
        <span
          className={[
            classes.label,
            (internalState || isFocused) && classes.labelActive,
            disabled && classes.labelDisabled,
            hasError && classes.labelError,
          ].filter(Boolean).join(' ')}
        >
          {`${label}${required ? '*' : ''}`}
        </span>
        <input
          {...{
            ref: inputRef,
            id,
            name,
            type,
            disabled,
            autoComplete,
            className: classes.htmlInput,
            onChange: (e) => {
              const { value: incomingValue } = e.target;
              setInternalState(incomingValue);
              if (formContext) {
                formContext.dispatchFieldState({
                  type: 'UPDATE_FIELD',
                  payload: {
                    name,
                    value: incomingValue,
                  },
                });
              }
              if (typeof onChange === 'function') onChange(incomingValue);
            },
            min,
            step,
            value: internalState || '',
            required,
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </label>
    </div>
  );
};

Input.defaultProps = {
  id: '',
  label: undefined,
  type: 'text',
  className: undefined,
  disabled: false,
  autoComplete: undefined,
  onChange: undefined,
  value: undefined,
  hasError: undefined,
  resetTo: undefined,
  min: undefined,
  step: undefined,
  required: undefined,
};

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'number',
    'hidden',
  ]),
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasError: PropTypes.bool,
  resetTo: PropTypes.string,
  min: PropTypes.number,
  step: PropTypes.number,
  required: PropTypes.bool,
};

export default Input;
