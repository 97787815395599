import { createUseStyles } from 'react-jss';
import vars, { base, colors, shadows } from '../../css/vars';
import { largeBreak, midBreak } from '../../css/queries';
import { resetButton } from '../../css/other';

export default createUseStyles({
  slider: {
    display: 'flex',
    ...largeBreak({
      flexDirection: 'column',
    }),
  },
  content: {
    flexShrink: '0',
    width: `calc(33.33% - ${base(2)})`,
    marginRight: base(2),
    ...largeBreak({
      marginRight: 0,
      width: '66.66%',
      marginBottom: base(2),
    }),
    ...midBreak({
      width: '100%',
      marginBottom: base(),
    }),
  },
  progress: {
    width: '100%',
    height: vars.strokeWidth * 2,
    backgroundColor: colors.lightGray,
    marginBottom: base(),
    position: 'relative',
  },
  progressBar: ({ progressPercentage }) => ({
    backgroundColor: colors.darkGray,
    height: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
    width: `${progressPercentage}%`,
    transition: `${vars.transTime}ms ${vars.transCurve} width`,
  }),
  controls: {
    ...midBreak({
      display: 'none',
    }),
  },
  control: {
    ...resetButton,
    width: base(1),
    height: base(1),
    '&:hover path': {
      stroke: colors.darkGray,
    },
    '&:not(:last-child)': {
      marginRight: base(0.5),
    },
  },
  prevNext: {
    display: 'flex',
    flexShrink: '0',
    alignItems: 'center',
    marginBottom: base(),
  },
  desktopCTA: {
    marginTop: base(),
    ...largeBreak({
      display: 'none',
    }),
  },
  mobileCTA: {
    display: 'none',
    marginTop: base(2),
    ...largeBreak({
      display: 'block',
    }),
    ...midBreak({
      marginTop: base(),
    }),
  },
  sliderWrapper: {
    position: 'relative',
    flexShrink: '0',
    width: `calc(66.66% + ${base(2)})`, // coordinate with marginRight on 'slide'
    flexGrow: '1',
    overflow: 'hidden',
    '& .slick-track': {
      display: 'flex',
    },
    '& .slick-slide': {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    ...largeBreak({
      width: `calc(100% + ${base()})`, // coordinate with marginRight on 'slide'
    }),
    ...midBreak({
      overflow: 'auto',
      '& .slick-track': {
        width: '100% !important',
        transform: 'translate3d(0, 0, 0) !important',
      },
      '& .slick-slide': {
        width: '75% !important',
        flexShrink: '0',
      },
    }),
  },
  wrapperWithBackground: {
    backgroundColor: colors.lighterGray,
    borderRadius: `${vars.borderRadius}px`,
    boxShadow: shadows.l,
    padding: base(2),
    width: '66.66%',
    ...midBreak({
      width: '100%',
    }),
  },
  sliderFallback: {
    display: 'flex',
    '& > *': {
      flexShrink: 0,
      maxWidth: '33.33%', // use maxWidth to overcome the 'flexGrow' on the 'slide'
    },
    ...midBreak({
      '& > *': {
        width: '75%', // match '.slick-slide' width on midBreak
        maxWidth: 'unset', // see 'maxWidth' note above
      },
    }),
  },
  slide: {
    flexGrow: '1',
    display: 'flex !important',
    flexDirection: 'column',
    '&:focus': {
      outline: 'none',
    },
  },
  card: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    marginRight: base(2), // coordinate with width of 'slider'
    ...largeBreak({
      marginRight: base(), // coordinate with width of 'slider'
    }),
  },
  noResults: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});
