import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const ChevronIcon = (props) => {
  const { color, rotation, className, size } = props;
  const classes = useIconStyles({ color, rotation });

  return (
    <svg
      className={[
        classes.wrapper,
        classes.rotation,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.stroke}
        d="M2 1.75L9.5 9.25L17 1.75"
      />
    </svg>
  );
};

ChevronIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

ChevronIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default ChevronIcon;
