import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { fireRequest } from '../../api';
import Button from '../../components/Button';
import Submit from '../../forms/fields/Submit';
import Input from '../../forms/fields/Input';
import Form from '../../forms/Form';
import Errors from '../../forms/Errors';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { useAuthentication } from '../../wrappers/Authentication';
import MarginGrid from '../../components/MarginGrid';

const SetNewPassword = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();
  const { token } = useParams();
  const [success, setSuccess] = useState(false);
  const [apiErrors, setAPIError] = useState(false);
  const { setUser } = useAuthentication();

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  const onSubmit = useCallback(async (data) => {
    const { res: { json }, err } = await fireRequest({
      method: 'post',
      url: `${process.env.API_URL}/api/users/reset-password`,
      options: {
        body: JSON.stringify({
          ...data || {},
          token,
        }),
      },
    });

    if (err) {
      console.warn(err);
    }
    if (json) {
      const { errors } = json;
      if (errors) setAPIError(errors);
      else {
        setSuccess(json);
        const { user } = json;
        if (user) setUser(user);
      }
    }
  }, [token, setUser]);

  return (
    <Fragment>
      <Helmet>
        <title>
          Set New Password
        </title>
        <meta
          name="description"
          content="Forgot your password? Set a new password here."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.setPassword}>
        {success ? (
          <Fragment>
            <h1>
              Success! Your password has been reset.
            </h1>
            <p>
              What would you like to do next?
            </p>
            <MarginGrid>
              <Button
                to="/new-project"
                label="Create new project"
              />
              <Button
                to="/projects"
                color="lightGray"
                label="Browse your projects"
              />
              <Button
                to="/store"
                color="lightGray"
                label="Browse the store"
              />
            </MarginGrid>
          </Fragment>
        ) : (
          <h1>
            Set new password
          </h1>
        )}
        {!success && (
          <Form
            className={classes.form}
            onSubmit={onSubmit}
          >
            <p className={classes.intro}>
              Enter your new password below.
            </p>
            {Array.isArray(apiErrors) && apiErrors.length > 0 && (
              <Errors
                errors={apiErrors[0]}
              />
            )}
            <Input
              type="password"
              name="password"
              id="password"
              label="New Password"
            />
            <Input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              label="Confirm New Password"
            />
            <Submit
              className={classes.submit}
              label="Update password"
            />
          </Form>
        )}
      </div>
    </Fragment>
  );
};

export default SetNewPassword;
