import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowInfo } from '@faceless-ui/window-info';
import useStyles from './css';
import CloseIcon from '../../icons/CloseIcon';
import useClickAway from '../../utilities/useClickAway';
import { breakpoints } from '../../css/vars';
import { useOnboarding } from '../../wrappers/Onboarding';

const OnboardingTooltip = (props) => {
  const {
    position = 'topLeft',
    contentPosition = 'right',
    mobileContentPosition = 'right',
    title,
    message,
    initialOpen,
    className,
  } = props;

  const {
    optedOut,
    optOut,
  } = useOnboarding();

  const [showMessage, setShowMessage] = useState(initialOpen);
  const classes = useStyles();
  const contentRef = useRef(null);
  useClickAway(contentRef, () => setShowMessage(false));
  const { width: windowWidth } = useWindowInfo();

  useEffect(() => {
    if (optedOut) {
      setShowMessage(false);
    }
  }, [optedOut]);

  if (!optedOut) {
    const positionToUse = windowWidth < breakpoints.m ? mobileContentPosition : contentPosition;

    return (
      <div
        className={[
          classes.onboardingTooltip,
          position && classes[position],
          className && className,
          showMessage && classes.active,
        ].filter(Boolean).join(' ')}
      >
        <div
          className={classes.button}
          role="button"
          tabIndex={0}
          onClick={() => setShowMessage(!showMessage)}
          onKeyPress={() => setShowMessage(!showMessage)}
        >
          <div className={classes.buttonLabel}>
            <small>
              <b>
                ?
              </b>
            </small>
          </div>
        </div>
        <div
          ref={contentRef}
          className={[
            classes.content,
            contentPosition && classes[`content--position-${positionToUse}`],
            showMessage && classes.showTooltip,
          ].filter(Boolean).join(' ')}
        >
          <div
            className={classes.closeTooltip}
            role="button"
            tabIndex={0}
            onClick={() => setShowMessage(false)}
            onKeyPress={() => setShowMessage(false)}
          >
            <CloseIcon
              size="s"
              className={classes.closeIcon}
            />
          </div>
          {title && (
            <div className={classes.title}>
              {title}
            </div>
          )}
          {message && (
            <div className={classes.message}>
              {message}
            </div>
          )}
          <div
            onClick={optOut}
            onKeyPress={optOut}
            tabIndex={0}
            role="button"
            className={classes.optOut}
          >
            Hide all messages like this
          </div>
        </div>
      </div>
    );
  }
  return null;
};

OnboardingTooltip.defaultProps = {
  title: undefined,
  message: undefined,
  position: undefined,
  contentPosition: undefined,
  mobileContentPosition: undefined,
  initialOpen: false,
  className: undefined,
};

OnboardingTooltip.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  position: PropTypes.oneOf([
    'topLeft',
    'top',
    'topRight',
    'right',
    'bottomRight',
    'bottom',
    'bottom',
    'bottomLeft',
    'left',
  ]),
  contentPosition: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
  ]),
  mobileContentPosition: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
  ]),
  initialOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default OnboardingTooltip;
