import { createUseStyles } from 'react-jss';
import { midBreak } from '../../css/queries';
import { colors } from '../../css/vars';
import bgDecoration from '../../../assets/images/bg-decoration.svg';

export default createUseStyles({
  fixedBackground: {
    position: 'fixed',
    left: '0',
    bottom: '0',
    right: '0',
    top: '0',
    backgroundColor: colors.lighterGray,
    backgroundImage: `url(${bgDecoration})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    ...midBreak({
      backgroundImage: 'none',
    }),
  },
});
