import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { base } from '../../css/vars';
import { midBreak, largeBreak, desktopBreak } from '../../css/queries';

const useStyles = createUseStyles({
  marginGrid: {
  },
  withOverflow: {
    ...largeBreak({
      overflowX: 'auto',
      width: `calc(100% + ${base(4)})`, // coordinate with 'View' padding
    }),
    ...midBreak({
      width: `calc(100% + ${base()})`, // coordinate with 'View' padding
    }),
  },
  forceWithOverflow: {
    overflow: 'visible',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: base(-1),
    padding: base(0.5),
    '& > *': {
      display: 'inline-flex',
      margin: base(0.5),
    },
    ...midBreak({
      margin: base(-0.5),
      padding: base(0.25),
      '& > *': {
        margin: base(0.25),
      },
    }),
  },
  wrapperWithOverflow: {
    ...largeBreak({
      flexWrap: 'nowrap',
      '&::after': {
        content: '""',
        display: 'inline-flex',
        paddingRight: base(3.5), // coordinate with 'wrapper' margins
      },
    }),
    ...midBreak({
      '&::after': {
        paddingRight: base(0.75), // coordinate with 'wrapper' margins
      },
    }),
  },
  staticColumns: {
    '& > *': {
      flexShrink: '0',
      width: `calc(33.33% - ${base()}) !important`, // coordinate with 'wrapper' margins
      ...desktopBreak({
        width: `calc(50% - ${base()}) !important`, // coordinate with 'wrapper' margins
      }),
      ...largeBreak({
        width: `calc(66.66% - ${base()}) !important`, // coordinate with margins above
      }),
    },
  },
});

const MarginGrid = (props) => {
  const {
    className,
    overflowMobile,
    forceWithOverflow,
    staticColumns,
    children,
  } = props;

  const classes = useStyles();

  return (
    <div
      className={[
        classes.marginGrid,
        className,
        forceWithOverflow && classes.forceWithOverflow,
        overflowMobile && classes.withOverflow,
      ].filter(Boolean).join(' ')}
    >
      <div
        className={[
          classes.wrapper,
          overflowMobile && classes.wrapperWithOverflow,
          staticColumns && classes.staticColumns,
        ].filter(Boolean).join(' ')}
      >
        {children && children}
      </div>
    </div>
  );
};

MarginGrid.defaultProps = {
  className: undefined,
  overflowMobile: false,
  forceWithOverflow: false,
  staticColumns: undefined,
  children: undefined,
};

MarginGrid.propTypes = {
  className: PropTypes.string,
  overflowMobile: PropTypes.bool,
  forceWithOverflow: PropTypes.bool,
  staticColumns: PropTypes.bool,
  children: PropTypes.node,
};

export default MarginGrid;
