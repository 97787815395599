import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShadowBox from '../ShadowBox';
import useStyles from './css';
import Notice from '../Notice';
import { useAuthentication } from '../../wrappers/Authentication';
import formatPrice from '../../utilities/formatPrice';
import useDiscount from '../../utilities/useDiscount';
import getProjectPrice from '../../utilities/getProjectPrice';
import { useForm } from '../../forms/Form/context';

const Summary = (props) => {
  const {
    isLoggedIn,
    user,
  } = useAuthentication();

  const {
    projects,
    products,
    action,
    notice,
  } = props;

  const classes = useStyles();
  const discount = useDiscount();

  const {
    fieldState: {
      shippingAccountNumber: {
        value: shippingAccountNumber,
      } = {},
    } = {},
  } = useForm() || {};

  let listPrice = 0;

  projects.forEach((project) => {
    const { quantity } = project;
    const price = getProjectPrice(project);
    listPrice += (price * quantity) || 0;
  });

  products.forEach(({ price, quantity }) => {
    listPrice += (price * quantity) || 0;
  });

  let discountedAmount = 0;
  if (discount >= 0 && discount <= 1) discountedAmount = listPrice * discount;

  const netPrice = listPrice - discountedAmount;

  const {
    organization,
  } = user || {};

  const {
    taxRate,
    taxExempt,
  } = organization || {}; // org may be 'null'

  let taxedAmount = 0;

  if (!taxExempt && taxRate && taxRate > 0) {
    taxedAmount = netPrice * taxRate;
  }

  let shippingCost = 10; // minimum shipping cost

  if (!shippingAccountNumber) {
    const trueShippingCost = listPrice * 0.025;
    if (trueShippingCost > 10) shippingCost = trueShippingCost;
  } else {
    shippingCost = 0; // customers may also provide their own shipping
  }

  const total = netPrice + taxedAmount + shippingCost;

  return (
    <ShadowBox
      color="white"
      className={classes.summary}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <strong>
            Order Summary
          </strong>
        </div>
        {!isLoggedIn && (
          <h3 className={classes.notLoggedIn}>
            <Link to="/login">
              Log in for price
            </Link>
          </h3>
        )}
        {isLoggedIn && (
          <Fragment>
            <div className={classes.row}>
              <h3 className={classes.priceLabel}>
                List Price
              </h3>
              <h3>
                {formatPrice(listPrice)}
              </h3>
            </div>
            {discount > 0 && (
              <Fragment>
                <div className={classes.row}>
                  <h3 className={classes.priceLabel}>
                    Discount
                  </h3>
                  <h3>
                    {`-${formatPrice(discountedAmount)}`}
                  </h3>
                </div>
                <div className={classes.row}>
                  <h3 className={classes.priceLabel}>
                    Net Price
                  </h3>
                  <h3>
                    {formatPrice(netPrice)}
                  </h3>
                </div>
              </Fragment>
            )}
            {taxedAmount > 0 && (
              <div className={classes.row}>
                <h3 className={classes.priceLabel}>
                  Tax
                </h3>
                <h3>
                  {formatPrice(taxedAmount)}
                </h3>
              </div>
            )}
            <div className={classes.row}>
              <h3 className={classes.priceLabel}>
                Shipping
              </h3>
              <h3>
                {formatPrice(shippingCost)}
              </h3>
            </div>
            <div className={`${classes.row} ${classes.total}`}>
              <h3 className={classes.priceLabel}>
                Total
              </h3>
              <h3>
                {formatPrice(total)}
              </h3>
            </div>
          </Fragment>
        )}
      </div>
      {notice && (
        <div className={classes.noticeWrap}>
          <Notice message={notice} />
        </div>
      )}
      {action && (
        <div className={classes.footer}>
          {action}
        </div>
      )}
    </ShadowBox>
  );
};

Summary.defaultProps = {
  projects: undefined,
  products: undefined,
  action: undefined,
  notice: undefined,
};

Summary.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
    }),
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
    }),
  ),
  action: PropTypes.node,
  notice: PropTypes.string,
};

export default Summary;
