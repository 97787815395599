import { createUseStyles } from 'react-jss';
import { colors, base } from '../../css/vars';

export default createUseStyles({
  specHeader: {
    width: '100%',
    cursor: 'pointer',
    color: colors.darkGray,
    '& > a': {
      display: 'block',
      textDecoration: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  label: {
    color: colors.darkGray,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
