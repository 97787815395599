import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../../css/vars';

export default createUseStyles({
  formattedCard: {
  },
  pagination: {
    position: 'absolute',
    left: base(0.5),
    bottom: base(0.5),
  },
  signSize: {
    color: colors.gray,
  },
});
