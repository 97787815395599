import { createUseStyles } from 'react-jss';
import vars, { base, colors, borderShadows } from '../../css/vars';
import { resetButton } from '../../css/other';
import { midBreak } from '../../css/queries';
import { small } from '../../css/type';

const buttonBase = {
  ...resetButton,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: `${vars.borderRadius}px`,
  fontWeight: 'bold',
  textDecoration: 'none',
  transition: `${vars.defaultTransTime}ms ${vars.defaultTransCurve} box-shadow, ${vars.defaultTransTime}ms ${vars.defaultTransCurve} background-color`,
  boxShadow: 'inset 0px 0px 0px 3px transparent',
};

const buttonSizes = {
  xs: {
    padding: `${base(0.25)} ${base(0.5)}`,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    letterSpacing: '2px',
    ...small(),
  },
  s: {
    padding: base(0.5),
  },
  l: {
    padding: `${base(0.75)} ${base(1.25)}`,
    ...midBreak({
      padding: base(1),
    }),
  },
};

const labelSizes = {
  s: {
    ...small(),
  },
};

const buttonColors = {
  lightGray: {
    backgroundColor: colors.lightGray,
    color: colors.darkGray,
    fontWeight: 'normal',
    boxShadow: `${borderShadows.lightGray}`,
    '&:hover': {
      boxShadow: `${borderShadows.gray}`,
    },
  },
  darkGray: {
    backgroundColor: colors.darkGray,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.darkerGray,
    },
  },
  red: {
    backgroundColor: colors.red,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.brighterRed,
    },
  },
};

export default createUseStyles({
  primary: ({ color, size, flexReverse }) => ({
    ...buttonBase,
    ...buttonColors[color],
    ...buttonSizes[size],
    flexDirection: flexReverse ? 'row-reverse' : undefined,
  }),
  text: ({ flexReverse }) => ({
    ...resetButton,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'underline',
    flexDirection: flexReverse ? 'row-reverse' : undefined,
  }),
  disabled: {
    pointerEvents: 'none',
  },
  label: ({ labelSize }) => ({
    ...labelSizes[labelSize],
    order: 1,
  }),
  icon: {
    order: 3,
  },
  seperator: ({ size }) => ({
    width: size === 'xs' ? base(0.25) : base(0.5),
    order: 2,
  }),
  linkWrap: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
