import React from 'react';
import PropTypes from 'prop-types';
import CheckmarkIcon from '../../../icons/CheckmarkIcon';
import useStyles from './css';

const SelectOverlay = (props) => {
  const {
    name,
    value,
    currentValue,
    children,
    id,
    type,
    className,
    onChange,
    onClick,
  } = props;

  let checked = false;
  if (type === 'radio') checked = currentValue === value;
  if (type === 'checkbox') checked = Boolean(currentValue);
  const classes = useStyles(checked);

  if (type) {
    return (
      <div
        onClick={onClick}
        onKeyPress={onClick}
        className={[
          classes.selectOverlay,
          className,
        ].filter(Boolean).join(' ')}
        role="button"
        tabIndex={0}
      >
        <label
          className={classes.htmlLabel}
          htmlFor={id}
        >
          {children && children}
          <div className={classes.border} />
          <div className={classes.inputWrapper}>
            <div className={classes.icon}>
              <CheckmarkIcon color="white" />
            </div>
            <input
              {...{
                className: classes.htmlInput,
                id,
                name,
                type,
                value,
                onChange: onChange ? (e) => {
                  if (type === 'radio') onChange(e.target.value);
                  if (type === 'checkbox') onChange(!checked);
                } : undefined,
              }}
            />
          </div>
        </label>
      </div>
    );
  }
  return children;
};

SelectOverlay.defaultProps = {
  name: undefined,
  value: undefined,
  children: undefined,
  id: undefined,
  type: undefined,
  className: undefined,
  onChange: undefined,
  onClick: undefined,
  currentValue: undefined,
};

SelectOverlay.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.node,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'checkbox',
    'radio',
  ]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  currentValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default SelectOverlay;
