import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets, signTemplateMeta } from '@two90signs/290-renderer';
import Button from '../../../components/Button';
import BlockContainer from '../../../components/BlockContainer';
import MarginGrid from '../../../components/MarginGrid';
import useStyles from './css';
import FormattedCard from './FormattedCard';
import { useNotifications } from '../../../wrappers/Notifications';

const { signCategories } = specSheets;

const orderSignsByCategory = (signs) => {
  const organizedByCategory = {};
  if (Array.isArray(signs) && signs.length > 0) {
    signs.forEach((sign) => {
      const { templateID } = sign;
      const templateMeta = signTemplateMeta[templateID];

      if (templateMeta) {
        const { category } = templateMeta;
        if (!organizedByCategory[category]) {
          organizedByCategory[category] = [{ ...sign }];
        } else {
          organizedByCategory[category].push({ ...sign });
        }
      }
    });
  }

  return organizedByCategory;
};

const Content = (props) => {
  const { setTimedNotification } = useNotifications();

  const {
    project,
    setProjectState,
    project: {
      uuid: projectID,
      signs,
      name: projectName,
    },
  } = props;

  const classes = useStyles();

  const signsByCategory = orderSignsByCategory(signs);
  const allCategories = Object.keys(signsByCategory);
  const hasCategories = Array.isArray(allCategories) && allCategories.length > 0;
  const hasSigns = Array.isArray(signs) && signs.length > 0;

  return (
    <div className={classes.content}>
      {(hasSigns && hasCategories)
        ? (
          <Fragment>
            {allCategories.map((category, index) => {
              const signsInCategory = signsByCategory[category];
              const { label: categoryLabel } = signCategories[category];

              const deleteSign = (signID) => {
                const withDeletedSign = [...signs];
                const indexToDelete = signs.findIndex((sign) => sign.uuid === signID);
                withDeletedSign.splice(indexToDelete, 1);
                setProjectState({
                  ...project,
                  signs: withDeletedSign,
                });
                setTimedNotification({
                  id: `${signID}--deleted`,
                  message: `${signs?.[indexToDelete]?.name} has been deleted from ${projectName}`,
                });
              };

              const cloneSign = (signID) => {
                const withClonedSign = [...signs];
                const indexToClone = signs.findIndex((sign) => sign.uuid === signID);
                if (indexToClone > -1) {
                  const signToClone = signs[indexToClone];
                  withClonedSign.splice(indexToClone, 0, {
                    ...signToClone,
                    name: `${signToClone.name} Copy`,
                  });
                }
                setProjectState({
                  ...project,
                  signs: withClonedSign,
                });
                setTimedNotification({
                  id: `${signID}--cloned`,
                  message: `${signs?.[indexToClone]?.name} has been cloned into ${projectName}`,
                });
              };

              if (Array.isArray(signsInCategory) && signsInCategory.length > 0) {
                return (
                  <BlockContainer
                    key={`container-${index}`}
                    className={classes.categoryWrapper}
                  >
                    <h3>
                      {categoryLabel}
                    </h3>
                    <MarginGrid
                      className={classes.categorySigns}
                      overflowMobile
                      staticColumns
                    >
                      {signsInCategory.map((sign, indexInCategory) => {
                        const { specs: signSpecs } = sign;

                        return (
                          <FormattedCard
                            key={`card-${index}-${indexInCategory}`}
                            index={indexInCategory}
                            sign={{
                              ...sign,
                              specs: signSpecs,
                            }}
                            project={project}
                            onDelete={deleteSign}
                            onClone={cloneSign}
                          />
                        );
                      })}
                    </MarginGrid>
                  </BlockContainer>
                );
              }
              return null;
            })}
            <BlockContainer className={classes.bottomCTA}>
              <Button
                color="lightGray"
                label="Add new sign(s)"
                to={`/project/${projectID}/add-sign`}
              />
            </BlockContainer>
          </Fragment>
        ) : (
          <Fragment>
            <h3>
              This project has no signs.
            </h3>
            <p>
              {'Add a '}
              <Button
                to={`/project/${projectID}/add-sign`}
                kind="text"
                label="new sign"
              />
              {' to this project to get started.'}
            </p>
          </Fragment>
        )}
    </div>
  );
};

Content.defaultProps = {
  project: undefined,
};

Content.propTypes = {
  setProjectState: PropTypes.func.isRequired,
  project: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    wallColor: PropTypes.string,
    signs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    specs: PropTypes.shape({}),
  }),
};

export default Content;
