import React from 'react';
import { useHistory } from 'react-router-dom';
import SignSystemCard from '../../../components/SignSystemCard';
import Button from '../../../components/Button';
import useStyles from './css';

const Hero = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.hero}>
      <div className={classes.content}>
        <h2>
          Design Center
        </h2>
        <p>
          <strong>
            The ultimate tool to complete your project.
          </strong>
        </p>
        <p>
          Need a custom client-ready sign package? Start with our Essentials offering of 25 pre-configured signs or select only the sign types you need. Assign base specifications to your entire project, then edit individual signs with custom specs and personalized copy. Share your project with a team member or client then submit your order all in one place.
        </p>
        <Button
          to="/new-project"
          type="primary"
          color="red"
          label="Let&apos;s go!"
        />
      </div>
      <ul className={classes.list}>
        <li className={classes.item}>
          <SignSystemCard
            system="S"
            label="Slide"
            onClick={() => history.push('/new-project?signSystem=S')}
          />
        </li>
        <li className={classes.item}>
          <SignSystemCard
            system="A"
            label="Arc"
            onClick={() => history.push('/new-project?signSystem=A')}
          />
        </li>
        <li className={classes.item}>
          <SignSystemCard
            system="K"
            label="Klik"
            onClick={() => history.push('/new-project?signSystem=K')}
          />
        </li>
      </ul>
    </div>
  );
};

export default Hero;
