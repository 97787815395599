import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';

const Notice = (props) => {
  const {
    message,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.notice}>
      <small>
        <strong>
          {'Notice: '}
        </strong>
        {message}
      </small>
    </div>
  );
};

Notice.defaultProps = {
  message: undefined,
};

Notice.propTypes = {
  message: PropTypes.string,
};

export default Notice;
