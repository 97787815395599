import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import defaultImage from '../../../assets/images/my290-default-image.png';
import Price from '../Price';
import Button from '../Button';
import Tooltip from '../Tooltip';
import useStyles from './css';

const Card = (props) => {
  const {
    className,
    title,
    subtitle,
    price,
    ctas,
    linkTitleTo,
    image,
    customImg,
    enableImagePadding,
    imageBackgroundColor,
  } = props;

  const classes = useStyles({ enableImagePadding, imageBackgroundColor });

  const ImageWrapElement = linkTitleTo ? Link : 'span';

  return (
    <div
      className={[
        classes.card,
        className,
      ].filter(Boolean).join(' ')}
    >
      <div className={classes.image}>
        {customImg ? (
          <div className={classes.imageInnerWrapper}>
            <div className={classes.customImg}>
              {customImg}
            </div>
          </div>
        ) : (
          <ImageWrapElement
            className={classes.imageInnerWrapper}
            to={linkTitleTo || undefined}
          >
            <img
              className={classes.htmlImage}
              src={image || defaultImage}
              alt={title}
            />
          </ImageWrapElement>
        )}

      </div>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.titleWrapper}>
            <div className={classes.title}>
              <strong>
                {linkTitleTo && (
                  <Link to={linkTitleTo}>
                    {title}
                  </Link>
                )}
                {!linkTitleTo && title}
              </strong>
            </div>
            {subtitle && (
              <div>
                <small>
                  {subtitle}
                </small>
              </div>
            )}
          </div>
          {Array.isArray(ctas) && ctas.length > 0 && (
            <div className={classes.ctas}>
              {ctas.map((cta, index) => {
                const {
                  tooltipLabel,
                  AdditionalComponent,
                } = cta;

                return (
                  <div
                    style={{ position: 'relative' }}
                    key={`card-cta-${index}`}
                  >
                    <Tooltip
                      key={index}
                      label={tooltipLabel}
                      delayHover
                    >
                      <Button
                        size="s"
                        color="lightGray"
                        {...cta}
                      />
                    </Tooltip>
                    {AdditionalComponent}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <Price
            size="s"
            price={price}
          />
        </div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  className: undefined,
  title: undefined,
  subtitle: undefined,
  price: undefined,
  ctas: undefined,
  linkTitleTo: undefined,
  image: undefined,
  customImg: undefined,
  enableImagePadding: undefined,
  imageBackgroundColor: undefined,
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  price: PropTypes.number,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  linkTitleTo: PropTypes.string,
  image: PropTypes.string,
  customImg: PropTypes.node,
  enableImagePadding: PropTypes.bool,
  imageBackgroundColor: PropTypes.string,
};

export default Card;
