import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { midBreak, largeBreak } from '../../css/queries';
import { resetButton } from '../../css/other';

export default createUseStyles({
  colorSwatchAccordion: {
    '&:not(:last-child)': {
      marginBottom: base(),
    },
    ...largeBreak({
      '&:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
  toggler: {
    ...resetButton,
    width: '100%',
  },
  togglerContent: {
    padding: base(2),
    display: 'flex',
    alignItems: 'center',
    ...midBreak({
      padding: base(),
    }),
  },
  togglerLabel: {
    flexGrow: '1',
    marginRight: base(),
  },
  togglerIcon: {
    flexShrink: '0',
    width: base(0.5),
    height: base(0.5),
  },
  swatchWrapper: {
    padding: base(2),
    paddingTop: '0',
    ...midBreak({
      padding: base(),
      paddingTop: '0',
    }),
  },
  swatchInnerWrapper: {
    marginTop: base(-0.25), // coordinate with 'swatchWrapper' negative margins
    marginBottom: base(0.25), // coordinate with 'swatchWrapper' negative margins
  },
  swatchInnerInnerWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: base(-0.25),
    '& > *': {
      margin: base(0.25),
      width: `calc(${(1 / 5) * 100}% - ${base(0.5)})`,
    },
  },
});
