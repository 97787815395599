import React, { useCallback, useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthentication } from '../../wrappers/Authentication';
import Button from '../../components/Button';
import Submit from '../../forms/fields/Submit';
import Input from '../../forms/fields/Input';
import Form from '../../forms/Form';
import Errors from '../../forms/Errors';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const intros = {
  default: () => (
    <b>
      You’re only one step away!
    </b>
  ),
  preCheckout: () => (
    <b>
      Before continuing to checkout, you need to create an account.
    </b>
  ),
};

const SignUp = () => {
  const { setUser } = useAuthentication();
  const history = useHistory();
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  const {
    state: {
      redirect,
      intro,
    } = {},
  } = useLocation();

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  const onResponse = useCallback(async (response) => {
    const { status } = response;
    if (status === 201) {
      const { json: { doc: { user: incomingUser } } } = response;
      setUser(incomingUser);
      if (incomingUser) history.push(redirect || '/account');
    }
  }, [setUser, history, redirect]);

  const Intro = intros[intro] || intros.default;

  return (
    <Fragment>
      <Helmet>
        <title>Sign Up</title>
        <meta
          name="description"
          content="Sign up for a My290 account."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.signUp}>
        <h1>Sign up</h1>
        <Form
          onResponse={onResponse}
          method="post"
          action={`${process.env.API_URL}/api/users`}
          className={classes.form}
          defaultValues={{
            firstName: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'First Name is required',
              }),
            },
            lastName: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Last Name is required',
              }),
            },
            email: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Email is required',
              }),
            },
            password: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Password is required',
              }),
            },
            passwordConfirm: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Confirm Password is required',
              }),
            },
          }}
          htmlAttributes={{
            autoComplete: 'on',
          }}
        >
          <div>
            <Intro />
          </div>
          <Errors />
          <Input
            {...{
              type: 'text',
              name: 'firstName',
              id: 'SignUpViewFirstName',
              label: 'First Name',
              required: true,
            }}
          />
          <Input
            {...{
              type: 'text',
              name: 'lastName',
              id: 'SignUpViewLastName',
              label: 'Last Name',
              required: true,
            }}
          />
          <Input
            {...{
              type: 'email',
              name: 'email',
              id: 'SignUpViewEmail',
              label: 'Email Address',
              required: true,
            }}
          />
          <Input
            {...{
              type: 'password',
              name: 'password',
              id: 'SignUpViewPassword',
              label: 'Password',
              required: true,
            }}
          />
          <Input
            {...{
              type: 'password',
              name: 'passwordConfirm',
              id: 'SignUpViewPasswordConfirm',
              label: 'Confirm Password',
              required: true,
            }}
          />
          <div>
            <Submit
              className={classes.submit}
              label="Sign up"
            />
          </div>
          <div>
            Already have an account?
            {' '}
            <Button
              to={{
                pathname: '/login',
                state: {
                  redirect: '/checkout',
                },
              }}
              kind="text"
              label="Sign in here"
            />
            .
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default SignUp;
