import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const EditIcon = (props) => {
  const { color, className, size } = props;
  const iconClasses = useIconStyles({ color });

  return (
    <svg
      className={[
        iconClasses.wrapper,
        className,
        size && iconClasses[size],
      ].filter(Boolean).join(' ')}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6426 5.58005L19.5929 9.53034L6.31376 22.8094L0.773054 24.3999L2.36347 18.8592L15.6426 5.58005Z"
        className={iconClasses.fill}
      />
      <path
        d="M24.625 4.4982L20.6747 0.547913L18.0412 3.18144L21.9915 7.13173L24.625 4.4982Z"
        className={iconClasses.fill}
      />
    </svg>
  );
};

EditIcon.defaultProps = {
  color: undefined,
  className: undefined,
  size: undefined,
};

EditIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default EditIcon;
