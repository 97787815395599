import { createUseStyles } from 'react-jss';
import vars, { colors, base } from '../../../css/vars';

export default createUseStyles({
  selectOverlay: {
    display: 'flex',
    flexDirection: 'column',
    '&:focus': {
      outline: 'none',
    },
  },
  htmlLabel: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  border: (checked) => ({
    border: `${vars.strokeWidth * 2}px solid`,
    borderRadius: vars.borderRadius,
    borderColor: checked ? colors.darkGray : 'transparent',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    '&:hover': {
      borderColor: checked ? colors.darkGray : colors.gray,
    },
  }),
  inputWrapper: (checked) => ({
    display: checked ? 'block' : 'none',
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate3d(50%, -50%, 0)',
  }),
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    width: base(0.5),
    height: base(0.5),
  },
  htmlInput: {
    width: base(),
    height: base(),
    backgroundColor: colors.darkGray,
    borderRadius: '100%',
    appearance: 'none',
    border: '0',
    padding: '0',
    margin: '0',
    color: 'transparent',
    '&:focus': {
      outline: 'none',
    },
  },
});
