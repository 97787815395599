import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { midBreak, largeBreak } from '../../css/queries';

export default createUseStyles({
  project: {
    display: 'flex',
    alignItems: 'flex-start',
    ...largeBreak({
      flexDirection: 'column',
    }),
  },
  sidebarWrapper: {
    position: 'sticky',
    top: base(7),
    flexShrink: 0,
    width: '33.33%',
    marginRight: base(4), // double to accommodate 'BlockContainer' negative margins
    ...largeBreak({
      position: 'static',
      width: '100%',
      marginRight: 0,
      marginBottom: base(2),
    }),
  },
  contentWrapper: {
    flexGrow: '1',
    marginBottom: base(2),
    ...largeBreak({
      width: '100%',
    }),
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  categoryWrapper: {
    '&:not(:last-child)': {
      marginBottom: base(2),
    },
  },
  categorySigns: {
    listStyle: 'none',
  },
  sign: {
    '&:not(:last-child)': {
      marginBottom: base(2),
    },
  },
  mobileCTA: {
    display: 'none',
    ...midBreak({
      display: 'block',
    }),
  },
  mobileCTAButton: {
    width: '100%',
  },
});
