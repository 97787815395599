import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './css';

const SpecPanel = (props) => {
  const { specs, heading } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.specPanel}>
      <header className={classes.header}>
        <h4>
          {heading}
        </h4>
      </header>
      <ul className={classes.specs}>
        {Array.isArray(specs) && specs.map((spec, i) => {
          const hasNestedSpecs = Array.isArray(spec.specs);

          return (
            <li
              key={i}
              className={[
                classes.spec,
                hasNestedSpecs && classes.nestedSpecsWrap,
              ].filter(Boolean).join(' ')}
            >
              {spec.heading && (<h4>{spec.heading}</h4>)}
              {hasNestedSpecs && (
                <div className={classes.nestedSpecs}>
                  {spec.specs.map((nestedSpec, nestedSpecIndex) => {
                    const hasSubNestedSpec = Array.isArray(nestedSpec.specs);

                    return (
                      <React.Fragment key={nestedSpecIndex}>
                        {nestedSpec.heading && (<h4>{nestedSpec.heading}</h4>)}
                        {nestedSpec.label && (<div className={classes.label}>{nestedSpec.label}</div>)}
                        {nestedSpec.value && (<div className={classes.value}>{nestedSpec.value}</div>)}
                        {hasSubNestedSpec && (
                          <ul className={classes.subNestedSpecs}>
                            {nestedSpec.specs.map((subNestedSpec, subNestedSpecIndex) => (
                              <li
                                key={subNestedSpecIndex}
                                className={classes.subNestedSpec}
                              >
                                <div className={classes.label}>{subNestedSpec.label}</div>
                                <div className={classes.value}>{subNestedSpec.value}</div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              {!hasNestedSpecs && (
                <React.Fragment>
                  <div className={classes.label}>{spec.label}</div>
                  <div className={classes.value}>{spec.value}</div>
                </React.Fragment>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SpecPanel.defaultProps = {
  specs: undefined,
  background: 'lightGray',
};

SpecPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      heading: PropTypes.string,
      specs: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          specs: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  // eslint-disable-next-line react/no-unused-prop-types
  background: PropTypes.oneOf(['white', 'lightGray']),
};

export default SpecPanel;
