import { createUseStyles } from 'react-jss';
import { colors, base } from '../../css/vars';
import { midBreak, largeBreak } from '../../css/queries';

export default createUseStyles({
  signUp: {
    '& a': {
      color: colors.darkGray,
    },
  },
  form: {
    width: '50%',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...largeBreak({
      width: '75%',
    }),
    ...midBreak({
      width: '100%',
    }),
  },
});
