export default [{
  question: 'Do I have to have an account to use the Design Center?',
  answer: '<p>No, you can play until your heart’s content, but if you want to see list pricing or save your projects, you need to sign up for an account.</p>',
}, {
  question: 'Can I use my same credentials that I used on your previous design center?',
  answer: '<p>No, unfortunately you must create a new account, but you are free to use the same information.</p>',
}, {
  question: 'Will my sign projects from the old site transfer to the new site?',
  answer: '<p>No, projects will not transfer to the new My290 Design Center. You have until 12.31.20 to reference projects before the site is retired.</p>',
}, {
  question: 'I would like to place orders on behalf of my organization that is an existing 2/90 customer, do our terms apply for online ordering?',
  answer: '<p>Yes, existing 2/90 customers can set up their organization on My290 by sending a request to marketing@290signs.com. Once an organization is set up, the user can request their account to be connected to their organization by providing their company’s name and 2/90 customer in the request email at the bottom of their Account profile. Once a user is connected to an organization, they’ll be able to see discounted pricing and in addition to paying by credit card, may have the option to submit purchase orders to be invoiced at the time of shipment. Users will also be able to access all order history placed using the My290 app.</p>',
}, {
  question: 'I order the same signs repeatedly, but they are not from your Essentials offering in the design center, can you help make re-ordering easier?',
  answer: '<p>Yes, select customers qualify for My Products, a re-ordering portal for customized sign products unique to your organization, inquire at marketing@290signs.com.</p>',
}, {
  question: 'I’d love to insert the sign I designed into my presentation, is there a way to download the sign without taking a screenshot?',
  answer: '<p>Yes, while in the edit individual sign mode, select the “PNG” button above the sign to download an image of the sign.</p>',
}, {
  question: 'How do I print a sign family and individual sign drawings?',
  answer: '<p>While in the “Edit individual sign” mode, you can select “Print View” to download an individual sign drawing. While in the “edit base specification” mode, you can select “Print view” to download a schematic design of the entire sign family along with individual sign drawings.</p>',
}, {
  question: 'I’m creating a presentation for my client and would like to hide pricing, is that an option?',
  answer: '<p>Yes, whether demonstrating the app or printing projects, the user can hide pricing by selecting the option under Account Profile.</p>',
}, {
  question: 'When I’m editing sign specs, how do I change the copy?',
  answer: '<p>First, make sure you are not in the “Edit base specifications” mode, these are options that affect all sign types. Instead when you are in Project view, individually select the sign type you’d like to edit and scroll down to the very bottom to select copy to personalize your sign. Note, if your quantity is more than one, scroll down to the next sign and select preview so you can confirm your changes. If you have a large quantity of sign types and would rather submit your copy schedule, please include your order number upon check out and submit to info@290signs.com.</p>',
}, {
  question: 'What is the difference between a Header and Accent Insert?',
  answer: '<p>A Header Insert is the main insert on a one insert sign or the top insert of a multi-insert sign. Accent inserts are those below the header insert that complete the sign. Sometimes it is ADA-compliant (Tactile and Braille) but when it is not, you have the option of choosing a Direct Print on a Painted or Laminate Insert or Window Insert with Paper for Non-ADA Insert Types.</p>',
}, {
  question: 'I’m ordering the same sign type with multiple mounting options; how do I accomplish this?',
  answer: '<p>While editing the individual sign type, scroll down to mounting option to select desired mount and update quantity. Then go back to Project view and select Add sign(s), find same sign type, and add sign to project. Edit the new individual sign type and repeat. Note if you edited any options to your initial sign type, you’ll need to make the same edits to the new sign as it’s pulling specs from your base specifications.</p>',
}, {
  question: 'I really love the backer shapes, but I don’t need them on every sign type, can I order some sign types without backers?',
  answer: '<p>Yes, simply edit the sign type individually that you’d like to remove and scroll down to the backer options. Select backer(s) followed by the None option.</p>',
}, {
  question: 'Why can’t I change the copy case to upper and lower or reduce copy size?',
  answer: '<p>Most likely you are trying to edit an ADA-compliant insert. The app defaults to all caps and a minimum of 5/8” to maintain compliancy. Also note, only ADA-compliant fonts are available on ADA inserts.</p>',
}, {
  question: 'Why can’t I select certain materials or enhancement shapes for my Klik sign project?',
  answer: '<p>Flare backer shape and laminates (Metals, Woods, & Stones) are not available for Klik. Laminates are only available for Klik’s Backer 2 option.</p>',
}, {
  question: 'I don’t see my favorite font style; can you add it?',
  answer: '<p>Not at this time, but we would love to hear what improvements and options you’d like to see in future enhancements by dropping a suggestion to marketing@290signs.com.</p>',
}, {
  question: 'I don’t see my question here, how do I get additional support?',
  answer: '<p>Please reach out to our team at marketing@290signs.com, we are more than happy to assist you during normal business hours.</p>',
}];
