import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';

export default createUseStyles({
  alreadyLoggedIn: {
    marginRight: 'auto',
  },
  whatNext: {
    marginTop: base(),
  },
});
