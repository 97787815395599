import React from 'react';
import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';
import NewProjectPortal from '../../../components/NewProjectPortal';
import BlockContainer from '../../../components/BlockContainer';
import { useAuthentication } from '../../../wrappers/Authentication';
import Button from '../../../components/Button';

const useStyles = createUseStyles({
  noProjects: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: base(4),
    },
    ...midBreak({
      '&:not(:last-child)': {
        marginBottom: base(2),
      },
    }),
  },
});

const NoProjects = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAuthentication();

  return (
    <div className={classes.noProjects}>
      <BlockContainer>
        <NewProjectPortal
          title="You have no projects...yet"
          body={(
            <span>
              {!isLoggedIn && (
                <span>
                  {'Have an account? Try '}
                  <Button
                    to="/login"
                    kind="text"
                    label="logging in"
                  />
                  {'. '}
                </span>
              )}
              Start a new project by giving it a name and selecting a sign system (you can change these later).
            </span>
          )}
        />
      </BlockContainer>
    </div>
  );
};

export default NoProjects;
