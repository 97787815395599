import React from 'react';
import PropTypes from 'prop-types';
import ShadowBox from '../../components/ShadowBox';
import Checkbox from '../../forms/fields/Checkbox';
import useStyles from './css';
import CloseIcon from '../../icons/CloseIcon';

const EnableDisable = (props) => {
  const {
    className,
    htmlName,
    label,
    currentValue,
    onChange,
    themeValue,
    onClear,
  } = props;

  const classes = useStyles();

  return (
    <div
      className={[
        classes.input,
        className,
      ].filter(Boolean).join(' ')}
    >
      <ShadowBox
        color="white"
        className={classes.wrapper}
      >
        <Checkbox
          {...{
            className: classes.checkbox,
            name: htmlName,
            label,
            value: currentValue,
            onChange,
            ghostChecked: typeof currentValue === 'undefined' && themeValue === true,
          }}
        />
        {typeof currentValue !== 'undefined' && typeof themeValue !== 'undefined' && (
          <div
            className={classes.clear}
            onClick={onClear}
            onKeyPress={onClear}
            role="button"
            tabIndex={0}
          >
            <CloseIcon
              color="gray"
              size="s"
            />
          </div>
        )}
      </ShadowBox>
    </div>
  );
};

EnableDisable.defaultProps = {
  htmlName: undefined,
  label: undefined,
  className: undefined,
  currentValue: undefined,
  onChange: undefined,
  themeValue: undefined,
  onClear: undefined,
};

EnableDisable.propTypes = {
  htmlName: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  currentValue: PropTypes.bool,
  onChange: PropTypes.func,
  themeValue: PropTypes.bool,
  onClear: PropTypes.func,
};

export default EnableDisable;
