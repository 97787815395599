import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

export default createUseStyles({
  emptyCart: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: base(4),
    },
    ...midBreak({
      '&:not(:last-child)': {
        marginBottom: base(2),
      },
    }),
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});
