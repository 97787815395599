import React, { useEffect, Fragment } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import BlockContainer from '../../../components/BlockContainer';
import { useBreadcrumbs } from '../../../wrappers/BreadcrumbsProvider';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import Item from './Item';
import useAPI from '../../../utilities/data/useAPI';
import formatPrice from '../../../utilities/formatPrice';

import useStyles from './css';
import Loader from '../../../components/Loader';
import formatDateTime from '../../../utilities/formatDateTime';
import Errors from '../../../forms/Errors';

const Order = () => {
  const { setCrumbs } = useBreadcrumbs();
  const params = useParams();
  const classes = useStyles();

  const { orderID } = params;

  const [{ res: orderRes, isLoading }] = useAPI('get', `${process.env.API_URL}/api/orders/${orderID}`);
  const { json: order } = orderRes || {};

  useEffect(() => {
    setCrumbs([
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Orders',
        url: '/account/orders',
      },
      {
        label: order?.id,
      },
    ]);
  }, [setCrumbs, order]);

  const {
    orderedBy,
    createdAt,
    customerID,
    shippingAddress,
    shippingAccountNumber,
    billingAddress,
    creditCard,
    paymentMethod,
    purchaseOrderID,
    products,
    projects,
    listPrice,
    discount,
    discountedAmount,
    netPrice,
    taxedAmount,
    shippingCost,
    total,
  } = order || {};

  if (isLoading && order === undefined) return <Loader fixed />;
  if (!order) return <Redirect to="/not-found" />;

  if (Array.isArray(order.errors) && order.errors.length > 0) {
    const allErrorMessages = order.errors.map((error) => error.message);
    return (
      <Errors
        errors={{ ...allErrorMessages }}
      />
    );
  }

  const {
    companyName,
    city: shippingCity,
    state: shippingState,
    line1: shippingLine1,
    line2: shippingLine2,
    zip: shippingZip,
    country: shippingCountry,
  } = shippingAddress;

  const {
    city: billingCity,
    state: billingState,
    line1: billingLine1,
    line2: billingLine2,
    zip: billingZip,
    country: billingCountry,
  } = billingAddress;

  const {
    firstName,
    lastName,
    organization,
    email,
    phone,
  } = orderedBy;

  return (
    <Fragment>
      <Helmet>
        <title>{`Order #${orderID}`}</title>
        <meta
          name="description"
          content="Check your order details."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.item}>
        <div className={classes.header}>
          <h2 className={classes.orderIDWrapper}>
            <span>
              {'Order '}
            </span>
            <span className={classes.orderID}>
              {`#${orderID}`}
            </span>
          </h2>
          <ul className={classes.meta}>
            <li>
              <h4>
                Ordered by
              </h4>
              <div>
                {firstName && (
                  `${firstName} `
                )}
                {lastName && (
                  lastName
                )}
              </div>
              <div>
                {email}
              </div>
              {phone && (
                <div>
                  {phone}
                </div>
              )}
              {organization && (
                <div>
                  {organization?.name}
                </div>
              )}
              <div>
                {formatDateTime(createdAt)}
              </div>
            </li>
            <li>
              <h4>
                Shipping address
              </h4>
              {companyName && (
                <div>
                  {companyName}
                </div>
              )}
              {shippingAccountNumber && (
                <div>
                  {shippingAccountNumber}
                </div>
              )}
              {shippingLine1 && (
                <div>
                  {shippingLine1}
                </div>
              )}
              {shippingLine2 && (
                <div>
                  {shippingLine2}
                </div>
              )}
              <div>
                {shippingCity && (
                  `${shippingCity}, `
                )}
                {shippingState && (
                  `${shippingState} `
                )}
                {shippingZip && (
                  shippingZip
                )}
              </div>
              <div>
                {shippingCountry}
              </div>
            </li>
            <li>
              <h4>
                Billing address
              </h4>
              {billingLine1 && (
                <div>
                  {billingLine1}
                </div>
              )}
              {billingLine2 && (
                <div>
                  {billingLine2}
                </div>
              )}
              <div>
                {billingCity && (
                  `${billingCity}, `
                )}
                {billingState && (
                  `${billingState} `
                )}
                {billingZip && (
                  billingZip
                )}
              </div>
              {billingCountry && (
                <div>
                  {billingCountry}
                </div>
              )}
            </li>
            <li>
              <h4>Payment method</h4>
              {paymentMethod === 'credit-card' && (
                <Fragment>
                  <div>
                    {creditCard?.brand}
                  </div>
                  <div>
                    {creditCard?.number}
                  </div>
                </Fragment>
              )}
              {paymentMethod === 'invoice' && (
                <Fragment>
                  Invoiced
                  <div>
                    Purchase Order ID:
                    {' '}
                    {purchaseOrderID}
                  </div>
                </Fragment>
              )}
              {customerID && (
                <Fragment>
                  <br />
                  <h4>
                    Customer ID:
                    {' '}
                    {customerID}
                  </h4>
                </Fragment>
              )}
            </li>
          </ul>
        </div>
        <BlockContainer>
          <div className={classes.itemsHeader}>
            <span className={classes.itemHeading}>
              Item
            </span>
            <span className={classes.quantityHeading}>
              Quantity
            </span>
            <span className={classes.totalHeading}>
              Total
            </span>
          </div>
          <ul className={classes.items}>
            {projects?.length > 0 && projects.map((project, i) => (
              <li key={`project-${i}`}>
                <Item
                  project={project}
                  discount={discount}
                />
              </li>
            ))}
            {products?.length > 0 && products.map((product, i) => (
              <li key={`product-${i}`}>
                <Item
                  product={product}
                  discount={discount}
                />
              </li>
            ))}
          </ul>
          <div className={classes.total}>
            <div className={classes.totalTitles}>
              <span>
                List Price:
              </span>
              <span>
                Discount:
              </span>
              <span>
                Net Price:
              </span>
              <span>
                Tax:
              </span>
              <span>
                Shipping:
              </span>
              <span>
                <strong>
                  Total:
                </strong>
              </span>
            </div>
            <div className={classes.totalValues}>
              <span>
                {formatPrice(listPrice)}
              </span>
              <span>
                {`-${formatPrice(discountedAmount)}`}
              </span>
              <span>
                {formatPrice(netPrice)}
              </span>
              <span>
                {formatPrice(taxedAmount)}
              </span>
              <span>
                {formatPrice(shippingCost)}
              </span>
              <span>
                <strong>
                  {formatPrice(total)}
                </strong>
              </span>
            </div>
          </div>
        </BlockContainer>
      </div>
    </Fragment>
  );
};

export default Order;
