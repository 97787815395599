import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  AutoScalingSign,
  mergeCopy,
  formatPartNumber,
  getSignPrice,
  signTemplateMeta,
  countBackers,
  mergeSpecs,
  injectBackerPositions,
  themes,
  getBackerCode,
} from '@two90signs/290-renderer';
import { useHistory, Link } from 'react-router-dom';
import useStyles from './css';
import BlockContainer from '../../../components/BlockContainer';
import Button from '../../../components/Button';
import MarginGrid from '../../../components/MarginGrid';
import Card from '../../../components/Card';
import { useCart } from '../../../wrappers/CartProvider';
import { useActionConfirmation } from '../../../wrappers/ActionConfirmation';
import deleteProject from '../../../utilities/data/deleteProject';
import { useAuthentication } from '../../../wrappers/Authentication';
import Price from '../../../components/Price';
import getProjectPrice from '../../../utilities/getProjectPrice';
import { useNotifications } from '../../../wrappers/Notifications';

const Section = (props) => {
  const { setTimedNotification } = useNotifications();
  const classes = useStyles();
  const { confirmAction } = useActionConfirmation();
  const { isLoggedIn } = useAuthentication();
  const {
    isProjectInCart,
    deleteProjectFromCart,
  } = useCart();

  const {
    project,
    project: {
      name,
      signs,
      uuid: projectID,
      theme,
      specs: themeSpecs,
      wallColor: projectWallColor,
    } = {},
    triggerRefresh,
  } = props;

  const baseThemeSpecs = themes[theme];
  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

  const { addProjectToCart } = useCart();
  const history = useHistory();

  const hasSigns = Array.isArray(signs) && signs.length > 0;
  const signCount = signs?.length || 0;
  const threshold = 6;
  const indicesUnderThreshold = signCount > threshold ? threshold : signCount;

  const projectIsInCart = isProjectInCart(projectID);

  return (
    <BlockContainer className={classes.section}>
      <div className={classes.sidebar}>
        {name && (
          <Link
            to={`/project/${projectID}`}
            className={classes.title}
          >
            <h2>
              {name}
            </h2>
          </Link>
        )}
        <div>
          <div className={classes.info}>
            {projectIsInCart && (
              <div>
                {'This project is currently in your '}
                <Button
                  className={classes.isInCartLink}
                  label="cart"
                  kind="text"
                  to="/cart"
                />
              </div>
            )}
            <div className={classes.detailsWrapper}>
              <div>
                <b className={classes.number}>
                  {signs?.length || 0}
                </b>
                {` Sign Type${signs?.length !== 1 ? 's' : ''}`}
              </div>
              <div>
                <b className={classes.number}>
                  {signCount}
                </b>
                {` Total Sign${signs?.length !== 1 ? 's' : ''}`}
              </div>
              <div>
                <Price price={getProjectPrice(project)} />
              </div>
            </div>
          </div>
        </div>
        <MarginGrid>
          <Button
            to={`/project/${projectID}`}
            color="darkGray"
            label="Edit project"
          />
          {!projectIsInCart && hasSigns && (
            <Button
              label="Add to cart"
              onClick={() => {
                addProjectToCart(project);
                history.push('/cart');
              }}
            />
          )}
          <Button
            label="Delete project"
            color="lightGray"
            onClick={() => {
              confirmAction({
                message: (
                  <Fragment>
                    <div>
                      {'Are you sure you want to delete '}
                      <b>{name || 'this project'}</b>
                      ? This action
                      <b>
                        {' cannot '}
                      </b>
                      be undone.
                    </div>
                    <div>
                      Please type
                      <b>
                        {` ${name} `}
                      </b>
                      to confirm, or press
                      <b>
                        {' esc '}
                      </b>
                      to cancel.
                    </div>
                  </Fragment>
                ),
                inputLabel: 'Project name',
                useInput: true,
                onValidate: (value) => Boolean(value === name),
                cancelLabel: 'No, thank you!',
                confirmLabel: 'Yes, delete!',
                action: async () => {
                  await deleteProject({ project, isLoggedIn });
                  deleteProjectFromCart(projectID);
                  triggerRefresh();
                  setTimedNotification({
                    id: `${projectID}--deleted`,
                    message: `${name} has been deleted`,
                  });
                },
              });
            }}
          />
        </MarginGrid>
      </div>
      <div className={classes.cards}>
        <div className={classes.scrollContainer}>
          {hasSigns ? (
            <div className={classes.cardsWrapper}>
              {Array.from(Array(indicesUnderThreshold).keys()).map((currentIndex) => {
                const sign = signs[currentIndex];

                if (sign) {
                  const {
                    templateID,
                    uuid: signID,
                    name: signName,
                    specs: signSpecs,
                    copy,
                    wallColor,
                  } = sign;

                  const {
                    overallSizes,
                    insertArea,
                  } = signTemplateMeta?.[templateID] || {};
                  const numberOfBackers = countBackers(mergedTheme, signSpecs);

                  const withMergedCopy = mergeCopy(signSpecs, copy[0]); // use the copy from the first sign
                  const withMergedTheme = mergeSpecs(mergedTheme, withMergedCopy);
                  const backersWithPositions = injectBackerPositions(withMergedTheme.backers, withMergedTheme.signSystem, templateID);
                  const backerCode = getBackerCode(withMergedTheme);

                  return (
                    <Card
                      {...{
                        key: currentIndex,
                        title: signName,
                        subtitle: (
                          <div>
                            <div>
                              {formatPartNumber(mergedTheme, signSpecs, templateID)}
                            </div>
                            {insertArea && (
                              <div className={classes.signSize}>
                                {`Insert area: ${insertArea.height}" x ${insertArea.width}"`}
                              </div>
                            )}
                            {numberOfBackers > 0 && overallSizes && (
                              <div className={classes.signSize}>
                                {`Overall size: ${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`}
                              </div>
                            )}
                          </div>
                        ),
                        linkTitleTo: `/project/${projectID}/${signID}`,
                        price: getSignPrice(mergedTheme, signSpecs, templateID),
                        enableImagePadding: true,
                        customImg: (
                          <Link to={`/project/${projectID}/${signID}`}>
                            <AutoScalingSign
                              hideWhenOffscreen
                              signProps={{
                                specs: {
                                  ...withMergedTheme,
                                  backers: backersWithPositions,
                                },
                              }}
                              centerInContainer
                            />
                          </Link>
                        ),
                        imageBackgroundColor: wallColor || projectWallColor,
                      }}
                    />
                  );
                }
                return null;
              })}
              {signs.length > threshold && (
                <div className={classes.finalCard}>
                  <Button
                    label={`And ${signs.length - threshold} more`}
                    to={`/project/${projectID}`}
                    color="lightGray"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={classes.noSigns}>
              <h3>
                This project has no signs.
              </h3>
              <p>
                {'Add a '}
                <Button
                  to={`/project/${projectID}/add-sign`}
                  kind="text"
                  label="new sign"
                />
                {' to this project to get started.'}
              </p>
            </div>
          )}
        </div>
      </div>
    </BlockContainer>
  );
};

Section.defaultProps = {
  project: undefined,
  triggerRefresh: undefined,
};

Section.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    signs: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    uuid: PropTypes.string,
    specs: PropTypes.shape({}),
    wallColor: PropTypes.string,
  }),
  triggerRefresh: PropTypes.func,
};

export default Section;
