import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../css/vars';

export default createUseStyles({
  radio: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  htmlInput: {
    flexShrink: '0',
    margin: '0',
    width: base(),
    height: base(),
    border: `solid ${vars.strokeWidth}px`,
    borderColor: colors.lightGray,
    marginRight: base(0.5),
    cursor: 'inherit',
    appearance: 'none',
    borderRadius: '100%',
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
    '&::after': {
      content: '""',
      opacity: '0',
      width: `calc(${base()} - ${vars.strokeWidth * 6}px)`,
      height: `calc(${base()} - ${vars.strokeWidth * 6}px)`,
      backgroundColor: colors.darkGray,
      borderRadius: '100%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
    },
    '&:hover:not(:checked)': {
      '&::after': {
        opacity: '1',
        backgroundColor: colors.lightGray,
      },
    },
    '&:checked': {
      borderColor: colors.gray,
      '&::after': {
        opacity: '1',
        backgroundColor: colors.darkGray,
      },
    },
  },
  radioIsChecked: {
    color: colors.darkGray,
  },
});
