import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';
import { resetButton } from '../../css/other';

export default createUseStyles({
  paginator: {
    display: 'flex',
    marginBottom: base(),
  },
});

export const button = {
  ...resetButton,
  width: base(2),
  height: base(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 0,
  padding: base(0.5),
  color: colors.gray,
  lineHeight: base(),
  '&:hover': {
    background: colors.lightGray,
    color: colors.darkGray,
  },
};
