import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const AddIcon = (props) => {
  const { color, rotation, className, size } = props;
  const classes = useIconStyles({ color, rotation });

  return (
    <svg
      className={[
        classes.wrapper,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.stroke}
        d="M2.40002 7.8H12.6"
      />
      <path
        className={classes.stroke}
        d="M7.80005 2.4L7.80005 12.6"
      />
    </svg>

  );
};

AddIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

AddIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default AddIcon;
