import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useWindowInfo } from '@faceless-ui/window-info';
import { useParams } from 'react-router-dom';
import EditableHeading from '../../../components/EditableHeading';
import Price from '../../../components/Price';
import DesktopSignArea from './DesktopSignArea';
import MobileSignArea from './MobileSignArea';
import useStyles from './css';
import getProjectPrice from '../../../utilities/getProjectPrice';
import OnboardingTooltip from '../../../components/OnboardingTooltip';
import Back from '../../../components/Back';
import projectNameValidations from '../../../utilities/validations/projectName';

const Content = (props) => {
  const { projectID } = useParams();

  const {
    breakpoints: {
      m: jsMidBreak,
    },
    eventsFired: windowEventsFired,
  } = useWindowInfo();

  const {
    projectState,
    projectState: {
      signs,
      name,
      theme,
      specs: themeSpecs,
    },
    dispatchProjectState,
    darkUI,
  } = props;

  const classes = useStyles({ darkUI });

  const onNameSubmit = useCallback((incomingName) => {
    dispatchProjectState({
      type: 'UPDATE_PROJECT',
      payload: {
        name: incomingName,
      },
    });
  }, [dispatchProjectState]);

  return (
    <div className={classes.content}>
      <header className={classes.header}>
        <Back
          to={`/project/${projectID}`}
          label="Go back to project"
          darkUI={darkUI}
        />
        <EditableHeading
          onSubmit={onNameSubmit}
          onValidate={(incomingName) => {
            const isValid = incomingName.match(projectNameValidations.regex);
            if (!isValid) return projectNameValidations.message;
            return true;
          }}
          requiredMessage={projectNameValidations.requiredMessage}
          initialValue={name}
          inline
          darkUI={darkUI}
          additionalButtons={[
            {
              icon: 'document',
              flexReverse: true,
              label: 'Print view',
              to: `/project/${projectID}/print`,
              tooltip: true,
              tooltipContent: {
                position: 'topLeft',
                contentPosition: 'right',
                title: 'Select to download sign family and individual sign drawings',
                message: 'Under profile settings select to display or hide pricing',
              },
            },
          ]}
        />
        <div className={classes.meta}>
          <span>
            {`${signs?.length || 0} Sign Type${signs?.length === 1 ? '' : 's'}`}
          </span>
          <span>
            {' '}
            &mdash;
            {' '}
            <Price
              className={classes.price}
              price={getProjectPrice(projectState)}
            />
          </span>
        </div>
      </header>
      <div className={classes.renderingArea}>
        <OnboardingTooltip
          position="topLeft"
          contentPosition="right"
          title="Changes are immediately reflected here"
          message="Every change you make is automatically saved while you work, no extra steps necessary!"
        />
        {!jsMidBreak && (
          <DesktopSignArea
            {...{
              windowEventsFired,
              theme,
              themeSpecs,
              signs,
            }}
          />
        )}
        {jsMidBreak && (
          <MobileSignArea
            {...{
              theme,
              themeSpecs,
              signs,
            }}
          />
        )}
      </div>
    </div>
  );
};

Content.defaultProps = {
  dispatchProjectState: undefined,
  projectState: undefined,
  projectID: undefined,
  darkUI: undefined,
};

Content.propTypes = {
  projectState: PropTypes.shape({
    signs: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    price: PropTypes.number,
    name: PropTypes.string,
    templateID: PropTypes.string,
    prices: PropTypes.shape({
      signOnly: PropTypes.number,
    }),
    signSystem: PropTypes.string,
    wallColor: PropTypes.string,
    theme: PropTypes.string,
    specs: PropTypes.shape({}),
  }),
  dispatchProjectState: PropTypes.func,
  projectID: PropTypes.string,
  darkUI: PropTypes.bool,
};

export default Content;
