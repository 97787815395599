import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SpecHeader from '../SpecHeader';
import useStyles from './css';
import ColorSwatchAccordion from '../ColorSwatchAccordion';
import Notice from '../../components/Notice';
import ShadowBox from '../../components/ShadowBox';

const {
  klikInsertFinishesByCategory,
  windowInsertFinishesByCategory,
  standardInsertFinishesByCategory,
} = specSheets;

const InsertFinishes = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    insertType,
    headerLabel,
    signSystem,
    specId,
  } = props;

  const classes = useStyles();

  let finishesToUse = { ...standardInsertFinishesByCategory };

  // BOTH Klik AND Window inserts support only specific finishes
  if (signSystem === 'K') {
    finishesToUse = { ...klikInsertFinishesByCategory };
  }

  if (insertType === 'WI') {
    finishesToUse = { ...windowInsertFinishesByCategory };
    // '101-SA' Satin Aluminum is only available for window inserts of Slide signs
    if (signSystem !== 'S') delete finishesToUse.satinAluminum;
  }

  const entries = Object.entries(finishesToUse);

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertFinishes}>
        <SpecHeader
          label={headerLabel || 'Insert Finish'}
          to={backTo}
          specId={specId}
        />
        {insertType === 'WI' && (
          <ShadowBox color="white">
            <Notice message="This is a window insert and only allows Prism, Designer, and Metallic finishes." />
          </ShadowBox>
        )}

        {entries.map(([categoryID, category], index) => {
          const colorsWithID = Object.entries(category).map(([colorID, color]) => ({ ...color, id: colorID }));

          return (
            <ColorSwatchAccordion
              key={index}
              title={categoryID}
              colors={colorsWithID}
              name="finish"
              onChange={onChange}
              currentValue={currentValue}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

InsertFinishes.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  insertType: undefined,
  headerLabel: undefined,
  signSystem: undefined,
  specId: undefined,
};

InsertFinishes.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  insertType: PropTypes.string,
  headerLabel: PropTypes.string,
  signSystem: PropTypes.string,
  specId: PropTypes.string,
};

export default InsertFinishes;
