import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const BackerSpecs = (props) => {
  const { backer, classes } = props;

  const {
    shape,
    pattern,
    patternColor,
    finish,
  } = backer;

  // force patternColor to none for backers with no pattern, or grayscale patterns.
  // I couldn't fingure out how to do it elsewhere because this app is confusing.
  const invalidPatterns = ['none', 'O5'];
  const hidePatternColor = invalidPatterns.includes(pattern);

  return (
    <Fragment>
      <div className={classes.spec}>
        <div>
          Shape:
        </div>
        <div>
          {shape}
        </div>
      </div>
      {shape !== 'none' && (
        <Fragment>
          <div className={classes.spec}>
            <div>
              Pattern:
            </div>
            <div>
              {pattern}
            </div>
          </div>
          <div className={classes.spec}>
            <div>
              Pattern Color:
            </div>
            <div>
              {hidePatternColor ? 'none' : patternColor}
            </div>
          </div>
          <div className={classes.spec}>
            <div>
              Finish:
            </div>
            <div>
              {finish}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

BackerSpecs.defaultProps = {
  backer: undefined,
  classes: undefined,
};

BackerSpecs.propTypes = {
  backer: PropTypes.shape({
    shape: PropTypes.string,
    pattern: PropTypes.string,
    patternColor: PropTypes.string,
    finish: PropTypes.string,
  }),
  classes: PropTypes.shape({
    spec: PropTypes.string,
  }),
};

export default BackerSpecs;
