import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const SettingsIcon = (props) => {
  const { color, rotation, className, size } = props;
  const classes = useIconStyles({ color, rotation });

  return (
    <svg
      className={[
        classes.wrapper,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        className={classes.stroke}
        x1="1"
        y1="15"
        x2="1"
        y2="6"
      />
      <line
        className={classes.stroke}
        x1="7.5"
        y1="15"
        x2="7.5"
      />
      <line
        className={classes.stroke}
        x1="14"
        y1="15"
        x2="14"
        y2="3"
      />
    </svg>
  );
};

SettingsIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

SettingsIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default SettingsIcon;
