import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import Input from '../../../forms/fields/Input';
import { useCart } from '../../../wrappers/CartProvider';
import ShadowBox from '../../../components/ShadowBox';
import Price from '../../../components/Price';
import { useActionConfirmation } from '../../../wrappers/ActionConfirmation';
import useStyles from './css';

const DeletionConfirmationMessage = ({ title }) => (
  <Fragment>
    <div>
      Are you sure you want to remove
      <b>
        {` ${title} `}
      </b>
      from your cart?
    </div>
    <div>
      Press
      <b>
        {' enter '}
      </b>
      to confirm, or
      <b>
        {' esc '}
      </b>
      to cancel.
    </div>
  </Fragment>
);

DeletionConfirmationMessage.defaultProps = {
  title: undefined,
};

DeletionConfirmationMessage.propTypes = {
  title: PropTypes.string,
};

const Row = (props) => {
  const {
    quantity,
    singular,
    itemType,
    itemIndex,
    description,
    price,
    backlinkTo,
    backlinkLabel,
    img,
    title,
    onDelete,
  } = props;

  const { confirmAction } = useActionConfirmation();
  const { updateCartItem } = useCart();
  const classes = useStyles();

  const TitleWrapper = backlinkTo ? Link : 'span';

  return (
    <div className={classes.row}>
      <ShadowBox
        color="white"
        className={classes.content}
      >
        <div className={classes.header}>
          <div className={classes.image}>
            {img}
          </div>
          <div className={classes.subtitleWrapper}>
            <div className={classes.subtitle}>
              <div className={classes.singular}>
                <small>
                  <strong>
                    {singular}
                  </strong>
                </small>
              </div>
              <TitleWrapper
                className={classes.titleWrapper}
                to={backlinkTo}
              >
                <h4 className={classes.title}>
                  {title}
                </h4>
                {backlinkLabel && (
                  <small className={classes.backlinkLabel}>
                    {backlinkLabel}
                  </small>
                )}
              </TitleWrapper>
              {description && (
                <div>
                  <small>
                    {description}
                  </small>
                </div>
              )}
            </div>
            <div className={classes.mobileCTA}>
              <Button
                onClick={() => {
                  confirmAction({
                    message: <DeletionConfirmationMessage title={title} />,
                    action: () => {
                      if (typeof onDelete === 'function') onDelete();
                    },
                  });
                }}
                htmlAttributes={{ type: 'button' }}
                color="lightGray"
                size="s"
                icon="close"
              />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Input
            {...{
              id: `${itemType}-${itemIndex}-quantity`,
              className: classes.quantityInput,
              type: 'number',
              label: 'Quantity',
              name: 'quantity',
              value: quantity,
              onChange: (incomingValue) => {
                updateCartItem({
                  type: itemType,
                  index: itemIndex,
                  payload: {
                    quantity: Number(incomingValue),
                  },
                });
              },
              min: 1,
              step: 1,
            }}
          />
          <div className={classes.priceWrapper}>
            <Price
              price={price * quantity}
              neverHide
            />
          </div>
        </div>
      </ShadowBox>
      <div className={classes.desktopCTA}>
        <Button
          onClick={() => {
            confirmAction({
              message: <DeletionConfirmationMessage title={title} />,
              action: () => {
                if (typeof onDelete === 'function') onDelete();
              },
            });
          }}
          htmlAttributes={{ type: 'button' }}
          color="lightGray"
          size="s"
          icon="close"
        />
      </div>
    </div>
  );
};

Row.defaultProps = {
  quantity: undefined,
  singular: undefined,
  itemType: undefined,
  itemIndex: undefined,
  description: undefined,
  price: undefined,
  backlinkTo: undefined,
  backlinkLabel: undefined,
  img: undefined,
  title: undefined,
  onDelete: undefined,
};

Row.propTypes = {
  quantity: PropTypes.number,
  singular: PropTypes.string,
  itemType: PropTypes.string,
  itemIndex: PropTypes.number,
  description: PropTypes.string,
  price: PropTypes.number,
  backlinkTo: PropTypes.string,
  backlinkLabel: PropTypes.string,
  img: PropTypes.node,
  title: PropTypes.string,
  onDelete: PropTypes.func,
};

export default Row;
