import React, { useState, useEffect, forwardRef, useRef, useReducer, useCallback } from 'react';
import { flatten, unflatten } from 'flatley';
import PropTypes from 'prop-types';
import { fireRequest } from '../../api';
import useStyles from './css';
import reducer from './reducer';
import validateFields from './validateFields';
import { FormContext } from './context';
import isFormValid from './isFormValid';

const Form = forwardRef((props, ref) => {
  const {
    className,
    method,
    action,
    onResponse,
    onError,
    defaultValues,
    children,
    name,
    onSubmit,
    apiErrors: apiErrorsFromProps,
    isLoading: isLoadingFromProps,
    flattenOnSubmit,
    unflattenOnSubmit,
    submitOnChange,
    htmlAttributes,
  } = props;

  const [fieldState, dispatchFieldState] = useReducer(reducer);
  const [apiErrors, setAPIErrors] = useState();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButton = useRef();
  const fieldsAreInitialized = useRef(false);
  const submitOnChangeIsReady = useRef(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // initialize the fields, do not validate
  useEffect(() => {
    if (defaultValues && !fieldsAreInitialized.current) {
      dispatchFieldState({
        type: 'SET_ALL_FIELDS',
        payload: defaultValues,
      });
      fieldsAreInitialized.current = true;
    }
  }, [defaultValues]);

  // click a submit button on every change
  useEffect(() => {
    if (submitOnChange) {
      if (fieldState && fieldsAreInitialized.current) {
        if (submitOnChangeIsReady.current) {
          hiddenSubmitButton.current.click();
        } else submitOnChangeIsReady.current = true;
      }
    }
  }, [submitOnChange, fieldState]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setHasSubmitted(true);

    const validatedFields = validateFields(fieldState);
    const formIsValid = isFormValid(validatedFields);

    if (!formIsValid) {
      dispatchFieldState({
        type: 'SET_ALL_FIELDS',
        payload: validatedFields,
      });
      setIsLoading(false);
    } else {
      const keyValuePairs = {};
      Object.entries(fieldState).forEach(([key, { value }]) => { keyValuePairs[key] = value; });

      if (typeof onSubmit === 'function') return onSubmit(keyValuePairs);

      let body = keyValuePairs;

      if (flattenOnSubmit) body = flatten(body);
      if (unflattenOnSubmit) body = unflatten(body);

      const { res, err } = await fireRequest({
        method,
        url: action,
        options: {
          body: JSON.stringify(body),
        },
        sleepDuration: 1000,
      });

      if (err && typeof onError === 'function') onError(err);

      if (res) {
        const { json: { errors } } = res;
        if (errors) setAPIErrors(errors);
        if (typeof onResponse === 'function') await onResponse(res);
      }

      setIsLoading(false);
      return true;
    }
    return false;
  }, [action, fieldState, flattenOnSubmit, method, onError, onResponse, onSubmit, unflattenOnSubmit]);

  return (
    <FormContext.Provider
      value={{
        apiErrors: apiErrorsFromProps || apiErrors,
        isLoading: typeof isLoadingFromProps === 'boolean' ? isLoadingFromProps : isLoading,
        fieldState,
        dispatchFieldState: (args) => {
          dispatchFieldState({
            ...args,
            hasSubmitted,
          });
        },
        hasSubmitted,
      }}
    >
      <form
        onSubmit={handleSubmit}
        name={name}
        noValidate
        className={[
          classes.form,
          className,
        ].filter(Boolean).join(' ')}
        ref={ref}
        {...htmlAttributes}
      >
        {children && children}
        {submitOnChange && (
          <button
            type="submit"
            ref={hiddenSubmitButton}
            className={classes.hiddenSubmitButton}
          >
            Submit
          </button>
        )}
      </form>
    </FormContext.Provider>
  );
});

Form.defaultProps = {
  apiErrors: undefined,
  isLoading: undefined,
  method: 'get',
  onResponse: undefined,
  onError: undefined,
  defaultValues: undefined,
  className: undefined,
  children: undefined,
  name: undefined,
  onSubmit: undefined,
  action: '',
  flattenOnSubmit: false,
  unflattenOnSubmit: false,
  submitOnChange: undefined,
  htmlAttributes: undefined,
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  apiErrors: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  method: PropTypes.oneOf([
    'get',
    'post',
    'put',
    'del',
  ]),
  action: PropTypes.string,
  onResponse: PropTypes.func,
  onError: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  flattenOnSubmit: PropTypes.bool,
  unflattenOnSubmit: PropTypes.bool,
  submitOnChange: PropTypes.bool,
  htmlAttributes: PropTypes.shape({}),
};

export default Form;
