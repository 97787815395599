import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default () => {
  const { search } = useLocation();
  const prevSearch = useRef(search);

  useEffect(() => {
    if (search.current !== prevSearch.current) {
      window.scrollTo(0, 0);
    }
  }, [search]);

  return null;
};
