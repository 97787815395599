import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  specLink: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  link: {
    flexGrow: 1,
    cursor: 'pointer',
    '& > a': {
      textDecoration: 'inherit',
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    '&:hover': {
      textDecoration: 'underline',
      color: colors.darkGray,
    },
  },
  label: {
    flexShrink: 0, // match with 'Quantity' and 'SpecAccordion'
    flexBasis: base(5), // match with 'Quantity' and 'SpecAccordion'
    ...midBreak({
      flexBasis: base(4), // match with 'Quantity' and 'SpecAccordion'
    }),
  },
  valueWrapper: {
    flexGrow: '1',
    color: colors.darkGray,
    marginLeft: base(),
    fontWeight: 'bold',
  },
  themeValue: {
    color: colors.gray,
  },
  clear: {
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
});
