import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BreadcrumbsContext = createContext({});
export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

const BreadcrumbsProvider = (props) => {
  const { children } = props;
  const [crumbs, setCrumbs] = useState([]);

  return (
    <BreadcrumbsContext.Provider value={{ crumbs, setCrumbs }}>
      {children && children}
    </BreadcrumbsContext.Provider>
  );
};

BreadcrumbsProvider.defaultProps = {
  children: undefined,
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node,
};

export default BreadcrumbsProvider;
