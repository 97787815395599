import * as React from 'react';

const defaultEvents = ['mousedown', 'touchstart'];

const useClickAway = (ref, onClickAway, events = defaultEvents) => {
  const savedCallback = React.useRef(onClickAway);

  React.useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  React.useEffect(() => {
    const clickAwayHandler = (event) => {
      const { current: el } = ref;
      if (el && !el.contains(event.target)) savedCallback.current(event);
    };

    events.forEach((eventName) => {
      document.addEventListener(eventName, clickAwayHandler);
    });

    return () => {
      events.forEach((eventName) => {
        document.removeEventListener(eventName, clickAwayHandler);
      });
    };
  }, [events, ref]);
};

export default useClickAway;
