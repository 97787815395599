import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';
import { desktopBreak, midBreak } from '../../css/queries';

export default createUseStyles({
  product: {
    marginBottom: '0',
  },

  wrapper: {
    display: 'flex',
    marginBottom: base(2),
    ...desktopBreak({
      flexDirection: 'column',
    }),
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  contentWrapper: {
    flexBasis: '66%',
    flexGrow: '1',
    overflow: 'hidden',
    marginRight: base(2),
    ...desktopBreak({
      overflow: 'unset',
      marginRight: '0',
      marginBottom: base(2),
    }),
  },
  content: {
    display: 'flex',
  },
  header: {
    marginBottom: base(2),
    '& h1': {
      marginBottom: base(0.5),
    },
  },
  sliderWrapper: {
    flex: '1',
    overflow: 'hidden',
    marginRight: base(2),
    ...desktopBreak({
      marginRight: '0',
    }),
  },
  thumbnails: {
    display: 'flex',
  },
  desktopDescription: {
    flex: '1',
    ...midBreak({
      display: 'none',
    }),
    a: {
      '&:hover': {
        color: colors.gray,
      },
    },
  },
  attributesWrapper: {
    flexBasis: '33%',
    flexShrink: '0',
    ...desktopBreak({
      flexBasis: 'auto',
      marginBottom: base(),
    }),
  },
  mobileDescription: {
    display: 'none',
    ...midBreak({
      display: 'block',
    }),
  },
  mobileCTA: {
    display: 'none',
    '& > *': {
      width: '100%',
    },
    ...midBreak({
      display: 'block',
    }),
  },
});
