import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { midBreak, desktopBreak } from '../../css/queries';

export default createUseStyles({
  noOrganization: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(1),
      },
    }),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    width: '75%',
    ...midBreak({
      width: '100%',
    }),
  },
  form: {
    width: '50%',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...desktopBreak({
      width: '75%',
    }),
    ...midBreak({
      width: '100%',
    }),
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
  orgInput: {
    width: '100%',
  },
  nestedInputWrapper: {
    display: 'flex',
    marginLeft: base(-0.5),
    marginRight: base(-0.5),
    ...midBreak({
      flexDirection: 'column',
      marginLeft: 0,
      marginRight: 0,
    }),
  },
  input: {
    width: `calc(50% - ${base()})`,
    margin: `0 ${base(0.5)}`,
    ...midBreak({
      width: '100%',
      margin: 0,
      '&:not(:last-child)': {
        marginBottom: base(),
      },
    }),
  },
  submit: {
    height: '100%',
  },
});
