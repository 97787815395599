import React, { useState } from 'react';
import { useWindowInfo } from '@faceless-ui/window-info';
import PropTypes from 'prop-types';
import { AutoScalingSign, mergeCopy } from '@two90signs/290-renderer';
import ShadowBox from '../../../../components/ShadowBox';
import defaultImage from '../../../../../assets/images/my290-default-image.png';
import Price from '../../../../components/Price';
import Button from '../../../../components/Button';
import SpecPanel from '../../../../components/SpecPanel';
import SignRow from './SignRow';

import useStyles from './css';

const Item = (props) => {
  const { project, product, discount } = props;
  const { breakpoints: { m: midBreak } } = useWindowInfo();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const quantity = product ? product.quantity : project.quantity;
  const price = product ? product.price : project.price;
  const name = product ? product.product.name : project.name;

  return (
    <div className={classes.item}>
      <ShadowBox color="white">
        <div className={classes.wrap}>
          <div className={classes.thumbnail}>
            {project && (
              <AutoScalingSign
                className={classes.projectThumbnail}
                signProps={{
                  themeSpecs: project.specs,
                  specs: mergeCopy(project?.signs?.[0]?.specs || {}, project?.signs?.[0]?.copy || {}),
                }}
                centerInContainer
              />
            )}
            {product && (
              <img
                className={classes.image}
                src={product?.product?.images?.[0]?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${product.product.images[0].image.sizes.card.filename}` : defaultImage}
                alt={product.name}
              />
            )}
          </div>
          <div className={classes.content}>
            <div className={classes.type}>
              {product && 'Product'}
              {project && 'Project'}
            </div>
            <div className={classes.title}>
              {name}
            </div>
          </div>
          <div className={classes.quantity}>
            {midBreak && 'Quantity: '}
            {quantity}
          </div>
          <div className={classes.price}>
            <Price
              price={price}
              discount={discount}
              neverHide
            />
          </div>
          <div className={classes.toggle}>
            <Button
              onClick={() => setIsOpen(!isOpen)}
              htmlAttributes={{ type: 'button' }}
              color="lightGray"
              size="s"
              icon="chevron"
              iconRotation={isOpen ? 180 : 0}
            />
          </div>
        </div>
        {isOpen && (
          <div className={classes.drawerContent}>
            {product && (
              <SpecPanel
                heading="Specifications"
                specs={product.attributes ? product.attributes.map((individualAttribute) => {
                  const {
                    option,
                    value,
                  } = individualAttribute;

                  return {
                    label: option,
                    value,
                  };
                }) : []}
              />
            )}
            {project && Array.isArray(project.signs) && project.signs.length > 0 && project.signs.map((sign, signIndex) => (
              <SignRow
                key={signIndex}
                sign={sign}
                project={project}
                discount={discount}
              />
            ))}
          </div>
        )}
      </ShadowBox>
    </div>
  );
};


Item.defaultProps = {
  product: undefined,
  project: undefined,
  discount: undefined,
};
Item.propTypes = {
  discount: PropTypes.number,
  product: PropTypes.shape({
    attributes: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    product: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.shape({
            sizes: PropTypes.shape({
              card: PropTypes.shape({
                filename: PropTypes.string,
              }),
            }),
          }),
        }),
      ),
    }),
  }),
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    specs: PropTypes.shape({}),
    signs: PropTypes.arrayOf(
      PropTypes.shape({
        copy: PropTypes.arrayOf(
          PropTypes.shape({}),
        ),
        specs: PropTypes.shape({}),
      }),
    ),
  }),
};

export default Item;
