import { createUseStyles } from 'react-jss';
import { midBreak } from '../../css/queries';
import vars, { base, colors, shadows } from '../../css/vars';

export default createUseStyles({
  shadowBox: {
    borderRadius: `${vars.borderRadius}px`,
    boxShadow: shadows.s,
  },
  white: {
    backgroundColor: colors.white,
  },
  lighterGray: {
    backgroundColor: colors.lighterGray,
  },
  l: {
    padding: base(2),
    ...midBreak({
      padding: base(),
    }),
  },
  s: {
    padding: base(),
  },
});
