import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const { backerPatternColors } = specSheets;
const entries = Object.entries(backerPatternColors);

const BackerPatternColors = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.backerPatternColors}>
        <SpecHeader
          label={headerLabel || 'Backer Pattern Color'}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          {entries.map(([colorID, color], index) => {
            const { label } = color;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'patternColor',
                  label,
                  value: colorID,
                  onChange,
                  checked: colorID === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

BackerPatternColors.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  headerLabel: undefined,
  specId: undefined,
};

BackerPatternColors.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default BackerPatternColors;
