import { createUseStyles } from 'react-jss';
import vars, { base, colors, shadows } from '../../css/vars';
import { largeBreak } from '../../css/queries';

export default createUseStyles({
  card: {
    position: 'relative',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    padding: base(2),
    borderRadius: `${vars.borderRadius}px`,
    boxShadow: shadows.s,
    ...largeBreak({
      padding: base(1),
    }),
  },
  img: {
    width: `calc(100% + ${base(2)})`,
    maxWidth: 'unset',
    marginBottom: base(2),
    ...largeBreak({
      marginBottom: base(),
      width: `calc(100% + ${base()})`,
    }),
  },
  cta: {
    position: 'relative',
    width: '100%',
    zIndex: 1, // have to lift out of the 'SelectOverlay'
  },
});
