import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../Form/context';
import useStyles from './css';
import isFormValid from '../Form/isFormValid';

const Errors = (props) => {
  const {
    color,
    className,
    errors: errorsFromProps,
  } = props;

  const { apiErrors, fieldState } = useForm() || {};
  const formIsValid = isFormValid(fieldState);
  const hasPropsErrors = errorsFromProps && Object.keys(errorsFromProps).length > 0;

  const classes = useStyles();

  if (apiErrors || !formIsValid || hasPropsErrors) {
    return (
      <div
        className={[
          classes[color],
          className,
        ].filter(Boolean).join(' ')}
      >
        {apiErrors && Array.isArray(apiErrors)
          && apiErrors.map((error, index) => {
            const { field, message } = error;
            return (
              <div key={index}>
                <span>
                  {field && `${field}: `}
                  {message}
                </span>
              </div>
            );
          })}
        {formIsValid === false
          && Object.entries(fieldState).map(([, entry], index) => {
            const { isValid, validationMessage } = entry;
            if (isValid === false) {
              return (
                <div key={index}>
                  {validationMessage}
                </div>
              );
            }
            return null;
          })}
        {hasPropsErrors && Object.keys(errorsFromProps).map((index) => (
          <div key={index}>
            {errorsFromProps[index]}
          </div>
        ))}
      </div>
    );
  }
  return null;
};

Errors.defaultProps = {
  color: 'red',
  className: undefined,
  errors: undefined,
};

Errors.propTypes = {
  color: PropTypes.oneOf([
    'white',
    'red',
  ]),
  className: PropTypes.string,
  errors: PropTypes.shape({}),
};

export default Errors;
