import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

const BackerShapes = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    options,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.backerShapes}>
        <SpecHeader
          label={headerLabel || 'Backer Shape'}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          <Radio
            {...{
              className: classes.radio,
              name: 'shape',
              label: 'None',
              value: 'none',
              onChange,
              checked: currentValue === 'none',
            }}
          />
          {Array.isArray(options) && options.length > 0
            && options.map((shape, index) => {
              const { label, id } = shape;

              return (
                <Radio
                  {...{
                    key: index,
                    className: classes.radio,
                    name: 'shape',
                    label,
                    value: id,
                    onChange,
                    checked: id === currentValue,
                  }}
                />
              );
            })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

BackerShapes.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  options: undefined,
  headerLabel: undefined,
  specId: undefined,
};

BackerShapes.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default BackerShapes;
