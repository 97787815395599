import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

const InsertCopyPositions = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    options,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertCopyPositions}>
        <SpecHeader
          label={headerLabel || 'Insert Copy Position'}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          {Array.isArray(options) && options.length > 0 && options.map((option, index) => {
            const { id: positionID, label } = option;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'copyPosition',
                  label,
                  value: positionID,
                  onChange,
                  checked: positionID === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

InsertCopyPositions.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  options: undefined,
  headerLabel: undefined,
  specId: undefined,
};

InsertCopyPositions.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default InsertCopyPositions;
