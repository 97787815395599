import { createUseStyles } from 'react-jss';
import vars, { base, colors, shadows, zIndex } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  onboardingTooltip: {
    position: 'absolute',
    zIndex: zIndex.onboardingTooltip,
  },
  active: {
    zIndex: zIndex.activeOnboardingTooltip,
  },
  topLeft: {
    top: 0,
    left: 0,
    transform: 'translate3d(-50%, -50%, 0)',
  },
  top: {
    top: 0,
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  topRight: {
    top: 0,
    right: 0,
    transform: 'translate3d(50%, -50%, 0)',
  },
  right: {
    top: '50%',
    right: 0,
    transform: 'translate3d(50%, -50%, 0)',
  },
  bottomRight: {
    right: 0,
    bottom: 0,
    transform: 'translate3d(50%, 50%, 0)',
  },
  bottom: {
    left: '50%',
    bottom: 0,
    transform: 'translate3d(-50%, 50%, 0)',
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
    transform: 'translate3d(-50%, 50%, 0)',
  },
  left: {
    left: 0,
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  content: {
    width: 'max-content',
    maxWidth: '400px',
    display: 'none',
    backgroundColor: colors.white,
    boxShadow: shadows.s,
    position: 'absolute',
    zIndex: 3,
    transform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    padding: base(1),
    borderRadius: vars.borderRadius,
    '&::after': {
      content: '" "',
      position: 'absolute',
      borderWidth: '10px',
      borderStyle: 'solid',
      zIndex: 1,
    },
    ...midBreak({
      maxWidth: '250px',
    }),
  },
  showTooltip: {
    display: 'block',
  },
  'content--position-right': {
    left: '200%',
    top: '50%',
    transform: 'translateY(-50%)',
    '&::after': {
      borderColor: `transparent ${colors.white} transparent transparent`,
      top: '50%',
      right: '100%',
      transform: 'translateY(-50%)',
    },
  },
  'content--position-left': {
    right: '200%',
    top: '50%',
    transform: 'translateY(-50%)',
    '&::after': {
      borderColor: `transparent transparent transparent ${colors.white} `,
      top: '50%',
      left: '100%',
      transform: 'translateY(-50%)',
    },
  },
  'content--position-top': {
    bottom: '200%',
    transform: 'translateX(-50%)',
    '&::after': {
      borderColor: `${colors.white} transparent transparent transparent`,
      top: '100%',
      left: 'calc(50%)',
    },
  },
  'content--position-bottom': {
    top: '200%',
    transform: 'translateX(-50%)',
    '&::after': {
      borderColor: `transparent transparent ${colors.white} transparent`,
      bottom: '100%',
      left: 'calc(50%)',
    },
  },
  button: {
    border: `${vars.strokeWidth}px solid transparent`,
    backgroundColor: colors.blue,
    transition: `all ${vars.transTime}ms linear`,
    borderRadius: '100%',
    width: '18px',
    height: '18px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonLabel: {
  },
  closeTooltip: {
    height: 0,
    textAlign: 'right',
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontWeight: 700,
    color: colors.darkGray,
    marginRight: base(1),
    marginBottom: base(0.5),
  },
  message: {
    color: colors.gray,
  },
  optOut: {
    cursor: 'pointer',
    marginTop: base(0.5),
    '&:hover': {
      color: colors.darkGray,
    },
  },
});
