import { createUseStyles } from 'react-jss';
import { button } from '../css';

export default createUseStyles({
  clickableArrow: {
    ...button,
    cursor: 'pointer',
  },
  isDisabled: {
    cursor: 'default',
    '&:hover': {
      background: 'none',
    },
  },
});
