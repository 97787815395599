import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';

const Tooltip = (props) => {
  const {
    children,
    label,
    delayHover,
  } = props;

  const classes = useStyles({ delayHover });

  return (
    <div className={classes.tooltip}>
      {children}
      {label && (
        <div className={classes.label}>
          {label}
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  children: undefined,
  label: undefined,
  delayHover: undefined,
};

Tooltip.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  delayHover: PropTypes.bool,
};

export default Tooltip;
