import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

const { endCapStyles } = specSheets;
const entries = Object.entries(endCapStyles);

const EndCapStyles = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.endCapStyles}>
        <SpecHeader
          label="End Cap Style"
          to={backTo}
        />
        <ShadowBox color="white">
          {entries.map(([styleID, position], index) => {
            const { label } = position;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'endCapStyle',
                  label,
                  value: styleID,
                  onChange,
                  checked: styleID === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

EndCapStyles.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
};

EndCapStyles.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default EndCapStyles;
