import React, { Fragment } from 'react';
import {
  AutoScalingSign,
  specSheets,
  getSignPrice,
  formatPartNumber,
  countBackers,
  signTemplates,
  signTemplateMeta,
  themes,
  mergeSpecs,
  getBackerCode,
} from '@two90signs/290-renderer';
import PropTypes from 'prop-types';
import MarginGrid from '../../../components/MarginGrid';
import Card from '../../../components/Card';
import SelectOverlay from '../../../forms/fields/SelectOverlay';
import useStyles from './css';
import OnboardingTooltip from '../../../components/OnboardingTooltip';

const { signCategories } = specSheets;

const signTemplatesByCategory = {};

const allCategories = Object.keys(signCategories);

allCategories.forEach((categoryKey) => {
  signTemplatesByCategory[categoryKey] = [];
});

signTemplates.forEach((signTemplate) => {
  const { templateID } = signTemplate;
  const templateMeta = signTemplateMeta[templateID];
  if (templateMeta) {
    const { category } = templateMeta;
    if (signTemplatesByCategory[category]) signTemplatesByCategory[category].push({ ...signTemplate });
  }
});

const Results = (props) => {
  const {
    project: {
      wallColor: projectWallColor,
      theme,
      specs: themeSpecs,
    },
    selectedCategories,
    selectedSigns,
    dispatchSelectedSigns,
    selectedSignSystem,
  } = props;

  const baseThemeSpecs = themes[theme];
  const mergedThemeSpecs = mergeSpecs(baseThemeSpecs, themeSpecs);

  const classes = useStyles();

  const hasFilters = Object.values(selectedCategories).some((filter) => filter);

  if (!hasFilters) {
    return (
      <Fragment>
        <h2>No results to display</h2>
        <p>Select a category to get started.</p>
      </Fragment>
    );
  }

  if (Array.isArray(allCategories) && allCategories.length > 0) {
    return (
      <Fragment>
        {allCategories.map((category, categoryIndex) => {
          const { label: categoryLabel } = signCategories[category];
          const isActive = selectedCategories[category];

          if (isActive) {
            const templatesInCategory = signTemplatesByCategory[category];

            return (
              <div
                key={categoryIndex}
                className={classes.categoryGroup}
              >
                <h3>
                  {categoryLabel}
                </h3>
                <div className={classes.gridWrapper}>
                  {categoryIndex === 0 && (
                    <OnboardingTooltip
                      className={classes.onboardingTooltip}
                      position="topLeft"
                      contentPosition="right"
                      title="Select your signs"
                      message="Start by selecting the sign(s) you'd like to add to your project. You can select as many as you’d like. Don’t worry, you can add or delete signs later."
                    />
                  )}
                  <MarginGrid
                    className={classes.marginGrid}
                    overflowMobile
                    staticColumns
                  >
                    {Array.isArray(templatesInCategory) && templatesInCategory.length > 0
                      && templatesInCategory.map((signTemplate, indexInCategory) => {
                        const {
                          name,
                          templateID,
                          specs: templateSpecs,
                          alternateSpecs: alternateTemplateSpecs,
                        } = signTemplate;

                        const specsToUse = alternateTemplateSpecs?.[selectedSignSystem] || templateSpecs;
                        const {
                          insertArea,
                          overallSizes,
                        } = signTemplateMeta?.[templateID] || {};

                        const numberOfBackers = countBackers(mergedThemeSpecs, specsToUse);
                        const backerCode = getBackerCode(specsToUse);

                        return (
                          <div
                            className={classes.cardWrapper}
                            key={indexInCategory}
                          >
                            <SelectOverlay
                              {...{
                                id: `sign-${templateID}`,
                                type: 'checkbox',
                                name: `selectedSigns.${templateID}`,
                                currentValue: selectedSigns[templateID],
                                onChange: (incomingValue) => {
                                  dispatchSelectedSigns({
                                    type: 'CHANGE',
                                    payload: { [templateID]: incomingValue },
                                  });
                                },
                              }}
                            >
                              <Card
                                title={name}
                                subtitle={(
                                  <div>
                                    <div>
                                      {formatPartNumber(mergedThemeSpecs, specsToUse, templateID)}
                                    </div>
                                    {insertArea && (
                                      <div className={classes.signSize}>
                                        {`Insert area: ${insertArea.height}" x ${insertArea.width}"`}
                                      </div>
                                    )}
                                    {numberOfBackers > 0 && overallSizes && (
                                      <div className={classes.signSize}>
                                        {`Overall size: ${overallSizes?.[backerCode]?.height}" x ${overallSizes?.[backerCode]?.width}"`}
                                      </div>
                                    )}
                                  </div>
                                )}
                                price={getSignPrice({ ...mergedThemeSpecs, signSystem: selectedSignSystem }, specsToUse, templateID)}
                                enableImagePadding
                                customImg={(
                                  <AutoScalingSign
                                    signProps={{
                                      themeSpecs: {
                                        ...mergedThemeSpecs,
                                        signSystem: selectedSignSystem,
                                      },
                                      specs: specsToUse,
                                    }}
                                    centerInContainer
                                  />
                                )}
                                imageBackgroundColor={projectWallColor}
                              />
                            </SelectOverlay>
                          </div>
                        );
                      })}
                  </MarginGrid>
                </div>
              </div>
            );
          }
          return null;
        })}
      </Fragment>
    );
  }
  return null;
};

Results.defaultProps = {
  project: undefined,
  selectedCategories: undefined,
  selectedSigns: undefined,
  dispatchSelectedSigns: undefined,
  selectedSignSystem: undefined,
  numberOfSelectedSigns: undefined,
};

Results.propTypes = {
  project: PropTypes.shape({
    wallColor: PropTypes.string,
    theme: PropTypes.string,
    specs: PropTypes.shape({}),
    uuid: PropTypes.string,
  }),
  selectedCategories: PropTypes.shape({}),
  selectedSigns: PropTypes.shape({}),
  dispatchSelectedSigns: PropTypes.func,
  selectedSignSystem: PropTypes.string,
  numberOfSelectedSigns: PropTypes.number,
};

export default Results;
