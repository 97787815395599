import React, { Fragment } from 'react';
import { Modal, ModalToggler, useModal } from '@faceless-ui/modal';
import { useHistory, NavLink } from 'react-router-dom';
import useDrawerStyles from '../useDrawerStyles';
import { useAuthentication } from '../../../wrappers/Authentication';
import CloseIcon from '../../../icons/CloseIcon';
import Button from '../../Button';
import useStyles from './css';

const MainMenuDrawer = () => {
  const drawerClasses = useDrawerStyles();
  const { isLoggedIn, logOut, user } = useAuthentication();
  const classes = useStyles();
  const { closeAll: closeAllModals } = useModal();
  const history = useHistory();

  return (
    <Modal
      slug="menu"
      className={`${classes.modal} ${drawerClasses.modal}`}
    >
      <Fragment>
        <ModalToggler
          slug="menu"
          className={drawerClasses.closeButton}
        >
          <CloseIcon color="white" />
        </ModalToggler>
        <div className={classes.navWrapper}>
          <nav className={classes.nav}>
            <NavLink to="/projects">
              <h1>
                My Projects
              </h1>
            </NavLink>
            <NavLink to="/store">
              <h1>
                Store
              </h1>
            </NavLink>
            {isLoggedIn && user?.organization && (
              <NavLink to="/my-products">
                <h1>
                  My Products
                </h1>
              </NavLink>
            )}
            <NavLink to="/help">
              <h1>
                Help
              </h1>
            </NavLink>
          </nav>
          {isLoggedIn && (
            <Button
              onClick={() => {
                logOut();
                closeAllModals();
                history.push('/logged-out');
              }}
              color="darkGray"
              label="Log out"
            />
          )}
        </div>
      </Fragment>
    </Modal>
  );
};

export default MainMenuDrawer;
