import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import {
  AutoScalingSign,
  mergeCopy,
  mergeSpecs,
  injectBackerPositions,
  themes,
} from '@two90signs/290-renderer';
import { Link } from 'react-router-dom';
import BlockContainer from '../../components/BlockContainer';
import { useCart } from '../../wrappers/CartProvider';
import Row from './Row';
import EmptyCart from './EmptyCart';
import Summary from '../../components/CartSummary';
import Button from '../../components/Button';
import StickyFooter from '../../components/StickyFooter';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import useStyles from './css';
import { useAuthentication } from '../../wrappers/Authentication';
import getProjectPrice from '../../utilities/getProjectPrice';
import defaultImage from '../../../assets/images/my290-default-image.png';
import MarginGrid from '../../components/MarginGrid';

const Cart = () => {
  const { isLoggedIn, user } = useAuthentication();
  const classes = useStyles();

  const {
    cart,
    deleteProjectFromCart,
    deleteProductFromCart,
    cartIsEmpty,
  } = useCart();

  const {
    projects,
    products,
  } = cart;

  const { setCrumbs } = useBreadcrumbs();

  useEffect(() => {
    setCrumbs([{
      label: 'Cart',
    }]);
  }, [setCrumbs]);

  if (cart && cartIsEmpty) return <EmptyCart />;

  return (
    <Fragment>
      <Helmet>
        <title>Cart</title>
        <meta
          name="description"
          content="View items in your cart, here you can make changes to the items in your cart prior to checkout."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.cart}>
        <header className={classes.header}>
          <h1 className={classes.headerTitle}>
            Cart
          </h1>
          <MarginGrid className={classes.desktopCTA}>
            <Button
              to="/store"
              label="Back to store"
              color="lightGray"
            />
            {user?.organization
              && (
                <Button
                  to="/my-products"
                  label="Back to my products"
                  color="lightGray"
                />
              )}
            <Button
              to="/projects"
              label="Back to projects"
              color="lightGray"
            />
          </MarginGrid>
        </header>
        <BlockContainer className={classes.container}>
          <div className={classes.contentWrapper}>
            <div className={classes.table}>
              {Array.isArray(projects) && projects.length > 0
                && projects.map((project, index) => {
                  const {
                    signs,
                    uuid: projectID,
                    theme,
                    specs: themeSpecs,
                    name,
                    quantity,
                  } = project;

                  let signCount = 0;
                  signs.forEach((item) => { signCount += item.quantity; });

                  const baseThemeSpecs = themes[theme];
                  // NOTE: These specs are for first sign in the project
                  const {
                    specs: sign1Specs,
                    copy: sign1Copy,
                    templateID,
                  } = signs[0];

                  const withMergedCopy = mergeCopy(sign1Specs, sign1Copy[0]); // use the copy from the first instance of sign 1 (quantity)
                  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);
                  const withMergedTheme = mergeSpecs(mergedTheme, withMergedCopy);
                  const backersWithPositions = injectBackerPositions(withMergedTheme.backers, withMergedTheme.signSystem, templateID);

                  return (
                    <Row
                      key={index}
                      itemType="projects"
                      singular="Project"
                      title={name}
                      quantity={quantity}
                      backlinkTo={`/project/${projectID}`}
                      backlinkLabel="edit"
                      price={getProjectPrice(project)}
                      itemIndex={index}
                      description={`${signs.length} Sign Type${signs.length > 0 ? 's' : ''}, ${signCount} Total Sign${signCount > 0 ? 's' : ''}`}
                      img={(
                        <Link to={`/project/${projectID}`}>
                          <AutoScalingSign
                            signProps={{
                              specs: {
                                ...withMergedTheme,
                                backers: backersWithPositions,
                              },
                            }}
                            centerInContainer
                          />
                        </Link>
                      )}
                      onDelete={() => { deleteProjectFromCart(projectID); }}
                    />
                  );
                })}
              {Array.isArray(products) && products.length > 0
                && products.map((product, index) => {
                  const {
                    attributes,
                    name,
                    quantity,
                    id: productID,
                    images,
                  } = product;

                  const description = attributes.reduce((acc, attribute, attributeIndex) => {
                    const { value, label } = attribute;
                    let suffix = ', ';
                    const isLastAttribute = attributeIndex + 1 === attributes.length;
                    if (isLastAttribute) suffix = '';
                    return `${acc}${label}: ${value}${suffix}`;
                  }, '');

                  return (
                    <Row
                      key={index}
                      itemType="products"
                      singular="Product"
                      price={product.price}
                      itemIndex={index}
                      description={description}
                      title={name}
                      quantity={quantity}
                      backlinkTo={`/store/${productID}`}
                      img={(
                        <Link to={`/store/${productID}`}>
                          <img
                            className={classes.image}
                            src={images?.[0]?.image?.sizes?.card?.filename ? `${process.env.API_URL}/media/${images[0].image.sizes.card.filename}` : defaultImage}
                            alt={name}
                          />
                        </Link>
                      )}
                      onDelete={() => { deleteProductFromCart(index); }}
                    />
                  );
                })}
              <div>
                Submit copy schedule to
                {' '}
                <a href="mailto:info@290signs.com"><strong>info@290signs.com</strong></a>
                .
              </div>
            </div>

            <div className={classes.summaryWrapper}>
              <Summary
                {...cart}
                action={(
                  <Button
                    to={{
                      pathname: `${isLoggedIn ? '/checkout' : '/login'}`,
                      state: {
                        redirect: `${!isLoggedIn ? '/checkout' : undefined}`,
                      },
                    }}
                    className={classes.cta}
                    label={`${isLoggedIn ? 'Checkout Now' : 'Log in to checkout'}`}
                  />
                )}
              />
            </div>
          </div>
        </BlockContainer>
        <StickyFooter className={classes.mobileCTA}>
          <Button
            to={{
              pathname: `${isLoggedIn ? '/checkout' : '/login'}`,
              state: {
                redirect: `${!isLoggedIn ? '/checkout' : undefined}`,
              },
            }}
            className={classes.mobileCTAButton}
            label={`${isLoggedIn ? 'Checkout Now' : 'Log in to checkout'}`}
          />
        </StickyFooter>
      </div>
    </Fragment>
  );
};

export default Cart;
