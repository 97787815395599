import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import useStyles from './css';
import Checkbox from '../../forms/fields/Checkbox';
import ChevronIcon from '../../icons/ChevronIcon';
import Button from '../../components/Button';
import NestedSpecLink from './Lines/Line/LineSpecLink';
import Lines from './Lines';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import OnboardingTooltip from '../../components/OnboardingTooltip';

const Copy = (props) => {
  const { url, path } = useRouteMatch();

  const {
    backTo,
    numberOfSigns,
    insertsMeta,
    currentCopy,
    currentDeferCopy,
    onCopyChange,
    onDeferCopyChange,
    selectedPreviewIndex,
    setSelectedPreviewIndex,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.copy}>
        <Switch>
          <Route path={`${path}/:signIndex/:insertIndex`}>
            <Lines
              onCopyChange={onCopyChange}
              currentCopy={currentCopy}
              insertsMeta={insertsMeta}
              backTo={url}
            />
          </Route>
          <Route>
            <div style={{ position: 'relative' }}>
              <SpecHeader
                label="Copy"
                to={backTo}
              />
              <OnboardingTooltip
                position="topLeft"
                contentPosition="left"
                title="Personalize signs"
                message="Select sign to customize copy or select box and send copy schedule to info@290signs.com. Once you have customized each sign, click to, return to previous screen."
              />
            </div>

            <ShadowBox color="white">
              <Checkbox
                {...{
                  name: 'deferCopy',
                  label: 'Email copy to info@290signs.com',
                  value: currentDeferCopy,
                  onChange: onDeferCopyChange,
                }}
              />
            </ShadowBox>
            {!currentDeferCopy
              && Array.from(Array(numberOfSigns).keys()).map((sign, signIndex) => {
                const isPreviewing = signIndex === selectedPreviewIndex;

                return (
                  <Collapsible
                    key={signIndex}
                    openOnInit
                  >
                    {({ isOpen }) => (
                      <ShadowBox
                        padding={false}
                        color="white"
                        className={classes.collapsible}
                      >
                        <header className={classes.collapsibleHeader}>
                          {signIndex === 0 && (
                            <OnboardingTooltip
                              position="topLeft"
                              contentPosition="left"
                              title="Each sign is listed here"
                              message="If your copy specs are not showing up in the rendering, that sign is not currently being previewed, click the preview button to activate."
                            />
                          )}
                          <Fragment>
                            <div className={classes.collapsibleHeaderContent}>
                              <CollapsibleToggler className={classes.collapsibleTitle}>
                                <strong>
                                  {`Sign ${signIndex + 1}`}
                                </strong>
                              </CollapsibleToggler>
                              {numberOfSigns > 1 && (
                                <Button
                                  size="xs"
                                  label={`${isPreviewing ? 'Previewing' : 'Preview'}`}
                                  color="lightGray"
                                  icon={isPreviewing ? 'recording' : undefined}
                                  iconColor="red"
                                  htmlElement="span"
                                  onClick={() => setSelectedPreviewIndex(signIndex)}
                                  disabled={isPreviewing}
                                  flexReverse
                                />
                              )}
                              <CollapsibleToggler className={classes.collapsibleIcon}>
                                <ChevronIcon
                                  rotation={isOpen ? 180 : 0}
                                  size="s"
                                  color="gray"
                                />
                              </CollapsibleToggler>
                            </div>
                            {isOpen && (
                              <div className={classes.seperator} />
                            )}
                          </Fragment>
                        </header>
                        <CollapsibleContent>
                          <div className={classes.collapsibleContent}>
                            <Switch>
                              <Route>
                                {Array.isArray(insertsMeta) && insertsMeta.length > 0
                                  && insertsMeta.map((metaInsert, insertIndex) => (
                                    <div
                                      key={insertIndex}
                                      style={{ position: 'relative' }}
                                    >
                                      {signIndex === 0 && insertIndex === 0 && (
                                        <OnboardingTooltip
                                          position="topLeft"
                                          contentPosition="left"
                                          title="Copy specs are organized by insert"
                                          message="This depends on your sign type but may include copy, symbols, room numbers, and positioning."
                                        />
                                      )}
                                      <NestedSpecLink
                                        to={`${url}/${signIndex}/${insertIndex}`}
                                        label={`Insert ${insertIndex + 1}`}
                                        arrow
                                      />
                                    </div>
                                  ))}
                              </Route>
                            </Switch>
                          </div>
                        </CollapsibleContent>
                      </ShadowBox>
                    )}
                  </Collapsible>
                );
              })}
          </Route>
        </Switch>
      </div>
    </Fragment>
  );
};

Copy.defaultProps = {
  backTo: undefined,
  currentCopy: undefined,
  currentDeferCopy: undefined,
  onCopyChange: undefined,
  onDeferCopyChange: undefined,
  numberOfSigns: undefined,
  insertsMeta: undefined,
  selectedPreviewIndex: undefined,
  setSelectedPreviewIndex: undefined,
};

Copy.propTypes = {
  backTo: PropTypes.string,
  currentDeferCopy: PropTypes.bool,
  currentCopy: PropTypes.arrayOf(
    PropTypes.shape({
      inserts: PropTypes.arrayOf(
        PropTypes.shape({
          copy: PropTypes.string,
        }),
      ),
    }),
  ),
  onCopyChange: PropTypes.func,
  onDeferCopyChange: PropTypes.func,
  numberOfSigns: PropTypes.number,
  insertsMeta: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  selectedPreviewIndex: PropTypes.number,
  setSelectedPreviewIndex: PropTypes.func,
};

export default Copy;
