import React from 'react';
import { createUseStyles } from 'react-jss';

import PropTypes from 'prop-types';
import { midBreak } from '../../css/queries';
import { base, colors, zIndex } from '../../css/vars';

const useStyles = createUseStyles({
  stickyFooter: {
    zIndex: zIndex.modal,
    position: 'sticky',
    bottom: '0',
    width: `calc(100% + ${base(8)})`, // coordinate with 'view' padding
    marginLeft: base(-4), // coordinate with 'view' padding
    padding: `${base()} ${base(4)}`, // coordinate with 'view' padding
    backgroundColor: colors.white,
    ...midBreak({
      width: `calc(100% + ${base(2)})`, // coordinate with 'view' padding
      marginLeft: base(-1), // coordinate with 'view' padding
      padding: base(), // coordinate with 'view' padding
    }),
  },
  mobileCTAButton: {
    width: '100%',
  },
});

const StickyFooter = (props) => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <div
      className={[
        classes.stickyFooter,
        className,
      ].filter(Boolean).join(' ')}
    >
      {children && children}
    </div>
  );
};

StickyFooter.defaultProps = {
  className: '',
  children: undefined,
};
StickyFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default StickyFooter;
