import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Button';
import Submit from '../../../../forms/fields/Submit';
import useAuthDrawerStyles from '../useAuthDrawerStyles';
import Input from '../../../../forms/fields/Input';
import Form from '../../../../forms/Form';
import Errors from '../../../../forms/Errors';

const ResetPassword = (props) => {
  const { setContent } = props;
  const authDrawerClasses = useAuthDrawerStyles();
  const [success, setSuccess] = useState(false);

  const onResponse = useCallback((response) => {
    const { status } = response;
    if (status === 200) {
      setSuccess(true);
    }
  }, [setSuccess]);

  return (
    <div>
      <h1>
        Reset password
      </h1>
      <Form
        method="post"
        action={`${process.env.API_URL}/api/users/forgot-password`}
        onResponse={onResponse}
        className={authDrawerClasses.form}
        defaultValues={{
          email: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Email is required',
            }),
          },
        }}
        htmlAttributes={{
          autoComplete: 'on',
        }}
      >
        <div>
          <b>
            Can’t remember your password? No worries. Enter your email to reset it securely.
          </b>
        </div>
        <Errors color="white" />
        {success && (
          <p>Email sent! Please check your inbox for instructions on how to reset your passord.</p>
        )}
        <Input
          {...{
            type: 'email',
            name: 'email',
            id: 'resetPasswordEmail',
            label: 'Email Address',
            required: true,
          }}
        />
        <div>
          <Submit
            color="darkGray"
            className={authDrawerClasses.submit}
            label="Send reset instructions"
          />
        </div>
        <div>
          {'You can also '}
          <Button
            kind="text"
            onClick={() => setContent('logIn')}
            label="try again"
          />
          {' or create a '}
          <Button
            kind="text"
            onClick={() => setContent('signUp')}
            label="new account"
          />
          .
        </div>
      </Form>
    </div>
  );
};

ResetPassword.defaultProps = {
  setContent: undefined,
};

ResetPassword.propTypes = {
  setContent: PropTypes.func,
};

export default ResetPassword;
