import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';

export default createUseStyles({
  back: {
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    color: ({ darkUI }) => (darkUI ? colors.white : colors.darkGray),
    opacity: 0.66,
    '& > *:not(:last-child)': {
      marginRight: base(0.5),
    },
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
});
