const reducer = (state, action) => {
  const selectedSigns = { ...state };
  const { type, payload } = action;
  switch (type) {
    case 'CHANGE': {
      return ({
        ...selectedSigns,
        ...payload,
      });
    }
    case 'SELECT_ONE': {
      const { templateID } = payload;
      return ({
        ...selectedSigns,
        [templateID]: true,
      });
    }
    case 'SELECT_ALL': {
      const withAllSelected = { ...selectedSigns };
      Object.entries(selectedSigns).forEach(([templateID]) => { withAllSelected[templateID] = true; }); // all true
      return withAllSelected;
    }
    case 'DESELECT_ALL': {
      const withAllDeSelected = { ...selectedSigns };
      Object.entries(selectedSigns).forEach(([templateID]) => { withAllDeSelected[templateID] = false; }); // all true
      return withAllDeSelected;
    }
    default: {
      break;
    }
  }
  return selectedSigns;
};

export default reducer;
