import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';

const View = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.view}>
      {children && children}
    </div>
  );
};

View.defaultProps = {
  children: undefined,
};

View.propTypes = {
  children: PropTypes.node,
};

export default View;
