import { createUseStyles } from 'react-jss';
import { largeBreak, midBreak } from '../../../css/queries';
import vars, { base, colors } from '../../../css/vars';

export default createUseStyles({
  categoryGroup: {
    '&:not(:last-child)': {
      marginBottom: base(2),
    },
  },
  cardWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: `calc(33.33% - ${base(1)})`,
    ...largeBreak({
      flexShrink: '0',
      width: `calc(66.66% - ${base(1)})`,
    }),
  },
  gridWrapper: {
    position: 'relative',
  },
  onboardingTooltip: {
    marginTop: base(0.5),
  },
  marginGrid: {
    paddingRight: base(0.5), // To account for the 'SelectOverlay' icon, and the overflow hidden on 'MarginGrid'
    marginRight: base(-0.5),
    paddingTop: base(0.5), // To account for the 'SelectOverlay' icon, and the overflow hidden on 'MarginGrid'
    ...largeBreak({
      width: `calc(100% + ${base(4)})`,
    }),
    ...midBreak({
      width: `calc(100% + ${base()})`,
    }),
  },
  signSize: {
    color: colors.gray,
  },
  bottomCTA: {
    borderRadius: `${vars.borderRadius}px`,
    backgroundColor: colors.white,
    padding: base(2),
    ...midBreak({
      display: 'none',
    }),
  },
});
