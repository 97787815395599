import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../../../components/ScrollToTopOnMount';
import ShadowBox from '../../../../components/ShadowBox';
import OnboardingTooltip from '../../../../components/OnboardingTooltip';
import SpecHeader from '../../../SpecHeader';
import Radio from '../../../../forms/fields/Radio';
import useStyles from './css';

const { copySizes } = specSheets;

// copy sizes are defined on an insert-by-insert basis, therefore options must be passed via props
// as opposed to bulk imported and generically mapped through

const CopySizes = (props) => {
  const { lineIndex } = useParams();

  const {
    backTo,
    currentLines,
    onChange,
    linesMeta,
  } = props;

  const classes = useStyles();
  const { copySizes: availableCopySizes } = linesMeta[lineIndex];
  const { copySize: currentCopySize } = currentLines ? currentLines[lineIndex] : {};
  const numberOfLines = linesMeta.length;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.copySizes}>
        <div style={{ position: 'relative' }}>
          <SpecHeader
            label={`${numberOfLines > 1 ? `Line ${Number(lineIndex) + 1} ` : ''}Copy Size`}
            to={backTo}
          />
          <OnboardingTooltip
            position="topLeft"
            contentPosition="left"
            title="Change copy size"
            message=" Select desired copy size. Adjust copy size if it doesn’t fit."
          />
        </div>

        {Array.isArray(availableCopySizes) && availableCopySizes.length > 0 && (
          <ShadowBox color="white">
            {availableCopySizes.map((copySizeID, index) => {
              const copySize = copySizes[copySizeID];
              const { label } = copySize;

              return (
                <Radio
                  {...{
                    key: index,
                    className: classes.radio,
                    name: 'copySize',
                    label,
                    value: copySizeID,
                    onChange: (incomingValue) => onChange({ lineIndex, incomingValue }),
                    checked: copySizeID === currentCopySize,
                  }}
                />
              );
            })}
          </ShadowBox>
        )}
      </div>
    </Fragment>
  );
};

CopySizes.defaultProps = {
  backTo: undefined,
  currentLines: undefined,
  onChange: undefined,
  linesMeta: undefined,
};

CopySizes.propTypes = {
  backTo: PropTypes.string,
  currentLines: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  onChange: PropTypes.func,
  linesMeta: PropTypes.arrayOf(
    PropTypes.shape({
      copySizes: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
  ),
};

export default CopySizes;
