import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  dashboard: {
    '&:after': {
      content: '""',
      paddingTop: base(4),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(),
      },
    }),
  },
  header: {
    padding: `${base(1)} 0 ${base(4)}`,
  },
  title: {
    marginBottom: 0,
  },
  section: {
    position: 'relative',
    '&:not(:last-child)': {
      marginBottom: base(4),
    },
    ...midBreak({
      '&:not(:last-child)': {
        marginBottom: base(2),
      },
    }),
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});
