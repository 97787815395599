import { createUseStyles } from 'react-jss';
import { hexToRGBA } from '@trbl/utils';
import vars, { colors, base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

const modalClass = '.my290__modal-item';

export default createUseStyles({
  onboardingModal: {
    pointerEvents: 'none', // let pointer events pass through to 'ModalContainer' so that closeOnBlur will work (enable it again where needed in children)
    transition: `${vars.defaultTransTime}ms ${vars.defaultTransCurve} opacity`,
    border: 'none',
    backgroundColor: hexToRGBA(colors.darkGray, 0.93),
    padding: base(2),
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0',
    [`&${modalClass}--appearActive, &${modalClass}--appearDone, &${modalClass}--enterActive, &${modalClass}--enterDone`]: {
      opacity: '1',
    },
    [`&${modalClass}--exitActive, &${modalClass}--exitDone`]: {
      opacity: '0',
    },
    ...midBreak({
      padding: base(),
    }),
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pointerEvents: 'all',
    maxWidth: '800px',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: base(2),
    },
    ...midBreak({
      '& > *:not(:last-child)': {
        marginBottom: base(),
      },
    }),
  },
  title: {
    marginBottom: 0,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    ...midBreak({
      flexDirection: 'column',
    }),
  },
  desktopButton: {
    ...midBreak({
      display: 'none',
    }),
  },
  text: {
    textAlign: 'center',
    flexGrow: 1,
    padding: `0 ${base(2)}`,
    ...midBreak({
      padding: '0',
    }),
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(0.5),
    },
  },
  mobileButton: {
    display: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    ...midBreak({
      display: 'block',
    }),
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(0.5),
    },
  },
  dot: {
    width: base(0.5),
    height: base(0.5),
    borderRadius: '100%',
    backgroundColor: colors.lightGray,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.darkGray,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  dotActive: {
    backgroundColor: colors.darkGray,
  },
});
