import React, { useEffect, Fragment } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { useAuthentication } from '../../wrappers/Authentication';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import MarginGrid from '../../components/MarginGrid';

const LoggedOut = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAuthentication();
  const { setCrumbs } = useBreadcrumbs();
  const { state } = useLocation();
  const { title } = state || {};

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  if (isLoggedIn) return <Redirect to="/already-logged-in" />;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.loggedOut}>
        <h1>{title || 'You are now logged out'}</h1>
        <p>
          {'What would you like to do next? If you '}
          <Button
            to="/new-project"
            kind="text"
            label="create a new project"
          />
          {' as a guest, it will be permanently saved to your profile the next time you log in.'}
        </p>
        <MarginGrid className={classes.whatNext}>
          <Button
            to="/login"
            label="Log back in"
          />
          <Button
            to="/new-project"
            color="lightGray"
            label="Create new project"
          />
          <Button
            to="/store"
            color="lightGray"
            label="Browse the store"
          />
        </MarginGrid>
      </div>
    </Fragment>
  );
};

export default LoggedOut;
