import React from 'react';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import PropTypes from 'prop-types';
import ShadowBox from '../../components/ShadowBox';
import ChevronIcon from '../../icons/ChevronIcon';
import ColorSwatch from '../ColorSwatch';
import useStyles from './css';

const ColorSwatchAccordion = (props) => {
  const {
    title,
    colors,
    name,
    onChange,
    currentValue,
    openOnInit,
  } = props;

  const classes = useStyles();

  const openOnInitbackup = colors.findIndex((color) => color.id === currentValue) > -1;

  let casedTitle = title.replace(/([A-Z])/g, ' $1');
  casedTitle = casedTitle.charAt(0).toUpperCase() + casedTitle.slice(1);

  return (
    <Collapsible
      openOnInit={openOnInit ?? openOnInitbackup}
      open={openOnInit ?? openOnInitbackup}
      transTime={250}
    >
      {({ isOpen }) => (
        <ShadowBox
          color="white"
          padding={false}
          className={classes.colorSwatchAccordion}
        >
          <CollapsibleToggler
            className={classes.toggler}
            htmlAttributes={{ type: 'button' }}
          >
            <div className={classes.togglerContent}>
              <div className={classes.togglerLabel}>
                {casedTitle}
              </div>
              <div className={classes.togglerIcon}>
                <ChevronIcon
                  rotation={isOpen ? 180 : 0}
                  color="gray"
                />
              </div>
            </div>
          </CollapsibleToggler>
          {Array.isArray(colors) && colors.length > 0 && (
            <CollapsibleContent>
              <div className={classes.swatchWrapper}>
                <div className={classes.swatchInnerWrapper}>
                  <div className={classes.swatchInnerInnerWrapper}>
                    {colors.map((color, index) => {
                      const {
                        hex,
                        laminateSrc,
                        patternSrc,
                        label,
                        id,
                        isMetallic,
                      } = color;

                      return (
                        <ColorSwatch
                          {...{
                            key: index,
                            color: hex,
                            img: laminateSrc ?? patternSrc,
                            label,
                            currentValue,
                            value: id,
                            name,
                            id: `${name}-${id}`,
                            onChange,
                            isMetallic,
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </CollapsibleContent>
          )}
        </ShadowBox>
      )}
    </Collapsible>
  );
};

ColorSwatchAccordion.defaultProps = {
  title: undefined,
  colors: undefined,
  name: undefined,
  onChange: undefined,
  currentValue: undefined,
  openOnInit: undefined,
};

ColorSwatchAccordion.propTypes = {
  title: PropTypes.string,
  colors: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  currentValue: PropTypes.string,
  openOnInit: PropTypes.bool,
};

export default ColorSwatchAccordion;
