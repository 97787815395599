import React from 'react';
import PropTypes from 'prop-types';
import ChevronIcon from '../../icons/ChevronIcon';
import ShadowBox from '../../components/ShadowBox';
import useStyles from './css';

const Quantity = (props) => {
  const {
    id,
    className,
    onChange,
    currentValue,
    min,
  } = props;

  const classes = useStyles();

  return (
    <div
      className={[
        classes.input,
        className,
      ].filter(Boolean).join(' ')}
    >
      <ShadowBox
        color="white"
        htmlFor={id}
        className={classes.htmlLabel}
        htmlElement="label"
      >
        <span className={classes.label}>
          Quantity
        </span>
        <input
          {...{
            id,
            name: 'quantity',
            type: 'number',
            className: classes.htmlInput,
            onChange: onChange ? (e) => {
              const { value } = e.target;
              let adjustedValue = Number(value);
              if (value < (min || 0)) adjustedValue = min || 0;
              onChange(adjustedValue);
            } : undefined,
            value: currentValue,
            min,
            step: 1,
          }}
        />
        <div className={classes.controls}>
          <button
            type="button"
            onClick={onChange ? () => {
              if (currentValue > (min || 0)) onChange((Number(currentValue) || 0) - 1);
            } : undefined}
            className={classes.control}
          >
            <ChevronIcon
              color="gray"
              size="s"
            />
          </button>
          <button
            type="button"
            onClick={onChange ? () => { onChange((Number(currentValue) || 0) + 1); } : undefined}
            className={classes.control}
          >
            <ChevronIcon
              rotation={180}
              color="gray"
              size="s"
            />
          </button>
        </div>
      </ShadowBox>
    </div>
  );
};

Quantity.defaultProps = {
  id: '',
  className: undefined,
  onChange: undefined,
  currentValue: undefined,
  min: undefined,
};

Quantity.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  currentValue: PropTypes.number,
  min: PropTypes.number,
};

export default Quantity;
