import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShadowBox from '../../components/ShadowBox';
import useStyles from './css';
import CloseIcon from '../../icons/CloseIcon';

const SpecLink = (props) => {
  const {
    label,
    to,
    currentValue,
    currentLabel,
    themeValue,
    themeLabel,
    onClear,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.specLink}>
      <ShadowBox
        color="white"
        className={classes.wrapper}
      >
        <div className={classes.link}>
          <Link to={to}>
            <div className={classes.label}>
              {label}
            </div>
            <div className={classes.valueWrapper}>
              {currentValue && (
                <div className={classes.currentValue}>
                  {currentLabel}
                </div>
              )}
              {!currentValue && themeValue && (
                <div className={classes.themeValue}>
                  {themeLabel}
                </div>
              )}
            </div>
          </Link>
        </div>
        {currentValue && themeValue && (
          <div
            className={classes.clear}
            onClick={onClear}
            onKeyPress={onClear}
            role="button"
            tabIndex={0}
          >
            <CloseIcon
              color="gray"
              size="s"
            />
          </div>
        )}
      </ShadowBox>
    </div>
  );
};

SpecLink.defaultProps = {
  label: undefined,
  currentValue: undefined,
  currentLabel: undefined,
  themeValue: undefined,
  themeLabel: undefined,
  to: undefined,
  onClear: undefined,
};

SpecLink.propTypes = {
  label: PropTypes.string,
  currentValue: PropTypes.string,
  currentLabel: PropTypes.string,
  themeValue: PropTypes.string,
  themeLabel: PropTypes.string,
  to: PropTypes.string,
  onClear: PropTypes.func,
};

export default SpecLink;
