import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../icons/ArrowIcon';
import ChevronIcon from '../../icons/ChevronIcon';
import CloseIcon from '../../icons/CloseIcon';
import AddIcon from '../../icons/AddIcon';
import CopyIcon from '../../icons/CopyIcon';
import ResetIcon from '../../icons/ResetIcon';
import RecordingIcon from '../../icons/RecordingIcon';
import useStyles from './css';
import SettingsIcon from '../../icons/SettingsIcon';
import EditIcon from '../../icons/EditIcon';
import DownloadIcon from '../../icons/DownloadIcon';
import DocumentIcon from '../../icons/DocumentIcon';

const icons = {
  arrow: ArrowIcon,
  chevron: ChevronIcon,
  close: CloseIcon,
  add: AddIcon,
  copy: CopyIcon,
  reset: ResetIcon,
  recording: RecordingIcon,
  settings: SettingsIcon,
  edit: EditIcon,
  download: DownloadIcon,
  document: DocumentIcon,
};

const Button = forwardRef((props, ref) => {
  const {
    className,
    onClick,
    htmlElement,
    htmlAttributes,
    kind,
    color,
    size,
    label,
    icon,
    iconRotation,
    iconColor,
    labelSize,
    to,
    flexReverse,
    disabled,
  } = props;

  const classes = useStyles({
    color,
    size,
    labelSize,
    flexReverse,
  });

  const mergedClasses = [
    className,
    classes[kind],
    htmlAttributes.className,
    (disabled || htmlAttributes.disabled) && classes.disabled,
  ].filter(Boolean).join(' ');

  const mergedAttributes = {
    type: 'button',
    ...htmlAttributes,
    onClick: (e) => typeof onClick === 'function' && onClick(e),
  };

  const Icon = icons[icon];

  let Wrap = 'span';
  const wrapProps = {};

  if (to) {
    Wrap = Link;
    wrapProps.to = to;
    wrapProps.className = classes.linkWrap;
  }

  const Tag = htmlElement || (kind === 'text' ? 'span' : 'button');

  return (
    <Wrap {...wrapProps}>
      <Tag
        {...{
          className: mergedClasses,
          ...mergedAttributes,
          ref, // needs to be on the html 'button' for the 'ConfirmationPrompt' component
        }}
      >
        {label && (
          <span className={classes.label}>
            {label}
          </span>
        )}
        {label && Icon && <span className={classes.seperator} />}
        {Icon && (
          <Icon
            className={classes.icon}
            rotation={iconRotation}
            color={iconColor}
            size={size}
          />
        )}
      </Tag>
    </Wrap>
  );
});

Button.defaultProps = {
  kind: 'primary',
  color: 'red',
  size: 'l',
  className: undefined,
  onClick: undefined,
  htmlElement: undefined,
  htmlAttributes: {},
  label: undefined,
  icon: undefined,
  iconColor: undefined,
  iconRotation: undefined,
  labelSize: undefined,
  to: undefined,
  flexReverse: undefined,
  disabled: undefined,
};

Button.propTypes = {
  kind: PropTypes.oneOf([
    'primary',
    'text',
  ]),
  color: PropTypes.oneOf([
    'red',
    'darkGray',
    'lightGray',
  ]),
  size: PropTypes.oneOf([
    'xs',
    's',
    'l',
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  htmlElement: PropTypes.string,
  htmlAttributes: PropTypes.shape({
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }),
  label: PropTypes.string,
  icon: PropTypes.string,
  iconRotation: PropTypes.number,
  iconColor: PropTypes.string,
  labelSize: PropTypes.oneOf([
    's',
  ]),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  flexReverse: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
