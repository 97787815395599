import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAuthentication } from '../../wrappers/Authentication';
import Button from '../Button';
import useStyles from './css';
import formatPrice from '../../utilities/formatPrice';
import useDiscount from '../../utilities/useDiscount';

const Price = (props) => {
  const {
    isLoggedIn,
    user,
  } = useAuthentication();

  const {
    hidePrices,
  } = user || {};

  const {
    price,
    size,
    className,
    discount: discountFromProps,
    neverHide,
    useLineBreak,
  } = props;

  const classes = useStyles();
  const originalPrice = formatPrice(price);
  const organizationDiscount = useDiscount();

  // support static discount, to show a discount _at the time of the order_ for example, not necessarily their _current_ discount
  let discount = 0;
  if (discountFromProps !== undefined) discount = discountFromProps;
  else discount = organizationDiscount;
  const discountPrice = formatPrice(price * (1 - discount));

  const ConditionalSmall = size === 's' ? 'small' : Fragment;
  const ConditionalDel = discount > 0 ? 'del' : Fragment;

  if (isLoggedIn && hidePrices && !neverHide) return null;

  return (
    <span
      className={[
        classes.price,
        className,
      ].filter(Boolean).join(' ')}
    >
      {isLoggedIn && (
        <Fragment>
          <span>
            <ConditionalSmall>
              <ConditionalDel>
                {originalPrice}
              </ConditionalDel>
            </ConditionalSmall>
          </span>
          {useLineBreak && (
            <br />
          )}
          {discount > 0 && (
            <span>
              <ConditionalSmall>
                {discountPrice}
              </ConditionalSmall>
            </span>
          )}
        </Fragment>
      )}
      {!isLoggedIn && (
        <Link to="/login">
          <Button
            {...{
              kind: 'text',
              label: 'Log in for price',
              labelSize: size === 's' ? 's' : undefined,
            }}
          />
        </Link>
      )}
    </span>
  );
};

Price.defaultProps = {
  price: undefined,
  size: undefined,
  className: undefined,
  discount: undefined,
  neverHide: undefined,
  useLineBreak: undefined,
};

Price.propTypes = {
  discount: PropTypes.number,
  price: PropTypes.number,
  size: PropTypes.oneOf([
    undefined,
    's',
  ]),
  className: PropTypes.string,
  neverHide: PropTypes.bool,
  useLineBreak: PropTypes.bool,
};

export default Price;
