import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';

const {
  copyPositions,
} = specSheets;

const InsertSpecs = (props) => {
  const {
    templateID,
    insert,
    classes,
    showCopyCase = true,
    showCopyPosition = true,
    isCoverPage,
  } = props;

  const {
    type,
    finish,
    paper,
    copyStyle,
    copyStyleADA,
    copyColor,
    // copyColorLP,
    copyPosition,
    copyCase,
    lines,
  } = insert;

  const hasLines = lines && Array.isArray(lines) && lines.length > 0;
  const hasOneLineOfCopy = hasLines && lines.length === 1;
  const hasMultipleLinesOfCopy = hasLines && lines.length > 1;
  let allLinesAreSameSize;
  if (hasMultipleLinesOfCopy) {
    const copySizes = hasMultipleLinesOfCopy && lines.map(({ copySize }) => copySize);
    allLinesAreSameSize = copySizes.every((size) => size === copySizes[0]);
  }

  const actualCopy = lines?.map(({ copy }) => copy).filter(Boolean) || [];
  const hasText = actualCopy && Array.isArray(actualCopy) && actualCopy.length > 0;

  // signs with room numbers (sign template 28) need to represent them in their copy position
  let copyPositionToRender = copyPositions?.[copyPosition]?.label;
  const showRoomNumbers = hasLines && lines.every(({ showRoomNumber }) => showRoomNumber);
  if (showRoomNumbers) {
    const flexReverse = hasLines && lines.every(({ flexReverse: flip }) => flip);
    if (flexReverse) copyPositionToRender = `L/${copyPosition}`;
    else copyPositionToRender = `${copyPosition}/R`;
  }

  // all arrow symbols should read "AS" (as seen) instead of rendering their actual width and height :shrug:
  const [line1] = lines || [];
  const { symbol: symbolID } = line1 || {};
  // const renderSymbolSize = !symbolID?.startsWith('A') && symbolID !== undefined && symbolID !== 'none';
  // const symbolSizeToUse = renderSymbolSize ? `${line1?.symbolSize?.width} x ${line1?.symbolSize?.height}` : 'AS';

  const hideCopySpecs = type === 'WI' && (templateID === '20' || templateID === '21' || templateID === '22');

  return (
    <Fragment>
      {!isCoverPage && (
        <div className={classes.spec}>
          <div>
            Type:
          </div>
          <div>
            {type}
          </div>
        </div>
      )}
      <div className={classes.spec}>
        <div>
          Finish:
        </div>
        <div>
          {finish}
        </div>
      </div>
      {type === 'WI' && (
        <div className={classes.spec}>
          <div>
            Paper:
          </div>
          <div>
            {paper}
          </div>
        </div>
      )}
      {/*
       // NOTE: Although changing copyColorLP does effect window insert graphics in the renderer,
       // this spec is omitted here because window inserts are ordered with blank paper, the app currently does not support upload
       {type === 'WI' && (
        <div className={classes.spec}>
          <div>
            Copy Color:
          </div>
          <div>
            {copyColorLP}
          </div>
        </div>
      )}
      */}
      {!hideCopySpecs && (
        <Fragment>
          <div className={classes.spec}>
            <div>
              Copy Color:
            </div>
            <div>
              {copyColor}
            </div>
          </div>
          {(((hasText || type === 'SI') || isCoverPage) && type !== 'ADA') && (
            <div className={classes.spec}>
              <div>
                Copy Style:
              </div>
              <div>
                {copyStyle}
              </div>
            </div>
          )}
          {((hasText || isCoverPage) && type === 'ADA') && (
            <div className={classes.spec}>
              <div>
                Copy Style:
              </div>
              <div>
                {copyStyleADA}
              </div>
            </div>
          )}
          {((hasText || type === 'SI') && copyCase && showCopyCase) && (
            <div className={classes.spec}>
              <div>
                Copy Case:
              </div>
              <div>
                {copyCase}
              </div>
            </div>
          )}
          {((hasText || type === 'SI') && copyPosition && showCopyPosition) && (
            <div className={classes.spec}>
              <div>
                Copy Position:
              </div>
              <div>
                {copyPositionToRender}
              </div>
            </div>
          )}
          {type === 'SI' && ( // this is a hack, should be actually written smart inserts within the sign templates from the renderer
            <div className={classes.spec}>
              <div>
                Copy Size:
              </div>
              <div>
                0.5in
              </div>
            </div>
          )}
          {(hasOneLineOfCopy || allLinesAreSameSize) && (
            <Fragment>
              {line1.copy && (
                <div className={classes.spec}>
                  <div>
                    Copy Size:
                  </div>
                  <div>
                    {line1?.copySize}
                  </div>
                </div>
              )}
              {line1?.showSymbol && symbolID !== undefined && symbolID !== 'none' && (
                <Fragment>
                  <div className={classes.spec}>
                    <div>
                      Symbol
                    </div>
                    <div>
                      {`SYM.${line1.symbol}`}
                    </div>
                  </div>
                  {/* <div className={classes.spec}>
                    <div>
                      Symbol Size
                    </div>
                    <div>
                      {symbolSizeToUse}
                    </div>
                  </div> */}
                </Fragment>
              )}
            </Fragment>
          )}
          {hasMultipleLinesOfCopy && !allLinesAreSameSize && lines.map((line, lineIndex) => {
            const {
              copySize,
              showSymbol,
              // symbolSize,
              copy,
              symbol,
            } = line;

            return (
              <Fragment key={lineIndex}>
                {copy && (
                  <div className={classes.spec}>
                    <div>
                      {`Line ${lineIndex + 1} Copy Size:`}
                    </div>
                    <div>
                      {copySize}
                    </div>
                  </div>
                )}
                {showSymbol && (
                  <Fragment>
                    <div className={classes.spec}>
                      <div>
                        Symbol
                      </div>
                      <div>
                        {symbol}
                      </div>
                    </div>
                    {/* <div className={classes.spec}>
                      <div>
                        Symbol Size
                      </div>
                      <div>
                        {`${symbolSize?.width} x ${symbolSize?.height}`}
                      </div>
                    </div> */}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
};

InsertSpecs.defaultProps = {
  insert: undefined,
  classes: undefined,
  showCopyCase: undefined,
  showCopyPosition: undefined,
  isCoverPage: undefined,
};

InsertSpecs.propTypes = {
  templateID: PropTypes.string.isRequired,
  insert: PropTypes.shape({
    type: PropTypes.string,
    finish: PropTypes.string,
    copyColor: PropTypes.string,
    copyColorLP: PropTypes.string,
    copyPosition: PropTypes.string,
    copyCase: PropTypes.string,
    copyStyle: PropTypes.string,
    copyStyleADA: PropTypes.string,
    paper: PropTypes.string,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        copy: PropTypes.string,
        copySize: PropTypes.string,
        showSymbol: PropTypes.bool,
        symbol: PropTypes.string,
        symbolSize: PropTypes.shape({
          width: PropTypes.string,
          height: PropTypes.string,
        }),
        flipContent: PropTypes.bool,
        showRoomNumber: PropTypes.bool,
      }),
    ),
  }),
  classes: PropTypes.shape({
    spec: PropTypes.string,
  }),
  showCopyCase: PropTypes.bool,
  showCopyPosition: PropTypes.bool,
  isCoverPage: PropTypes.bool,
};

export default InsertSpecs;
