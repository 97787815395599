import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../../css/vars';

export default createUseStyles({
  checkboxBase: {
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  htmlInput: {
    margin: '0',
    appearance: 'none',
    width: base(),
    height: base(),
    borderRadius: '3px',
    cursor: 'inherit',
    color: 'inherit',
    border: `currentColor solid ${vars.strokeWidth}px`,
    '&:focus': {
      outline: 'none',
    },
  },
  htmlInputActive: {
    backgroundColor: colors.darkGray,
    border: 'none',
  },
  htmlInputGhostActive: {
    backgroundColor: colors.lightGray,
  },
  checkmark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    color: colors.white,
    display: 'none',
    width: base(0.5),
    height: base(0.5),
    pointerEvents: 'none',
  },
  checkmarkActive: {
    display: 'block',
  },
  checkmarkGhost: {
    opacity: 0.5,
  },
});
