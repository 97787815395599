import React, { createContext, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './css';
import ConfirmationPrompt from './ConfirmationPrompt';

const ActionConfirmationContext = createContext({});
export const useActionConfirmation = () => useContext(ActionConfirmationContext);

const ActionConfirmation = (props) => {
  const { children } = props;

  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [content, setContent] = useState({
    message: undefined,
    confirmLabel: undefined,
    cancel: undefined,
    action: undefined,
    inputLabel: undefined,
    inputValidation: undefined,
  });

  const confirmAction = useCallback((newContent) => {
    setNeedsConfirmation(true);
    setContent({ ...newContent });
  }, []);

  const setNeedsConfirmationCallback = useCallback((status) => {
    setNeedsConfirmation(status);
  }, []);

  const classes = useStyles();

  return (
    <ActionConfirmationContext.Provider
      value={{
        confirmAction,
      }}
    >
      {children}
      <ConfirmationPrompt
        closeOnBlur
        className={classes.confirmationPrompt}
        {...{
          needsConfirmation,
          setNeedsConfirmation: setNeedsConfirmationCallback,
          ...content,
        }}
      />
    </ActionConfirmationContext.Provider>
  );
};

ActionConfirmation.defaultProps = {
  children: undefined,
};

ActionConfirmation.propTypes = {
  children: PropTypes.node,
};

export default ActionConfirmation;
