import { fireRequest } from '../../api';

export default async ({ project, isLoggedIn }) => {
  if (project) {
    const {
      id: projectID,
      uuid: projectUUID,
    } = project;

    if (isLoggedIn) {
      if (projectID) {
        return fireRequest({
          method: 'put',
          url: `${process.env.API_URL}/api/projects/${projectID}`,
          options: {
            body: JSON.stringify(project),
          },
        });
      }
    } else {
      const storedProjects = window.localStorage.getItem('projects');
      const withSavedProject = storedProjects ? JSON.parse(storedProjects) : [];
      const projectIndex = withSavedProject.findIndex((item) => item.uuid === projectUUID);
      const storedProject = withSavedProject[projectIndex];

      withSavedProject[projectIndex] = {
        ...storedProject || {},
        ...project || {},
      };

      window.localStorage.setItem('projects', JSON.stringify(withSavedProject));

      return {
        res: {
          status: 200,
        },
      };
    }
  }

  return null;
};
