import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';

export default createUseStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    textAlign: 'right',
    marginTop: base(3),
    '& a:not(:hover)': {
      textDecoration: 'none',
    },
  },
  navWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    overflowY: 'auto',
  },
});
