import { createUseStyles } from 'react-jss';
import { colors } from '../../css/vars';

export default createUseStyles({
  specDocument: {
    width: '11in',
    height: '8.5in',
    transform: 'scale(2)', // double for resolution (see 'PrintSign' and 'PrintProject' views)
    transformOrigin: 'top left',
    fontSize: '8px',
    lineHeight: '1.5',
    padding: '40px',
    color: colors.gray,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'flex-start',
    marginBottom: '8.5in', // account for transform scale
    marginRight: '11in', // account for transform scale
    backgroundColor: colors.lighterGray,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(85% - 50px)', // 40px to match 'specDocument', plus 10px for the left border on the sidebar
    marginRight: '50px',
    position: 'relative',
    height: '100%',
  },
  specs: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% + 20px)',
    margin: '0 -10px',
    marginBottom: '40px',
  },
  specGroup: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px',
    width: 'calc(20% - 20px)',
    position: 'relative',
    '&:not(:last-child)': {
      '&::after': {
        position: 'absolute',
        content: '""',
        right: '-10px',
        top: 0,
        height: '100%',
        width: '1px',
        backgroundColor: colors.gray,
      },
    },
  },
  spec: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    '& > *': {
      width: '50%',
    },
  },
  signArea: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    margin: '-40px', // use negative margin / padding trick so shadows are not clipped
    padding: '40px ', // see note above
  },
  signWrapper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  signAppendix: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: '0',
    left: '0',
  },
  sidebar: {
    width: '15%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '20px',
    '&::before': {
      position: 'absolute',
      content: '""',
      left: '-10px',
      top: 0,
      height: '100%',
      width: '1px',
      backgroundColor: colors.gray,
    },
  },
  sidebarHeader: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > *:not(:last-child)': {
      marginBottom: '10px',
    },
  },
  logo: {
    height: 'auto',
    width: '75%',
  },
  address: {
    textAlign: 'right',
  },
  sidebarBody: {
    display: 'flex',
    flexDirection: 'column',
    margin: '-10px 0',
    '& > *': {
      position: 'relative',
      margin: '10px 0',
      '&:not(:last-child)': {
        '&::after': {
          position: 'absolute',
          content: '""',
          right: 0,
          bottom: '-10px',
          width: '100%',
          height: '1px',
          backgroundColor: colors.gray,
        },
      },
    },
  },
  adaSection: {
    '& > *:not(:last-child)': {
      marginBottom: '10px',
    },
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '5px',
    },
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    position: 'relative',
  },
  checkbox: {
    width: '10px',
    height: '10px',
    border: `${colors.gray} 1px solid`,
    marginRight: '5px',
  },
  checkboxChecked: {
    backgroundColor: colors.gray,
  },
  disclaimer: {
  },
  schematics: {
    display: 'flex',
    '& > *': {
      flexGrow: '1',
      '&:not(:last-child)': {
        marginRight: '10px',
      },
      '& img': {
        maxHeight: '75px',
      },
    },
  },
  schematicLabels: {
    display: 'flex',
    '& > *': {
      flexGrow: '1',
      textAlign: 'center',
      '&:not(:last-child)': {
        marginRight: '10px',
      },
    },
  },
});

export const printStyles = createUseStyles({
  '@page': {
    size: 'letter landscape',
  },
}, { media: 'print' });
