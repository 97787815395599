import { v4 as uuidv4 } from 'uuid';
import { requests } from '../../api';
import { defaultWallColor } from '../../css/vars';

export default async ({ signSystem, name, isLoggedIn }) => {
  const projectID = uuidv4();

  const newProject = {
    uuid: projectID,
    name,
    wallColor: defaultWallColor,
    theme: 'default',
    specs: {
      signSystem,
      inserts: [{}, {}], // need to instantiate two inserts so that 'mergeSpecs' has something to iterate over
    },
    signs: [],
  };

  if (isLoggedIn) {
    await requests.post({
      url: `${process.env.API_URL}/api/projects`,
      options: {
        body: JSON.stringify(newProject),
      },
    });
  } else {
    const storedProjects = window.localStorage.getItem('projects');
    const withNewProject = (storedProjects && storedProjects !== 'null') ? JSON.parse(storedProjects) : [];
    withNewProject.push(newProject);
    window.localStorage.setItem('projects', JSON.stringify(withNewProject));
  }

  return projectID;
};
