import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import MarginGrid from '../../../components/MarginGrid';
import BlockContainer from '../../../components/BlockContainer';
import Summary from '../../../components/CartSummary';
import Errors from '../../../forms/Errors';
import Input from '../../../forms/fields/Input';
import Select from '../../../forms/fields/Select';
import Submit from '../../../forms/fields/Submit';
import StickyFooter from '../../../components/StickyFooter';
import { requests } from '../../../api';
import useStyles from './css';
import Form from '../../../forms/Form';
import Payment from './Payment';
import { allCountries, unitedStates } from '../../../../../shared/countries';
import states from '../../../../../shared/states';
import { useAuthentication } from '../../../wrappers/Authentication';

const CheckoutForm = (props) => {
  const { user } = useAuthentication();

  const {
    cart,
    clearCart,
  } = props;

  const [apiErrors, setAPIErrors] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const history = useHistory();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const {
      paymentMethod,
      shippingAccountNumber,
      purchaseOrderID,
      customerID,
    } = data;

    const order = {
      ...cart,
      paymentMethod,
      purchaseOrderID,
      orderedBy: {
        firstName: data['orderedBy.firstName'],
        lastName: data['orderedBy.lastName'],
        phone: data['orderedBy.phone'],
      },
      customerID,
      billingAddress: {
        city: data['billingAddress.city'],
        country: data['billingAddress.country'],
        line1: data['billingAddress.line1'],
        line2: data['billingAddress.line2'],
        state: data['billingAddress.state'],
        zip: data['billingAddress.zip'],
      },
      shippingAccountNumber,
      shippingAddress: {
        companyName: data['shippingAddress.companyName'],
        city: data['shippingAddress.city'],
        country: data['shippingAddress.country'],
        line1: data['shippingAddress.line1'],
        line2: data['shippingAddress.line2'],
        state: data['shippingAddress.state'],
        zip: data['shippingAddress.zip'],
      },
    };

    if (paymentMethod === 'credit-card') {
      const tokenResult = await stripe.createToken(elements.getElement(CardElement), {
        address_line1: data['billingAddress.line1'],
        address_line2: data['billingAddress.line2'],
        address_city: data['billingAddress.city'],
        address_state: data['billingAddress.state'],
        address_zip: data['billingAddress.zip'],
      });

      const { token: { id: tokenID } = {} } = tokenResult;
      if (tokenID) order.stripePaymentSource = tokenID;
    }

    const response = await requests.post({
      url: `${process.env.API_URL}/api/orders`,
      options: {
        body: JSON.stringify(order),
      },
    });

    const json = await response.json();

    if (response.ok) {
      clearCart();

      history.push({
        pathname: '/order-confirmation',
        state: { data: json.doc },
      });
    } else {
      const { errors } = json;

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      if (errors) setAPIErrors(errors);
      else setAPIErrors([{ message: 'There was a problem placing your order. Please contact us for more information.' }]);
    }

    setIsLoading(false);
  };

  if (user) {
    const {
      firstName,
      lastName,
      phone,
      billingAddress,
      shippingAddress,
    } = user || {};

    return (
      <Form
        onSubmit={onSubmit}
        isLoading={isLoading}
        apiErrors={apiErrors}
        defaultValues={{
          'orderedBy.firstName': {
            value: firstName,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'First Name is required',
            }),
          },
          'orderedBy.lastName': {
            value: lastName,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Last Name is required',
            }),
          },
          'orderedBy.phone': {
            value: phone,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Phone is required',
            }),
          },
          customerID: {
            value: null,
          },
          'billingAddress.line1': {
            value: billingAddress?.line1,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Billing Address Line 1 is required',
            }),
          },
          'billingAddress.line2': {
            value: billingAddress?.line2,
          },
          'billingAddress.city': {
            value: billingAddress?.city,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Billing City is required',
            }),
          },
          'billingAddress.state': {
            value: billingAddress?.state,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Billing State is required',
            }),
          },
          'billingAddress.country': {
            value: billingAddress?.country || 'United States',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Billing Country is required',
            }),
          },
          'billingAddress.zip': {
            value: billingAddress?.zip,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Billing Zip is required',
            }),
          },
          'shippingAddress.companyName': {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Company Name is required',
            }),
          },
          shippingAccountNumber: {
            value: null,
            validation: () => ({
              isValid: true, // TODO: write string validation function
            }),
          },
          'shippingAddress.line1': {
            value: shippingAddress?.line1,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Shipping Line 1 is required',
            }),
          },
          'shippingAddress.line2': {
            value: shippingAddress?.line2,
          },
          'shippingAddress.city': {
            value: shippingAddress?.city,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Shipping City is required',
            }),
          },
          'shippingAddress.state': {
            value: shippingAddress?.state,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Shipping State is required',
            }),
          },
          'shippingAddress.country': {
            value: shippingAddress?.country || 'United States',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Shipping Country is required',
            }),
          },
          'shippingAddress.zip': {
            value: shippingAddress?.zip,
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Shipping Zip is required',
            }),
          },
          paymentMethod: {
            value: 'credit-card',
          },
          purchaseOrderID: {
            value: null,
          },
        }}
      >
        <Errors className={classes.errors} />
        <div className={classes.contentWrapper}>
          <ul className={classes.sections}>
            <li className={classes.section}>
              <BlockContainer className={classes.blockContainer} />
              <div className={classes.sectionContent}>
                <h2 className={classes.sectionTitle}>
                  Basic Info
                </h2>
                <MarginGrid
                  className={classes.marginGrid}
                  forceWithOverflow
                >
                  <Input
                    type="text"
                    name="orderedBy.firstName"
                    id="orderedBy.firstName"
                    label="First Name"
                    required
                  />
                  <Input
                    type="text"
                    name="orderedBy.lastName"
                    id="orderedBy.lastName"
                    label="Last Name"
                    required
                  />
                  <Input
                    type="text"
                    name="orderedBy.phone"
                    id="orderedBy.phone"
                    label="Phone"
                    required
                  />
                  <Input
                    type="text"
                    name="customerID"
                    id="customerID"
                    label="Customer Number (optional)"
                  />
                </MarginGrid>
              </div>
            </li>
            <li className={classes.section}>
              <BlockContainer className={classes.blockContainer} />
              <div className={classes.sectionContent}>
                <h2 className={classes.sectionTitle}>Billing Address</h2>
                <MarginGrid
                  className={classes.marginGrid}
                  forceWithOverflow
                >
                  <Input
                    type="text"
                    name="billingAddress.line1"
                    id="billingAddress.line1"
                    label="Address Line 1"
                    required
                  />
                  <Input
                    type="text"
                    name="billingAddress.line2"
                    id="billingAddress.line2"
                    label="Address Line 2"
                  />
                  <Input
                    type="text"
                    name="billingAddress.city"
                    id="billingAddress.city"
                    label="City"
                    required
                  />
                  <Select
                    type="text"
                    name="billingAddress.state"
                    id="billingAddress.state"
                    label="State"
                    options={states}
                    required
                  />
                  <Input
                    type="number"
                    name="billingAddress.zip"
                    id="billingAddress.zip"
                    label="Zip Code"
                    required
                  />
                  <Select
                    type="text"
                    name="billingAddress.country"
                    id="billingAddress.country"
                    label="Country"
                    options={[...allCountries]}
                    required
                  />
                </MarginGrid>
              </div>
            </li>
            <li className={classes.section}>
              <BlockContainer className={classes.blockContainer} />
              <div className={classes.sectionContent}>
                <h2 className={classes.sectionTitle}>
                  Shipping Address
                </h2>
                <MarginGrid
                  className={classes.marginGrid}
                  forceWithOverflow
                >
                  <Input
                    type="text"
                    name="shippingAddress.companyName"
                    id="shippingAddress.companyName"
                    label="Company Name"
                    required
                  />
                  <Input
                    type="text"
                    name="shippingAccountNumber"
                    id="shippingAccountNumber"
                    label="Shipping Account Number (optional)"
                  />
                  <Input
                    type="text"
                    name="shippingAddress.line1"
                    id="shippingAddress.line1"
                    label="Address Line 1"
                    required
                  />
                  <Input
                    type="text"
                    name="shippingAddress.line2"
                    id="shippingAddress.line2"
                    label="Address Line 2"
                  />
                  <Input
                    type="text"
                    name="shippingAddress.city"
                    id="shippingAddress.city"
                    label="City"
                    required
                  />
                  <Select
                    type="text"
                    name="shippingAddress.state"
                    id="shippingAddress.state"
                    label="State"
                    options={states}
                    required
                  />
                  <Input
                    type="number"
                    name="shippingAddress.zip"
                    id="shippingAddress.zip"
                    label="Zip Code"
                    required
                  />
                  <Input
                    type="text"
                    name="shippingAddress.country"
                    id="shippingAddress.country"
                    label="Country"
                    options={[{ ...unitedStates }]}
                    disabled
                    required
                  />
                </MarginGrid>
              </div>
            </li>
            <li className={classes.section}>
              <BlockContainer className={classes.blockContainer} />
              <div className={classes.sectionContent}>
                <h2 className={classes.sectionTitle}>Payment</h2>
                <Payment
                  CardElement={CardElement}
                  user={user}
                />
              </div>
            </li>
          </ul>
          <div className={classes.summaryWrapper}>
            <Summary
              {...cart}
              action={(
                <Submit label="Checkout" />
              )}
            />
          </div>
        </div>
        <StickyFooter className={classes.mobileCTA}>
          <Submit label="Checkout" />
        </StickyFooter>
      </Form>
    );
  }
  return null;
};

CheckoutForm.propTypes = {
  cart: PropTypes.shape({}).isRequired,
  clearCart: PropTypes.func.isRequired,
};

export default CheckoutForm;
