import { createUseStyles } from 'react-jss';
import { hexToRGBA } from '@trbl/utils';
import vars, { base, colors, zIndex } from '../../css/vars';
import { resetButton } from '../../css/other';
import { midBreak } from '../../css/queries';

export default createUseStyles({
  header: {
    position: 'sticky',
    top: '0',
    display: 'flex',
    zIndex: zIndex.modal,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${base(2)} ${base(4)}`, // coordinate with 'EditTheme' height, and other 'position: sticky' components
    transition: `all linear ${vars.transTime}ms`,
    '& > *:not(:last-child)': {
      marginRight: base(2),
    },
    ...midBreak({
      padding: `${base()} ${base()}`, // coordinate with 'EditTheme' height, and other 'position: sticky' components
      '& > *:not(:last-child)': {
        marginRight: base(),
      },
    }),
  },
  hasScrolled: {
    backgroundColor: hexToRGBA(colors.lighterGray, 0.9),
  },
  logoWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    marginRight: base(),
    '& > *:not(:last-child)': {
      marginRight: base(0.5), // coordinate with marginRight of 'Breadcrumb' separator
    },
  },
  logo: {
    height: base(1.5),
    width: 'auto',
  },
  breadcrumbs: {
    ...midBreak({
      display: 'none',
    }),
  },
  menu: {
    padding: '0',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      width: base(), // coordinate with 'EditTheme' height, and other 'position: sticky' components
      height: base(), // coordinate with 'EditTheme' height, and other 'position: sticky' components
      '&:not(:last-child)': {
        marginRight: base(2),
        ...midBreak({
          marginRight: base(),
        }),
      },
    },
  },
  modalToggler: {
    ...resetButton,
    cursor: 'pointer',
  },
  accountLink: {
    minWidth: base(),
    width: base(6),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    color: colors.gray,
    '&:hover': {
      color: colors.darkGray,
    },
  },
  accountLinkActive: {
    color: colors.darkGray,
  },
});
