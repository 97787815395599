import React, { useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import BlockContainer from '../../components/BlockContainer';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import useStyles from './css';

const NotFound = () => {
  const classes = useStyles();
  const { setCrumbs } = useBreadcrumbs();

  useEffect(() => {
    setCrumbs([{
      label: '404',
    }]);
  }, [setCrumbs]);

  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found</title>
        <meta
          name="description"
          content="Page not found."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.notFound}>
        <BlockContainer>
          <h1>Not Found</h1>
        </BlockContainer>
      </div>
    </Fragment>
  );
};

export default NotFound;
