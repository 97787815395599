import { createUseStyles } from 'react-jss';
import vars, { colors, base, shadows } from '../../css/vars';

export default createUseStyles({
  arrowNavigation: {
    backgroundColor: colors.white,
    boxShadow: shadows.xs,
    borderRadius: `${vars.borderRadius}px`,
    display: 'inline-flex',
    alignItems: 'center',
    padding: ({ size }) => `${size === 's' ? base(0.25) : base(0.5)}`,
  },
  control: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
  label: {
    color: colors.darkGray,
    margin: ({ size }) => `0 ${size === 's' ? base(0.5) : base()}`,
    fontWeight: 'bold',
  },
});
