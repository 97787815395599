import { createUseStyles } from 'react-jss';
import { base, colors } from '../../css/vars';

export default createUseStyles({
  input: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  checkbox: {
    flexGrow: 1,
  },
  clear: {
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
});
