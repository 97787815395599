import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../css/vars';
import { largeBreak, midBreak } from '../../../css/queries';

export default createUseStyles({
  sidebar: {
    marginBottom: base(2),
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...largeBreak({
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
      ...midBreak({
        marginBottom: base(),
      }),
    }),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
  wallColor: {
    display: 'flex',
    alignItems: 'center',
  },
  wallColorClear: {
    cursor: 'pointer',
    flexShrink: '0',
    marginLeft: base(0.5),
    '&:hover path': {
      stroke: colors.darkGray,
    },
  },
});
