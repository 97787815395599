import React from 'react';
import PropTypes from 'prop-types';
import { textures } from '@two90signs/290-renderer';
import SelectOverlay from '../../forms/fields/SelectOverlay';
import useStyles from './css';
import Tooltip from '../../components/Tooltip';

const { metallic } = textures;

const ColorSwatch = (props) => {
  const {
    color,
    img,
    label,
    currentValue,
    value,
    name,
    id,
    onChange,
    isMetallic,
  } = props;

  const classes = useStyles({
    color,
    img,
    isMetallic,
    metallicPNG: metallic.png,
    metallicWidth: metallic.width,
    metallicHeight: metallic.height,
  });

  return (
    <Tooltip
      label={`${value} ${label}`}
      className={classes.colorSwatch}
    >
      {isMetallic && (
        <div className={classes.metallic} />
      )}
      <SelectOverlay
        {...{
          type: 'radio',
          value,
          name,
          currentValue,
          id,
          className: classes.selectOverlay,
          onChange,
        }}
      >
        <div className={classes.colorSwatchInner} />
      </SelectOverlay>
    </Tooltip>
  );
};

ColorSwatch.defaultProps = {
  color: undefined,
  img: undefined,
  label: undefined,
  name: undefined,
  value: undefined,
  currentValue: undefined,
  id: undefined,
  onChange: undefined,
  isMetallic: undefined,
};

ColorSwatch.propTypes = {
  color: PropTypes.string,
  img: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  currentValue: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isMetallic: PropTypes.bool,
};

export default ColorSwatch;
