import { createUseStyles } from 'react-jss';
import { largeBreak, midBreak } from '../../css/queries';
import { base } from '../../css/vars';

export default createUseStyles({
  editTheme: {
    marginBottom: 0,
    display: 'flex',
    paddingBottom: base(2),
    ...largeBreak({
      flexDirection: 'column',
      paddingBottom: '0',
    }),
  },
  contentWrapper: {
    position: 'sticky',
    width: '66.66%',
    display: 'flex',
    top: base(5.5), // coordinate with 'Header' height
    height: `calc(100vh - ${base(8)})`, // coordinate with 'Header' height
    ...largeBreak({
      position: 'static',
      top: 'unset',
      width: '100%',
      marginBottom: base(),
    }),
    ...midBreak({
      height: 'unset',
    }),
  },
  blockContainer: {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '0',
  },
  sidebarWrapper: {
    position: 'relative',
    marginTop: base(2),
    width: '33.33%',
    flexShrink: '0',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...largeBreak({
      overflow: 'visible',
      marginTop: 0,
      width: `calc(100% + ${base(4)})`, // coordinate with 'BlockContainer' negative margins
      marginLeft: base(-2), // coordinate with 'BlockContainer' negative margins
      marginRight: base(-2), // coordinate with 'BlockContainer' negative margins
    }),
    ...midBreak({
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    }),
  },
  wallOverlay: {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.075) 100%)',
    pointerEvents: 'none',
  },
});
