import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';
import useStyles from './css';

const CircleIcon = (props) => {
  const { color, rotation, className, size } = props;
  const iconClasses = useIconStyles({ color, rotation });
  const classes = useStyles();

  return (
    <svg
      className={[
        classes.recording,
        iconClasses.wrapper,
        className,
        size && iconClasses[size],
      ].filter(Boolean).join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <circle
        className={iconClasses.fill}
        cx="9"
        cy="9"
        r="8.5"
      />
    </svg>
  );
};

CircleIcon.defaultProps = {
  color: undefined,
  rotation: undefined,
  className: undefined,
  size: undefined,
};

CircleIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default CircleIcon;
