import React, { Fragment } from 'react';

export default {
  techSupportEmailAddress: 'marketing@290signs.com',
  storeMessage: 'Need something else? In addition to our Essentials customized sign collection offering, the My290 Store offers a complete selection of off-the-shelf sign-related products.',
  signSystemChangeMessage: (
    <Fragment>
      <div>
        <b>
          {'Attention: '}
        </b>
        <span>
          {'Changing the sign system could potentially reset '}
          <b>
            copy
          </b>
          {', '}
          <b>
            copy style
          </b>
          {', '}
          <b>
            copy case
          </b>
          {', '}
          <b>
            insert type
          </b>
          {', '}
          <b>
            backer shape
          </b>
          {', and/or '}
          <b>
            backer finish
          </b>
          .
        </span>
      </div>
      <div>
        Press
        <b>
          {' enter '}
        </b>
        to confirm, or
        <b>
          {' esc '}
        </b>
        to cancel.
      </div>
    </Fragment>
  ),
};
