import React, { useRef, useState, useEffect, Fragment } from 'react';
import SlickSlider from 'react-slick';
import { useWindowInfo } from '@faceless-ui/window-info';
import PropTypes from 'prop-types';
import vars from '../../css/vars';
import ArrowIcon from '../../icons/ArrowIcon';
import MarginGrid from '../MarginGrid';
import Card from '../Card';
import useStyles from './css';
import Loader from '../Loader';

const CardSlider = (props) => {
  const {
    title,
    body,
    ctas,
    cards,
    isLoading,
    noResults,
  } = props;

  const { breakpoints: { l: largeBreak } } = useWindowInfo();

  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const sliderSettings = {
    arrows: false,
    infinite: !largeBreak,
    slidesToShow: cards?.length <= 3 ? cards.length : 3,
    slidesToScroll: 1,
    ref: (ref) => { sliderRef.current = (ref); },
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
    speed: vars.transTime,
  };

  useEffect(() => {
    if (Array.isArray(cards) && cards.length > 0) {
      setProgressPercentage(((currentIndex + 1) / cards.length) * 100);
    }
  }, [currentIndex, cards]);

  const useSlickSlider = Array.isArray(cards) && cards.length > 3;
  const SliderElement = useSlickSlider ? SlickSlider : 'div';

  const classes = useStyles({ progressPercentage, isLoading, isUsingSlickSlider: useSlickSlider });

  return (
    <div className={classes.slider}>
      <div className={classes.content}>
        {useSlickSlider && (
          <div className={classes.controls}>
            <div className={classes.progress}>
              <div className={classes.progressBar} />
            </div>
            <div className={classes.prevNext}>
              <button
                className={classes.control}
                onClick={() => sliderRef.current.slickPrev()}
                type="button"
              >
                <ArrowIcon color="gray" />
              </button>
              <button
                className={classes.control}
                onClick={() => sliderRef.current.slickNext()}
                type="button"
              >
                <ArrowIcon
                  color="gray"
                  rotation={180}
                />
              </button>
            </div>
          </div>
        )}
        {title && (
          <h2>
            {title}
          </h2>
        )}
        {body && (
          <span>
            {body}
          </span>
        )}
        {ctas && (
          <div className={classes.desktopCTA}>
            <MarginGrid>
              {ctas}
            </MarginGrid>
          </div>
        )}
      </div>
      <div
        className={[
          classes.sliderWrapper,
          (isLoading || (!isLoading && !cards.length > 0)) && classes.wrapperWithBackground,
        ].filter(Boolean).join(' ')}
      >
        {!isLoading ? (
          <Fragment>
            {Array.isArray(cards) && cards.length > 0 ? (
              <SliderElement
                {...useSlickSlider
                  ? { ...sliderSettings }
                  : { className: classes.sliderFallback }
                }
              >
                {cards.map((card, index) => (
                  <div
                    key={index}
                    className={classes.slide}
                  >
                    <Card
                      className={classes.card}
                      {...card}
                    />
                  </div>
                ))}
              </SliderElement>
            ) : (
              <div className={classes.noResults}>
                {noResults}
              </div>
            )}
          </Fragment>
        ) : (
          <Loader absolute />
        )}
      </div>
      {ctas && (
        <div className={classes.mobileCTA}>
          <MarginGrid>
            {ctas}
          </MarginGrid>
        </div>
      )}
    </div>
  );
};

CardSlider.defaultProps = {
  title: undefined,
  body: undefined,
  ctas: undefined,
  cards: undefined,
  isLoading: undefined,
  noResults: undefined,
};

CardSlider.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  ctas: PropTypes.node,
  cards: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        linkTitleTo: PropTypes.string,
        image: PropTypes.string,
      }),
    ),
  ]),
  isLoading: PropTypes.bool,
  noResults: PropTypes.node,
};

export default CardSlider;
