import { createUseStyles } from 'react-jss';
import vars, { colors, base, bodyFontSize } from '../../../css/vars';
import { small } from '../../../css/type';
import { inputShareables } from '../../../css/other';

export default createUseStyles({
  '@keyframes onAutoFillStart': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  '@keyframes onAutoFillCancel': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  input: ({ type }) => ({
    width: '100%',
    ...type === 'hidden' ? {
      height: 0,
      overflow: 'hidden',
    } : {},
  }),
  label: {
    position: 'absolute',
    top: '50%',
    left: base(),
    transform: 'translate3d(0, -50%, 0)',
    transition: `${vars.defaultTransTime}ms ${vars.defaultTransCurve}`,
    color: colors.darkGray,
    pointerEvents: 'none',
    fontWeight: 'bold',
  },
  labelActive: {
    composes: '$label',
    top: base(),
    color: colors.gray,
    extend: small,
    fontWeight: 'normal',
  },
  labelDisabled: {
    composes: '$label',
    color: colors.gray,
  },
  labelError: {
    composes: '$label',
    color: colors.red,
  },
  htmlLabel: {
    position: 'relative',
    borderRadius: `${vars.borderRadius}px`,
    backgroundColor: colors.white,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    ...inputShareables,
  },
  htmlInput: {
    fontSize: `${bodyFontSize}px`,
    backgroundColor: 'transparent',
    color: colors.darkGray,
    fontWeight: 'bold',
    padding: {
      top: base(1.5),
      bottom: base(0.5),
      left: base(),
      right: base(),
    },
    border: 'none',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: colors.gray,
    },
  },
});
