import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { desktopBreak, midBreak } from '../../css/queries';

export default createUseStyles({
  container: {
    marginBottom: base(2),
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    ...desktopBreak({
      flexDirection: 'column',
    }),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: base(2),
    ...midBreak({
      marginTop: base(),
      flexDirection: 'column',
      alignItems: 'flex-start',
    }),
  },
  headerTitle: {
    flexGrow: 1,
    marginBottom: 0,
    marginRight: base(2),
    ...midBreak({
      marginRight: 0,
      marginBottom: base(),
    }),
  },
  table: {
    flexGrow: '1',
    marginRight: base(4),
    width: '66.66%',
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...desktopBreak({
      width: '100%',
      marginRight: '0',
      marginBottom: base(),
    }),
  },
  summaryWrapper: {
    flexShrink: '0',
    position: 'sticky',
    width: '33.33%',
    top: base(7),
    ...desktopBreak({
      width: '100%',
    }),
  },
  mobileCTA: {
    display: 'none',
    ...midBreak({
      display: 'block',
    }),
  },
  mobileCTAButton: {
    width: '100%',
  },
});
