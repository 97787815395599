import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';

export default createUseStyles({
  lines: {
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
  },
});
