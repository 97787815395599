import React from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@faceless-ui/modal';
import Button from '../Button';
import arcImg from '../../../assets/images/arc-thumbnail.jpg';
import klikImg from '../../../assets/images/klik-thumbnail.jpg';
import slideImg from '../../../assets/images/slide-thumbnail.jpg';
import SelectOverlay from '../../forms/fields/SelectOverlay';
import useStyles from './css';
import VideoModal from './VideoModal';

const images = {
  A: arcImg,
  K: klikImg,
  S: slideImg,
};

const videoIDs = {
  A: '1iAYwfd0FYQ',
  K: 'Y1Cul0PAYNQ',
  S: 'bQ5LzlSECXc',
};

const SignSystemCard = (props) => {
  const {
    system,
    label,
    onChange,
    onClick,
    currentValue,
  } = props;

  const classes = useStyles();
  const { open: openModal } = useModal();
  const id = `signSystem-${system}`;

  return (
    <SelectOverlay
      {...{
        type: 'radio',
        value: system,
        name: 'signSystem',
        currentValue,
        id,
        onClick,
        onChange,
      }}
    >
      <div className={classes.card}>
        <div className={classes.cardWrapper}>
          <h1 className={classes.title}>
            {label}
          </h1>
          <img
            className={classes.img}
            src={images[system]}
            alt={`${label} sign system`}
          />
          <Button
            className={classes.cta}
            size="s"
            color="lightGray"
            onClick={(e) => {
              e.stopPropagation();
              openModal(id);
            }}
            label="See video"
          />
          <VideoModal
            label={label}
            modalSlug={id}
            videoID={videoIDs[system]}
          />
        </div>
      </div>
    </SelectOverlay>
  );
};

SignSystemCard.defaultProps = {
  label: undefined,
  system: undefined,
  onChange: undefined,
  onClick: undefined,
  currentValue: undefined,
};

SignSystemCard.propTypes = {
  label: PropTypes.oneOf([
    'Arc',
    'Klik',
    'Slide',
  ]),
  system: PropTypes.oneOf([
    'A',
    'K',
    'S',
  ]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  currentValue: PropTypes.string,
};

export default SignSystemCard;
