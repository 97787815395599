import { useRef, useEffect, useCallback } from 'react';

export default (callback, delay, deps) => {
  const timerRef = useRef(null);
  const debouncedFn = useCallback(callback, deps);

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );

  return (...args) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      debouncedFn(...args);
    }, delay);
  };
};
