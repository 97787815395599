import React, { Fragment } from 'react';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { specSheets } from '@two90signs/290-renderer';
import PropTypes from 'prop-types';
import ChevronIcon from '../../../icons/ChevronIcon';
import ShadowBox from '../../../components/ShadowBox';
import Radio from '../../../forms/fields/Radio';
import Checkbox from '../../../forms/fields/Checkbox';
import useStyles from './css';
import Notice from '../../../components/Notice';

const { signCategories, signSystems } = specSheets;

const Filter = (props) => {
  const {
    themeSignSystem,
    selectedSignSystem,
    setSelectedSignSystem,
    selectedCategories,
    setSelectedCategories,
    dispatchSelectedSigns,
    allSignsAreSelected,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.filter}>
      <ShadowBox color="white">
        <Checkbox
          {...{
            name: 'selectAll',
            label: allSignsAreSelected ? 'Deselect all' : 'Select all',
            value: allSignsAreSelected,
            onChange: () => {
              if (!allSignsAreSelected) dispatchSelectedSigns({ type: 'SELECT_ALL' });
              else dispatchSelectedSigns({ type: 'DESELECT_ALL' });
            },
          }}
        />
      </ShadowBox>
      <ShadowBox color="white">
        <Collapsible openOnInit>
          {({ isOpen }) => (
            <Fragment>
              <CollapsibleToggler
                className={classes.label}
                htmlAttributes={{ type: 'button' }}
              >
                <strong className={classes.labelTitle}>
                  Sign system
                </strong>
                <div className={classes.icon}>
                  <ChevronIcon rotation={isOpen ? 180 : 0} />
                </div>
              </CollapsibleToggler>
              <CollapsibleContent>
                <div className={classes.contentWrapper}>
                  <ul className={classes.list}>
                    {Object.keys(signSystems).map((key, index) => {
                      const system = signSystems[key];
                      const { label } = system;

                      return (
                        <li
                          className={classes.item}
                          key={index}
                        >
                          <Radio
                            {...{
                              key: index,
                              className: classes.radio,
                              name: 'signSystem',
                              label,
                              value: key,
                              onChange: (incomingSignSystem) => setSelectedSignSystem(incomingSignSystem),
                              checked: key === selectedSignSystem,
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  {selectedSignSystem !== themeSignSystem && (
                    <Notice message={`the sign system you selected (${signSystems[selectedSignSystem]?.label}) is different than your project (${signSystems[themeSignSystem]?.label}).`} />
                  )}
                </div>
              </CollapsibleContent>
            </Fragment>
          )}
        </Collapsible>
      </ShadowBox>
      <ShadowBox color="white">
        <Collapsible openOnInit>
          {({ isOpen }) => (
            <Fragment>
              <CollapsibleToggler
                className={classes.label}
                htmlAttributes={{ type: 'button' }}
              >
                <strong className={classes.labelTitle}>
                  Filter by category
                </strong>
                <div className={classes.icon}>
                  <ChevronIcon rotation={isOpen ? 180 : 0} />
                </div>
              </CollapsibleToggler>
              <CollapsibleContent>
                <ul className={classes.list}>
                  {Object.keys(signCategories).map((key, index) => {
                    const category = signCategories[key];
                    const { label } = category;

                    return (
                      <li
                        key={index}
                        className={classes.item}
                      >
                        <Checkbox
                          {...{
                            key: index,
                            name: `selectedCategories.${key}`,
                            label,
                            onChange: (incomingValue) => {
                              const withChangedCategory = { ...selectedCategories };
                              withChangedCategory[key] = incomingValue;
                              setSelectedCategories(withChangedCategory);
                            },
                            value: selectedCategories[key],
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </CollapsibleContent>
            </Fragment>
          )}
        </Collapsible>
      </ShadowBox>
    </div>
  );
};

Filter.defaultProps = {
  themeSignSystem: undefined,
  selectedSignSystem: undefined,
  setSelectedSignSystem: undefined,
  selectedCategories: undefined,
  setSelectedCategories: undefined,
  dispatchSelectedSigns: undefined,
  allSignsAreSelected: undefined,
};

Filter.propTypes = {
  themeSignSystem: PropTypes.string,
  selectedSignSystem: PropTypes.string,
  setSelectedSignSystem: PropTypes.func,
  selectedCategories: PropTypes.shape({}),
  setSelectedCategories: PropTypes.func,
  dispatchSelectedSigns: PropTypes.func,
  allSignsAreSelected: PropTypes.bool,
};

export default Filter;
