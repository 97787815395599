import React, { forwardRef, Fragment } from 'react';
import { specSheets } from '@two90signs/290-renderer';
import PropTypes from 'prop-types';
import BackerSpecs from './BackerSpecs';
import InsertSpecs from './InsertSpecs';
import useStyles from './css';
import CompanyLogo from '../CompanyLogo';
import Price from '../Price';
import { useAuthentication } from '../../wrappers/Authentication';
import formatDateTime from '../../utilities/formatDateTime';

const {
  signSystems,
  endCapStyles,
} = specSheets;

const SpecDocument = forwardRef((props, incomingRef) => {
  const { user } = useAuthentication();

  const {
    templateID,
    showCopyCase,
    showCopyPosition,
    specs,
    insertArea,
    backingArea,
    partNumber,
    quantity,
    renderingArea,
    projectName,
    showSignStandards,
    price,
    signTemplate,
    isCoverPage,
  } = props;

  const {
    signSystem,
    mountingStyle,
    endCapStyle,
    edgeRailColor,
    endCapColor,
    frameColor,
    accentStripColor,
    baseplateFinish,
    inserts,
    backers,
  } = specs || {};

  const classes = useStyles();

  // If any insert is 'ADA' then the standards are applicable
  let adaStandardsApply = false;
  if (Array.isArray(inserts) && inserts.length > 0) {
    inserts.forEach((insert) => {
      const { type } = insert;
      if (type === 'ADA') adaStandardsApply = true;
    });
  }

  const headerInsert = Array.isArray(inserts) && inserts.length > 0 && inserts[0];

  // sign template 25 and 28 has a blank accent insert 2 out-of-the-box, so use insert 3 specs
  let accentInsertToUse = Array.isArray(inserts) && inserts.length > 0 && inserts[1];
  if (signTemplate === '25' || signTemplate === '28') accentInsertToUse = inserts[2]; // eslint-disable-line prefer-destructuring

  const endCapLabel = endCapStyles?.[endCapStyle]?.label;

  return (
    <section
      ref={incomingRef}
      className={classes.specDocument}
    >
      <style
        type="text/css"
        media="print"
      >
        {'@page { size: letter landscape; margin: 0; -webkit-print-color-adjust: exact;}'}
      </style>
      <div className={classes.content}>
        <div className={classes.specs}>
          <div className={classes.specGroup}>
            {partNumber && (
              <div className={classes.spec}>
                <b>
                  Part Number:
                </b>
                <b>
                  {partNumber}
                </b>
              </div>
            )}
            {!partNumber && signSystem && (
              <div className={classes.spec}>
                <b>
                  Sign System:
                </b>
                <b>
                  {signSystems?.[signSystem]?.label || signSystem}
                </b>
              </div>
            )}
            {insertArea && (
              <div className={classes.spec}>
                <div>
                  Insert Area:
                </div>
                <div>
                  {insertArea}
                </div>
              </div>
            )}
            {backingArea && (
              <div className={classes.spec}>
                <div>
                  Overall Size:
                </div>
                <div>
                  {backingArea}
                </div>
              </div>
            )}
            {signSystem === 'A' && (
              <div className={classes.spec}>
                <div>
                  Edge Rail Color:
                </div>
                <div>
                  {edgeRailColor}
                </div>
              </div>
            )}
            {(signSystem === 'A' && inserts.length > 0) && (
              <div className={classes.spec}>
                <div>
                  Accent Strip Color:
                </div>
                <div>
                  {accentStripColor}
                </div>
              </div>
            )}
            {signSystem === 'S' && (
              <Fragment>
                <div className={classes.spec}>
                  <div>
                    End Cap Style:
                  </div>
                  <div>
                    {`${endCapStyle}${endCapLabel ? ` - ${endCapLabel}` : ''}`}
                  </div>
                </div>
                <div className={classes.spec}>
                  <div>
                    End Cap Color:
                  </div>
                  <div>
                    {endCapColor}
                  </div>
                </div>
              </Fragment>
            )}
            {signSystem === 'A' && (
              <div className={classes.spec}>
                <div>
                  Frame Color:
                </div>
                <div>
                  {frameColor}
                </div>
              </div>
            )}
            {signSystem === 'K' && (
              <div className={classes.spec}>
                <div>
                  Baseplate Color:
                </div>
                <div>
                  {baseplateFinish}
                </div>
              </div>
            )}
            {mountingStyle && (
              <div className={classes.spec}>
                <div>
                  Mount:
                </div>
                <div>
                  {mountingStyle}
                </div>
              </div>
            )}
            <div className={classes.spec}>
              <div>
                Price:
              </div>
              <div>
                <Price
                  price={price}
                  useLineBreak
                />
              </div>
            </div>
          </div>
          <div className={classes.specGroup}>
            <div className={classes.spec}>
              <b>
                INSERT: A
              </b>
            </div>
            {headerInsert && (
              <InsertSpecs
                templateID={templateID}
                isCoverPage={isCoverPage}
                signSystem={signSystem}
                insert={headerInsert}
                classes={classes}
                showCopyCase={showCopyCase}
                showCopyPosition={showCopyPosition}
              />
            )}
          </div>
          <div className={classes.specGroup}>
            <div className={classes.spec}>
              <b>
                INSERT: B
              </b>
            </div>
            {accentInsertToUse && (
              <InsertSpecs
                templateID={templateID}
                isCoverPage={isCoverPage}
                insert={accentInsertToUse}
                classes={classes}
                showCopyCase={showCopyCase}
                showCopyPosition={showCopyPosition}
              />
            )}
          </div>
          <div className={classes.specGroup}>
            <div className={`${classes.spec}`}>
              <b>
                Backer:
              </b>
              <b>
                1
              </b>
            </div>
            {Array.isArray(backers) && backers.length > 0 && backers[0] && (
              <BackerSpecs
                signSystem={signSystem}
                backer={backers[0]}
                classes={classes}
              />
            )}
          </div>
          <div className={`${classes.specGroup}`}>
            <div className={`${classes.spec}`}>
              <b>
                Backer:
              </b>
              <b>
                2
              </b>
            </div>
            {Array.isArray(backers) && backers.length > 0 && backers[1] && (
              <BackerSpecs
                backer={backers[1]}
                classes={classes}
              />
            )}
          </div>
        </div>
        <div className={classes.signArea}>
          {renderingArea && renderingArea}
        </div>
      </div>
      <div className={classes.sidebar}>
        <div className={classes.sidebarHeader}>
          <CompanyLogo className={classes.logo} />
          <div className={classes.address}>
            <div>
              Grand Rapids, MI 49512
            </div>
            <div>
              800.777.4310 | 616.656.4310
            </div>
            <div>
              F: 800.777.4300 | info@290signs.com
            </div>
          </div>
        </div>
        <div className={classes.sidebarBody}>
          <div>
            <div>
              PROJECT:
            </div>
            <div>
              {projectName}
            </div>
          </div>
          {user && (
            <div>
              <div>
                USER:
              </div>
              <div>
                {user.email}
              </div>
            </div>
          )}
          <div>
            <div>
              DATE:
            </div>
            <div>
              {formatDateTime()}
            </div>
          </div>
          <div>
            <div>
              {`${signSystems[signSystem]?.label} Sign System`}
            </div>
            <div className={classes.schematics}>
              {signSystem === 'S' && (
                <Fragment>
                  <div>
                    <img
                      src={`${process.env.API_URL}/assets/schematics/slide-rails.svg`}
                      alt="Slide rails"
                    />
                  </div>
                  <div>
                    <img
                      src={`${process.env.API_URL}/assets/schematics/slide-inserts.svg`}
                      alt="Slide inserts"
                    />
                  </div>
                  <div>
                    <img
                      src={`${process.env.API_URL}/assets/schematics/slide-endcaps.svg`}
                      alt="Slide endcaps"
                    />
                  </div>
                </Fragment>
              )}
              {signSystem === 'K' && (
                <div>
                  <img
                    src={`${process.env.API_URL}/assets/schematics/klik.svg`}
                    alt="Klik schematic"
                  />
                </div>
              )}
              {signSystem === 'A' && (
                <div>
                  <img
                    src={`${process.env.API_URL}/assets/schematics/arc.svg`}
                    alt="Arc schematic"
                  />
                </div>
              )}
            </div>
            <div className={classes.schematicLabels}>
              {signSystem === 'S' && (
                <Fragment>
                  <div>
                    Rails
                  </div>
                  <div>
                    Inserts
                  </div>
                  <div>
                    Endcaps
                  </div>
                </Fragment>
              )}
              {signSystem === 'K' && (
                <Fragment>
                  <div>
                    Inserts
                  </div>
                  <div>
                    Baseplate
                  </div>
                </Fragment>
              )}
              {signSystem === 'A' && (
                <Fragment>
                  <div>
                    Frame
                  </div>
                  <div>
                    Inserts
                  </div>
                  <div>
                    Edgerails
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          {showSignStandards && (
            <div className={classes.adaSection}>
              <div>
                ADA/ANSI SIGN STANDARDS:
              </div>
              <div className={classes.checkboxes}>
                <div className={classes.checkboxRow}>
                  <div className={classes.checkboxWrapper}>
                    <span
                      className={[
                        classes.checkbox,
                        adaStandardsApply && classes.checkboxChecked,
                      ].filter(Boolean).join(' ')}
                    />
                    <span>
                      Applies
                    </span>
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <span
                      className={[
                        classes.checkbox,
                        !adaStandardsApply && classes.checkboxChecked,
                      ].filter(Boolean).join(' ')}
                    />
                    <span>
                      Doesn&apos;t Apply
                    </span>
                  </div>
                </div>
                <div className={classes.checkboxRow}>
                  <div className={classes.checkboxWrapper}>
                    <span
                      className={[
                        classes.checkbox,
                        adaStandardsApply && classes.checkboxChecked,
                      ].filter(Boolean).join(' ')}
                    />
                    <span>
                      Compliant
                    </span>
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <span
                      className={[
                        classes.checkbox,
                        !adaStandardsApply && classes.checkboxChecked,
                      ].filter(Boolean).join(' ')}
                    />
                    <span>
                      Non-Compliant
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.adaMessage}>
                Due to the ADA/ANSI&apos;s wide interpretation and other factors that may exist, 2/90 cannot affirm compliance in all situations and is hereby removed from responsibility regarding these issues.
              </div>
            </div>
          )}
          <div className={classes.disclaimer}>
            Sign drawing is for illustration purposes only.
          </div>
          {quantity && (
            <div>
              <div>
                QUANTITY:
              </div>
              <div>
                {`${quantity} Sign${quantity > 1 ? 's' : ''}`}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

SpecDocument.defaultProps = {
  templateID: undefined,
  specs: undefined,
  insertArea: undefined,
  backingArea: undefined,
  renderingArea: undefined,
  partNumber: undefined,
  quantity: undefined,
  projectName: undefined,
  showSignStandards: undefined,
  price: undefined,
  showCopyCase: undefined,
  showCopyPosition: undefined,
  signTemplate: undefined,
  isCoverPage: undefined,
};

SpecDocument.propTypes = {
  templateID: PropTypes.string,
  specs: PropTypes.shape({}),
  insertArea: PropTypes.string,
  backingArea: PropTypes.string,
  renderingArea: PropTypes.node,
  partNumber: PropTypes.string,
  quantity: PropTypes.string,
  projectName: PropTypes.string,
  showSignStandards: PropTypes.bool,
  price: PropTypes.number,
  showCopyCase: PropTypes.bool,
  showCopyPosition: PropTypes.bool,
  signTemplate: PropTypes.string,
  isCoverPage: PropTypes.bool,
};

export default SpecDocument;
