import { createUseStyles } from 'react-jss';
import { desktopBreak, midBreak } from '../../css/queries';
import { base, colors } from '../../css/vars';
import { resetButton } from '../../css/other';

export default createUseStyles({
  help: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(0.5),
      },
    }),
  },
  intro: {
    width: '66%',
    ...desktopBreak({
      width: '100%',
    }),
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: base(),
    },
  },
  sectionHeader: {
    marginBottom: base(2),
    '& > *': {
      marginBottom: '0',
    },
    ...midBreak({
      marginTop: base(0.5),
      marginBottom: base(),
    }),
  },
  collapsible: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: base(),
    },
    ...midBreak({
      '&:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
  collapsibleToggler: {
    ...resetButton,
  },
  collapsibleTogglerContent: {
    display: 'flex',
    alignItems: 'center',
  },
  collapsibleTitle: {
    flexGrow: '1',
    textAlign: 'left',
    marginRight: base(),
    color: colors.darkGray,
  },
  collapsibleContent: {
    marginTop: base(),
    '& > *:last-child': {
      marginBottom: '0',
    },
    ...midBreak({
      marginTop: base(0.5),
    }),
  },
});
