import { colors } from '../../css/vars';

const { createUseStyles } = require('react-jss');

export default createUseStyles({
  red: {
    color: colors.red,
  },
  white: {
    color: colors.white,
  },
});
