import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../../css/vars';
import { midBreak } from '../../../../css/queries';

export default createUseStyles({
  item: {
    marginBottom: base(),
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    ...midBreak({
      flexWrap: 'wrap',
    }),
  },
  thumbnail: {
    width: base(4),
    ...midBreak({
      width: '100%',
      marginBottom: base(),
    }),
  },
  content: {
    paddingLeft: base(),
    ...midBreak({
      width: '100%',
      paddingLeft: 0,
      marginBottom: base(),
    }),
  },
  type: {
    fontWeight: 'bold',
    color: colors.darkGray,
  },
  quantity: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: colors.darkGray,
    textAlign: 'right',
    width: base(4),
    ...midBreak({
      marginLeft: 0,
      textAlign: 'left',
      width: '25%',
    }),
  },
  price: {
    textAlign: 'right',
    width: base(8),
    ...midBreak({
      marginLeft: 'auto',
      width: '50%',
    }),
  },
  toggle: {
    paddingLeft: base(1),
    width: base(4),
    textAlign: 'right',
    ...midBreak({
      paddingLeft: 0,
      width: base(2),
    }),
  },
  drawerContent: {
    marginTop: base(2),
    paddingTop: base(2),
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: base(-2),
      top: 0,
      width: `calc(100% + ${base(4)})`,
      height: `${vars.strokeWidth}px`,
      backgroundColor: colors.lightGray,
      ...midBreak({
        width: `calc(100% + ${base(2)})`,
        left: base(-1),
      }),
    },
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...midBreak({
      marginTop: base(),
      paddingTop: base(),
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
});
