import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@faceless-ui/modal';
import { useAuthentication } from '../../../../wrappers/Authentication';
import Button from '../../../Button';
import Submit from '../../../../forms/fields/Submit';
import Input from '../../../../forms/fields/Input';
import useAuthDrawerStyles from '../useAuthDrawerStyles';
import Form from '../../../../forms/Form';
import Errors from '../../../../forms/Errors';

const SignUp = (props) => {
  const { setContent } = props;
  const { setUser } = useAuthentication();
  const authDrawerClasses = useAuthDrawerStyles();
  const { closeAll: closeAllModals } = useModal();

  const onResponse = useCallback((response) => {
    const { status } = response;
    if (status === 201) {
      const { json: { doc: { user: incomingUser } } } = response;
      setUser(incomingUser);
      if (incomingUser) closeAllModals();
    }
  }, [setUser, closeAllModals]);


  return (
    <Fragment>
      <h1>
        Sign up
      </h1>
      <Form
        onResponse={onResponse}
        method="post"
        action={`${process.env.API_URL}/api/users`}
        className={authDrawerClasses.form}
        defaultValues={{
          firstName: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'First Name is required',
            }),
          },
          lastName: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Last Name is required',
            }),
          },
          email: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Email is required',
            }),
          },
          password: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Password is required',
            }),
          },
          passwordConfirm: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Confirm Password is required',
            }),
          },
        }}
        htmlAttributes={{
          autoComplete: 'on',
        }}
      >
        <div>
          <b>
            You’re only one step away!
          </b>
        </div>
        <Errors color="white" />
        <Input
          {...{
            type: 'text',
            name: 'firstName',
            id: 'SignUpDrawerFirstName',
            label: 'First Name',
            required: true,
          }}
        />
        <Input
          {...{
            type: 'text',
            name: 'lastName',
            id: 'SignUpDrawerLastName',
            label: 'Last Name',
            required: true,
          }}
        />
        <Input
          {...{
            type: 'email',
            name: 'email',
            id: 'SignUpDrawerEmail',
            label: 'Email Address',
            required: true,
          }}
        />
        <Input
          {...{
            type: 'password',
            name: 'password',
            id: 'SignUpDrawerPassword',
            label: 'Password',
            required: true,
          }}
        />
        <Input
          {...{
            type: 'password',
            name: 'passwordConfirm',
            id: 'SignUpDrawerPasswordConfirm',
            label: 'Confirm Password',
            required: true,
          }}
        />
        <div>
          <Submit
            color="darkGray"
            className={authDrawerClasses.submit}
            label="Sign up"
          />
        </div>
        <div>
          Already have an account?
          {' '}
          <Button
            kind="text"
            onClick={() => setContent('logIn')}
            label="Sign in here"
          />
          .
        </div>
      </Form>
    </Fragment>
  );
};

SignUp.defaultProps = {
  setContent: undefined,
};

SignUp.propTypes = {
  setContent: PropTypes.func,
};

export default SignUp;
