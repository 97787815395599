import React, { useCallback, useEffect, Fragment, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import Form from '../../forms/Form';
import Errors from '../../forms/Errors';
import Input from '../../forms/fields/Input';
import Submit from '../../forms/fields/Submit';
import Button from '../../components/Button';
import { useAuthentication } from '../../wrappers/Authentication';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

const LogIn = () => {
  const classes = useStyles();

  const {
    isLoggedIn,
    setUser,
  } = useAuthentication();

  const { setCrumbs } = useBreadcrumbs();

  const {
    state: {
      redirect,
    } = {},
  } = useLocation();

  const [willRedirect, setWillRedirect] = useState(redirect);

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  const history = useHistory();

  const onResponse = useCallback(async (response) => {
    const { status } = response;

    if (status === 200) {
      const { json: { user: incomingUser } } = response;

      if (incomingUser) {
        setUser(incomingUser);
        history.push(redirect || '/account');
      }
    }
  }, [setUser, history, redirect]);

  useEffect(() => {
    if (redirect) {
      setWillRedirect(true);
    }
  }, [redirect]);

  if (!willRedirect && isLoggedIn) {
    return <Redirect to="/already-logged-in" />;
  }

  if (willRedirect && isLoggedIn) {
    return <Redirect to={redirect} />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Log In</title>
        <meta
          name="description"
          content="Login to your account."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.logIn}>
        <h1>Log In</h1>
        <Form
          onResponse={onResponse}
          method="post"
          action={`${process.env.API_URL}/api/users/login`}
          className={classes.form}
          defaultValues={{
            email: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Email is required',
              }),
            },
            password: {
              value: '',
              validation: (incomingValue) => ({
                isValid: Boolean(incomingValue),
                message: 'Password is required',
              }),
            },
          }}
          htmlAttributes={{
            autoComplete: 'on',
          }}
        >
          <div>
            <b>
              Already have a My290 account? Welcome back!
            </b>
            <br />
            <br />
            {'If not, welcome in! Create an account '}
            <Button
              to="/signup"
              kind="text"
              label="here"
            />
            .
          </div>
          <Errors />
          <Input
            {...{
              type: 'email',
              name: 'email',
              id: 'LoginEmail',
              label: 'Email Address',
              required: true,
            }}
          />
          <Input
            {...{
              type: 'password',
              name: 'password',
              id: 'LoginPassword',
              label: 'Password',
              required: true,
            }}
          />
          <div>
            <Button
              to="/reset-password"
              kind="text"
              label="Forgot your password?"
            />
          </div>
          <Submit
            className={classes.submit}
            label="Sign in"
          />
        </Form>
      </div>
    </Fragment>
  );
};

export default LogIn;
