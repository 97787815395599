import React, { Fragment, useEffect, useState } from 'react';
import { specSheets } from '@two90signs/290-renderer';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import ShadowBox from '../../../../components/ShadowBox';
import CheckboxBase from '../../../../forms/fields/Checkbox/Base';
import Input from '../../../../forms/fields/Input';
import useStyles from './css';
import LineSpecLink from './LineSpecLink';
import Radio from '../../../../forms/fields/Radio';

const { copySizes, symbols } = specSheets;

const Line = (props) => {
  const {
    signIndex,
    insertIndex,
    currentLineCopy,
    lineIndex,
    showLineIndex,
    onCopyChange,
    copySizes: availableCopySizes,
    allowFlexReverse,
    allowRoomNumber,
    allowSymbol,
    showCopySizeControl,
    showCopyTextControl,
  } = props;

  const {
    copy: lineText,
    copySize: lineTextSize,
    symbol,
    flexReverse,
    roomNumber,
    showRoomNumber,
    showSymbol,
    renderCopy,
  } = currentLineCopy || {};

  const classes = useStyles();
  const { url } = useRouteMatch();
  const [value, setValue] = useState(lineText);

  // force a choice between one or the other when BOTH symbol and room numbers are allowed on this line
  const [useConditionalLogic, setUseConditionalLogic] = useState(() => Boolean(allowSymbol && allowRoomNumber));
  useEffect(() => setUseConditionalLogic(Boolean(allowSymbol && allowRoomNumber)), [allowSymbol, allowRoomNumber]);

  // NOTE: initialization logic does not look deeply
  const [selectedSpec, setSelectedSpec] = useState(() => {
    if (showSymbol) return 'symbol';
    if (showRoomNumber) return 'roomNumber';
    return '';
  });

  return (
    <ShadowBox
      key={lineIndex}
      color="white"
      className={classes.line}
    >
      {showLineIndex && (
        <div className={classes.lineTitle}>
          <b>
            {`Line ${lineIndex + 1}`}
          </b>
        </div>
      )}
      {useConditionalLogic && (
        <Fragment>
          <Radio
            {...{
              onChange: () => {
                setSelectedSpec('roomNumber');
                onCopyChange({
                  signIndex,
                  insertIndex,
                  lineIndex,
                  specs: {
                    showRoomNumber: !(selectedSpec === 'roomNumber'),
                    showSymbol: false,
                  },
                });
              },
              name: `copy-${signIndex}-${insertIndex}-${lineIndex}-selection`,
              label: 'Room number',
              value: roomNumber,
              checked: selectedSpec === 'roomNumber',
            }}
          />
          <Radio
            {...{
              onChange: () => {
                setSelectedSpec('symbol');
                onCopyChange({
                  signIndex,
                  insertIndex,
                  lineIndex,
                  specs: {
                    showSymbol: !(selectedSpec === 'symbol'),
                    showRoomNumber: false,
                  },
                });
              },
              name: `copy-${signIndex}-${insertIndex}-${lineIndex}-selection`,
              label: 'Symbol',
              value: selectedSpec,
              checked: selectedSpec === 'symbol',
            }}
          />
        </Fragment>
      )}
      {((useConditionalLogic && selectedSpec === 'symbol') || (!useConditionalLogic && allowSymbol)) && (
        <LineSpecLink
          label="Symbol"
          to={`${url}/${lineIndex}/symbol`}
          currentValue={symbol}
          currentLabel={symbols[symbol]?.label}
        />
      )}
      {((useConditionalLogic && selectedSpec === 'roomNumber') || (!useConditionalLogic && allowRoomNumber)) && (
        <Input
          {...{
            id: `copy-${signIndex}-${insertIndex}-${lineIndex}-room-number`,
            name: `copy-${signIndex}-${insertIndex}-${lineIndex}-room-number`,
            type: 'text',
            label: 'Room number',
            onChange: (incomingValue) => onCopyChange({
              signIndex,
              insertIndex,
              lineIndex,
              specs: {
                roomNumber: incomingValue,
              },
            }),
            value: roomNumber,
          }}
        />
      )}
      {Array.isArray(availableCopySizes) && availableCopySizes.length > 0 && (
        <Fragment>
          {renderCopy !== false && showCopyTextControl !== false && (
            <Input
              {...{
                id: `copy-${signIndex}-${insertIndex}-${lineIndex}-text`,
                name: `copy-${signIndex}-${insertIndex}-${lineIndex}-text`,
                type: 'text',
                label: 'Copy',
                onChange: (incomingValue) => {
                  onCopyChange({
                    signIndex,
                    insertIndex,
                    lineIndex,
                    specs: {
                      copy: incomingValue,
                    },
                  });

                  setValue(incomingValue);
                },
                value,
              }}
            />
          )}
          {renderCopy !== false && showCopySizeControl !== false && (
            <LineSpecLink
              label="Copy size"
              to={`${url}/${lineIndex}/copy-size`}
              currentValue={lineTextSize}
              currentLabel={copySizes[lineTextSize]?.label}
            />
          )}
        </Fragment>
      )}
      {allowFlexReverse && (
        <label // eslint-disable-line
          className={classes.checkbox}
          htmlFor={`copy-${signIndex}-${insertIndex}-${lineIndex}-flexReverse`}
        >
          <div className={classes.checkboxLabel}>
            Flip content
          </div>
          <CheckboxBase
            {...{
              onChange: (incomingValue) => onCopyChange({
                signIndex,
                insertIndex,
                lineIndex,
                specs: {
                  flexReverse: incomingValue,
                },
              }),
              name: `copy-${signIndex}-${insertIndex}-${lineIndex}-flexReverse`,
              value: flexReverse,
            }}
          />
        </label>
      )}
    </ShadowBox>
  );
};

Line.defaultProps = {
  signIndex: undefined,
  insertIndex: undefined,
  copySizes: undefined,
  allowFlexReverse: undefined,
  allowRoomNumber: undefined,
  allowSymbol: undefined,
  currentLineCopy: undefined,
  lineIndex: undefined,
  showLineIndex: undefined,
  onCopyChange: undefined,
  showCopyTextControl: undefined,
  showCopySizeControl: undefined,
};

Line.propTypes = {
  signIndex: PropTypes.number,
  insertIndex: PropTypes.number,
  copySizes: PropTypes.arrayOf(
    PropTypes.string,
  ),
  allowFlexReverse: PropTypes.bool,
  allowRoomNumber: PropTypes.bool,
  allowSymbol: PropTypes.bool,
  currentLineCopy: PropTypes.shape({
    renderCopy: PropTypes.bool,
  }),
  lineIndex: PropTypes.number,
  showLineIndex: PropTypes.bool,
  onCopyChange: PropTypes.func,
  showCopySizeControl: PropTypes.bool,
  showCopyTextControl: PropTypes.bool,
};

export default Line;
