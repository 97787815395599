import React, { useState } from 'react';
import { ModalToggler } from '@faceless-ui/modal';
import useStyles from './css';

const Hamburger = () => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles(isHovered);

  return (
    <ModalToggler
      slug="menu"
      className={classes.hamburger}
      htmlAttributes={{
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
      }}
    >
      <div className={classes.hamburgerTop} />
      <div className={classes.hamburgerMiddle} />
      <div className={classes.hamburgerBottom} />
    </ModalToggler>
  );
};

export default Hamburger;
