import { createUseStyles } from 'react-jss';
import vars, { base, colors } from '../../../../../css/vars';
import { largeBreak, midBreak } from '../../../../../css/queries';

const metaRow = {
  listStyle: 'none',
  width: '100%',
  display: 'flex',
  padding: 0,
  '& > *:not(:last-child)': {
    marginRight: base(),
  },
};

export default createUseStyles({
  signRow: {
    background: colors.lighterGray,
    borderRadius: vars.borderRadius,
    padding: base(2),
    ...midBreak({
      padding: base(0.5),
    }),
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: base(2),
    },
    ...midBreak({
      flexWrap: 'wrap',
    }),
  },
  thumbnail: {
    width: base(12),
    ...largeBreak({
      width: base(6),
    }),
    ...midBreak({
      width: '100%',
      marginBottom: base(),
    }),
  },
  content: {
    flexGrow: 1,
  },
  primaryContent: {
    ...metaRow,
    justifyContent: 'space-between',
    margin: `0 0 ${base()}`,
  },
  secondaryContent: {
    ...metaRow,
    alignItems: 'flex-end',
  },
  metaLabel: {
    display: 'block',
  },
  metaValue: {
    color: colors.darkGray,
    fontWeight: 'bold',
  },
  toggle: {
    marginLeft: 'auto',
  },
  toggleButton: {
    fontWeight: 'bold',
    color: colors.darkGray,
    textDecoration: 'none',
  },
  drawerContent: {
    padding: 0, // override user-agent stylesheet for ul
    margin: 0, // override user-agent stylesheet for ul
    marginTop: base(2),
    paddingTop: base(2),
    listStyle: 'none',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: base(-2),
      top: 0,
      width: `calc(100% + ${base(4)})`,
      height: `${vars.strokeWidth}px`,
      backgroundColor: colors.lightGray,
      ...midBreak({
        width: `calc(100% + ${base()})`,
        left: base(-0.5),
      }),
    },
    '& > *:not(:last-child)': {
      marginBottom: base(),
    },
    ...midBreak({
      marginTop: base(),
      paddingTop: base(),
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
});
