import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Profile from './Profile';
import OrderHistory from './OrderHistory';
import Order from './Order';
import { useAuthentication } from '../../wrappers/Authentication';
import useStyles from './css';

const Account = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAuthentication();

  if (isLoggedIn) {
    return (
      <div className={classes.account}>
        <Helmet>
          <title>Account</title>
          <meta
            name="description"
            content="View and edit your account details."
          />
        </Helmet>
        <header className={classes.header}>
          <h1 className={classes.title}>
            Account
          </h1>
          <menu className={classes.menu}>
            <NavLink
              to="/account"
              exact
              className={classes.navLink}
            >
              <h3 className={classes.menuItem}>
                Details
              </h3>
            </NavLink>
            <NavLink
              to="/account/orders"
              exact
              className={classes.navLink}
            >
              <h3 className={classes.menuItem}>
                Order History
              </h3>
            </NavLink>
          </menu>
        </header>
        <Switch>
          <Route
            exact
            path="/account"
            title="Hello"
          >
            <Profile />
          </Route>
          <Route path="/account/orders/:orderID">
            <Order />
          </Route>
          <Route path="/account/orders">
            <OrderHistory />
          </Route>
        </Switch>
      </div>
    );
  }
  return <Redirect to="/login" />;
};

export default Account;
