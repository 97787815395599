import React, { useEffect, Fragment, useState } from 'react';
import Helmet from 'react-helmet';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import Button from '../../components/Button';
import { useCart } from '../../wrappers/CartProvider';
import StickyFooter from '../../components/StickyFooter';
import Sidebar from './Sidebar';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import Content from './Content';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import Loader from '../../components/Loader';
import useProject from '../../utilities/data/useProject';
import useDebounce from '../../utilities/performance/useDebounce';
import useDebouncedCallback from '../../utilities/performance/useDebouncedCallback';
import saveProject from '../../utilities/data/saveProject';
import { useAuthentication } from '../../wrappers/Authentication';
import { useNotifications } from '../../wrappers/Notifications';

const Project = () => {
  const { setSavingInProgress, setTimedNotification } = useNotifications();
  const { isLoggedIn } = useAuthentication();
  const { projectID } = useParams();
  const classes = useStyles();
  const { history } = useHistory();
  const { addProjectToCart, updatePotentialProjectInCart } = useCart();
  const { setCrumbs } = useBreadcrumbs();
  const { project: loadedProject, isLoading } = useProject(projectID);
  const [projectState, setProjectState] = useState(undefined);

  // When the project loads, set the result
  useEffect(() => {
    setProjectState(loadedProject);
  }, [loadedProject]);

  // Although sign state is already debounced (see dispatchDebouncedProjectState)
  // interactions with the API and localStorage needs an additional, heavier throttling
  const debouncedProjectState = useDebounce(projectState, 1000);

  useEffect(() => {
    if (debouncedProjectState) {
      const makeUpdate = async () => {
        const { res, err } = await saveProject({
          project: debouncedProjectState,
          isLoggedIn,
        });

        if (err) console.warn(err);

        if (res?.json?.errors) {
          setTimedNotification({
            id: 'errorSavingProject',
            message: res.json.errors?.[0]?.message || 'An error has occured',
          });
        }

        if (res?.status === 200) {
          updatePotentialProjectInCart(debouncedProjectState);
          setSavingInProgress(false);
        }
      };

      makeUpdate();
    }
  }, [
    debouncedProjectState,
    projectID,
    isLoggedIn,
    updatePotentialProjectInCart,
    setSavingInProgress,
    setTimedNotification,
  ]);

  // This callback needs a minor debounce to handle re-renderings of more higher-rate specs like wallColor
  const setDebouncedProjectState = useDebouncedCallback((project) => {
    setSavingInProgress(true);
    setProjectState({ ...project });
  }, 25, []);

  useEffect(() => {
    setCrumbs([{
      label: 'My Projects',
      url: '/projects',
    }, {
      label: projectState?.name || projectID,
      useEllipsis: true,
    }]);
  }, [setCrumbs, projectID, projectState]);


  if (isLoading || projectState === undefined) return <Loader fixed />;
  if (!projectState) return <Redirect to="/not-found" />;

  return (
    <Fragment>
      <Helmet>
        <title>
          {`Project -
          ${projectState.name}`}
        </title>
        <meta
          name="description"
          content="View your project and make changes."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.project}>
        <div className={classes.sidebarWrapper}>
          <Sidebar project={projectState} />
        </div>
        <div className={classes.contentWrapper}>
          <Content
            project={projectState}
            setProjectState={setDebouncedProjectState}
          />
        </div>
        <StickyFooter className={classes.mobileCTA}>
          <Button
            label="Add project to cart"
            className={classes.mobileCTAButton}
            onClick={() => {
              addProjectToCart(projectState);
              history.push('/cart');
            }}
          />
        </StickyFooter>
      </div>
    </Fragment>
  );
};

export default Project;
