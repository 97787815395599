import React from 'react';
import PropTypes from 'prop-types';
import Error from '../Error';
import useStyles from './css';

const Color = (props) => {
  const {
    id,
    name,
    label,
    disabled,
    onChange,
    errors,
    currentValue,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.input}>
      <label
        htmlFor={id}
        className={classes.htmlLabel}
      >
        <input
          {...{
            id,
            name,
            type: 'color',
            disabled,
            className: classes.htmlInput,
            onChange: onChange ? (e) => onChange(e.target.value) : undefined,
            value: currentValue,
          }}
        />
        <span
          className={[
            classes.label,
            errors && classes.labelError,
          ].filter(Boolean).join(' ')}
        >
          {label}
        </span>
      </label>
      <Error {...errors} />
    </div>
  );
};

Color.defaultProps = {
  id: '',
  label: 'Label',
  disabled: false,
  onChange: undefined,
  errors: undefined,
  currentValue: undefined,
};

Color.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.shape({}),
  currentValue: PropTypes.string,
};

export default Color;
