import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

const { papers } = specSheets;
const entries = Object.entries(papers);

const Papers = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.papers}>
        <SpecHeader
          label={headerLabel || 'Insert Paper'}
          to={backTo}
          specId={specId}
        />
        <ShadowBox color="white">
          {entries.map(([id, paper], index) => {
            const { label } = paper;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'paper',
                  label,
                  value: id,
                  onChange,
                  checked: id === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

Papers.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  headerLabel: undefined,
  specId: undefined,
};

Papers.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default Papers;
