import React from 'react';
import PropTypes from 'prop-types';

import useCSS from './css';

const Page = ({
  page, isCurrent, updatePage, isLastPage,
}) => {
  const classes = useCSS();

  const className = [
    classes.page,
    isCurrent && classes.isCurrent,
    isLastPage && classes.isLastPage,
  ].filter(Boolean).join(' ');

  return (
    <button
      className={className}
      onClick={() => updatePage(page)}
      type="button"
    >
      {page}
    </button>
  );
};

Page.defaultProps = {
  page: 1,
  isCurrent: false,
  updatePage: null,
  isLastPage: false,
};

Page.propTypes = {
  page: PropTypes.number,
  isCurrent: PropTypes.bool,
  updatePage: PropTypes.func,
  isLastPage: PropTypes.bool,
};

export default Page;
