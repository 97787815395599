import { createUseStyles } from 'react-jss';
import { midBreak } from '../../css/queries';
import vars, { base, colors } from '../../css/vars';

export default createUseStyles({
  specPanel: {
    backgroundColor: ({ background }) => {
      const options = {
        white: 'white',
        lightGray: colors.lighterGray,
      };

      return options[background];
    },
    borderRadius: vars.borderRadius,
  },
  header: {
    padding: base(),
    borderBottom: ({ background }) => {
      const options = {
        white: colors.lighterGray,
        lightGray: 'white',
      };

      return `${vars.strokeWidth}px solid ${options[background]}`;
    },
    '& h4': {
      margin: 0,
    },
    ...midBreak({
      padding: base(0.5),
    }),
  },
  specs: {
    padding: `${base()} ${base()} ${base(0.5)}`,
    listStyle: 'none',
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    ...midBreak({
      padding: `${base(0.5)} ${base(0.5)} ${base(0.25)}`,
    }),
  },
  spec: {
    marginBottom: base(0.5),
    paddingRight: base(2),
    ...midBreak({
      marginBottom: base(0.25),
    }),
  },
  nestedSpecsWrap: {
    width: base(18),
  },
  nestedSpecs: {

  },
  subNestedSpecs: {
    listStyle: 'none',
    padding: 0,
    margin: `0 ${base(-0.5)}`,
    width: `calc(100% + ${base()})`,
    display: 'flex',
  },
  subNestedSpec: {
    width: '50%',
    padding: `0 ${base(0.5)}`,
    margin: `0 0 ${base(0.5)}`,
  },
  label: {

  },
  value: {
    fontWeight: 'bold',
    color: colors.darkGray,
  },
});
