import PropTypes from 'prop-types';
import { useAuthentication } from '../Authentication';

const Init = (props) => {
  const { children } = props;
  const { user } = useAuthentication();

  if (user !== undefined) return children;
  return null;
};

Init.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Init;
