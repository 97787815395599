import { createUseStyles } from 'react-jss';
import { base } from '../../css/vars';
import { largeBreak, midBreak } from '../../css/queries';

export default createUseStyles({
  products: {
    '&:after': {
      content: '""',
      paddingTop: base(2),
      display: 'block',
    },
    ...midBreak({
      '&:after': {
        paddingTop: base(0.5),
      },
    }),
  },
  wrapper: {
    display: 'flex',
    ...largeBreak({
      flexDirection: 'column',
    }),
  },
  sidebar: {
    alignSelf: 'flex-start',
    position: 'sticky',
    top: base(7),
    flexShrink: 0,
    width: '33.33%',
    marginRight: base(2),
    ...largeBreak({
      position: 'static',
      marginRight: '0',
      marginBottom: base(2),
      width: '100%',
    }),
    ...midBreak({
      marginBottom: base(0.5),
    }),
  },
  content: {
    position: 'relative',
    width: '66.66%',
    display: 'flex',
    flexDirection: 'column',
    ...largeBreak({
      width: '100%',
    }),
  },
});
