import { requests } from '../../api';

const syncProjects = async () => {
  const projectPromises = [];

  const projectsFromStorage = JSON.parse(window.localStorage.getItem('projects'));

  const successfullyCreatedUUIDs = [];

  if (projectsFromStorage) {
    projectsFromStorage.forEach((project) => {
      const promise = async () => {
        const response = await requests.post({
          url: `${process.env.API_URL}/api/projects`,
          options: {
            body: JSON.stringify(project),
          },
        });

        if (response.ok) {
          const json = await response.json();
          const { doc: { uuid: projectUUID } } = json;
          if (projectUUID) successfullyCreatedUUIDs.push(projectUUID);
        }
      };

      projectPromises.push(promise());
    });
  }

  await Promise.all(projectPromises);

  successfullyCreatedUUIDs.forEach((uuid) => {
    const localProjectIndex = projectsFromStorage.find((project) => project.uuid === uuid);
    if (localProjectIndex) projectsFromStorage.splice(localProjectIndex, 1);
  });

  window.localStorage.setItem('projects', JSON.stringify(projectsFromStorage));

  return successfullyCreatedUUIDs;
};

export default syncProjects;
