import { createUseStyles } from 'react-jss';
import { base } from '../../../css/vars';
import { midBreak } from '../../../css/queries';

export default createUseStyles({
  profile: {
    marginBottom: base(2),
    '& > *:not(:last-child)': {
      marginBottom: base(2),
    },
    ...midBreak({
      marginBottom: base(0.5),
      '& > *:not(:last-child)': {
        marginBottom: base(0.5),
      },
    }),
  },
});
