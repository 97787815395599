import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

const { signSystems } = specSheets;

const SignSystems = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.signSystems}>
        <SpecHeader
          label="Sign System"
          to={backTo}
        />
        <ShadowBox color="white">
          {Object.keys(signSystems).map((key, index) => {
            const system = signSystems[key];
            const { label } = system;

            return (
              <Radio
                {...{
                  key: index,
                  className: classes.radio,
                  name: 'signSystem',
                  label,
                  value: key,
                  onChange,
                  checked: key === currentValue,
                }}
              />
            );
          })}
        </ShadowBox>
      </div>
    </Fragment>
  );
};

SignSystems.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
};

SignSystems.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default SignSystems;
