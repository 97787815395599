import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../../forms/Form/context';
import Radio from '../../../../forms/fields/Radio';
import Input from '../../../../forms/fields/Input';

import useStyles from './css';

const Payment = (props) => {
  const {
    user: {
      organization,
    } = {},
    CardElement,
  } = props;

  const allowInvoicing = organization?.allowInvoicing;

  const classes = useStyles();

  const {
    fieldState: {
      paymentMethod = {},
    } = {},
  } = useForm();

  return (
    <React.Fragment>
      <div className={classes.paymentWrapper}>
        {allowInvoicing && (
          <div className={classes.selectPayment}>
            <Radio
              name="paymentMethod"
              value="credit-card"
              label="Credit Card"
              hi
            />
            <Radio
              name="paymentMethod"
              value="invoice"
              label="Invoice"
            />
          </div>
        )}
        {(!allowInvoicing || paymentMethod.value === 'credit-card') && (
          <React.Fragment>
            <span className={classes.label}>
              Once your order is processed, you will receive an order acknowledgement by email.
            </span>
            <div className={classes.paymentInput}>
              <CardElement />
            </div>
          </React.Fragment>
        )}
        {(allowInvoicing && paymentMethod.value === 'invoice') && (
          <div className={classes.invoice}>
            <p>
              Once your order is processed, you will receive an order acknowledgement by email.
            </p>
            <Input
              type="text"
              name="purchaseOrderID"
              id="purchaseOrderID"
              label="Purchase Order ID"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Payment.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  CardElement: PropTypes.func.isRequired,
};

export default Payment;
