import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from '../Notifications';

export const OnboardingContext = createContext({});
export const useOnboarding = () => useContext(OnboardingContext);

const OnboardingProvider = (props) => {
  const { children } = props;
  const { setTimedNotification } = useNotifications();

  const [optedOut, setOptedOut] = useState(() => {
    const hasCancelled = localStorage.getItem('cancelledOnboardingTooltips') === 'true';
    return hasCancelled;
  });

  const optOut = useCallback(() => {
    localStorage.setItem('cancelledOnboardingTooltips', true);
    setOptedOut(true);
    setTimedNotification({
      id: 'cancelledOnboardingTooltips',
      message: 'Tips hidden!',
    });
  }, [setTimedNotification]);


  const optIn = useCallback(() => {
    localStorage.removeItem('cancelledOnboardingTooltips');
    setOptedOut(false);
  }, []);

  return (
    <OnboardingContext.Provider
      value={{
        optedOut,
        optOut,
        optIn,
      }}
    >
      {children && children}
    </OnboardingContext.Provider>
  );
};

OnboardingProvider.defaultProps = {
  children: undefined,
};

OnboardingProvider.propTypes = {
  children: PropTypes.node,
};

export default OnboardingProvider;
