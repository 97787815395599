import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@faceless-ui/modal';
import { useAuthentication } from '../../../../wrappers/Authentication';
import Button from '../../../Button';
import Submit from '../../../../forms/fields/Submit';
import Input from '../../../../forms/fields/Input';
import useAuthDrawerStyles from '../useAuthDrawerStyles';
import Form from '../../../../forms/Form';
import Errors from '../../../../forms/Errors';

const Login = (props) => {
  const { setContent } = props;
  const { setUser } = useAuthentication();
  const authDrawerClasses = useAuthDrawerStyles();
  const { closeAll: closeAllModals } = useModal();

  const onResponse = useCallback((response) => {
    const { status } = response;
    if (status === 200) {
      const { json: { user: incomingUser } } = response;
      if (incomingUser) setUser(incomingUser);
      closeAllModals();
    }
  }, [setUser, closeAllModals]);

  return (
    <Fragment>
      <h1>
        Log in
      </h1>
      <Form
        onResponse={onResponse}
        method="post"
        action={`${process.env.API_URL}/api/users/login`}
        className={authDrawerClasses.form}
        defaultValues={{
          email: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Email is required',
            }),
          },
          password: {
            value: '',
            validation: (incomingValue) => ({
              isValid: Boolean(incomingValue),
              message: 'Password is required',
            }),
          },
        }}
        htmlAttributes={{
          autoComplete: 'on',
        }}
      >
        <div>
          <b>
            Already have a My290 account? Welcome back!
          </b>
          <br />
          <br />
          {'If not, welcome in! Create an account '}
          <Button
            kind="text"
            onClick={() => setContent('signUp')}
            label="here"
          />
          .
        </div>
        <Errors color="white" />
        <Input
          {...{
            type: 'email',
            name: 'email',
            id: 'LogInEmail',
            label: 'Email Address',
            required: true,
          }}
        />
        <Input
          {...{
            type: 'password',
            name: 'password',
            id: 'LogInPassword',
            label: 'Password',
            required: true,
          }}
        />
        <div>
          <Button
            kind="text"
            onClick={() => setContent('resetPassword')}
            label="Forgot your password?"
          />
        </div>
        <Submit
          color="darkGray"
          className={authDrawerClasses.submit}
          label="Log in"
        />
      </Form>
    </Fragment>
  );
};

Login.defaultProps = {
  setContent: undefined,
};

Login.propTypes = {
  setContent: PropTypes.func,
};

export default Login;
