import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';

// insert types are defined on an insert-by-insert basis, therefore options must be passed via props
// as opposed to bulk imported and generically mapped through

const InsertTypes = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    options,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertTypes}>
        <SpecHeader
          label={headerLabel || 'Insert Type'}
          to={backTo}
          specId={specId}
        />
        {Array.isArray(options) && options.length > 0 && (
          <ShadowBox color="white">
            {options.map((insertType, index) => {
              const { label, id } = insertType;

              return (
                <Radio
                  {...{
                    key: index,
                    className: classes.radio,
                    name: 'type',
                    label,
                    value: id,
                    onChange,
                    checked: id === currentValue,
                  }}
                />
              );
            })}
          </ShadowBox>
        )}
      </div>
    </Fragment>
  );
};

InsertTypes.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  options: undefined,
  headerLabel: undefined,
  specId: undefined,
};

InsertTypes.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default InsertTypes;
