import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { specSheets } from '@two90signs/290-renderer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SpecHeader from '../SpecHeader';
import ColorSwatchAccordion from '../ColorSwatchAccordion';
import useStyles from './css';

const { backerPatterns } = specSheets;
const entries = Object.entries(backerPatterns);

const BackerPatterns = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    headerLabel,
    specId,
  } = props;

  const classes = useStyles();
  const colorsWithID = entries.map(([colorID, color]) => ({ ...color, id: colorID }));

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.insertFinishes}>
        <SpecHeader
          label={headerLabel || 'Backer Pattern'}
          to={backTo}
          specId={specId}
        />
        <ColorSwatchAccordion
          key={1}
          title="Backer Patterns"
          colors={colorsWithID}
          name="finish"
          onChange={onChange}
          currentValue={currentValue}
          openOnInit
        />
      </div>
    </Fragment>
  );
};

BackerPatterns.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  headerLabel: undefined,
  specId: undefined,
};

BackerPatterns.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  headerLabel: PropTypes.string,
  specId: PropTypes.string,
};

export default BackerPatterns;
