import React, { useState, useCallback, useEffect, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom';
import Form from '../../forms/Form';
import Errors from '../../forms/Errors';
import Input from '../../forms/fields/Input';
import Submit from '../../forms/fields/Submit';
import { useAuthentication } from '../../wrappers/Authentication';
import { useBreadcrumbs } from '../../wrappers/BreadcrumbsProvider';
import useStyles from './css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import Button from '../../components/Button';
import MarginGrid from '../../components/MarginGrid';

const NoOrganization = () => {
  const classes = useStyles();
  const { user } = useAuthentication();
  const { setCrumbs } = useBreadcrumbs();
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setCrumbs([]);
  }, [setCrumbs]);

  const onResponse = useCallback((response) => {
    const { status } = response;
    if (status === 200) {
      setEmailSent(true);
    }
  }, [setEmailSent]);

  if (!user) return <Redirect to="/login" />;

  const {
    id,
    firstName,
    lastName,
    email,
    requestedOrg,
    organization,
  } = user;

  if (organization) return <Redirect to="/checkout" />;

  return (
    <Fragment>
      <Helmet>
        <title>
          No Organization
        </title>
        <meta
          name="description"
          content="No Organization found."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <div className={classes.noOrganization}>
        <div className={classes.header}>
          <h2>
            You must be tied to an organization before checking out
          </h2>
        </div>
        {(requestedOrg || emailSent) && (
          <div>
            <p>
              {'We\'re processing your request. It may take up to 24-48 hours to add you to an organization.'}
            </p>
            <p>
              If a shorter lead time is required, please contact us!
            </p>
            <MarginGrid>
              <Button
                label="Email: info@290signs.com"
                htmlElement="a"
                htmlAttributes={{
                  href: `mailto:info@290signs.com?subject=${encodeURIComponent('I need be assigned to an organization, ASAP!')}&body=${encodeURIComponent('I\'ve requested my organization already, but this is a rush order and I don\'t 48 hours to wait! Can you make me a priority? Thank you!')}`,
                  target: '_blank',
                }}
              />
              <Button
                color="lightGray"
                label="Phone: 800-777-4310"
                htmlElement="a"
                htmlAttributes={{
                  href: 'tel:800-777-4310',
                }}
              />
            </MarginGrid>
            .
          </div>
        )}
        {(!requestedOrg && !emailSent) && (
          <Fragment>
            <Form
              className={classes.form}
              method="post"
              action={`${process.env.API_URL}/request-organization`}
              onResponse={onResponse}
              defaultValues={{
                organization: {
                  value: '',
                  validation: (incomingValue) => ({
                    isValid: Boolean(incomingValue),
                    message: 'Organization name is required',
                  }),
                },
                phoneNumber: {
                  value: '',
                },
                customerNumber: {
                  value: '',
                },
                userID: {
                  value: id,
                },
                firstName: {
                  value: firstName,
                },
                lastName: {
                  value: lastName,
                },
                email: {
                  value: email,
                },
              }}
              htmlAttributes={{
                autoComplete: 'on',
              }}
            >
              <div>
                <b>
                  Ask us to add you to one and we&apos;ll promptly reply!
                </b>
              </div>
              <Errors />
              {emailSent && (
                <p>
                  Email sent! We will get back to you shortly about your Organization Request.
                </p>
              )}
              <div className={classes.inputWrapper}>
                <Input
                  {...{
                    className: classes.orgInput,
                    type: 'text',
                    name: 'organization',
                    id: 'OrganizationName',
                    label: 'Enter your organization name',
                    required: true,
                  }}
                />
                <div className={classes.nestedInputWrapper}>
                  <Input
                    {...{
                      className: classes.input,
                      type: 'text',
                      name: 'phoneNumber',
                      id: 'PhoneNumber',
                      label: 'Enter your phone number',
                      required: false,
                    }}
                  />
                  <Input
                    {...{
                      className: classes.input,
                      type: 'text',
                      name: 'customerNumber',
                      id: 'CustomerNumber',
                      label: 'Enter your customer number',
                      required: false,
                    }}
                  />
                </div>
                <Submit
                  className={classes.submit}
                  label="Submit"
                />
              </div>
            </Form>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default NoOrganization;
