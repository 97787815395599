import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ShadowBox from '../../components/ShadowBox';
import SpecHeader from '../SpecHeader';
import Radio from '../../forms/fields/Radio';
import useStyles from './css';
import Notice from '../../components/Notice';

const MoutingStyles = (props) => {
  const {
    backTo,
    currentValue,
    onChange,
    options,
  } = props;

  const classes = useStyles();

  const mountsThatNeedSizing = ['F', 'R', 'P'];
  const mountNeedsSizing = mountsThatNeedSizing.indexOf(currentValue) > -1;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className={classes.mountingStyles}>
        <SpecHeader
          label="Mounting Style"
          to={backTo}
        />
        {mountNeedsSizing && (
          <ShadowBox color="white">
            <Notice message="2/90 will contact you for exact sizing." />
          </ShadowBox>
        )}
        {Array.isArray(options) && options.length > 0 && (
          <ShadowBox color="white">
            {options.map((mountingStyle, index) => {
              const { label, id } = mountingStyle;

              return (
                <Radio
                  {...{
                    key: index,
                    className: classes.radio,
                    name: 'mountingStyle',
                    label,
                    value: id,
                    onChange,
                    checked: id === currentValue,
                  }}
                />
              );
            })}
          </ShadowBox>
        )}
      </div>
    </Fragment>
  );
};

MoutingStyles.defaultProps = {
  backTo: undefined,
  currentValue: undefined,
  onChange: undefined,
  options: undefined,
};

MoutingStyles.propTypes = {
  backTo: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default MoutingStyles;
