import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import MarginGrid from '../../../components/MarginGrid';
import Button from '../../../components/Button';
import { useCart } from '../../../wrappers/CartProvider';
import Price from '../../../components/Price';
import OnboardingTooltip from '../../../components/OnboardingTooltip';
import EditableHeading from '../../../components/EditableHeading';
import useStyles from './css';
import saveProject from '../../../utilities/data/saveProject';
import { useAuthentication } from '../../../wrappers/Authentication';
import getProjectPrice from '../../../utilities/getProjectPrice';
import projectNameValidations from '../../../utilities/validations/projectName';
import deleteProject from '../../../utilities/data/deleteProject';
import { useActionConfirmation } from '../../../wrappers/ActionConfirmation';
import { useNotifications } from '../../../wrappers/Notifications';

const Sidebar = (props) => {
  const {
    setSavingInProgress,
    savingInProgress,
    setTimedNotification,
  } = useNotifications();
  const { projectID } = useParams();
  const { confirmAction } = useActionConfirmation();
  const { isLoggedIn } = useAuthentication();
  const { project } = props;
  const { signs, name } = project;
  const [localProjectName, setLocalProjectName] = useState(name);

  const classes = useStyles();
  const history = useHistory();

  const {
    addProjectToCart,
    isProjectInCart,
    updatePotentialProjectInCart,
  } = useCart();

  useEffect(() => {
    if (savingInProgress && project) { // savingInProgress controls the submit
      const makeUpdate = async () => {
        const updatedProject = {
          ...project,
          name: localProjectName,
        };

        const { res, err } = await saveProject({
          project: updatedProject,
          isLoggedIn,
        });

        if (err) console.warn(err);

        if (res?.json?.errors) {
          setTimedNotification({
            id: 'errorSavingProjectName',
            message: res.json.errors?.[0]?.message || 'An error has occured',
          });
        }

        if (res?.status === 200) {
          updatePotentialProjectInCart(updatedProject);
          setSavingInProgress(false);
        }
      };
      makeUpdate();
    }
  }, [
    savingInProgress,
    localProjectName,
    setSavingInProgress,
    isLoggedIn,
    project,
    updatePotentialProjectInCart,
    setTimedNotification,
  ]);

  const onNameSubmit = useCallback((incomingName) => {
    setSavingInProgress(true);
    setLocalProjectName(incomingName);
  }, [setSavingInProgress]);

  let signCount = 0;
  signs.forEach((item) => { signCount += item.quantity; });

  const projectIsInCart = isProjectInCart(projectID);
  const hasSigns = Array.isArray(signs) && signs.length > 0;

  return (
    <div className={classes.sidebar}>
      <div className={classes.intro}>
        <EditableHeading
          onSubmit={onNameSubmit}
          onValidate={(incomingName) => {
            const isValid = incomingName.match(projectNameValidations.regex);
            if (!isValid) return projectNameValidations.message;
            return true;
          }}
          requiredMessage={projectNameValidations.requiredMessage}
          initialValue={name}
          inline
          htmlElement="h1"
        />
      </div>
      {projectIsInCart && (
        <div>
          {'This project is currently in your '}
          <Button
            className={classes.isInCartLink}
            label="cart"
            kind="text"
            to="/cart"
          />
        </div>
      )}
      <MarginGrid>
        <div style={{ position: 'relative' }}>
          <OnboardingTooltip
            position="topLeft"
            contentPosition="right"
            title="Edit base specifications"
            message="You can edit the base specifications for your sign family. These are the specs that affect every sign in the project."
          />
          <Button
            to={`/project/${projectID}/edit`}
            label="Edit base specifications"
            color="lightGray"
          />
        </div>
        <div style={{ position: 'relative' }}>
          <OnboardingTooltip
            position="topLeft"
            contentPosition="bottom"
            title="Add new sign(s)"
            message="Add more signs. If the same sign type needs a different mounting option, add sign type again. If you desire an individual sign drawing for every sign, add sign type again verses increasing quantity."
          />
          <Button
            to={`/project/${projectID}/add-sign`}
            color="darkGray"
            label="Add new sign(s)"
            icon="add"
          />
        </div>
        {!projectIsInCart && hasSigns && (
          <Button
            label="Add project to cart"
            className={classes.addToCart}
            onClick={() => {
              addProjectToCart(project);
              history.push('/cart');
            }}
          />
        )}
        <Button
          label="Delete project"
          color="lightGray"
          onClick={() => {
            confirmAction({
              message: (
                <Fragment>
                  <div>
                    {'Are you sure you want to delete '}
                    <b>{name || 'this project'}</b>
                    ? This action
                    <b>
                      {' cannot '}
                    </b>
                    be undone.
                  </div>
                  <div>
                    Please type
                    <b>
                      {` ${name} `}
                    </b>
                    to confirm, or press
                    <b>
                      {' esc '}
                    </b>
                    to cancel.
                  </div>
                </Fragment>
              ),
              inputLabel: 'Project name',
              useInput: true,
              onValidate: (value) => Boolean(value === name),
              cancelLabel: 'No, thank you!',
              confirmLabel: 'Yes, delete!',
              action: async () => {
                await deleteProject({ project, isLoggedIn });
                history.push('/projects');
                setTimedNotification({
                  id: `deleted-${projectID}`,
                  message: `${name} has been deleted`,
                });
              },
            });
          }}
        />
      </MarginGrid>
      <div className={classes.detailsWrapper}>
        <div>
          <b className={classes.number}>
            {signs?.length || 0}
          </b>
          {` Sign Type${signs?.length !== 1 ? 's' : ''}`}
        </div>
        <div>
          <b className={classes.number}>
            {signCount}
          </b>
          {` Total Sign${signs?.length !== 1 ? 's' : ''}`}
        </div>
        <div>
          <Price price={getProjectPrice(project)} />
        </div>
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  project: {
    name: undefined,
    signs: undefined,
    price: undefined,
  },
};

Sidebar.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    signs: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    price: PropTypes.number,
  }),
};

export default Sidebar;
