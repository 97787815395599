import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Submit from '../../../forms/fields/Submit';
import Quantity from '../../../specControls/Quantity';
import ShadowBox from '../../../components/ShadowBox';
import Radio from '../../../forms/fields/Radio';
import useStyles from './css';
import SpecHeader from '../../../specControls/SpecHeader';
import SpecLink from '../../../specControls/SpecLink';
import EnableDisable from '../../../specControls/EnableDisable';
import Errors from '../../../forms/Errors';

const AttributeControls = (props) => {
  const { url, path } = useRouteMatch();
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    quantity,
    setQuantity,
    errors,
    setErrors,
  } = props;

  const classes = useStyles();

  const attributeRoutes = useCallback(() => {
    const routes = [];

    if (Array.isArray(attributes) && attributes.length > 0) {
      attributes.forEach((attribute) => {
        const {
          name,
          label,
          options,
          blockType,
        } = attribute;

        const currentValue = selectedAttributes[name];

        if (blockType === 'select') {
          routes.push(
            <Route
              key={`${path}/${name}`}
              path={`${path}/${name}`}
            >
              <SpecHeader
                {...{
                  to: url,
                  label,
                }}
              />
              <ShadowBox
                color="white"
                className={classes.radios}
              >
                {Array.isArray(options) && options.length > 0
                  && options.map((option, optionIndex) => {
                    const {
                      label: optionLabel,
                      value,
                    } = option;

                    return (
                      <Radio
                        {...{
                          key: optionIndex,
                          name,
                          value,
                          label: optionLabel,
                          onChange: (incomingValue) => {
                            const errorsWithoutThisAttribute = { ...errors };
                            delete errorsWithoutThisAttribute[name];
                            setErrors(errorsWithoutThisAttribute);
                            setSelectedAttributes({
                              ...selectedAttributes,
                              [name]: incomingValue,
                            });
                          },
                          checked: value === currentValue,
                        }}
                      />
                    );
                  })}
              </ShadowBox>
            </Route>,
          );
        }
      });
    }

    return routes;
  }, [attributes, path, url, classes, setSelectedAttributes, selectedAttributes, errors, setErrors]);

  return (
    <div className={classes.attributeControls}>
      <Switch>
        {attributeRoutes()}
        <Route>
          <Errors
            className={classes.errors}
            errors={errors}
          />
          <Quantity
            onChange={(value) => setQuantity(value)}
            currentValue={quantity}
          />
          {Array.isArray(attributes) && attributes.length > 0
            && attributes.map((attribute, index) => {
              const {
                blockType,
                name,
                label,
              } = attribute;

              const currentValue = selectedAttributes[name];

              if (blockType === 'select') {
                let currentLabel = '';
                if (currentValue) {
                  const selectedOption = attribute.options.find((option) => option.value === currentValue);
                  if (selectedOption) currentLabel = selectedOption.label;
                }

                return (
                  <SpecLink
                    {...{
                      key: index,
                      label,
                      currentValue,
                      currentLabel,
                      to: `${url}/${name}`,
                    }}
                  />
                );
              }

              if (blockType === 'checkbox') {
                return (
                  <EnableDisable
                    {...{
                      key: index,
                      label,
                      htmlName: name,
                      currentValue,
                      onChange: (incomingValue) => {
                        const errorsWithoutThisAttribute = { ...errors };
                        delete errorsWithoutThisAttribute[name];
                        setErrors(errorsWithoutThisAttribute);
                        setSelectedAttributes({
                          ...selectedAttributes,
                          [name]: incomingValue,
                        });
                      },
                    }}
                  />
                );
              }
              return null;
            })}
        </Route>
      </Switch>
      <div className={classes.cta}>
        <Submit label="Add to cart" />
      </div>
    </div>
  );
};

AttributeControls.defaultProps = {
  attributes: undefined,
  selectedAttributes: undefined,
  setSelectedAttributes: undefined,
  quantity: undefined,
  setQuantity: undefined,
  errors: undefined,
  setErrors: undefined,
};

AttributeControls.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  selectedAttributes: PropTypes.shape({}),
  setSelectedAttributes: PropTypes.func,
  quantity: PropTypes.number,
  setQuantity: PropTypes.func,
  errors: PropTypes.shape({}),
  setErrors: PropTypes.func,
};

export default AttributeControls;
