import React from 'react';
import PropTypes from 'prop-types';
import useIconStyles from '../useIconStyles';

const AccountIcon = (props) => {
  const { color, className, size } = props;
  const classes = useIconStyles({ color });

  return (
    <svg
      className={[
        classes.wrapper,
        className,
        size && classes[size],
      ].filter(Boolean).join(' ')}
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.stroke}
        d="M12.4737 5.3C12.4737 7.41121 10.7882 9.1 8.73685 9.1C6.68549 9.1 5.00001 7.41121 5.00001 5.3C5.00001 3.18879 6.68549 1.5 8.73685 1.5C10.7882 1.5 12.4737 3.18879 12.4737 5.3Z"
      />
      <path
        className={classes.stroke}
        d="M16 17.85C16 13.6526 12.6421 10.25 8.5 10.25C4.35786 10.25 1 13.6526 1 17.85"
      />
    </svg>
  );
};

AccountIcon.defaultProps = {
  color: undefined,
  className: undefined,
  size: undefined,
};

AccountIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default AccountIcon;
