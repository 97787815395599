const executeCondition = (value, condition, comparison) => {
  switch (condition) {
    case 'matches': {
      return value === comparison;
    }

    case 'doesNotMatch': {
      return value !== comparison;
    }

    default: {
      return !value;
    }
  }
};

export default executeCondition;
