import React, { useCallback, useState } from 'react';
import { Modal, useModal } from '@faceless-ui/modal';
import useStyles from './css';
import Button from '../../../components/Button';
import ShadowBox from '../../../components/ShadowBox';
import ChevronIcon from '../../../icons/ChevronIcon';
import MarginGrid from '../../../components/MarginGrid';

const steps = [
  'Now that you have started your project, you can start adding signs to it. Don’t worry, you can always add or delete signs later.',
  'Once your selections are completed, click “Add sign(s) to project” to save your changes.',
  'Next edit the base specifications for your sign family. These are the specs that effect every sign in the project.',
  'You can override the base specs by editing the individual sign. Here you can choose different options, add quantity, and personalize copy (message schedule can also be submitted via email).',
  'Once your project is completed, share your sign family and individual sign drawings with your team or client with or without pricing.',
  'When you are ready, add your project to your shopping cart and place your order.',
];

const OnboardingModal = () => {
  const { closeAll } = useModal();
  const [currentStep, setCurrentStep] = useState(0);
  const classes = useStyles();

  const goToPrev = useCallback(() => {
    let prevStep = currentStep - 1;
    if (prevStep < 0) prevStep = steps.length - 1;
    setCurrentStep(prevStep);
  }, [currentStep]);

  const goToNext = useCallback(() => {
    let nextStep = currentStep + 1;
    if (nextStep > steps.length - 1) nextStep = 0;
    setCurrentStep(nextStep);
  }, [currentStep]);

  return (
    <Modal
      className={classes.onboardingModal}
      slug="project-onboarding"
    >
      <ShadowBox
        color="white"
        className={classes.dialog}
      >
        <div className={classes.contentWrapper}>
          <h5 className={classes.title}>
            Welcome to your new project!
          </h5>
          <div className={classes.content}>
            <Button
              className={classes.desktopButton}
              size="s"
              icon="chevron"
              color="lightGray"
              iconRotation={90}
              onClick={goToPrev}
            />
            <div className={classes.text}>
              {steps[currentStep]}
            </div>
            <Button
              className={classes.desktopButton}
              size="s"
              icon="chevron"
              color="lightGray"
              iconRotation={-90}
              onClick={goToNext}
            />
          </div>
          <div className={classes.controls}>
            <div
              className={classes.mobileButton}
              onClick={goToPrev}
              onKeyPress={goToPrev}
              role="button"
              tabIndex={0}
            >
              <ChevronIcon
                rotation={90}
                color="gray"
                size="s"
              />
            </div>
            <div className={classes.dots}>
              {steps.map((item, index) => (
                <span
                  key={index}
                  className={[
                    classes.dot,
                    index === currentStep && classes.dotActive,
                  ].filter(Boolean).join(' ')}
                  onClick={() => { setCurrentStep(index); }}
                  onKeyPress={() => { setCurrentStep(index); }}
                  role="button"
                  tabIndex={0}
                  aria-label="Next step"
                />
              ))}
            </div>
            <div
              className={classes.mobileButton}
              onClick={goToNext}
              onKeyPress={goToNext}
              role="button"
              tabIndex={0}
            >
              <ChevronIcon
                rotation={-90}
                color="gray"
                size="s"
              />
            </div>
          </div>
        </div>
        <MarginGrid>
          <Button
            label="Okay, I got it"
            onClick={() => { closeAll(); }}
          />
          <Button
            color="lightGray"
            label="Don't remind me again"
            onClick={() => {
              localStorage.setItem('cancelledAddSignOnboarding', true);
              closeAll();
            }}
          />
        </MarginGrid>
      </ShadowBox>
    </Modal>
  );
};

OnboardingModal.defaultProps = {

};

OnboardingModal.propTypes = {

};

export default OnboardingModal;
