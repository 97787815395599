import { createUseStyles } from 'react-jss';
import { base } from '../../../../css/vars';
import { midBreak } from '../../../../css/queries';

export default createUseStyles({
  form: {
    display: 'flex',
    '& >*:not(:last-child)': {
      marginRight: base(),
    },
    ...midBreak({
      flexDirection: 'column',
    }),
  },
  header: {
    width: '33%',
    ...midBreak({
      marginBottom: base(),
      width: '100%',
    }),
  },
  title: {
    ...midBreak({
      marginTop: base(0.5),
    }),
  },
  errors: {
    marginBottom: base(),
  },
  body: {
    width: '66%',
    ...midBreak({
      width: '100%',
    }),
  },
  marginGroup: {
    '& > * > *': {
      width: `calc(50% - ${base(1)})`,
    },
    ...midBreak({
      '& > * > *': {
        width: '100%',
      },
    }),
  },
});
