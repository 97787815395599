import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import vars, { colors, shadows, base } from '../../css/vars';
import { midBreak } from '../../css/queries';

const useStyles = createUseStyles({
  blockContainer: {
    borderRadius: `${vars.borderRadius}px`,
    boxShadow: shadows.l,
    padding: base(2),
    margin: `0 ${base(-2)}`,
    width: `calc(100% + ${base(4)})`,
    backgroundColor: ({ dynamicColor }) => dynamicColor || undefined,
    ...midBreak({
      padding: base(0.5),
      margin: `0 ${base(-0.5)}`,
      width: `calc(100% + ${base(1)})`,
    }),
  },
  white: {
    backgroundColor: colors.white,
  },
  lighterGray: {
    backgroundColor: colors.lighterGray,
  },
});

const BlockContainer = (props) => {
  const { className, color, dynamicColor, children } = props;
  const classes = useStyles({ dynamicColor });

  return (
    <div
      className={[
        classes.blockContainer,
        classes[color],
        className,
      ].filter(Boolean).join(' ')}
    >
      {children && children}
    </div>
  );
};

BlockContainer.defaultProps = {
  className: '',
  color: 'lighterGray',
  dynamicColor: undefined,
  children: undefined,
};

BlockContainer.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'white',
    'lighterGray',
  ]),
  dynamicColor: PropTypes.string,
  children: PropTypes.node,
};

export default BlockContainer;
