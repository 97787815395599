import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  specSheets,
  signTemplates,
  signTemplateMeta,
  themes,
  mergeSpecs,
} from '@two90signs/290-renderer';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import OnboardingTooltip from '../../../components/OnboardingTooltip';
import { useActionConfirmation } from '../../../wrappers/ActionConfirmation';

import AccentStripColors from '../../../specControls/AccentStripColors';
import BackerShapes from '../../../specControls/BackerShapes';
import BackerFinishes from '../../../specControls/BackerFinishes';
import BackerPatterns from '../../../specControls/BackerPatterns';
import BackerPatternColors from '../../../specControls/BackerPatternColors';
import Button from '../../../components/Button';
import Color from '../../../forms/fields/Color';
import CloseIcon from '../../../icons/CloseIcon';
import Copy from '../../../specControls/Copy';
import EdgeRailColors from '../../../specControls/EdgeRailColors';
import EndCapColors from '../../../specControls/EndCapColors';
import EndCapStyles from '../../../specControls/EndCapStyles';
import FrameColors from '../../../specControls/FrameColors';
import BaseplateFinishes from '../../../specControls/BaseplateFinishes';
import InsertFinishes from '../../../specControls/InsertFinishes';
import InsertCopyColors from '../../../specControls/InsertCopyColors';
import InsertCopyStyles from '../../../specControls/InsertCopyStyles';
import InsertCopyCases from '../../../specControls/InsertCopyCases';
import InsertCopyPositions from '../../../specControls/InsertCopyPositions';
import InsertTypes from '../../../specControls/InsertTypes';
import MountingStyles from '../../../specControls/MountingStyles';
import Papers from '../../../specControls/Papers';
import SignSystems from '../../../specControls/SignSystems';
import SpecAccordion from '../../../specControls/SpecAccordion';
import SpecLink from '../../../specControls/SpecLink';
import Quantity from '../../../specControls/Quantity';

import useStyles from './css';
import { defaultWallColor } from '../../../css/vars';
import staticContent from '../../../staticContent';
import intToChar from '../../../utilities/charToInt';

const {
  insertTypes,
  signSystems,
  edgeRailColors,
  accentStripColors,
  endCapStyles,
  endCapColors,
  frameColors,
  baseplateFinishes,
  mountingStyles,
  insertFinishes,
  papers,
  copyColors,
  copyStyles,
  copyCases,
  copyPositions,
  backerShapes,
  backerFinishes,
  backerPatterns,
  backerPatternColors,
  laminates,
} = specSheets;

const laminateIDs = Object.keys(laminates);

const Sidebar = (props) => {
  const { confirmAction } = useActionConfirmation();
  const { url, path } = useRouteMatch();
  const { state } = useLocation();

  const {
    signState: {
      name: signName,
      quantity,
      specs: currentSpecs,
      templateID,
      wallColor,
      copy,
      deferCopy,
    },
    dispatchSignState,
    project: {
      wallColor: projectWallColor,
      theme,
      specs: themeSpecs,
    },
    selectedPreviewIndex,
    setSelectedPreviewIndex,
    mergedSpecs,
  } = props;

  const baseThemeSpecs = themes[theme];
  const mergedTheme = mergeSpecs(baseThemeSpecs, themeSpecs);

  const classes = useStyles();

  const {
    signSystem,
    edgeRailColor,
    accentStripColor,
    endCapStyle,
    endCapColor,
    frameColor,
    baseplateFinish,
    mountingStyle,
    inserts,
    backers,
    isDoubleSided,
  } = currentSpecs;

  const templateMeta = signTemplateMeta[templateID];
  // Note: check for alternate meta specs (based on sign system, see sign templates in '@two90signs/290-renderer')
  const templateMetaSpecs = templateMeta?.alternateSpecs?.[mergedSpecs.signSystem] || templateMeta.specs;

  const {
    mountingStyles: mountingStyleOptions,
    inserts: insertsMeta,
  } = templateMetaSpecs;

  const {
    signSystem: mergedSignSystem,
    inserts: mergedInserts,
    backers: mergedBackers,
  } = mergedSpecs;

  // Since every <Switch /> child needs to be a <Route />
  // mapping during render, and returning a <Fragment /> does not work.
  // Instead, generate an array of components and call the function during render
  // so that each <Route /> is top-level.
  const insertRoutes = useCallback(() => {
    const routes = [];

    if (Array.isArray(inserts) && inserts.length > 0) {
      inserts.forEach((insert, index) => {
        const insertMeta = insertsMeta?.[index] || {};
        const mergedInsert = mergedSpecs?.[index] || {};

        const {
          types: insertTypeOptions,
          copyPositions: copyPositionOptions,
        } = insertMeta;

        const {
          finish,
          copyColor,
          copyStyle,
          copyStyleADA,
          copyCase,
          copyPosition,
          type: insertType,
          paper,
          copyColorLP,
        } = insert;

        const headerPrefix = `Insert ${intToChar(index)} —`;

        routes.push(
          <Route
            key={`${path}/insert-${index + 1}/type`}
            path={`${path}/insert-${index + 1}/type`}
          >
            <InsertTypes
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'type',
                  specValue: incomingValue,
                },
              })}
              options={insertTypeOptions && insertTypeOptions.map((insertTypeID) => {
                const { label } = insertTypes[insertTypeID];
                return ({
                  id: insertTypeID,
                  label,
                });
              })}
              label="Insert Type"
              currentValue={insertType}
              headerLabel={`${headerPrefix} Type`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/finish`}
            path={`${path}/insert-${index + 1}/finish`}
          >
            <InsertFinishes
              signSystem={mergedSpecs.signSystem}
              insertType={mergedInsert.type}
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                sanitizerOptions: {
                  shouldSanitizeBaseplate: true,
                },
                payload: {
                  index,
                  specKey: 'finish',
                  specValue: incomingValue,
                },
              })}
              currentValue={finish}
              headerLabel={`${headerPrefix} Finish`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/paper`}
            path={`${path}/insert-${index + 1}/paper`}
          >
            <Papers
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'paper',
                  specValue: incomingValue,
                },
              })}
              currentValue={paper}
              headerLabel={`${headerPrefix} Paper`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/copy-color`}
            path={`${path}/insert-${index + 1}/copy-color`}
          >
            <InsertCopyColors
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'copyColor',
                  specValue: incomingValue,
                },
              })}
              currentValue={copyColor}
              headerLabel={`${headerPrefix} Copy Color`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/copy-color-lp`}
            path={`${path}/insert-${index + 1}/copy-color-lp`}
          >
            <InsertCopyColors
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'copyColorLP',
                  specValue: incomingValue,
                },
              })}
              currentValue={copyColorLP}
              headerLabel={`${headerPrefix} Copy Color`}
              noticeMessage="This is a window insert with paper and so the copy color is laser-printed."
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/copy-style`}
            path={`${path}/insert-${index + 1}/copy-style`}
          >
            <InsertCopyStyles
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'copyStyle',
                  specValue: incomingValue,
                },
              })}
              currentValue={copyStyle}
              headerLabel={`${headerPrefix} Copy Style`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/copy-style-ada`}
            path={`${path}/insert-${index + 1}/copy-style-ada`}
          >
            <InsertCopyStyles
              insertType="ADA"
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'copyStyleADA',
                  specValue: incomingValue,
                },
              })}
              currentValue={copyStyleADA}
              headerLabel={`${headerPrefix} Copy Style`}
              noticeMessage="This insert is ADA-compliant and only allows ADA-compliant fonts."
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/copy-case`}
            path={`${path}/insert-${index + 1}/copy-case`}
          >
            <InsertCopyCases
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'copyCase',
                  specValue: incomingValue,
                },
              })}
              currentValue={copyCase}
              insertType={insertType}
              headerLabel={`${headerPrefix} Copy Case`}
            />
          </Route>,
          <Route
            key={`${path}/insert-${index + 1}/copy-position`}
            path={`${path}/insert-${index + 1}/copy-position`}
          >
            <InsertCopyPositions
              backTo={url}
              specId={`insert-${index + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_INSERT',
                payload: {
                  index,
                  specKey: 'copyPosition',
                  specValue: incomingValue,
                },
              })}
              currentValue={copyPosition}
              options={copyPositionOptions && copyPositionOptions.map((copyPositionID) => {
                const { label } = copyPositions[copyPositionID];
                return ({
                  id: copyPositionID,
                  label,
                });
              })}
              headerLabel={`${headerPrefix} Copy Position`}
            />
          </Route>,
        );
      });
    }

    return routes;
  }, [url, path, inserts, insertsMeta, dispatchSignState, mergedSpecs]);

  const backerRoutes = useCallback(() => {
    const routes = [];

    if (Array.isArray(backers) && backers.length > 0) {
      backers.forEach((backer, backerIndex) => {
        const {
          shape,
          finish,
          pattern,
          patternColor,
        } = backer;

        const {
          finish: themeFinish,
          pattern: themePattern,
        } = mergedTheme.backers?.[backerIndex] || {};

        // note: lock down backers[1] shape options to match the backers[0] so as to keep with the "essentials" package (i.e. Arch, Double Arch, Curve, Double Curve, etc)
        // note: backer auto-positioning is also necessary to keep with essentials package, see 'sanitizeSpecs' in '@two90signs/290-renderer'
        const shapeOptions = [];
        if (backerIndex === 0) {
          Object.entries(backerShapes).forEach(([backerID, backerEntry]) => {
            shapeOptions.push({ ...backerEntry, id: backerID });
          });
        } else {
          const mergedBacker1Shape = mergedBackers?.[0]?.shape;
          if (mergedBacker1Shape && mergedBacker1Shape !== 'none') {
            const { label } = backerShapes[mergedBacker1Shape];
            shapeOptions.push({ id: mergedBacker1Shape, label });
          }
        }

        // note: flare on backer 1 is not available with klik signs
        if (mergedSignSystem === 'K') {
          const indexOfFlare = shapeOptions.findIndex((item) => item.id === 'F');
          if (indexOfFlare > -1) shapeOptions.splice(indexOfFlare, 1);
        }

        const headerPrefix = `Backer ${backerIndex + 1}  —`;

        routes.push(
          <Route
            key={`${path}/backer-${backerIndex + 1}/shape`}
            path={`${path}/backer-${backerIndex + 1}/shape`}
          >
            <BackerShapes
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(incomingValue) => dispatchSignState({
                type: 'UPDATE_BACKER',
                payload: {
                  index: backerIndex,
                  specs: {
                    shape: incomingValue,
                  },
                },
              })}
              options={shapeOptions}
              currentValue={shape}
              headerLabel={`${headerPrefix} Shape`}
            />
          </Route>,
          <Route
            key={`${path}/backer-${backerIndex + 1}/finish`}
            path={`${path}/backer-${backerIndex + 1}/finish`}
          >
            <BackerFinishes
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(incomingValue) => {
                const specsToUpdate = { finish: incomingValue };

                let shouldConfirmAction = false; // prevent finishes in combination with patterns
                if (incomingValue !== 'none') {
                  if ((pattern && pattern !== 'none') || (!pattern && themePattern && themePattern !== 'none')) {
                    shouldConfirmAction = true;
                  }
                  specsToUpdate.pattern = 'none'; // IMPORTANT: set to 'none' (not 'undefined') to prevent incompatible theme inheritances
                }

                const action = () => dispatchSignState({
                  type: 'UPDATE_BACKER',
                  payload: {
                    index: backerIndex,
                    specs: specsToUpdate,
                  },
                });

                if (shouldConfirmAction) {
                  confirmAction({
                    message: (
                      <Fragment>
                        <div>
                          <b>
                            Backers cannot have both a finish and a pattern at the same time.
                          </b>
                        </div>
                        <div>
                          {'Changing this backer\'s finish to '}
                          <b>
                            {`${incomingValue} ${backerFinishes[incomingValue]?.label}`}
                          </b>
                          {' will also remove it\'s '}
                          <b>
                            {`${backerPatterns[pattern || themePattern]?.label}`}
                          </b>
                          {' pattern.'}
                        </div>
                      </Fragment>
                    ),
                    action,
                  });
                } else action();
              }}
              currentValue={finish}
              headerLabel={`${headerPrefix} Finish`}
              signSystem={mergedSignSystem}
              backerIndex={backerIndex}
            />
          </Route>,
          <Route
            key={`${path}/backer-${backerIndex + 1}/pattern`}
            path={`${path}/backer-${backerIndex + 1}/pattern`}
          >
            <BackerPatterns
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(incomingValue) => {
                const specsToUpdate = { pattern: incomingValue };

                let shouldConfirmAction = false; // prevent patterns in combination with finishes
                if (incomingValue !== 'none') {
                  if ((finish && finish !== 'none') || (!finish && themeFinish && themeFinish !== 'none')) {
                    shouldConfirmAction = true;
                  }
                  specsToUpdate.finish = 'none'; // IMPORTANT: set to 'none' (not 'undefined') to prevent incompatible theme inheritances
                }

                const action = () => dispatchSignState({
                  type: 'UPDATE_BACKER',
                  payload: {
                    index: backerIndex,
                    specs: specsToUpdate,
                  },
                });

                if (shouldConfirmAction) {
                  confirmAction({
                    message: (
                      <Fragment>
                        <div>
                          <b>
                            Backers cannot have both a finish and a pattern at the same time.
                          </b>
                        </div>
                        <div>
                          {'Changing this backer\'s pattern to '}
                          <b>
                            {backerPatterns[incomingValue]?.label}
                          </b>
                          {' will also remove it\'s '}
                          <b>
                            {`${finish || themeFinish} ${backerFinishes[finish || themeFinish]?.label}`}
                          </b>
                          {' finish.'}
                        </div>
                      </Fragment>
                    ),
                    action,
                  });
                } else action();
              }}
              currentValue={pattern}
              headerLabel={`${headerPrefix} Pattern`}
            />
          </Route>,
          <Route
            key={`${path}/backer-${backerIndex + 1}/pattern-color`}
            path={`${path}/backer-${backerIndex + 1}/pattern-color`}
          >
            <BackerPatternColors
              backTo={url}
              specId={`backer-${backerIndex + 1}`}
              onChange={(incomingValue) => {
                dispatchSignState({
                  type: 'UPDATE_BACKER',
                  payload: {
                    index: backerIndex,
                    specs: {
                      patternColor: incomingValue,
                    },
                  },
                });
              }}
              currentValue={patternColor}
              headerLabel={`${headerPrefix} Pattern Color`}
            />
          </Route>,
        );
      });
    }

    return routes;
  }, [url, path, backers, dispatchSignState, confirmAction, mergedTheme, mergedBackers, mergedSignSystem]);

  return (
    <div className={classes.sidebar}>
      <Switch>
        <Route path={`${path}/sign-system`}>
          <SignSystems
            backTo={url}
            currentValue={signSystem}
            onChange={(incomingValue) => {
              const action = () => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'signSystem',
                  specValue: incomingValue,
                },
              });

              const shouldConfirm = incomingValue !== (mergedSignSystem);

              if (shouldConfirm) {
                confirmAction({
                  message: staticContent.signSystemChangeMessage,
                  confirmLabel: 'Ok',
                  action,
                });
              } else action();
            }}
          />
        </Route>
        <Route path={`${path}/edge-rail-color`}>
          <EdgeRailColors
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'edgeRailColor',
                specValue: incomingValue,
              },
            })}
            currentValue={edgeRailColor}
          />
        </Route>
        <Route path={`${path}/accent-strip-color`}>
          <AccentStripColors
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'accentStripColor',
                specValue: incomingValue,
              },
            })}
            currentValue={accentStripColor}
          />
        </Route>
        <Route path={`${path}/end-cap-style`}>
          <EndCapStyles
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'endCapStyle',
                specValue: incomingValue,
              },
            })}
            currentValue={endCapStyle}
          />
        </Route>
        <Route path={`${path}/end-cap-color`}>
          <EndCapColors
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'endCapColor',
                specValue: incomingValue,
              },
            })}
            currentValue={endCapColor}
          />
        </Route>
        {/* <Route path={`${path}/trim-color`}>
          <TrimColors
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'trimColor',
                specValue: incomingValue,
              },
            })}
            currentValue={trimColor}
          />
        </Route> */}
        <Route path={`${path}/frame-color`}>
          <FrameColors
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'frameColor',
                specValue: incomingValue,
              },
            })}
            currentValue={frameColor}
          />
        </Route>
        <Route path={`${path}/baseplate-finish`}>
          <BaseplateFinishes
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'baseplateFinish',
                specValue: incomingValue,
              },
            })}
            currentValue={baseplateFinish}
          />
        </Route>
        <Route path={`${path}/mounting-style`}>
          <MountingStyles
            backTo={url}
            onChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'mountingStyle',
                specValue: incomingValue,
              },
            })}
            options={mountingStyleOptions && mountingStyleOptions.map((mountingStyleID) => {
              const { label } = mountingStyles[mountingStyleID];
              return ({
                id: mountingStyleID,
                label,
              });
            })}
            currentValue={mountingStyle}
          />
        </Route>
        {insertRoutes()}
        {backerRoutes()}
        <Route path={`${path}/copy`}>
          <Copy
            backTo={url}
            numberOfSigns={quantity}
            insertsMeta={insertsMeta}
            onCopyChange={({
              signIndex,
              insertIndex,
              lineIndex,
              specs,
            }) => {
              dispatchSignState({
                type: 'UPDATE_LINE',
                payload: {
                  signIndex,
                  insertIndex,
                  lineIndex,
                  specs,
                },
              });
            }}
            onDeferCopyChange={(incomingValue) => dispatchSignState({
              type: 'UPDATE_SIGN',
              payload: { deferCopy: incomingValue },
            })}
            currentCopy={copy}
            currentDeferCopy={deferCopy}
            selectedPreviewIndex={selectedPreviewIndex}
            setSelectedPreviewIndex={setSelectedPreviewIndex}
          />
        </Route>
        <Route>
          <header className={classes.header}>
            <div style={{ position: 'relative' }}>
              <OnboardingTooltip
                position="topLeft"
                contentPosition="left"
                title="Change your mind?"
                message="Reset individual sign specs to match the base project specifications."
              />
              <Button
                label="Reset"
                icon="reset"
                color="lightGray"
                onClick={() => {
                  confirmAction({
                    message: (
                      <div>
                        {'Are you sure you want to reset '}
                        <b>{signName || 'this sign'}</b>
                        {' to match the base specifications? This action'}
                        <b>
                          {' cannot '}
                        </b>
                        be undone.
                      </div>
                    ),
                    cancelLabel: 'No, thank you!',
                    confirmLabel: 'Yes, please!',
                    action: () => dispatchSignState({
                      type: 'RESET_SIGN',
                    }),
                  });
                }}
                htmlAttributes={{
                  type: 'button',
                }}
              />
            </div>
            <div className={classes.wallColor}>
              <Color
                id="wallColor"
                name="wallColor"
                label="Wall color"
                currentValue={wallColor || projectWallColor || defaultWallColor}
                onChange={(incomingValue) => dispatchSignState({
                  type: 'UPDATE_SIGN',
                  payload: {
                    wallColor: incomingValue,
                  },
                })}
              />
              {wallColor && (
                <div
                  className={classes.wallColorClear}
                  onClick={() => dispatchSignState({
                    type: 'UPDATE_SIGN',
                    payload: { wallColor: undefined },
                  })}
                  onKeyPress={() => dispatchSignState({
                    type: 'UPDATE_SIGN',
                    payload: { wallColor: undefined },
                  })}
                  role="button"
                  tabIndex={0}
                >
                  <CloseIcon
                    color="gray"
                    size="s"
                  />
                </div>
              )}
            </div>
          </header>
          <div style={{ position: 'relative' }}>
            <OnboardingTooltip
              position="topLeft"
              contentPosition="left"
              title="Specs can be overridden"
              message="When a spec has an active override, you'll see an “X” on the right side of the label. Click the “X” to remove the override and inherit the base spec once again."
            />
            <SpecLink
              to={`${url}/sign-system`}
              label="Sign System"
              currentValue={signSystem}
              currentLabel={signSystems[signSystem]?.label}
              themeValue={mergedTheme.signSystem}
              themeLabel={signSystems[mergedTheme.signSystem]?.label}
              onClear={() => {
                const action = () => dispatchSignState({
                  type: 'UPDATE_SPEC',
                  payload: {
                    specKey: 'signSystem',
                    specValue: undefined,
                  },
                });

                const shouldConfirm = signSystem !== mergedTheme?.signSystem;

                if (shouldConfirm) {
                  confirmAction({
                    message: staticContent.signSystemChangeMessage,
                    confirmLabel: 'Ok',
                    action,
                  });
                } else action();
              }}
            />
          </div>
          {mergedSpecs.signSystem === 'A' && (
            <SpecLink
              to={`${url}/edge-rail-color`}
              label="Edge Rail Color"
              currentValue={edgeRailColor}
              currentLabel={`${edgeRailColor} ${edgeRailColors[edgeRailColor]?.label}`}
              themeValue={mergedTheme.edgeRailColor}
              themeLabel={`${mergedTheme.edgeRailColor} ${edgeRailColors[mergedTheme.edgeRailColor]?.label}`}
              onClear={() => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'edgeRailColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {mergedSpecs.signSystem === 'A' && inserts.length > 1 && (
            <SpecLink
              to={`${url}/accent-strip-color`}
              label="Accent Strip Color"
              currentValue={accentStripColor}
              currentLabel={`${accentStripColor} ${accentStripColors[accentStripColor]?.label}`}
              themeValue={mergedTheme.accentStripColor}
              themeLabel={`${mergedTheme.accentStripColor} ${accentStripColors[mergedTheme.accentStripColor]?.label}`}
              onClear={() => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'accentStripColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {mergedSpecs.signSystem === 'S' && (
            <SpecLink
              to={`${url}/end-cap-style`}
              label="End Cap Style"
              currentValue={endCapStyle}
              currentLabel={endCapStyles[endCapStyle]?.label}
              themeValue={mergedTheme.endCapStyle}
              themeLabel={endCapStyles[mergedTheme.endCapStyle]?.label}
              onClear={() => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'endCapStyle',
                  specValue: undefined,
                },
              })}
            />
          )}
          {mergedSpecs.signSystem === 'S' && (
            <SpecLink
              to={`${url}/end-cap-color`}
              label="End Cap Color"
              currentValue={endCapColor}
              currentLabel={`${endCapColor} ${endCapColors[endCapColor]?.label}`}
              themeValue={mergedTheme.endCapColor}
              themeLabel={`${mergedTheme.endCapColor} ${endCapColors[mergedTheme.endCapColor]?.label}`}
              onClear={() => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'endCapColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {mergedSpecs.signSystem === 'A' && (
            <SpecLink
              to={`${url}/frame-color`}
              label="Frame Color"
              currentValue={frameColor}
              currentLabel={`${frameColor} ${frameColors[frameColor]?.label}`}
              themeValue={mergedTheme.frameColor}
              themeLabel={`${mergedTheme.frameColor} ${frameColors[mergedTheme.frameColor]?.label}`}
              onClear={() => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'frameColor',
                  specValue: undefined,
                },
              })}
            />
          )}
          {Array.isArray(inserts) && inserts.length > 0
            && inserts.map((insert, insertIndex) => {
              const {
                type: insertType,
                finish,
                paper,
                copyColor,
                copyColorLP,
                copyStyle,
                copyStyleADA,
                copyCase,
                copyPosition,
                inheritIndex,
              } = insert;

              const insertMeta = insertsMeta?.[insertIndex] || {};

              const {
                types: insertTypeOptions,
                copyPositions: copyPositionOptions,
              } = insertMeta;

              const themeIndexToInherit = typeof inheritIndex === 'number' ? inheritIndex : insertIndex;

              const {
                type: themeInsertType,
                finish: themeFinish,
                paper: themePaper,
                copyColor: themeCopyColor,
                copyColorLP: themeCopyColorLP,
                copyStyle: themeCopyStyle,
                copyStyleADA: themeADACopyStyle,
                copyCase: themeCopyCase,
                copyPosition: themeCopyPosition,
              } = mergedTheme.inserts?.[themeIndexToInherit] || {};

              const mergedInsert = mergedInserts?.[insertIndex] || {};

              let canUseFinish = true;
              if (mergedInsert.type === 'WI') {
                if (mergedSignSystem === 'A') canUseFinish = false; // there is no visible window frames for Arc signs
              }
              return (
                <SpecAccordion
                  openOnInit={Boolean(state ? state.open === `insert-${insertIndex + 1}` : false)}
                  key={insertIndex}
                  label={`Insert ${intToChar(insertIndex)}`}
                  list={[
                    ...insertTypeOptions ? [{
                      type: 'link',
                      label: 'Type',
                      currentValue: insertType,
                      currentLabel: insertTypes[insertType]?.label,
                      themeValue: themeInsertType,
                      themeLabel: insertTypes[themeInsertType]?.label,
                      to: `${url}/insert-${insertIndex + 1}/type`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'type',
                          specValue: undefined,
                        },
                      }),
                    }] : [],
                    // Medium and large slide window inserts are only ever black
                    // Arc window inserts do not have frame edges
                    ...canUseFinish ? [{
                      type: 'link',
                      label: 'Finish',
                      currentValue: finish,
                      currentLabel: `${finish} ${insertFinishes[finish]?.label}`,
                      themeValue: themeFinish,
                      themeLabel: `${themeFinish} ${insertFinishes[themeFinish]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/finish`,
                      // Window inserts cannot be laminates, and cannot be 101-SA for slide only
                      disableClear: insertType === 'WI' && ((mergedSignSystem !== 'S' && themeFinish === '101-SA') || Boolean(laminateIDs.find((item) => item === themeFinish))),
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'finish',
                          specValue: undefined,
                        },
                      }),
                    }] : [],
                    ...mergedInsert.type === 'WI' ? [
                      {
                        type: 'link',
                        label: 'Paper',
                        currentValue: paper,
                        currentLabel: `${paper} ${papers[paper]?.label}`,
                        themeValue: themePaper,
                        themeLabel: `${themePaper} ${papers[themePaper]?.label}`,
                        to: `${url}/insert-${insertIndex + 1}/paper`,
                        onClear: () => dispatchSignState({
                          type: 'UPDATE_INSERT',
                          payload: {
                            index: insertIndex,
                            specKey: 'paper',
                            specValue: undefined,
                          },
                        }),
                      }] : [],
                    ...mergedInsert.type !== 'WI' ? [{
                      type: 'link',
                      label: 'Copy Color',
                      currentValue: copyColor,
                      currentLabel: `${copyColor} ${copyColors[copyColor]?.label}`,
                      themeValue: themeCopyColor,
                      themeLabel: `${themeCopyColor} ${copyColors[themeCopyColor]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/copy-color`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyColor',
                          specValue: undefined,
                        },
                      }),
                    }] : [{
                      type: 'link',
                      label: 'Copy Color',
                      currentValue: copyColorLP,
                      currentLabel: `${copyColorLP} ${copyColors[copyColorLP]?.label}`,
                      themeValue: themeCopyColorLP,
                      themeLabel: `${themeCopyColorLP} ${copyColors[themeCopyColorLP]?.label}`,
                      to: `${url}/insert-${insertIndex + 1}/copy-color-lp`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyColorLP',
                          specValue: undefined,
                        },
                      }),
                    }],
                    ...mergedInsert.type !== 'ADA' ? [{
                      type: 'link',
                      label: 'Copy Style',
                      currentValue: copyStyle,
                      currentLabel: copyStyles[copyStyle]?.label,
                      themeValue: themeCopyStyle,
                      themeLabel: copyStyles[themeCopyStyle]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-style`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyStyle',
                          specValue: undefined,
                        },
                      }),
                    }] : [{
                      type: 'link',
                      label: 'Copy Style',
                      currentValue: copyStyleADA,
                      currentLabel: copyStyles[copyStyleADA]?.label,
                      themeValue: themeADACopyStyle,
                      themeLabel: copyStyles[themeADACopyStyle]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-style-ada`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyStyleADA',
                          specValue: undefined,
                        },
                      }),
                    }],
                    ...mergedInsert.type !== 'ADA' ? [{
                      type: 'link',
                      label: 'Copy Case',
                      currentValue: copyCase,
                      currentLabel: copyCases[copyCase]?.label,
                      themeValue: themeCopyCase,
                      themeLabel: copyCases[themeCopyCase]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-case`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyCase',
                          specValue: undefined,
                        },
                      }),
                    }] : [],
                    ...copyPositionOptions ? [{
                      type: 'link',
                      label: 'Copy Position',
                      currentValue: copyPosition,
                      currentLabel: copyPositions[copyPosition]?.label,
                      themeValue: themeCopyPosition,
                      themeLabel: copyPositions[themeCopyPosition]?.label,
                      to: `${url}/insert-${insertIndex + 1}/copy-position`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_INSERT',
                        payload: {
                          index: insertIndex,
                          specKey: 'copyPosition',
                          specValue: undefined,
                        },
                      }),
                    }] : [],
                  ]}
                />
              );
            })}
          {mergedSpecs.signSystem === 'K' && (
            <SpecLink
              to={`${url}/baseplate-finish`}
              label="Baseplate Finish"
              currentValue={baseplateFinish}
              currentLabel={`${baseplateFinish} ${baseplateFinishes[baseplateFinish]?.label}`}
              themeValue={mergedTheme.baseplateFinish}
              themeLabel={`${mergedTheme.baseplateFinish} ${baseplateFinishes[mergedTheme.baseplateFinish]?.label}`}
              onClear={() => dispatchSignState({
                type: 'UPDATE_SPEC',
                payload: {
                  specKey: 'baseplateFinish',
                  specValue: undefined,
                },
              })}
            />
          )}
          {!isDoubleSided && Array.isArray(backers) && backers.length > 0
            && backers.map((backer, backerIndex) => {
              const {
                shape,
                finish,
                pattern,
                patternColor,
              } = backer;

              const {
                shape: themeShape,
                finish: themeFinish,
                pattern: themePattern,
                patternColor: themePatternColor,
              } = mergedTheme.backers?.[backerIndex] || {};

              const mergedBacker = mergedBackers?.[backerIndex] || {};
              const mergedBacker1Shape = backers[0].shape || mergedTheme.backers?.[0]?.shape;
              let themeShapeIsCompatible = false;
              if (backerIndex === 0) themeShapeIsCompatible = !(mergedSignSystem === 'K' && themeShape === 'F'); // Note: prevent klik signs from resetting to flare backer shape, see 'sanitizeSpecs' in '@two90signs/290-renderer'
              if (backerIndex > 0 && (!themeShape || themeShape === 'none' || themeShape === mergedBacker1Shape)) themeShapeIsCompatible = true;

              return (
                <SpecAccordion
                  key={backerIndex}
                  openOnInit={Boolean(state ? state.open === `backer-${backerIndex + 1}` : false)}
                  label={`Backer ${backerIndex + 1}`}
                  list={[
                    {
                      type: 'link',
                      label: 'Shape',
                      currentValue: shape,
                      currentLabel: shape === 'none' ? 'None' : backerShapes[shape]?.label,
                      themeValue: themeShape,
                      themeLabel: themeShape === 'none' ? 'None' : backerShapes[themeShape]?.label,
                      to: `${url}/backer-${backerIndex + 1}/shape`,
                      specId: `backer-${backerIndex + 1}`,
                      onClear: () => dispatchSignState({
                        type: 'UPDATE_BACKER',
                        payload: {
                          index: backerIndex,
                          specs: {
                            shape: undefined,
                          },
                        },
                      }),
                      // Prevent themeSpecs inheritance from pairing two non-compatible backers, see also 'sanitizeSpecs' from '@two90signs/290-renderer'
                      disableClear: !themeShapeIsCompatible,
                    },
                    ...(mergedBacker.shape !== 'none') ? [
                      {
                        type: 'link',
                        label: 'Finish',
                        currentValue: finish,
                        currentLabel: finish === 'none' ? 'None' : `${finish} ${backerFinishes[finish]?.label}`,
                        themeValue: themeFinish,
                        themeLabel: themeFinish === 'none' ? 'None' : `${themeFinish} ${backerFinishes[themeFinish]?.label}`,
                        to: `${url}/backer-${backerIndex + 1}/finish`,
                        disableClear: finish === 'none' && pattern && pattern !== 'none',
                        onClear: () => {
                          const specsToUpdate = { finish: undefined };

                          let shouldResetPattern = false;
                          const hasThemeFinish = themeFinish && themeFinish !== 'none';
                          const hasLocalPattern = pattern && pattern !== 'none';
                          if (hasLocalPattern && hasThemeFinish) {
                            shouldResetPattern = true; // prevent finishes in combination with patterns
                            // never set to 'undefined' here, that would require the theme reducer to map all signs and make potential adjustments
                            // but the theme reducer should only ever maintain theme specs, not sign specs
                            specsToUpdate.pattern = 'none';
                          }

                          const action = () => dispatchSignState({
                            type: 'UPDATE_BACKER',
                            payload: {
                              index: backerIndex,
                              specs: specsToUpdate,
                            },
                          });

                          if (shouldResetPattern) {
                            confirmAction({
                              message: (
                                <Fragment>
                                  <div>
                                    <b>
                                      Backers cannot have both a finish and a pattern at the same time.
                                    </b>
                                  </div>
                                  <div>
                                    {'Resetting this backer\'s finish to '}
                                    <b>
                                      {`${themeFinish} ${backerFinishes[themeFinish]?.label}`}
                                    </b>
                                    {' will also remove it\'s '}
                                    <b>
                                      {`${backerPatterns[pattern]?.label}`}
                                    </b>
                                    {' pattern.'}
                                  </div>
                                </Fragment>
                              ),
                              action,
                              confirmLabel: 'Ok',
                            });
                          } else action();
                        },
                      }, {
                        type: 'link',
                        label: 'Pattern',
                        currentValue: pattern,
                        currentLabel: pattern === 'none' ? 'None' : backerPatterns[pattern]?.label,
                        themeValue: themePattern,
                        themeLabel: themePattern === 'none' ? 'None' : backerPatterns[themePattern]?.label,
                        to: `${url}/backer-${backerIndex + 1}/pattern`,
                        disableClear: pattern === 'none' && finish && finish !== 'none',
                        onClear: () => {
                          const specsToUpdate = { pattern: undefined };

                          let shouldResetFinish = false;
                          const hasThemePattern = themePattern && themePattern !== 'none';
                          const hasLocalFinish = finish && finish !== 'none';
                          if (hasLocalFinish && hasThemePattern) {
                            shouldResetFinish = true; // prevent finishes in combination with patterns
                            // never set to 'undefined' here, that would require the theme reducer to map all signs and make potential adjustments
                            // but the theme reducer should only ever maintain theme specs, not sign specs
                            specsToUpdate.finish = 'none';
                          }

                          const action = () => dispatchSignState({
                            type: 'UPDATE_BACKER',
                            payload: {
                              index: backerIndex,
                              specs: specsToUpdate,
                            },
                          });

                          if (shouldResetFinish) {
                            confirmAction({
                              message: (
                                <Fragment>
                                  <div>
                                    <b>
                                      Backers cannot have both a finish and a pattern at the same time.
                                    </b>
                                  </div>
                                  <div>
                                    {'Resetting this backer\'s pattern to '}
                                    <b>
                                      {backerPatterns[themePattern]?.label}
                                    </b>
                                    {' will also remove it\'s '}
                                    <b>
                                      {`${finish} ${backerFinishes[finish]?.label}`}
                                    </b>
                                    {' finish.'}
                                  </div>
                                </Fragment>
                              ),
                              action,
                              confirmLabel: 'Ok',
                            });
                          } else action();
                        },
                      },
                      backerPatterns[pattern]?.grayScale // hide color options on grayscale backers
                        ? {}
                        : {
                          type: 'link',
                          label: 'Pattern Color',
                          currentValue: patternColor,
                          currentLabel: `${patternColor} ${backerPatternColors[patternColor]?.label}`,
                          themeValue: themePatternColor,
                          themeLabel: `${themePatternColor} ${backerPatternColors[themePatternColor]?.label}`,
                          to: `${url}/backer-${backerIndex + 1}/pattern-color`,
                          onClear: () => dispatchSignState({
                            type: 'UPDATE_BACKER',
                            payload: {
                              index: backerIndex,
                              specs: {
                                patternColor: undefined,
                              },
                            },
                          }),
                        },
                    ] : [],
                  ]}
                />
              );
            })}
          <SpecLink
            to={`${url}/mounting-style`}
            label="Mounting Style"
            currentValue={mountingStyle}
            currentLabel={mountingStyles[mountingStyle]?.label}
            themeValue={mergedTheme.mountingStyle}
            themeLabel={mountingStyles[mergedTheme.mountingStyle]?.label}
            onClear={() => dispatchSignState({
              type: 'UPDATE_SPEC',
              payload: {
                specKey: 'mountingStyle',
                specValue: undefined,
              },
            })}
          />
          <div style={{ position: 'relative' }}>
            <OnboardingTooltip
              position="topLeft"
              contentPosition="left"
              title="Adjust quantity"
              message="Choose as many copies of this sign as you need, each with its own personalization. If the same sign type needs a different mounting option, add sign type again. If you desire an individual sign drawing for every sign, add sign type again verses increasing quantity."
            />
            <Quantity
              onChange={(incomingQuantity) => {
                const newCopy = [...copy];

                // maintain old copy (to save progress if the user decreases quantity then change their mind)
                // if not yet saved, extract fresh copy from the sign template for the new index
                const quantityAsIndex = incomingQuantity - 1;
                const hasExistingCopy = Boolean(copy[quantityAsIndex]);

                if (!hasExistingCopy) {
                  const signTemplate = signTemplates.find((item) => item.templateID === templateID);

                  const {
                    specs: templateSpecs,
                    alternateSpecs: alternateTemplateSpecs,
                  } = signTemplate;

                  const specsToUse = alternateTemplateSpecs?.[mergedSpecs.signSystem] || templateSpecs;

                  const { inserts: insertsFromTemplate } = specsToUse;

                  if (Array.isArray(insertsFromTemplate) && insertsFromTemplate.length > 0) {
                    newCopy[quantityAsIndex] = {
                      inserts: [],
                    };
                    insertsFromTemplate.forEach((insert) => {
                      const { lines } = insert;
                      newCopy[quantityAsIndex].inserts.push({
                        lines: [...lines], // make a copy
                      });
                    });
                  }
                }

                const currentPreviewIsInScope = selectedPreviewIndex <= quantityAsIndex;
                if (!currentPreviewIsInScope) { setSelectedPreviewIndex(quantityAsIndex); }

                dispatchSignState({
                  type: 'UPDATE_SIGN',
                  payload: {
                    quantity: incomingQuantity,
                    copy: newCopy,
                  },
                });
              }}
              currentValue={quantity}
              min={1}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <OnboardingTooltip
              position="topLeft"
              contentPosition="left"
              title="Personalize each sign"
              message="Each sign can have its own copy, copy-size, symbol, and/or room numbers based on the type of sign. If you desire to have a sign drawing with each individual name, add another sign vs increasing quantity."
            />
            <SpecLink
              to={`${url}/copy`}
              label="Copy"
              currentValue="Edit sign copy"
            />
          </div>
        </Route>
      </Switch>
    </div>
  );
};

Sidebar.defaultProps = {
  project: undefined,
  signState: undefined,
  dispatchSignState: undefined,
  selectedPreviewIndex: undefined,
  setSelectedPreviewIndex: undefined,
  mergedSpecs: undefined,
};

Sidebar.propTypes = {
  project: PropTypes.shape({
    wallColor: PropTypes.string,
    theme: PropTypes.string,
    specs: PropTypes.shape({}),
  }),
  signState: PropTypes.shape({
    quantity: PropTypes.number,
    name: PropTypes.string,
    templateID: PropTypes.string,
    prices: PropTypes.shape({
      signOnly: PropTypes.number,
    }),
    wallColor: PropTypes.string,
    specs: PropTypes.shape({
      signSystem: PropTypes.string,
      edgeRailColor: PropTypes.string,
      accentStripColor: PropTypes.string,
      endCapStyle: PropTypes.string,
      endCapColor: PropTypes.string,
      frameColor: PropTypes.string,
      baseplateFinish: PropTypes.string,
      mountingStyle: PropTypes.string,
      isDoubleSided: PropTypes.bool,
      inserts: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        }),
      ),
      backers: PropTypes.arrayOf(
        PropTypes.shape({
          shape: PropTypes.string,
          pattern: PropTypes.string,
          finish: PropTypes.string,
        }),
      ),
    }),
    copy: PropTypes.arrayOf(
      PropTypes.shape({
        inserts: PropTypes.arrayOf(
          PropTypes.shape({
            copy: PropTypes.string,
          }),
        ),
      }),
    ),
    deferCopy: PropTypes.bool,
  }),
  dispatchSignState: PropTypes.func,
  selectedPreviewIndex: PropTypes.number,
  setSelectedPreviewIndex: PropTypes.func,
  mergedSpecs: PropTypes.shape({
    type: PropTypes.string,
    signSystem: PropTypes.string,
    enableAccentStrip: PropTypes.bool,
    enableTrim: PropTypes.bool,
    inserts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      }),
    ),
    backers: PropTypes.arrayOf(
      PropTypes.shape({
        shape: PropTypes.string,
        pattern: PropTypes.string,
        finish: PropTypes.string,
      }),
    ),
  }),
};

export default Sidebar;
